import { Box } from '@material-ui/core'
import { Printer } from 'react-feather'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngSecondaryButton }
} = components

function ShowCountComponent(props) {
  return (
    <span className={`summary-count-label-wrapper`}>
      <label>
        Showing {props.shownCount} out of {props.totalCount} results
      </label>
    </span>
  )
}

export default ShowCountComponent
