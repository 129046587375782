import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { Copy, Trash } from 'react-feather' //Can use this icon (matches wireframe better) but icon is very small
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import IconButton from '@material-ui/core/IconButton'
import { TranslateSharp } from '@material-ui/icons'

const {
  form: {
    field: { CngTextField, CngSelectField, CngDateField }
  },
  CngGridItem
} = components

const {
  component: {
    textField: {
      customType: { PLAIN_NUMBER }
    }
  }
} = constants

const { CngPackageUomAutocompleteField, CngCodeMasterAutocompleteField } =
  components.form.field

const SICargoDetailsComponent = (props) => {
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  return props.list.map((m, idx) => (
    <Grid container xs={12} sm={12} spacing={2} key={m.id}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box>
            <Grid container className='accordian-header'>
              <CngGridItem xs={6} sm={6}>
                <Box p={1} fontSize='large' fontWeight='bolder'>
                  {siTranslatedTextsObject.cargo + ' ' + (idx + 1)}
                </Box>
              </CngGridItem>
              <Grid
                container
                xs={6}
                sm={6}
                p={1}
                justify='flex-end'
                alignItems='center'
              >
                {!props.isCarrier && (
                  <Grid xs={1} sm={1} container>
                    <IconButton
                      aria-label='copy'
                      onClick={props.copyRowClick(idx)}
                      style={{
                        backgroundColor: 'white',
                        width: '35px',
                        height: '35px',
                        borderRadius: '20%'
                      }}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Grid>
                )}

                {!props.isCarrier && (
                  <Grid xs={1} sm={1} container>
                    <IconButton
                      aria-label='remove'
                      onClick={props.removeRowClick(idx)}
                      style={{
                        backgroundColor: 'white',
                        width: '35px',
                        height: '35px',
                        borderRadius: '20%',
                        display: props.list.length > 1 ? 'flex' : 'none'
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
                <Divider orientation='vertical' flexItem />
                <Box p={2} fontSize='small' fontWeight='bold'>
                  {siTranslatedTextsObject.cargoId +
                    ': ' +
                    (m.cargoId == null ? 'N/A' : m.cargoId)}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Accordion expanded={m.cargoBoxExpanded}>
            <AccordionSummary
              aria-controls='contactDetailsComponentContent'
              id='contactDetailsComponentHeader'
            >
              <Grid container xs={12} sm={12} spacing={2}>
                <CngGridItem xs={6} sm={6}>
                  <CngTextField
                    type='text'
                    name={`shippingInfoCargoes[${idx}].description`}
                    label={siTranslatedTextsObject.cargoDesc}
                    value={m.description}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 2970 }}
                    onChange={(e) => props.handleInputChange(e, idx)}
                    isRequired
                  />
                </CngGridItem>
                <CngGridItem xs={6} sm={6}>
                  <CngTextField
                    type='text'
                    name={`shippingInfoCargoes[${idx}].marksAndNumbers`}
                    label={siTranslatedTextsObject.marksNos}
                    value={m.marksAndNumbers}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 350 }}
                    onChange={(e) => props.handleInputChange(e, idx)}
                    disabled={props.isCarrier}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={12}>
                  <CngSelectField
                    name={`shippingInfoCargoes[${idx}].containerNo`}
                    label={siTranslatedTextsObject.relatedContainerNos}
                    multiple
                    items={props.containerNoItems}
                    onChange={(e) => props.handleInputChange(e, idx)}
                  />
                </CngGridItem>
              </Grid>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container xs={12} sm={12} spacing={2}>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='number'
                    name={`shippingInfoCargoes[${idx}].quantity`}
                    label={siTranslatedTextsObject.quantity}
                    value={m.quantity}
                    inputProps={{ maxLength: 5 }}
                    onChange={(e) => props.handleInputChange(e, idx)}
                    customType={PLAIN_NUMBER}
                    disabled={props.isCarrier}
                  />
                </CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    name={`shippingInfoCargoes[${idx}].hsCode`}
                    label={siTranslatedTextsObject.hsCode}
                    type='text'
                    value={m.hsCode}
                    inputProps={{ maxLength: 16 }}
                    onChange={(e) => props.handleInputChange(e, idx)}
                    disabled={props.isCarrier}
                  />
                </CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngPackageUomAutocompleteField
                    name={`shippingInfoCargoes[${idx}].packageType`}
                    label={siTranslatedTextsObject.packageType}
                    onChange={(val) =>
                      props.handleDropdownChange(val, idx, 'packageType')
                    }
                    key={props.cargoPackageKeyRef}
                   
                  />
                </CngGridItem>

                <CngGridItem xs={2} sm={2}></CngGridItem>
                <CngGridItem xs={10} sm={10}>
                  <Card className='inner-card-group'>
                    <Grid container xs={12} sm={12} spacing={2}>
                      <CngGridItem xs={2} sm={2}>
                        <Typography className='page-content-groupLabel text-upper'>
                          {siTranslatedTextsObject.gross}
                        </Typography>
                      </CngGridItem>
                      <CngGridItem xs={5} sm={5}>
                        <CngTextField
                          type='number'
                          name={`shippingInfoCargoes[${idx}].grossVolume`}
                          label={siTranslatedTextsObject.grossVolume}
                          value={m.grossVolume}
                          customType={PLAIN_NUMBER}
                          disabled={props.isCarrier}
                          onChange={(e) => props.handleInputChange(e, idx)}
                        />
                      </CngGridItem>
                      <CngGridItem xs={5} sm={5}>
                        <CngTextField
                          type='number'
                          name={`shippingInfoCargoes[${idx}].grossWeight`}
                          label={siTranslatedTextsObject.grossWeight}
                          value={m.grossWeight}
                          customType={PLAIN_NUMBER}
                          disabled={props.isCarrier}
                          onChange={(e) => props.handleInputChange(e, idx)}
                        />
                      </CngGridItem>
                    </Grid>
                  </Card>
                </CngGridItem>

                <CngGridItem xs={2} sm={2}></CngGridItem>
                <CngGridItem xs={10} sm={10}>
                  <Card className='inner-card-group'>
                    <Grid container xs={12} sm={12} spacing={2}>
                      <CngGridItem xs={2} sm={2}>
                        <Typography className='page-content-groupLabel text-upper'>
                          {siTranslatedTextsObject.net}
                        </Typography>
                      </CngGridItem>
                      <CngGridItem xs={5} sm={5}>
                        {/* <CngTextField
                          type='number'
                          name={`shippingInfoCargoes[${idx}].netVolume`}
                          label={siTranslatedTextsObject.netVolume}
                          value={m.netVolume}
                          customType={PLAIN_NUMBER}
                          disabled={props.isCarrier}
                          error={props.getFieldError(
                            idx,
                            `shippingInfoCargoes.netVolume`
                          )}
                          helperText={props.getFieldError(
                            idx,
                            `shippingInfoCargoes.netVolume`
                          )}
                          onChange={(e) => props.handleInputChange(e, idx)}
                        /> */}
                      </CngGridItem>
                      <CngGridItem xs={5} sm={5}>
                        <CngTextField
                          type='number'
                          name={`shippingInfoCargoes[${idx}].netWeight`}
                          label={siTranslatedTextsObject.netWeight}
                          value={m.netWeight}
                          customType={PLAIN_NUMBER}
                          disabled={props.isCarrier}
                          onChange={(e) => props.handleInputChange(e, idx)}
                        />
                      </CngGridItem>
                    </Grid>
                  </Card>
                </CngGridItem>

                <CngGridItem xs={12} sm={12}>
                  <Card className='inner-card-group'>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      spacing={2}
                      justify='space-evenly'
                    >
                      <CngGridItem>
                        <CngCodeMasterAutocompleteField
                          name={`shippingInfoCargoes[${idx}].uom`}
                          label={siTranslatedTextsObject.uom}
                          codeType='CAL_CARGO_LENGTH'
                          key={props.cargoUomKeyRef}
                          onChange={(val, evt) =>
                            props.handleDropdownChange(val, idx, 'uom')
                          }
                          disabled={props.isCarrier}
                        />
                      </CngGridItem>
                      <CngGridItem xs={3} sm={3}>
                        <CngTextField
                          type='number'
                          name={`shippingInfoCargoes[${idx}].length`}
                          label={siTranslatedTextsObject.length}
                          value={m.length}
                          customType={PLAIN_NUMBER}
                          disabled={props.isCarrier}
                          onChange={(e) => props.handleInputChange(e, idx)}
                        />
                      </CngGridItem>
                      <CngGridItem xs={3} sm={3}>
                        <CngTextField
                          type='number'
                          name={`shippingInfoCargoes[${idx}].height`}
                          label={siTranslatedTextsObject.height}
                          value={m.height}
                          customType={PLAIN_NUMBER}
                          disabled={props.isCarrier}
                          onChange={(e) => props.handleInputChange(e, idx)}
                        />
                      </CngGridItem>
                      <CngGridItem xs={3} sm={3}>
                        <CngTextField
                          type='number'
                          name={`shippingInfoCargoes[${idx}].width`}
                          label={siTranslatedTextsObject.width}
                          value={m.width}
                          customType={PLAIN_NUMBER}
                          disabled={props.isCarrier}
                          onChange={(e) => props.handleInputChange(e, idx)}
                        />
                      </CngGridItem>
                    </Grid>
                  </Card>
                </CngGridItem>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <AccordionFooterControlComponent
            handleAccordionExpand={props.handleCargoDetailsExpand(idx)}
            expanded={m.cargoBoxExpanded}
            footerText={siTranslatedTextsObject.additionalCargoDetails}
          />
        </Card>
      </CngGridItem>
    </Grid>
  ))
}

export default SICargoDetailsComponent
