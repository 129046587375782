import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Box,
  Typography
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import AuditTrailDualColumnMultipleFields from 'src/components/auditTrail/AuditTrailDualColumnMultipleFields'
import Utils from 'src/views/common/utils/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'

const {
  form: {
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const CODE = 'code'
const DESCRIPTION = 'descriptionEn'
const fields_classificationIdentication = [
  'imoClass',
  'undgNumber',
  'properShippingName',
  'technicalName',
  'imdgCodePage',
  'regulationPageNumber',
  'regulationVersionNumber',
  'emsNumber'
]
const fields_packagingWeight = [
  'outerPkgQty',
  'outerPkgQtyUom',
  'innerPkgQty',
  'innerPkgQtyUom',
  'limitedQty',
  'limitedQtyUom',
  'exceptedQty',
  'exceptedQtyUom',
  'netWeight',
  'grossWeight',
  'vgmWeight',
  'quantity',
  'packingGroupCode',
  'ibcPackageCode',
  'packingDetails'
]
const fields_hazardIdentification = [
  'marinePollutantStatus',
  'radioactiveMeasurement',
  'acidConcentration',
  'tremCardNumber',
  'regulatoryInfomation',
  'radioactiveGoodsInfo',
  'placardInformation'
]
const fields_additionalHazmatSettings = [
  'inhalantHazard',
  'emptyUncleanedRecept',
  'transportDgLimitQuant',
  'poisonInhalationHazardZone',
  'aggregationState',
  'reportableQty',
  'weightKgs',
  'weightLbs',
  'netExplosiveContent',
  'dateOfFumigation',
  'localCategory'
]
const fields_physicalChemicalProps = [
  'flashpoint',
  'flashpointUom',
  'boilingPoint',
  'boilingPointUom',
  'sadt',
  'sadtUom',
  'controlTemperature',
  'controlTemperatureUom',
  'emergencyTemperature',
  'emergencyTemperatureUom'
]
const fields_forEmergency = ['emergencyContactName', 'emergencyContactNumber']

const AuditTrailDgCargo = (props) => {
  const { cargos, codeMasterList, packageUom } = props
  const tranObj = FreightBookingTranslationText()
  const siTranObj = ShippingInstructionTranslationText()
  const [cargoExpanded, setCargoExpanded] = useState(false)
  //console.log('packageUom', packageUom)

  const handleCargoExpand = () => {
    setCargoExpanded(!cargoExpanded)
  }
  const cargo_numOfAdded = cargos && cargos.added ? cargos.added.length : 0
  const cargo_numOfRemoved =
    cargos && cargos.removed ? cargos.removed.length : 0

  const CargoDetails = ({ detail, index, ...props }) => {
    const [dgDetailExpanded, setDgDetailExpanded] = useState(false)
    let showClassificationIdentication = false
    let showPackagingWeight = false
    let showHazardIdentification = false
    let showAdditionalHazmatSettings = false
    let showPhysicalChemicalProps = false
    let showForEmergency = false

    if (props.updated) {
      showClassificationIdentication = Utils.hasFieldsChanged(
        detail,
        fields_classificationIdentication
      )
      showPackagingWeight = Utils.hasFieldsChanged(
        detail,
        fields_packagingWeight
      )
      showHazardIdentification = Utils.hasFieldsChanged(
        detail,
        fields_hazardIdentification
      )
      showAdditionalHazmatSettings = Utils.hasFieldsChanged(
        detail,
        fields_additionalHazmatSettings
      )
      showPhysicalChemicalProps = Utils.hasFieldsChanged(
        detail,
        fields_physicalChemicalProps
      )
      showForEmergency = Utils.hasFieldsChanged(detail, fields_forEmergency)
    } else {
      showClassificationIdentication = Utils.anyFieldsHasValue(
        detail,
        fields_classificationIdentication
      )
      showPackagingWeight = Utils.anyFieldsHasValue(
        detail,
        fields_packagingWeight
      )
      showHazardIdentification = Utils.anyFieldsHasValue(
        detail,
        fields_hazardIdentification
      )
      showAdditionalHazmatSettings = Utils.anyFieldsHasValue(
        detail,
        fields_additionalHazmatSettings
      )
      showPhysicalChemicalProps = Utils.anyFieldsHasValue(
        detail,
        fields_physicalChemicalProps
      )
      showForEmergency = Utils.anyFieldsHasValue(detail, fields_forEmergency)
    }

    const handleDgDetailExpand = () => {
      setDgDetailExpanded(!dgDetailExpanded)
      console.log('dgDetailExpanded: ', dgDetailExpanded)
    }
    return (
      <Box m={2}>
        <Accordion expanded={dgDetailExpanded}>
          <AccordionSummary
            aria-controls='dgCargoDetailContent'
            id={'dgCargoDetailHeader'}
          >
            <Grid container>
              <AccordionHeaderComponent
                title={tranObj.dgDetails + ' ' + (index + 1)}
                hideMandatory
              />
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container>
              {showClassificationIdentication && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant='h5'
                      className='font-bolder paddingLeft16 paddingTop16'
                    >
                      {tranObj.classificationIdentication}
                    </Typography>
                  </Grid>
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'imoClass'}
                    codeType={CalistaConstant.CAL_DG_IMO}
                    codeMasterList={codeMasterList}
                    label={tranObj.imoClass}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'undgNumber'}
                    label={tranObj.undgNumber}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'properShippingName'}
                    label={tranObj.shippingName}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'technicalName'}
                    label={tranObj.technicalName}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'imdgCodePage'}
                    label={tranObj.IMDGCodePage}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'regulationPageNumber'}
                    label={tranObj.regulationPageNumber}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'regulationVersionNumber'}
                    label={tranObj.regulationVersionNumber}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'emsNumber'}
                    label={tranObj.EMSNumber}
                  />
                </>
              )}
              {showPackagingWeight && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant='h5'
                      className='font-bolder paddingLeft16 paddingTop16'
                    >
                      {tranObj.packagingWeight}
                    </Typography>
                  </Grid>

                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['outerPkgQty', 'outerPkgQtyUom']}
                    codeType={CalistaConstant.CAL_DG_PKG_TYPE}
                    codeMasterList={codeMasterList}
                    delimiter=' x '
                    label={tranObj.outerPkgQTY}
                  />

                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['innerPkgQty', 'innerPkgQtyUom']}
                    codeType={CalistaConstant.CAL_DG_PKG_TYPE}
                    codeMasterList={codeMasterList}
                    delimiter=' x '
                    label={tranObj.innerPkgQTY}
                  />

                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['limitedQty', 'limitedQtyUom']}
                    match={Utils.matching(CODE, DESCRIPTION, packageUom)}
                    delimiter=' '
                    label={tranObj.limitedQTY}
                  />
                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['exceptedQty', 'exceptedQtyUom']}
                    match={Utils.matching(CODE, DESCRIPTION, packageUom)}
                    delimiter=' '
                    label={tranObj.exceptedQty}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'netWeight'}
                    label={tranObj.netWeight}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'grossWeight'}
                    label={tranObj.grossWeight}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'vgmWeight'}
                    label={tranObj.vgmWeight}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'quantity'}
                    label={tranObj.quantity}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'packingGroupCode'}
                    label={tranObj.packingGroup}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'ibcPackageCode'}
                    label={tranObj.ibcPackageCode}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'packingDetails'}
                    label={tranObj.packingDetails}
                  />
                </>
              )}
              {showForEmergency && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant='h5'
                      className='font-bolder paddingLeft16 paddingTop16'
                    >
                      {tranObj.forEmergency}
                    </Typography>
                  </Grid>
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'emergencyContactName'}
                    label={tranObj.emergencyContact}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'emergencyContactNumber'}
                    label={tranObj.emergencyContactNumber}
                  />
                </>
              )}
              {showHazardIdentification && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant='h5'
                      className='font-bolder paddingLeft16 paddingTop16'
                    >
                      {tranObj.hazardIdentification}
                    </Typography>
                  </Grid>

                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'marinePollutantStatus'}
                    codeType={CalistaConstant.CAL_DG_MARINE}
                    codeMasterList={codeMasterList}
                    label={tranObj.marinePollutantStatus}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'radioactiveMeasurement'}
                    uom={' Mbq'}
                    label={tranObj.radioactiveMeasurement}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'acidConcentration'}
                    uom={' %'}
                    label={tranObj.acidConcentration}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'tremCardNumber'}
                    label={tranObj.tremCardNo}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'regulatoryInfomation'}
                    label={tranObj.regulatoryInfo}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'radioactiveGoodsInfo'}
                    label={tranObj.radioactiveGoodsInfo}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'placardInformation'}
                    label={tranObj.placardInfo}
                  />
                </>
              )}
              {showAdditionalHazmatSettings && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant='h5'
                      className='font-bolder paddingLeft16 paddingTop16'
                    >
                      {tranObj.additionalHazmatSettings}
                    </Typography>
                  </Grid>
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'inhalantHazard'}
                    checkbox
                    label={tranObj.inhalantHazard}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'emptyUncleanedRecept'}
                    checkbox
                    label={tranObj.emptyUncleanedReceptable}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'transportDgLimitQuant'}
                    checkbox
                    label={tranObj.transportDgLimitQuant}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'poisonInhalationHazardZone'}
                    codeType={CalistaConstant.CAL_DG_PSN_INH_HZD_ZN}
                    codeMasterList={codeMasterList}
                    label={tranObj.poisonInhalationHazardZone}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'aggregationState'}
                    codeType={CalistaConstant.CAL_DG_AGGRE}
                    codeMasterList={codeMasterList}
                    label={tranObj.aggregationState}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'reportableQty'}
                    checkbox
                    label={tranObj.reportableQty}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'weightKgs'}
                    checkbox
                    label={tranObj.weightKgs}
                  />

                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'weightLbs'}
                    checkbox
                    label={tranObj.weightLbs}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'netExplosiveContent'}
                    checkbox
                    label={tranObj.netExplosiveContent}
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'dateOfFumigation'}
                    label={tranObj.dateOfFumigation}
                    timestamp
                  />
                  <AuditTrailDualColumn
                    {...props}
                    object={detail}
                    field={'localCategory'}
                    codeType={CalistaConstant.CAL_DG_MPA_CLASS}
                    codeMasterList={codeMasterList}
                    label={tranObj.localCategory}
                  />
                </>
              )}
              {showPhysicalChemicalProps && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant='h5'
                      className='font-bolder paddingLeft16 paddingTop16'
                    >
                      {tranObj.physicalChemicalProps}
                    </Typography>
                  </Grid>
                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['flashpoint', 'flashpointUom']}
                    codeType={CalistaConstant.CAL_DG_TEMP_UOM}
                    codeMasterList={codeMasterList}
                    delimiter=' '
                    label={tranObj.flashpoint}
                  />
                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['boilingPoint', 'boilingPointUom']}
                    codeType={CalistaConstant.CAL_DG_TEMP_UOM}
                    codeMasterList={codeMasterList}
                    delimiter=' '
                    label={tranObj.boilingPoint}
                  />
                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['sadt', 'sadtUom']}
                    codeType={CalistaConstant.CAL_DG_TEMP_UOM}
                    codeMasterList={codeMasterList}
                    delimiter=' '
                    label={tranObj.sadt}
                  />
                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['controlTemperature', 'controlTemperatureUom']}
                    codeType={CalistaConstant.CAL_DG_TEMP_UOM}
                    codeMasterList={codeMasterList}
                    delimiter=' '
                    label={tranObj.controlTemp}
                  />
                  <AuditTrailDualColumnMultipleFields
                    {...props}
                    object={detail}
                    fields={['emergencyTemperature', 'emergencyTemperatureUom']}
                    codeType={CalistaConstant.CAL_DG_TEMP_UOM}
                    codeMasterList={codeMasterList}
                    delimiter=' '
                    label={tranObj.emergencyTemp}
                  />
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <AccordionFooterControlComponent
          handleAccordionExpand={handleDgDetailExpand}
          expanded={dgDetailExpanded}
          footerText={tranObj.showDgDetails + ' ' + (index + 1)}
          footerTextExpanded={tranObj.hideDgDetails + ' ' + (index + 1)}
          noOptionalFields
        />
      </Box>
    )
  }
  const Cargo = ({ cargo, index, ...props }) => {
    let cargoDetails = cargo.shippingInfoDGCargoDetDTOs
    if (cargo._changes_ && cargo._changes_.shippingInfoDGCargoDetDTOs) {
      cargoDetails = cargo._changes_.shippingInfoDGCargoDetDTOs
    }
    const numOfAdded =
      cargoDetails && cargoDetails.added ? cargoDetails.added.length : 0
    const numOfRemoved =
      cargoDetails && cargoDetails.removed ? cargoDetails.removed.length : 0
    return (
      <ComponentWrapper
        header={tranObj.cargo + ' ' + (index + 1)}
        cardStyle={{ marginTop: 20 }}
        backgroundStyle='inner-card-background'
        rightConnerHeader={<span style={{ fontSize: 14 }}>ID: {cargo.id}</span>}
      >
        <Box width='100%'>
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'description'}
            label={tranObj.cargoDesc}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'hsCode'}
            label={tranObj.hsCode}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'packageType'}
            label={tranObj.packageType}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'containerNo'}
            array
            label={siTranObj.relatedContainerNos}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'quantity'}
            label={tranObj.quantity}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'totalGrossVolume'}
            label={siTranObj.totalGrossVolume}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'totalGrossWeight'}
            label={siTranObj.totalGrossWeight}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'totalNetVolume'}
            label={siTranObj.totalNetVolume}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'totalNetWeight'}
            label={siTranObj.totalNetWeight}
          />
          <AuditTrailDualColumn
            {...props}
            object={cargo}
            field={'totalVgmWeight'}
            label={siTranObj.totalVgmWeight}
          />
          {Array.isArray(cargoDetails) &&
            cargoDetails.map((detail, i) => (
              <CargoDetails detail={detail} index={i} {...props} />
            ))}
          {!Array.isArray(cargoDetails) &&
            cargoDetails &&
            cargoDetails.added &&
            cargoDetails.added.length > 0 &&
            cargoDetails.added.map((detail, i) => (
              <CargoDetails added detail={detail} index={i} {...props} />
            ))}
          {!Array.isArray(cargoDetails) &&
            cargoDetails &&
            cargoDetails.removed &&
            cargoDetails.removed.length > 0 &&
            cargoDetails.removed.map((detail, i) => (
              <CargoDetails
                removed
                detail={detail}
                index={i + numOfAdded}
                {...props}
              />
            ))}
          {!Array.isArray(cargoDetails) &&
            cargoDetails &&
            cargoDetails.updated &&
            cargoDetails.updated.length > 0 &&
            cargoDetails.updated.map((detail, i) => {
              if (Utils._changeValueEmpty(detail, ['dgCargoId'])) {
                return <div />
              }
              return (
                <CargoDetails
                  updated
                  detail={detail}
                  index={i + numOfAdded + numOfRemoved}
                  {...props}
                />
              )
            })}
        </Box>
      </ComponentWrapper>
    )
  }
  return (
    <Card>
      <Accordion expanded={cargoExpanded}>
        <AccordionSummary
          aria-controls='dgCargoComponentContent'
          id='dgCargoComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent title={tranObj.dgCargo} hideMandatory />
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container>
            {cargos &&
              cargos.added &&
              cargos.added.length > 0 &&
              cargos.added.map((c, i) => (
                <Grid container xs={12} sm={12}>
                  <Cargo added cargo={c} index={i} />
                </Grid>
              ))}
            {cargos &&
              cargos.removed &&
              cargos.removed.length > 0 &&
              cargos.removed.map((c, i) => (
                <Grid container xs={12} sm={12}>
                  <Cargo removed cargo={c} index={i + cargo_numOfAdded} />
                </Grid>
              ))}
            {cargos &&
              cargos.updated &&
              cargos.updated.length > 0 &&
              cargos.updated.map((c, i) => {
                if (
                  Utils._changeValueEmpty(c, [
                    'bookingId',
                    'version',
                    'updatedDate'
                  ])
                ) {
                  return <div />
                }
                return (
                  <Grid container xs={12} sm={12}>
                    <Cargo
                      cargo={c}
                      index={i + cargo_numOfAdded + cargo_numOfRemoved}
                    />
                  </Grid>
                )
              })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleCargoExpand}
        expanded={cargoExpanded}
        footerText={tranObj.showDgCargoDetails}
        footerTextExpanded={tranObj.hideDgCargoDetails}
        noOptionalFields
      />
    </Card>
  )
}

export default AuditTrailDgCargo
