import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import ManageScheduleTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
  form: {
    adapter: {
      useFormAdapter: {useField, useFormikContext}
    }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const { paymentData, auditSI } = props
  const translatedTextsObject = ManageScheduleTranslationText()
  // const [moveTypeField, , { setValue: setMoveTypeField }] = useField('moveType')
  // const myMoveType = paymentData.moveType

  const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCdeField }] =
    useField('placeOfReceiptCode')
  const [
    placeOfDeliveryCodeField,
    ,
    { setValue: setPlaceOfDeliveryCodeField }
  ] = useField('placeOfDeliveryCode')

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const moveTypeKeyRef = useRef(uuid())

  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    // setMoveTypeField(paymentData.moveType)
    setPlaceOfReceiptCdeField(paymentData.placeOfReceiptCode)
    setPlaceOfDeliveryCodeField(paymentData.placeOfDeliveryCode)

    placeOfReceiptKeyRef.current = uuid()
    placeOfDeliveryKeyRef.current = uuid()
    moveTypeKeyRef.current = uuid()

    setFieldValue('moveType', paymentData.moveType)

    setShouldRender(true)
  }, [])

  if (!shouldRender) {
    return null
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {translatedTextsObject.paymentInstruction}
          </Box>
          <Box>
            <Grid container xs={12} sm={12} spacing={0}>
              <CngGridItem
                xs={4}
                sm={4}
                style={Utils.auditHighLight(auditSI, ['chrgType'])}
              >
                <LabelValueVertical
                  label={translatedTextsObject.chargeType}
                  value={paymentData.chrgType}
                />
              </CngGridItem>

              <CngGridItem
                xs={4}
                sm={4}
                style={Utils.auditHighLight(auditSI, ['payer'])}
              >
                <LabelValueVertical
                  label={translatedTextsObject.payer}
                  value={paymentData.payer}
                />
              </CngGridItem>

              <CngGridItem
                xs={4}
                sm={4}
                style={Utils.auditHighLight(auditSI, ['frtTerm'])}
              >
                <LabelValueVertical
                  label={translatedTextsObject.freightTerm}
                  value={paymentData.freightTerm}
                />
              </CngGridItem>
            </Grid>
            <Grid container xs={12} sm={12} spacing={0}>
              <CngGridItem
                xs={5}
                sm={5}
                style={Utils.auditHighLight(auditSI, ['pmtLoc'])}
              >
                <LabelValueVertical
                  label={translatedTextsObject.pmtLoc}
                  value={paymentData.paymentLocation}
                />
              </CngGridItem>
            </Grid>
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({})

const ViewPaymentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewPaymentComponent
