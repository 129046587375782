import { Box, Card, CardActionArea, Grid, Typography } from '@material-ui/core'

import AvatarInitial from 'src/views/freightbooking/components/AvatarInitial'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import { useHistory } from 'react-router-dom'
import CreateSIDetailTop from './CreateSIDetailTop'
import SIDetailBottom from './SIDetailBottom'
import ManageScheduleTranslationText from '../ShippingInstructionTranslationText'
import LogoWithTitleVertical from 'src/views/freightbooking/components/LogoWithTitleVertical'
import LabelValueVerticalSimple from 'src/views/freightbooking/components/LabelValueVerticalSimple'
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'
import moment from 'moment'
import PortDetailComponent from 'src/views/freightbooking/components/PortDetailComponent'
import Utils from 'src/views/common/utils/Utils'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
  const translatedTextsObject = ManageScheduleTranslationText()
  const { booking } = props
  const history = useHistory()

  let carrierShortName = booking.carrierPartyIdName
  let isCarrierNameCut = false
  if (booking.carrierPartyIdName && booking.carrierPartyIdName.length > 5) {
    carrierShortName = booking.carrierPartyIdName.substring(0, 5).trim() + '...'
    isCarrierNameCut = true
  }

  let shipperShortName = booking.partyIdName
  let showTooltip = false
  if (booking.partyIdName && booking.partyIdName.length > 5) {
    shipperShortName = booking.partyIdName.substring(0, 5).trim() + '...'
    showTooltip = true
  }

  const gotoReviewBooking = () => {
    // console.log('gotoReviewBooking')
    history.push({
      pathname: pathMap.CREATE_SI_REVIEW_BOOKING,
      state: {
        bookingId: booking.bookingId,
        dockey: booking.dockey,
        bookStatus: booking.bookStatus
      }
    })
  }

  return (
    <Card className={`summary-list-item--card`}>
      <CreateSIDetailTop booking={booking} {...props} />
      <CardActionArea onClick={gotoReviewBooking}>
        <Card>
          <Grid container style={{ padding: 15 }}>
            <CngGridItem xs={12} sm={1}>
              <LogoWithTitleVertical
                logoSrc={`${process.env.PUBLIC_URL}/static/images/carriers/${booking.carrierPartyIdNameOth}.svg`}
                defaultImg={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                withTooltip={isCarrierNameCut}
                longName={booking.carrierPartyIdName}
                shortName={carrierShortName}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={5} style={{ paddingLeft: 20 }}>
              <Box pt={0.5}>
                <LabelValueVerticalSimple
                  label={translatedTextsObject.vesselName}
                  value={booking.vesselName}
                  defaultValue='N/A'
                  labelStyle={'frb-card-label'}
                  valueStyle={'frb-card-content'}
                />
              </Box>
              <Box pt={0.5}>
                <LabelValueVerticalSimple
                  label={translatedTextsObject.voyageNumber}
                  value={booking.voyageNo}
                  defaultValue='N/A'
                  labelStyle={'frb-card-label'}
                  valueStyle={'frb-card-content'}
                />
              </Box>

              <Box pt={0.5}>
                <LabelValueVerticalSimple
                  label={translatedTextsObject.shipperRefNo}
                  value={booking.shpRefNo}
                  defaultValue='N/A'
                  labelStyle={'frb-card-label'}
                  valueStyle={'frb-card-content'}
                />
              </Box>
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
              <Grid container>
                <CngGridItem xs={12} sm={12}>
                  <Grid container>
                    <CngGridItem xs={12} sm={6}>
                      <PortDetailComponent
                        title={translatedTextsObject.placeOfReceipt}
                        portCode={booking.placeOfReceiptCode}
                        portName={booking.placeOfReceipt}
                        date={booking.placeOfReceiptEtd}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <PortDetailComponent
                        title={translatedTextsObject.placeOfDelivery}
                        portCode={booking.placeOfDeliveryCode}
                        portName={booking.placeOfDelivery}
                        date={booking.placeOfDeliveryEta}
                      />
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
                <CngGridItem xs={12} sm={12}>
                  <Grid container>
                    <CngGridItem xs={12} sm={6}>
                      <LabelValueVerticalSimple
                        label={translatedTextsObject.bookingDateAndTime}
                        value={Utils.formatDate(
                          booking.submittedDate,
                          Utils.UI_FORMAT_DATE_TIME,
                          Utils.DASH
                        )}
                        defaultValue='-'
                        labelStyle={'frb-card-label'}
                        valueStyle={'frb-card-content'}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6}>
                      <LabelValueVerticalSimple
                        label={
                          translatedTextsObject.submittedShippingInstructions
                        }
                        value={booking.createdSINo}
                        defaultValue='0'
                        labelStyle={'frb-card-label'}
                        valueStyle={'frb-card-content'}
                      />
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
              </Grid>
            </CngGridItem>
          </Grid>
        </Card>
      </CardActionArea>
      <SIDetailBottom
        dockey={booking.dockey}
        updatedDate={booking.updatedDate}
        hazardous={booking.hazardous}
      />
    </Card>
  )
}

const CreateSIBookingListDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default CreateSIBookingListDetailComponent
