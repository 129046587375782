import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Checkbox,
  Card,
  Grid,
  CardContent
} from '@material-ui/core'
import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import { components } from 'cng-web-lib'
import GeneralButtonTabComponent from 'src/views/common/ui/GeneralButtonTabComponent'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

const FormBody = (props) => {
  const translatedTextsObject = ShippingInstructionTranslationText()

  const preCheckSortByChange = (e) => {
    console.log('handleSortByChange')
    //alert(e.target.value)
    if (e.target.value != null && e.target.value != 0) {
      props.handleSortByChange(e)
    }
  }

  return (
    <Box>
      <GeneralButtonTabComponent.FormBody
        firstTabLabel={translatedTextsObject.shipmentOverview}
        secondTabLabel={translatedTextsObject.drafts}
        firstTabCount={props.shipmentCount}
        secondTabCount={props.draftsCount}
        draftsCount={props.draftsCount}
        shownCount={props.shownCount}
        firstTabClicked={props.shipmentClicked}
        firstTabOnClick={props.clickShipment}
        secondTabOnClick={props.clickDrafts}
        sortByList={props.sortByList}
        handleSortByChange={preCheckSortByChange}
        draft={props.draft}
      />
    </Box>
  )
}

const SIButtonTabComponent = Object.freeze({
  FormBody: FormBody
})

export default SIButtonTabComponent
