import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Button,
  Box,
  Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import SIUploadDetailsComponents from '../components/SIUploadDetailsComponent'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls'
import SIApi from 'src/views/shippinginstruction/shared/api'
import DocumentApi from 'src/views/common/api/DocumentApi'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import { v4 as uuid } from 'uuid'
import { isNull } from 'lodash'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import { useFieldArray, useFormContext, useForm } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem,
  CngFilesDropzone,
  dropzone: { CngFileUpload, HelperTextArea },
  table: { useDefaultNotification }
} = components

const { CodeMaintenanceType } = constants

const FormBody = (props) => {
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { createRecord, fetchRecords, deleteRecord, securedSendRequest } =
    useServices()
  // const [pendingSIField, , { setValue: setPendingSIField }] =
  //   useField('pendingSIDocuments')
  const { fields: pendingSIField } = useFieldArray({
    name: 'pendingSIUploadedDocuments'
  })

  // const [uploadedSIField, , { setValue: setUploadedSIField }] =
  //   useField('uploadSIDocuments')
  const { fields: uploadedSIField } = useFieldArray({
    name: 'shippingInfoUploadDTO'
  })
  const [pendingSI, setPendingSI] = useState([]) //for the file list below drop zone
  const [uploadedSI, setUploadedSI] = useState(props.uploadedSIList) //for the file list above the drop zone
  const [documentExpanded, setDocumentExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { errors, touched, setFieldValue } = useFormikContext()
  const { reset } = useForm()
  const docTypeKeyRef = useRef(uuid())

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  const handleUploadExpand = () => {
    setDocumentExpanded(!documentExpanded)
  }

  const removeUploadedRowClick = (id) => {
    console.log('remove uploaded: ' + id)
    let removedFile = uploadedSI.splice(id, 1)[0]
    console.log('removedFile', removedFile)
    let fileToDelete = {
      id: removedFile.id,
      version: removedFile.version
    }
    console.log('fileToDelete', fileToDelete)

    deleteRecord.execute(
      ShippingInstructionApiUrls.UPLOAD_SI_DELETE,
      fileToDelete,
      (data) => {
        showSuccessNotification('File deleted - ' + removedFile.fileName)
      },
      (err) => {
        showErrorNotification('Error deleting file - ' + err.message)
      }
    )
    const newFiles = [].concat([], uploadedSI)
    setUploadedSI(newFiles)
    // setUploadedSIField(newFiles)
    setFieldValue('shippingInfoUploadDTO', newFiles)

    docTypeKeyRef.current = uuid()
  }

  const downloadLogRowClick = (id) => {
    console.log('download log: ' + id)
    console.log(uploadedSI[id])
    securedSendRequest.execute(
      'POST',
      ShippingInstructionApiUrls.UPLOAD_SI_DOWNLOAD_LOG,
      {
        id: uploadedSI[id].id
      },
      (data) => {
        console.log(data)
        var blob = data.data
        let url = window.URL.createObjectURL(blob)

        let link = document.createElement('a')
        link.href = url
        link.download = uploadedSI[id].responseFilename

        link.click()
      },
      (error) => {
        console.log(error)
        showErrorNotification('Error downloading file - ' + error.message)
      },
      () => {
        console.log('completed')
      },
      { responseType: 'blob' }
    )
  }

  const downloadUploadedRowClick = (id) => {
    console.log('download uploaded: ' + id)
    console.log(uploadedSI[id])
    securedSendRequest.execute(
      'POST',
      ShippingInstructionApiUrls.UPLOAD_SI_DOWNLOAD,
      {
        id: uploadedSI[id].id
      },
      (data) => {
        console.log(data)
        var blob = data.data
        let url = window.URL.createObjectURL(blob)

        let link = document.createElement('a')
        link.href = url
        link.download = uploadedSI[id].fileName

        link.click()
      },
      (error) => {
        console.log(error)
        showErrorNotification('Error downloading file - ' + error.message)
      },
      () => {
        console.log('completed')
      },
      { responseType: 'blob' }
    )
  }

  function uploadPendingSI() {
    // alert("Upload SI. Need to save draft (not implemented yet)")

    if (pendingSI.length < 1) {
      showErrorNotification('Nothing to upload')
    } else {
      setLoading(true)
      console.log('upload SI')

      setPendingSI([])
      var formData = new FormData()
      if (props.shippingInfo.shippingInfoId == undefined) {
        formData.append('shippingInfoId', 0)
      } else {
        formData.append('shippingInfoId', props.shippingInfo.shippingInfoId)
      }
      console.log(pendingSIField)

      pendingSIField.map((file, i) => {
        var fileBlob = file.file
        console.log(fileBlob)
        formData.append('fileContents', fileBlob) // name of the file upload box
      })
      console.log('formData', formData)
      console.log('shippingInfoId', props.shippingInfo.shippingInfoId)

      function onSuccess(response) {
        console.log('connection-request onSuccess', response)

        let newUploadedSIDocs = []
        if (response) {
          let uploadedSIDoc = generateUploadedDoc(response)
          newUploadedSIDocs = [uploadedSIDoc, ...uploadedSI]
        }
        console.log('newUploadedSIDocs', newUploadedSIDocs)
        //setUploadedSIField(uploadedSIDocs)
        setFieldValue('shippingInfoUploadDTO', newUploadedSIDocs)
        setUploadedSI(newUploadedSIDocs)
        //props.updateSIList(uploadedSIDocs)

        if (response.errorMessages.length > 0) {
          showErrorNotification(response.errorMessages)
          setLoading(false)
          console.log('response.errorMessages', response.errorMessages)
        } else {
          console.log('response', response)
          props.setShouldRender(false)
          const siRequest = {
            shippingInfoId: response.shippingInfoId
          }
          // reset({ shippingInfoCargoes: [] })

          //props.updateShippingInfo({})
          SIApi.fetchSIDetailsById(fetchRecords, siRequest, (data) => {
            showSuccessNotification('Updating SI...')
            //  let booking = SIUtils.expandBookingToShippingInfo(data)

            // SIUtils.nonNullableArrayFields.forEach((e) => {
            //   console.log('after upload e', e)
            //   console.log('after upload booking e', booking[e])
            //   if (booking[e] === null) {
            //     booking[e] = []
            //   }
            // })

            // console.log('after upload', booking)
            // reset(booking)
            // props.resetCurrentForm(booking)
            if (response.errorMessages.length === 0) {
              props.updateShippingInfo(
                SIUtils.expandBookingToShippingInfo(data)
              )
              props.refresh()
              setLoading(false)
            } else {
              showErrorNotification(response.errorMessages)
            }
          })
        }
      }

      function onError(error) {
        console.log('connection-request error', error.message)
        setLoading(false)
        //alert(JSON.stringify(error))

        showErrorNotification(error.message)

        // if connection fail, all failed
        // let uploadedSIDocs = uploadedSI || [] //to store the final booking documents
        // pendingSIField.map((file, i) => {
        //   let uploadedFailSIDoc = {
        //     filename: error.fileName,
        //     docId: error.id,
        //     filesize: error.fileSize,
        //     uploadSuccess: false,
        //     errorMsg: error.message,
        //     responseFilename: error.responseFilename,
        //     createdDate: error.createdDate
        //   }
        //   uploadedSIDocs.push(uploadedFailSIDoc)
        // })

        // //setUploadedSIField(uploadedSIDocs)
        // setFieldValue('shippingInfoUploadDTO', uploadedSIDocs)
        // setUploadedSI(uploadedSIDocs)
        // props.updateSIList(uploadedSIDocs)
        // console.log(uploadedSIDocs)
      }

      createRecord.execute(
        ShippingInstructionApiUrls.UPLOAD_SI_UPLOAD,
        formData,
        onSuccess,
        onError
      )
    }
  }

  function generateUploadedDoc(response) {
    console.log('generateUploadedDoc')
    let uploadedSIDoc = []

    if (response) {
      uploadedSIDoc = {
        fileName: response.fileName,
        id: response.id,
        fileSize: response.fileSize,
        uploadSuccess: response.errorMessages.length === 0,
        errorMessages: '',
        responseFilename: response.responseFilename,
        createdDate: response.createdDate
      }
    }
    console.log('generateUploadedDoc', uploadedSIDoc)
    return uploadedSIDoc
  }

  const moreActions = [
    {
      action: 'remove',
      name: 'Delete',
      icon: ['fal', 'trash']
    }
  ]

  const getFieldError = (itemIndex, fieldPath) => {
    //fieldPath : containers.quantity
    if (errors) {
      let pathArr = fieldPath.split('.')
      // alert(pathArr)
      // alert(errors[ pathArr[0] ])
      if (
        errors[pathArr[0]] &&
        errors[pathArr[0]][itemIndex] &&
        errors[pathArr[0]][itemIndex][pathArr[1]]
      ) {
        if (touched) {
          if (
            touched[pathArr[0]] &&
            touched[pathArr[0]][itemIndex] &&
            touched[pathArr[0]][itemIndex][pathArr[1]]
          ) {
            // return true
            return errors[pathArr[0]][itemIndex][pathArr[1]]
          } else {
            return null
          }
        }
      }
    }
    return null
  }

  const showErrorMessages = (errorMessages) => {
    //alert("rejected")
    showErrorNotification(errorMessages[0])
  }

  useEffect(() => {
    console.log('new formxxx', props.isEdit)
    console.log(props.isEdit)
    if (props.isEdit) {
      console.log('formxxx pendingSIField', pendingSIField)
      setPendingSI(pendingSIField)
      console.log('formxxx uploadedSIField', uploadedSIField)

      let uploadedSIList = uploadedSIField
      if (uploadedSIList != null) {
        setUploadedSI(uploadedSIList)
        //alert("list: " + JSON.stringify(uploadedSIList))
      }
    }
  }, [props.isEdit])

  return (
    <Card>
      <Accordion expanded={documentExpanded}>
        <AccordionSummary
          aria-controls='shipmentComponentContent'
          id='shipmentComponentHeader'
        >
          <AccordionHeaderComponent
            title={siTranslatedTextsObject.uploadSiInfo}
            hideMandatory
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid container justify='flex-end'>
              <Box pr={2} pb={2}>
                <a
                  className='download'
                  href={
                    process.env.PUBLIC_URL + '/static/doc/SI_Full_Template.xlsx'
                  }
                  download={'SI_Full_Template.xlsx'}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    classes={{ root: 'ng-button-filled-secondary' }}
                    startIcon={<GetAppOutlinedIcon />}
                  >
                    {siTranslatedTextsObject.excelTemplateButton}
                  </Button>
                </a>
              </Box>
            </Grid>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={12}>
                <SIUploadDetailsComponents
                  tile={uploadedSI}
                  removeRowClick={removeUploadedRowClick}
                  downloadRowClick={downloadUploadedRowClick}
                  downloadLogClick={downloadLogRowClick}
                //getFieldError={getFieldError}
                //docTypeKeyRef={docTypeKeyRef.current}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={12}>
                <CngFileUpload
                  accept={['.xlsx', '.xls']}
                  maxSize={2097152}
                  maxFiles={1}
                  files={pendingSI}
                  onFileSelect={(selectedFileList) => {
                    console.log(
                      'selected file list: ' + JSON.stringify(selectedFileList)
                    )
                    //setPendingSIField(selectedFileList)
                    setFieldValue(
                      'pendingSIUploadedDocuments',
                      selectedFileList
                    )
                    setPendingSI(selectedFileList)
                  }}
                  showFormFields={false}
                  fieldConfig={[]}
                  moreActions={moreActions}
                  onDropRejected={(e) => showErrorMessages(e)}
                  renderHelperText={() => {
                    return (
                      <HelperTextArea
                        accept={['.xlsx', '.xls']}
                        maxFiles={1}
                        maxSize={2097152}
                      />
                    )
                  }}
                />
              </CngGridItem>

              <Grid container justify='flex-end'>
                <Box pr={2}>
                  <Button
                    variant='contained'
                    color='secondary'
                    classes={{ root: 'ng-button-filled-secondary' }}
                    onClick={() => {
                      uploadPendingSI()
                    }}
                  >
                    {siTranslatedTextsObject.upload}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>

        <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
          <CngBackdrop loading={loading} />
        </CngGridItem>
      </Accordion>
      <AccordionFooterControlComponent
        handleAccordionExpand={handleUploadExpand}
        expanded={documentExpanded}
        footerText={siTranslatedTextsObject.uploadSiInfo}
      />
    </Card>
  )
}

const initialValues = {}

const SIUploadInformationComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIUploadInformationComponent
