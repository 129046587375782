import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import AuditTrailRowOfChange from 'src/components/auditTrail/AuditTrailRowOfChange'
import AuditTrailRowOfChangeComplex from 'src/components/auditTrail/AuditTrailRowOfChangeComplex'
import AuditTrailRowOfChangeReefer from 'src/components/auditTrail/AuditTrailRowOfChangeReefer'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import Api from 'src/views/freightbooking/shared/api'

const {
  CngGridItem,
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 16,
    fontWeight: 'bolder'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
})

const AuditTrailSummary = (props) => {
  const { ports, shipInfo, status, countries, blApplicationType } = props

  const classes = useStyles()
  const tranObj = FreightBookingTranslationText()
  const siTranObject = ShippingInstructionTranslationText()

  const calTranObj = CalistaUiComponentTranslationText()

  return (
    <div>
      <Grid container xs={12} sm={12}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <TableContainer component={Paper} style={{ padding: 0 }}>
              <Table className={classes.table} aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {siTranObject.auditSummaryHeader}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'siStatus'}
                    label={tranObj.status}
                    status={status}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'carRemarks'}
                    label={tranObj.carrierComments}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'shipperRemarks'}
                    label={tranObj.shipperComments}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'bookingRefNo'}
                    label={tranObj.bookingRefNo}
                  />
                  {/* Schedule */}
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'vesselName'}
                    label={tranObj.vesselName}
                    group={tranObj.schedule}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'voyageNo'}
                    label={tranObj.voyageNumber}
                    group={tranObj.schedule}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'polCode'}
                    ports={ports}
                    label={tranObj.portOfLoading}
                    group={tranObj.schedule}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'podCode'}
                    ports={ports}
                    label={tranObj.portOfDischarge}
                    group={tranObj.schedule}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'polDesc'}
                    label={tranObj.loadTerminal}
                    group={tranObj.schedule}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'podDesc'}
                    label={tranObj.dischargeTerminal}
                    group={tranObj.schedule}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'preCarriageVesName'}
                    label={siTranObject.preCarriageVesselName}
                    group={tranObj.schedule}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'preCarriageVoyNo'}
                    label={siTranObject.preCarriageVoyageNo}
                    group={tranObj.schedule}
                  />
                  {/* Contact */}
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'blApplnType'}
                    ports={blApplicationType}
                    label={siTranObject.blApplicationType}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'surrenderBl'}
                    label={siTranObject.surrenderBl}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'bolNumber'}
                    label={siTranObject.blNo}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'originalNo'}
                    label={siTranObject.noOfOriginals}
                    group={tranObj.contact}
                  />
                  {/* <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'orderNo'}
                    label={siTranObject.purchaseOrderNumber}
                    group={tranObj.contact}
                  /> */}
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'shipOnBoardDate'}
                    label={siTranObject.shippedOnBoardDateTime}
                    timestamp
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'cntrPkg'}
                    label={siTranObject.noOfContainersPackagesInWords}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'issueDate'}
                    label={siTranObject.issueDateTime}
                    timestamp
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'placeOfIssue'}
                    ports={countries}
                    label={siTranObject.issuePlace}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'carrierClauseDesc'}
                    label={siTranObject.carrierClauseDesc}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'formLayout'}
                    label={siTranObject.documentLayout}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'freightCharge'}
                    label={siTranObject.freightCharge}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'depot'}
                    label={siTranObject.depot}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'shpName'}
                    label={siTranObject.shipperName}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'shpAddress'}
                    label={siTranObject.shipperAddress}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'conName'}
                    label={siTranObject.consigneeName}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'conAddress'}
                    label={siTranObject.consigneeAddress}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'usccNo'}
                    label={siTranObject.usccNo}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'dstNotifyName'}
                    label={siTranObject.notifyPartyName}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'dstNotifyAddress'}
                    label={siTranObject.notifyPartyAddress}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'bookingRepresentativeDetail'}
                    label={siTranObject.bookingRepresentativeDetails}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'agtName'}
                    label={siTranObject.agentName}
                    group={tranObj.contact}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'agtAddress'}
                    label={siTranObject.agentAddress}
                    group={tranObj.contact}
                  />
                  {/* Shipment */}
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'moveType'}
                    label={tranObj.moveType}
                    group={tranObj.shipment}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'placeOfReceiptCode'}
                    ports={ports}
                    label={tranObj.placeOfReceipt}
                    group={tranObj.shipment}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'placeOfDeliveryCode'}
                    ports={ports}
                    label={tranObj.placeOfDelivery}
                    group={tranObj.shipment}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'cargoReadyDate'}
                    label={tranObj.cargoReadyDate}
                    timestamp
                    group={tranObj.shipment}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'cargoDeliveryDate'}
                    label={tranObj.cargoDeliveryDate}
                    timestamp
                    group={tranObj.shipment}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'originReceipt'}
                    label={tranObj.placeOfReceiptDesc}
                    group={tranObj.shipment}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'destReceipt'}
                    label={tranObj.placeOfDeliveryDesc}
                    group={tranObj.shipment}
                  />
                  {/* <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'porStatus'}
                    label={siTranObject.termPor}
                    group={tranObj.shipment}
                  />
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'podStatus'}
                    label={siTranObject.termPod}
                    group={tranObj.shipment}
                  /> */}
                  <AuditTrailRowOfChange
                    object={shipInfo}
                    field={'alignCntrCargo'}
                    label={siTranObject.alignContainerWithCargo}
                    group={tranObj.shipment}
                  />
                  {/* Container */}
                  {shipInfo &&
                    shipInfo.shippingInfoContBoxDTOs &&
                    shipInfo.shippingInfoContBoxDTOs.added &&
                    shipInfo.shippingInfoContBoxDTOs.added.map(
                      (container, i) => (
                        <>
                          <AuditTrailRowOfChangeComplex
                            object={container}
                            added
                            fields={['contNo']}
                            group={tranObj.containerDetails}
                            label={calTranObj.added}
                          />
                          <AuditTrailRowOfChangeReefer object={container} added field={'nonActiveReefer'} group={tranObj.reeferDetails} label={calTranObj.added} />
                          <AuditTrailRowOfChangeReefer object={container} added field={'equipmentTemp'} group={tranObj.reeferDetails} label={calTranObj.added} />
                          <AuditTrailRowOfChangeReefer object={container} added field={'tempUom'} group={tranObj.reeferDetails} label={calTranObj.added} />
                        </>
                      )
                    )}
                  {shipInfo &&
                    shipInfo.shippingInfoContBoxDTOs &&
                    shipInfo.shippingInfoContBoxDTOs.removed &&
                    shipInfo.shippingInfoContBoxDTOs.removed.map(
                      (container, i) => (
                        <>
                          <AuditTrailRowOfChangeComplex
                            removed
                            object={container}
                            fields={['contNo']}
                            group={tranObj.containerDetails}
                            label={calTranObj.removed}
                          />
                          <AuditTrailRowOfChangeReefer object={container} added field={'nonActiveReefer'} group={tranObj.reeferDetails} label={calTranObj.added} />
                          <AuditTrailRowOfChangeReefer object={container} added field={'equipmentTemp'} group={tranObj.reeferDetails} label={calTranObj.added} />
                          <AuditTrailRowOfChangeReefer object={container} added field={'tempUom'} group={tranObj.reeferDetails} label={calTranObj.added} />
                        </>
                      )
                    )}
                  {shipInfo &&
                    shipInfo.shippingInfoContBoxDTOs &&
                    shipInfo.shippingInfoContBoxDTOs.updated &&
                    shipInfo.shippingInfoContBoxDTOs.updated.map(
                      (container, i) => (
                        <>
                          <AuditTrailRowOfChangeComplex
                            object={container}
                            fields={['contNo']}
                            group={tranObj.containerDetails}
                            label={calTranObj.updated}
                          />
                          <AuditTrailRowOfChangeReefer object={container} added field={'nonActiveReefer'} group={tranObj.reeferDetails} label={calTranObj.added} />
                          <AuditTrailRowOfChangeReefer object={container} added field={'equipmentTemp'} group={tranObj.reeferDetails} label={calTranObj.added} />
                          <AuditTrailRowOfChangeReefer object={container} added field={'tempUom'} group={tranObj.reeferDetails} label={calTranObj.added} />
                        </>
                      )
                    )}
                  {/* Cargo */}
                  {shipInfo &&
                    shipInfo.shippingInfoCargoDTOs &&
                    shipInfo.shippingInfoCargoDTOs.added &&
                    shipInfo.shippingInfoCargoDTOs.added.map((cargo, i) => (
                      <AuditTrailRowOfChangeComplex
                        object={cargo}
                        added
                        fields={['cargoDesc']}
                        group={siTranObject.cargoDetails}
                        label={calTranObj.added}
                      />
                    ))}
                  {shipInfo &&
                    shipInfo.shippingInfoCargoDTOs &&
                    shipInfo.shippingInfoCargoDTOs.removed &&
                    shipInfo.shippingInfoCargoDTOs.removed.map((cargo, i) => (
                      <AuditTrailRowOfChangeComplex
                        removed
                        object={cargo}
                        fields={['cargoDesc']}
                        group={siTranObject.cargoDetails}
                        label={calTranObj.removed}
                      />
                    ))}
                  {shipInfo &&
                    shipInfo.shippingInfoCargoDTOs &&
                    shipInfo.shippingInfoCargoDTOs.updated &&
                    shipInfo.shippingInfoCargoDTOs.updated.map((cargo, i) => (
                      <AuditTrailRowOfChangeComplex
                        object={cargo}
                        fields={['cargoDesc']}
                        group={siTranObject.cargoDetails}
                        label={calTranObj.updated}
                      />
                    ))}
                  {/* DG Cargo */}
                  {shipInfo &&
                    shipInfo.shippingInfoDGCargoDTOs &&
                    shipInfo.shippingInfoDGCargoDTOs.added &&
                    shipInfo.shippingInfoDGCargoDTOs.added.map((cargo, i) => (
                      <AuditTrailRowOfChangeComplex
                        object={cargo}
                        added
                        fields={['description']}
                        group={siTranObject.cargoDetails}
                        label={calTranObj.added}
                      />
                    ))}
                  {shipInfo &&
                    shipInfo.shippingInfoDGCargoDTOs &&
                    shipInfo.shippingInfoDGCargoDTOs.removed &&
                    shipInfo.shippingInfoDGCargoDTOs.removed.map((cargo, i) => (
                      <AuditTrailRowOfChangeComplex
                        removed
                        object={cargo}
                        fields={['description']}
                        group={siTranObject.cargoDetails}
                        label={calTranObj.removed}
                      />
                    ))}
                  {shipInfo &&
                    shipInfo.shippingInfoDGCargoDTOs &&
                    shipInfo.shippingInfoDGCargoDTOs.updated &&
                    shipInfo.shippingInfoDGCargoDTOs.updated.map((cargo, i) => (
                      <AuditTrailRowOfChangeComplex
                        object={cargo}
                        fields={['description']}
                        group={siTranObject.cargoDetails}
                        label={calTranObj.updated}
                      />
                    ))}
                  {/* Document */}
                  {shipInfo &&
                    shipInfo.shippingInfoDocumentDTOs &&
                    shipInfo.shippingInfoDocumentDTOs.added &&
                    shipInfo.shippingInfoDocumentDTOs.added.map(
                      (document, i) => (
                        <AuditTrailRowOfChangeComplex
                          object={document}
                          added
                          fields={['filename']}
                          group={tranObj.documents}
                          label={calTranObj.added}
                        />
                      )
                    )}
                  {shipInfo &&
                    shipInfo.shippingInfoDocumentDTOs &&
                    shipInfo.shippingInfoDocumentDTOs.removed &&
                    shipInfo.shippingInfoDocumentDTOs.removed.map(
                      (document, i) => (
                        <AuditTrailRowOfChangeComplex
                          removed
                          object={document}
                          fields={['filename']}
                          group={tranObj.documents}
                          label={calTranObj.removed}
                        />
                      )
                    )}
                  {shipInfo &&
                    shipInfo.shippingInfoDocumentDTOs &&
                    shipInfo.shippingInfoDocumentDTOs.updated &&
                    shipInfo.shippingInfoDocumentDTOs.updated.map(
                      (document, i) => (
                        <AuditTrailRowOfChangeComplex
                          object={document}
                          fields={['filename']}
                          group={tranObj.documents}
                          label={calTranObj.updated}
                        />
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </CngGridItem>
      </Grid>
    </div>
  )
}

export default AuditTrailSummary
