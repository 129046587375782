import React from 'react'
import { Box, Grid } from '@material-ui/core'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import { faSquareList } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DirectSI(props) {
  const translatedTextsObject = ShippingInstructionTranslationText()
  const skipText = props.skipText

  return (
    <Grid
      container
      xs
      direction='row'
      justify='flex-end'
      alignItems='center'
      className={'ng-button-filled-secondary2'}
    >
      <FontAwesomeIcon icon={faSquareList} color='grey' />
   
      {!skipText && (
        <Box style={{ color: 'grey' }}>
          &nbsp;{translatedTextsObject.directSI}
        </Box>
      )}
    </Grid>
  )
}

export default DirectSI
