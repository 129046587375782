import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants } from 'cng-web-lib'
import { useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form'

import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import ContainerTypeAutocompleteField from 'src/components/autocomplete/ContainerTypeAutocompleteField'
import { CsvBuilder } from 'filefy'
import FooterAddComponent from '../../common/ui/FooterAddComponent'
import FooterUploadComponent from '../../common/ui/FooterUploadComponent'
import { PlusCircle } from 'react-feather'
import SICargoDetailsComponent from './SICargoDetailsComponent'
import SIParticularsControlTotalComponent from './SIParticularsControlTotalComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import Utils from 'src/views/common/utils/Utils'
import { v4 as uuid } from 'uuid'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

const initialValues = {
  shippingInfoCargoes: [
    {
      cargoId: '',
      description: '',
      marksAndNumbers: '',
      hsCode: '',
      quantity: '',
      packageType: '',
      grossVolume: '',
      grossWeight: '',
      netVolume: '',
      netWeight: '',
      vgmWeight: '',
      containerNo: [],
      cargoBoxExpanded: false,
      uom: ''
    }
  ]
}

const FormBody = (props) => {
  const { isCarrierAmend } = props
  const { errors, touched, setFieldValue } = useFormikContext()
  const { getValues } = useForm()
  const { watch } = useFormContext()
  const [previousContainerLength, setPreviousContainerLength] = useState()

  const [siCargoList, setSiCargoList] = useState([])
  const [cargoField] = useField('bookingCargoes')
  // const [siCargoField, , { setValue: setSiCargoField }] = useField(
  //   'shippingInfoCargoes'
  // )
  const { fields: siCargoField } = useFieldArray({
    name: 'shippingInfoCargoes'
  })
  // const [siContainersField, containersMeta, { setValue: setContainersField }] =
  //   useField('shippingInfoContainerBoxes')
  const { fields: siContainersField } = useFieldArray({
    name: 'shippingInfoContainerBoxes'
  })
  const shippingInfoContainerBoxesWatch = watch('shippingInfoContainerBoxes')

  //const siContainersField = getValues('shippingInfoContainerBoxes')

  const [siContainerList, setSiContainerList] = useState([])
  const [packageUOMType, setPackageUOMType] = useState([])
  const [containerNoItems, setContainerNoItems] = useState([])
  const [containerNoList, setContainerNoList] = useState([])
  const draftCargoId = useRef(-1)
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  const getFieldError = (itemIndex, fieldPath) => {
    //not necessary
  }
  useWatch({ name: 'shippingInfoCargoes' }); 
  //const frbTranslatedTextsObject = FreightBookingTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const cargoPackageKeyRef = useRef(uuid())
  const cargoUomKeyRef = useRef(uuid())

  const cargoItem = {
    cargoTempId: -1, //for react key reference, actual field is "cargoId"
    id: uuid(),
    cargoId: null,
    description: '',
    marksAndNumbers: '',
    hsCode: '',
    quantity: '',
    packageType: '',
    grossVolume: '',
    grossWeight: '',
    netVolume: '',
    netWeight: '',
    length: '',
    height: '',
    width: '',
    uom: '',
    cargoBoxExpanded: false,
    containerNo: []
  }

  const  containerDimentionFields= ['height','length','width']
 
  // handle input change
  const handleInputChange = (e, index) => {
    console.log(e.target)
    const { name, value } = e.target
    const list = [...siCargoField]
    let field = name.split('.')[1]
    //when field is containerNo, and in the case user modify the container no. which already tie to the cargo, the old container no. will still inside the value list
    //and in this case need to remove it.
    let newValue = parseValueWhenInputChange(field, value)

   //
    list[index][field] = newValue
    if(containerDimentionFields.includes(field) 
        && Utils.isEmpty(list[index].uom)
        && ( !Utils.isEmpty(list[index].length) || !Utils.isEmpty(list[index].width) ||!Utils.isEmpty(list[index].height))
        ){

      list[index].uom = 'CENTIMETER'
      cargoUomKeyRef.current = uuid()
    
    }

    console.log('cargohandleInputChange list', list)
    setSiCargoList(list)
    // setSiCargoField(list)

    if (field === 'containerNo') {
      setFieldValue(`shippingInfoCargoes[${index}].${field}`, newValue)
    } else {
      setFieldValue(`shippingInfoCargoes`, list)
    }
  }

  const populateFieldChange =(index, field, newValue)=>{
    const list = [...siCargoField]
    list[index][field] = newValue

    console.log('populateFieldChange newValue', newValue)
    setSiCargoList(list)
    if (field === 'containerNo') {
      setFieldValue(`shippingInfoCargoes[${index}].${field}`, newValue)
    } else {
      setFieldValue(`shippingInfoCargoes`, list)
    }
  }

  function parseValueWhenInputChange(field, value) {
    if (field === 'containerNo') {
      let newValue = []
      if (value.length > 0) {
        console.log(containerNoList)
        console.log(value)
        newValue = value.filter(function (elem) {
          return containerNoList.includes(elem)
        })
        console.log(newValue)
      }
      return newValue
    } else {
      return value
    }
  }

  const handleDropdownChange = (val, index, name) => {
    const list = [...siCargoField]

    list[index][name] = val

    setSiCargoList(list)
    // setSiCargoField(list)
    setFieldValue('shippingInfoCargoes', list)
  }

  // handle click event of the Remove button

  // handle click event of the Add button

  const exportCsv = () => {
    let finalDataArray = []

    siContainerList.map((contElem, i) => {
      let initial_row = [
        contElem.contId,
        contElem.containerNo,
        contElem.sealNo,
        contElem.containerIsoType,
        contElem.containerStatus,
        contElem.shipperOwned,
        contElem.grossWeight,
        contElem.totalVgmWeight
      ]
      if (siCargoField != null && siCargoField.length > 0) {
        console.log(siCargoField)
        siCargoField.map((cargo, j) => {
          console.log(cargo.containerNo)
          let tempContainerNoList = cargo.containerNo
          if (tempContainerNoList.includes(contElem.containerNo)) {
            //extend the cargo
            let cargo_info = [
              cargo.cargoTempId,
              cargo.description,
              cargo.marksAndNumbers,
              cargo.quantity,
              cargo.hsCode,
              cargo.packageType,
              cargo.grossVolume,
              cargo.grossWeight,
              cargo.netVolume,
              cargo.netWeight,
              cargo.length,
              cargo.height,
              cargo.width,
              cargo.uom
            ]
            finalDataArray.push(initial_row.concat(cargo_info))
          } else {
            finalDataArray.push(
              initial_row.concat([
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
              ])
            )
          }
          return null
        })
      }
      return null
    })

    var csvBuilder = new CsvBuilder('Container&CargoInformation.csv')
    csvBuilder
      .setColumns([
        'CONTAINER DETAILS',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'CARGO DETAILS'
      ])
      .addRow([
        'Container Id',
        'Container No.',
        'Seal No.',
        'Container Type',
        'Container Status',
        'Shipper Owned',
        'Container Gross Weight(KG)',
        'VGM Weight(KG)',
        'Cargo Id',
        'Description',
        'Marks & Numbers',
        'Quantity',
        'HS Code',
        'Package Type',
        'Gross Volume(CBM)',
        'Gross Weight(KG)',
        'Net Volume(CBM)',
        'Net Weight(KG)',
        'Length',
        'Height',
        'Width',
        'Unit of Measurement'
      ])
      .addRows(finalDataArray)
      .exportFile()
  }

  useEffect(() => {
    console.log('cargo component props.isEdit > ' + props.isEdit)
    console.log('cargo component props.isDirectSI > ' + props.isDirectSI)
    setFieldValue('shippingInfoDGCargoes', [])
    /**
     * there are 3 scenarios.
     * 1. isEdit is false, then this is a new si
     *    1.1. directSI is false --> populate si list from booking
     *    1.2  directSI is true --> add one general cargo
     * 2. isEdit is true, then this is a created si
     *    no matter this is direct si or not, --> popullate si list from si field
     */

    const cargo = { ...cargoItem }
    cargo.cargoTempId = draftCargoId.current

    draftCargoId.current = draftCargoId.current - 1
    console.log('siCargoField', siCargoField)

    if (props.isEdit) {
      // setSiCargoList([...siCargoField.value])
      if (siCargoField.length === 0) {
        //when user switch from dg cargo, the cargo field could be empty
        createNewCargoList(cargo)
      } else {
        let cargoListWithExpanded = siCargoField.map((v) => ({
          ...v,
          containerNo: v.containerNo === null ? [] : v.containerNo, //in case si without container
          // uom: 'M',
          // packageType: 'BAR',
          cargoBoxExpanded: false
        }))
        console.log('cargoListWithExpanded', cargoListWithExpanded)
        setSiCargoList(cargoListWithExpanded)
        setFieldValue('shippingInfoCargoes', cargoListWithExpanded)
      }
    } else {
      if (props.isDirectSI) {
        let cargoListWithExpanded = [cargoItem]

        setSiCargoList(cargoListWithExpanded)
        // setSiCargoField(cargoListWithExpanded)
        setFieldValue('shippingInfoCargoes', cargoListWithExpanded)
      } else {
        if (cargoField.value != null) {
          let temp = cargoField.value.map((v) => ({
            ...v,
            id: uuid(),
            cargoBoxExpanded: false,
            description: v.cargoDesc,
            containerNo: [],
            marksAndNumbers: '',
            cargoId: null
          }))

          setSiCargoList(temp)
          // setSiCargoField(temp)
          setFieldValue('shippingInfoCargoes', temp)
        }
      }
    }

    props.setShouldRender(true)
  }, [props.isEdit, cargoField.value, props.isDirectSI, props.reloadSIData])

  const createNewCargoList = (cargo) => {
    setSiCargoList([cargo])
    // setSiCargoField([cargo])
    setFieldValue('shippingInfoCargoes', [cargo])
  }

  //render again when containersField.value has been set
  useEffect(() => {
    console.log('siContainersField', siContainersField)

    //set container no list for cargo to link
    let containerList = []
    let containerItems = []
    if (siContainersField != undefined) {
      siContainersField.forEach((elem) => {
        let temp
        if (!Utils.isEmpty(elem.containerNo)) {
          temp = { text: elem.containerNo, value: elem.containerNo }
          containerList.push(elem.containerNo)
          // return
          containerItems.push(temp)
        }
        // else {
        //   return containerItems.remove(temp)
        // }
      })
      console.log('containerItems', containerItems)
      console.log('containerList', containerList)
      setContainerNoItems(containerItems)
      setContainerNoList(containerList)
      setSiContainerList(siContainersField)

      //check if cargo container no contains any deleted container no and remove them
      if (previousContainerLength > containerList.length) {
        console.log('scan and remove')
        const list = [...siCargoField]
        list.forEach((cargo) => {
          let containerNos = cargo.containerNo
          if (containerNos != null) {
            containerNos.forEach((containerNo, i) => {
              if (containerList.includes(containerNo)) {
                console.log('can keep', containerNo)
              } else {
                console.log('should remove', containerNo)
                containerNos.splice(i, 1)
              }
            })
          }
        })
      }
      setPreviousContainerLength(containerList.length)
    }
  }, [shippingInfoContainerBoxesWatch])

  const removeRowClick = (id) => () => {
    const list = [...siCargoField]
    list.splice(id, 1)

    cargoPackageKeyRef.current = uuid()

    cargoPackageKeyRef.current = uuid()
    cargoUomKeyRef.current = uuid()

    setSiCargoList(list)
    // setSiCargoField(list)
    setFieldValue('shippingInfoCargoes', list)
  }

  const copyRowClick = (index) => () => {
    const list = [...siCargoField]
    // const list = [...containersField];
    const copyCargo = list.slice(index, index + 1)[0]
    // alert('index : ' + index + ", " + JSON.stringify(copyContainer))

    const copyCargoItem = { ...copyCargo }
    copyCargoItem.cargoTempId = draftCargoId.current
    copyCargoItem.id = uuid()
    copyCargoItem.cargoId = null //cargo id is unique for every cargo,cannot be copied from an existing one
    copyCargoItem.seqNo = null// cargo seq no also must be unique for every cargo,cannot be copied from an existing one
    draftCargoId.current = draftCargoId.current - 1

    setSiCargoList([...siCargoField, copyCargoItem])
    // alert('container List,' + JSON.stringify(containerList))

    // setSiCargoField([...siCargoList, copyCargoItem])
    setFieldValue('shippingInfoCargoes', [...siCargoField, copyCargoItem])
  }

  const handleAddCargo = () => {
    setSiCargoList([...siCargoField, cargoItem])
    // setSiCargoField([...siCargoList, cargoItem])
    setFieldValue('shippingInfoCargoes', [...siCargoField, cargoItem])
  }

  const handleCargoDetailsExpand = (idx) => () => {
    const isCargoBoxExpanded = siCargoField[idx].cargoBoxExpanded
    console.log(isCargoBoxExpanded)
    const temp = [...siCargoField]
    temp[idx].cargoBoxExpanded = !isCargoBoxExpanded
    console.log('cargotemp', temp)
    setSiCargoList([].concat([], temp))
    // setSiCargoField([].concat([], temp))
    //  setFieldValue('shippingInfoCargoes', temp)
    setFieldValue(
      `shippingInfoCargoes[${idx}].cargoBoxExpanded`,
      !isCargoBoxExpanded
    )
  }

  return (
    props.shouldRender && (
      <Box>
        <Card>
          <Box px={2} my={1.5}>
            <Grid container justify='flex-end'>
              <AccordionHeaderComponent title={siTranslatedTextsObject.cargo} />
              {!props.isCarrier && (
                <Box pr={2} pb={2}>
                  <Button
                    variant='contained'
                    classes={{ root: 'ng-button-filled-secondary' }}
                    className='originalText'
                    onClick={() => {
                      exportCsv()
                    }}
                  >
                    {siTranslatedTextsObject.exportCsv}
                  </Button>
                </Box>
              )}
            </Grid>
            {/* {'siContainerList: ' + JSON.stringify(siContainerList)} */}
            {/* {'cargoList: ' + JSON.stringify(siCargoList)} */}
            <SICargoDetailsComponent
              list={siCargoList}
              packageUOMType={packageUOMType}
              removeRowClick={removeRowClick}
              copyRowClick={copyRowClick}
              handleCargoDetailsExpand={handleCargoDetailsExpand}
              handleInputChange={handleInputChange}
              getFieldError={getFieldError}
              handleDropdownChange={handleDropdownChange}
              cargoPackageKeyRef={cargoPackageKeyRef.current}
              cargoUomKeyRef={cargoUomKeyRef.current}
              containerNoItems={containerNoItems}
              isCarrier={props.isCarrier}
              populateFieldChange={populateFieldChange}
            />
          </Box>
          {(!props.isCarrier && props.shippingInfoId != null) && (
            <Box px={2} my={1} mr={2}>
              <Grid container spacing={2}>
                <CngGridItem xs={6} sm={6}>
                  <FooterAddComponent
                    handleAddChild={handleAddCargo}
                    footerText={siTranslatedTextsObject.addCargo}
                  />
                </CngGridItem>
                <CngGridItem xs={6} sm={6}>
                  <FooterUploadComponent
                    handleUploadChild={props.handleSICargoUploadDialog}
                    footerText={siTranslatedTextsObject.uploadCargo}
                    shippingInfoId={props.shippingInfoId}
                  />
                </CngGridItem>
              </Grid>
            </Box>
          )}
          {(!props.isCarrier && props.shippingInfoId == null) && (
            <FooterAddComponent
              handleAddChild={handleAddCargo}
              footerText={siTranslatedTextsObject.addCargo}
            />
          )}
        </Card>

        <Box pt={5}>
          <SIParticularsControlTotalComponent
            cargoList={siCargoField}
            containerList={siContainerList}
            isEdit={props.isEdit}
          />
        </Box>
      </Box>
    )
  )
}

const SICargoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SICargoComponent
