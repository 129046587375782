import * as CalistaConstant from 'src/constants/CalistaConstant'

import { Box, Card, Grid, Chip, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import roundToNearestMinutesWithOptions from 'date-fns/fp/roundToNearestMinutesWithOptions'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import Utils from 'src/views/common/utils/Utils'

const {
  form: {
    field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const { CodeMaintenanceType } = constants

const FormBody = (props) => {
  const translatedTextsObjects = ShippingInstructionTranslationText()
  const { fetchRecords } = useServices()
  //const [containerTypes, setContainerTypes] = useState([])
  const { auditSI } = props
  let siCargoList = props.cargo

  // function onContainerTypeLoadSuccess(data) {
  //   console.log(data)
  //   setContainerTypes(data)
  //   // const matched = data.find((container) => container?.isoCode === props.containerIsoType)
  //   // setContainerTypeMatched(matched)
  // }

  const initialHighLight = [
    {
      id: 0,
      cargo: {},
      cargoDesc: {},
      containerNo: {},
      grossVolume: {},
      grossWeight: {},
      height: {},
      hsCode: {},
      length: {},
      marksNNo: {},
      netWeight: {},
      packageType: {},
      quantity: {},
      uom: {},
      width: {}
    }
  ]

  const [highlight, setHighlight] = useState(initialHighLight)

  useEffect(() => {
    // SharedServiceApi.fetchContainerType(
    //   fetchRecords,
    //   false,
    //   onContainerTypeLoadSuccess
    // )

    if (auditSI && auditSI.shippingInfoCargoDTOs) {
      let hl = SIUtils.auditHighLightCargo(
        auditSI.shippingInfoCargoDTOs,
        siCargoList
        // containerTypes
      )
      setHighlight(hl)
      console.log('cargoCompareshl', hl)
    }
  }, [props])

  function getHighlight(id, field) {
    console.log('hl getHighlight', highlight)
    let matched = highlight.find((hl) => hl?.id == id)
    console.log('hl matched2', matched)

    return Utils.isNotEmpty(matched) ? matched[field] : {}
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>{translatedTextsObjects.cargo}</Box>

          <Box>
            {siCargoList.map((row, idx) => {
              return (
                <ComponentWrapper
                  headerStyle='view-card-header-no-bg'
                  cardStyle={{ margin: 20 }}
                  backgroundStyle='inner-card-background'
                >
                  <Card
                    className={'titleBox'}
                    style={{ backgroundColor: '#8996AF0A' }}
                  >
                    <Grid container xs={12} alignItems='center'>
                      <CngGridItem xs={3}>
                        <Typography
                          className={'boldLabel'}
                          style={{ fontSize: 16 }}
                        >
                          {'Cargo ' + (idx + 1)}
                        </Typography>
                      </CngGridItem>
                      <Grid xs />
                      <Grid container xs={3} alignItems='center'>
                        <Grid sm={10}>
                          <Typography className={'greyLabel alignRight'}>
                            {translatedTextsObjects.cargoId +
                              ': ' +
                              (row.cargoId === null ? 'N/A' : row.cargoId)}
                          </Typography>
                        </Grid>
                        <Grid sm={2} container justify='flex-end'></Grid>
                      </Grid>
                    </Grid>
                  </Card>

                  <Grid
                    container
                    xs={12}
                    sm={12}
                    className='view-card-item-content'
                    style={getHighlight(row.cargoId, 'cargo')}
                  >
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      style={getHighlight(row.cargoId, 'cargoDesc')}
                    >
                      <LabelValueVertical
                        label={translatedTextsObjects.cargoDesc}
                        value={row.description}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      style={{ paddingRight: 20, marginTop: 16 }}
                    >
                      <ComponentWrapper>
                        <Grid container xs={12} sm={12}>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            style={getHighlight(row.cargoId, 'hsCode')}
                          >
                            <LabelValueVertical
                              label={translatedTextsObjects.hsCode}
                              value={row.hsCode}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            alignContent='center'
                            style={getHighlight(row.cargoId, 'packageType')}
                          >
                            <Box className='view-card-content'>
                              <Grid container justify='space-between'>
                                <Grid item xs={12} sm={12}>
                                  <Box className='view-card-content-label'>
                                    {translatedTextsObjects.packageType}
                                  </Box>
                                </Grid>
                                <Box className='view-card-content-value'>
                                  <CodeMasterMiscViewField
                                    codeType={CodeMaintenanceType.PACKAGE_UOM}
                                    code={row.packageType}
                                  />
                                </Box>
                              </Grid>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            sm={4}
                            alignContent='flex-end'
                            style={getHighlight(row.cargoId, 'quantity')}
                          >
                            <LabelValueVertical
                              label={translatedTextsObjects.totalQuantity}
                              value={row.quantity}
                            />
                          </Grid>
                        </Grid>
                      </ComponentWrapper>

                      <Grid container xs={12} sm={12} style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={12}>
                          <ComponentWrapper>
                            <Grid
                              container
                              xs={12}
                              sm={12}
                              style={getHighlight(row.cargoId, 'containerNo')}
                            >
                              <Grid item xs={12} sm={12} alignContent='center'>
                                <Box className='view-card-content'>
                                  <Grid container justify='space-between'>
                                    <Grid item xs={12} sm={12}>
                                      <Box className='view-card-content-label'>
                                        {
                                          translatedTextsObjects.relatedContainerNos
                                        }
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <Box className='view-card-content-value'>
                                        <Grid
                                          container
                                          xs={12}
                                          sm={12}
                                          spacing={1}
                                        >
                                          {row.containerNo &&
                                            row.containerNo.map((contNo) => {
                                              return (
                                                <Grid item>
                                                  <Chip
                                                    size='small'
                                                    label={contNo}
                                                  ></Chip>
                                                </Grid>
                                              )
                                            })}
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </ComponentWrapper>
                        </Grid>
                      </Grid>

                      <Grid container xs={12} sm={12} style={{ marginTop: 10 }}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          style={{ paddingRight: 10 }}
                          // style={getHighlight(row.cargoId, 'grossVolume')}
                        >
                          <ComponentWrapper
                            rightConner={
                              <Chip
                                label={translatedTextsObjects.cbm}
                                size='small'
                                className='white-chip'
                              />
                            }
                          >
                            <LabelValueVertical
                              label={translatedTextsObjects.totalGrossVolume}
                              value={row.grossVolume}
                              boxStyle={{
                                ...getHighlight(row.cargoId, 'grossVolume'),
                                display: 'inline-block',
                                width: '120%'
                              }}
                            />
                            {/* <LabelValueVertical
                              label={translatedTextsObjects.totalNetVolume}
                              value={row.netVolume}
                            /> */}
                          </ComponentWrapper>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <ComponentWrapper
                            rightConner={
                              <Chip
                                label={translatedTextsObjects.kg}
                                size='small'
                                className='white-chip'
                              />
                            }
                          >
                            <LabelValueVertical
                              label={translatedTextsObjects.totalGrossWeight}
                              value={row.grossWeight}
                              boxStyle={{
                                ...getHighlight(row.cargoId, 'grossWeight'),
                                display: 'inline-block',
                                width: '120%'
                              }}
                            />
                            <LabelValueVertical
                              label={translatedTextsObjects.totalNetWeight}
                              value={row.netWeight}
                              boxStyle={{
                                ...getHighlight(row.cargoId, 'netWeight'),
                                display: 'inline-block',
                                width: '120%'
                              }}
                            />
                          </ComponentWrapper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      style={getHighlight(row.cargoId, 'marksNNo')}
                    >
                      <LabelValueVertical
                        label={translatedTextsObjects.marksAndNos}
                        value={row.marksAndNumbers}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      style={{ paddingRight: 20, marginTop: 16 }}
                    >
                      <ComponentWrapper
                        rightConner={
                          <Chip
                            label={row.uom}
                            size='small'
                            className='white-chip'
                          />
                        }
                      >
                        <Grid container xs={12} sm={12}>
                          <Grid item xs={4} sm={4}>
                            <LabelValueVertical
                              label={translatedTextsObjects.length}
                              value={row.length}
                              boxStyle={{
                                ...getHighlight(row.cargoId, 'length'),
                                display: 'inline-block'
                                // width: '120%'
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} sm={4} alignContent='center'>
                            <LabelValueVertical
                              label={translatedTextsObjects.height}
                              value={row.height}
                              boxStyle={{
                                ...getHighlight(row.cargoId, 'height'),
                                display: 'inline-block'
                              }}
                            />
                          </Grid>

                          <Grid item xs={4} sm={4} alignContent='flex-end'>
                            <LabelValueVertical
                              label={translatedTextsObjects.width}
                              value={row.width}
                              boxStyle={{
                                ...getHighlight(row.cargoId, 'width'),
                                display: 'inline-block'
                              }}
                            />
                          </Grid>
                        </Grid>
                      </ComponentWrapper>
                    </Grid>
                  </Grid>
                </ComponentWrapper>
              )
            })}
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const ViewSICargoComponent = Object.freeze({
  FormBody: FormBody
  // initialValues: initialValues
})

export default ViewSICargoComponent
