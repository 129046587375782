import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Alert from '@material-ui/lab/Alert'
import { components, useServices } from 'cng-web-lib'
import React, { useState, useContext, useEffect } from 'react'
import { FileText } from 'react-feather'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import DiscardButton from 'src/components/button/DiscardButton.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import SICargoUploadContext from './SICargoUploadContext'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import SIApi from 'src/views/shippinginstruction/shared/api'
import Utils from 'src/views/common/utils/Utils'

const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    }
  },
  CngDialog,
  CngGridItem,
  dropzone: { CngFileUpload, HelperTextArea },
  table: { useDefaultNotification },
  button: { CngPrimaryButton, CngSecondaryButton },
  form: { CngViewForm }
} = components

function SICargoUploadForm(props) {
  const dialogContext = useContext(SICargoUploadContext)

  const {
    showNotification,
    isDialogOpen,
    closeDialog,
    title,
    shippingInfo,
    setShippingInfo,
    setPageLoading,
    closeAndUpdateSI,
    refresh
  } = dialogContext

  return (
    <CngViewForm
      formikProps={{
        initialValues: {},
        //validationSchema: validationSchema,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          showNotification={showNotification}
          closeDialog={closeDialog}
          shippingInfo={shippingInfo}
          setShippingInfo={setShippingInfo}
          setPageLoading={setPageLoading}
          refresh={refresh}
          saveFiles={props.saveFiles}
          confirmOverwriteDialog={props.confirmOverwriteDialog}
          confirmDiscardDialog={props.confirmDiscardDialog}
          closeAndResetDialog={props.closeAndResetDialog}
          pendingFiles={props.pendingFiles}
          setPendingFiles={props.setPendingFiles}
          uploadedFiles={props.uploadedFiles}
          setUploadedFiles={props.setUploadedFiles}
          loading={props.loading}
          isSubmitting={props.isSubmitting}
          allSuccess={props.allSuccess}
          tempUploadCargoPendingFile={props.tempUploadCargoPendingFile}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}
export default SICargoUploadForm

function FormBody({
  showNotification,
  closeDialog,
  openDialog,
  shippingInfo,
  setShippingInfo,
  setPageLoading,
  refresh,
  saveFiles,
  confirmOverwriteDialog,
  confirmDiscardDialog,
  closeAndResetDialog,
  uploadedFiles,
  setUploadedFiles,
  pendingFiles,
  setPendingFiles,
  loading,
  isSubmitting,
  allSuccess,
  tempUploadCargoPendingFile
}) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  const { createRecord, fetchRecords } = useServices()
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const showErrorMessages = (errorMessages) => {
    showErrorNotification(errorMessages[0])
  }

  const moreActions = [
    {
      action: 'remove',
      name: 'Delete',
      icon: ['fal', 'trash']
    }
  ]

  useEffect(() => {
    if (
      Utils.isNotEmpty(tempUploadCargoPendingFile) &&
      tempUploadCargoPendingFile.length > 0
    ) {
      setPendingFiles(tempUploadCargoPendingFile)
    }
  }, [])

  return (
    <>
      <Paper>
        <Box m={1.5}>
          <Box pt={1}>
            <Grid container xs={12} sm={12} justify='center'>
              <Grid container xs={12} sm={12} justify='flex-end'>
                <a
                  className='download'
                  href={
                    process.env.PUBLIC_URL +
                    '/static/doc/SI_Container_Cargo_Template.xlsx'
                  }
                  download={'SI_Container_Cargo_Template.xlsx'}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    classes={{ root: 'ng-button-filled-secondary' }}
                    startIcon={<GetAppOutlinedIcon />}
                  >
                    {siTranslatedTextsObject.cargoTemplateButton}
                  </Button>
                </a>
              </Grid>
              <CngGridItem xs={12} sm={12}>
                <SICargoUploadDetails
                  uploadedFiles={uploadedFiles}
                  hideEdit={true}
                  hideDelete={false}
                  loading={loading}
                // removeRowClick={handleDeleteFile}
                // downloadRowClick={handleDownloadFile}
                // downloadLogClick={handleDownloadLog}
                />
              </CngGridItem>
              <CngGridItem
                xs={12}
                sm={12}
                style={{ display: loading ? 'none' : 'inline' }}
              >
                <CngFileUpload
                  accept={['.xlsx', '.xls']}
                  maxSize={10485760}
                  maxFiles={1}
                  files={pendingFiles}
                  onFileSelect={(selectedFileList) => {
                    console.log('selected file list: ', selectedFileList)
                    selectedFileList.forEach((f) => {
                      f.status = 'Pending'
                    })
                    setPendingFiles(selectedFileList)
                    //setPendingFilesField(selectedFileList)
                  }}
                  disabled={isSubmitting}
                  moreActions={moreActions}
                  showFormFields={false}
                  fieldConfig={[]}
                  onDropRejected={(e) => showErrorMessages(e)}
                  renderHelperText={() => {
                    return (
                      <HelperTextArea
                        accept={['.xlsx', '.xls']}
                        maxFiles={1}
                        maxSize={10485760}
                      />
                    )
                  }}
                />
              </CngGridItem>
              <CngGridItem>
                <Box></Box>
              </CngGridItem>
            </Grid>
          </Box>
          <Box display='flex' mt={2}>
            <Grid container xs={6} sm={6} justify='flex-start'></Grid>
            <Grid container xs={6} sm={6} justify='flex-end'>
              <Box pr={2} style={{ display: allSuccess ? 'none' : 'inline' }}>
                <DiscardButton
                  onClick={confirmDiscardDialog}
                  disabled={loading}
                />
              </Box>
              <Box style={{ display: allSuccess ? 'none' : 'inline' }}>
                <CngPrimaryButton
                  // isSubmitting={isSubmitting}
                  disabled={pendingFiles.length === 0}
                  onClick={confirmOverwriteDialog}
                >
                  {siTranslatedTextsObject.uploadCargo}
                </CngPrimaryButton>
              </Box>
              <Box style={{ display: allSuccess ? 'inline' : 'none' }}>
                <SubmitButton
                  isSubmitting={isSubmitting}
                  disabled={!allSuccess}
                  onClick={closeAndResetDialog}
                >
                  {siTranslatedTextsObject.dialogCloseDialog}
                </SubmitButton>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Paper>

      {/* <ConfirmDialog
        isConfirmDialogOpen={discardDialogOpen}
        closeDialog={() => {
          setDiscardDialogOpen(false)
          // closeDialog(true)
        }}
        confirmDialog={closeAndResetDialog}
        content={siTranslatedTextsObject.dialogDiscardCargoUploadContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={siTranslatedTextsObject.dialogDiscardCargoUploadTitle}
      />

      <ConfirmDialog
        isConfirmDialogOpen={overwriteDialogOpen}
        closeDialog={() => {
          setOverwriteDialogOpen(false)
          closeDialog(true)
        }}
        confirmDialog={saveFiles}
        content={siTranslatedTextsObject.dialogOverwriteCargoContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={siTranslatedTextsObject.dialogOverwriteCargoTitle}
      /> */}
    </>
  )
}

function SICargoUploadDetails({ uploadedFiles, loading }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    console.log('handle close')
    setAnchorEl(null)
  }

  function fileStatus(f) {
    if (f.status === 'Completed') {
      return (
        <Box mt={2} mr={8} className='rs-green'>
          <b>Success</b>
        </Box>
      )
    } else if (f.status === 'Error') {
      return (
        <Box mt={2} mr={8} style={{ color: 'red' }}>
          <b>File Error</b>
          <IconButton
            edge='end'
            className={`row-more-actions`}
            onClick={handleClick}
            style={{ display: f.statusMessages.length > 0 ? 'inline' : 'none' }}
          >
            <InfoOutlinedIcon />
          </IconButton>
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 'auto',
                width: 'auto'
              }
            }}
          >
            <MenuItem disabled={true}>
              <Box ml={1}>
                <Typography variant='inherit' style={{ fontSize: 'small' }}>
                  {f.statusMessages}
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      )
    } else {
      return (
        <Box mt={2} mr={8} style={{ color: '#8181a5' }}>
          <div style={{ display: loading ? 'none' : 'inline' }}>
            <b>In Progress...</b>
          </div>
          <div style={{ display: loading ? 'inline' : 'none' }}>
            <CircularProgress />
          </div>
        </Box>
      )
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table id='table'>
        <TableBody>
          {uploadedFiles.map((f, i) => {
            return (
              <TableRow key={`row-${i}`}>
                <TableCell>
                  <Box display='flex'>
                    <Box flexGrow={1}>
                      <Box display='flex'>
                        <Box>
                          <Avatar style={{ backgroundColor: '#f5f6fa' }}>
                            <FileText size='25px' />
                          </Avatar>
                        </Box>
                        <Box ml={2}>
                          <DocumentInfoDecoratorComponent
                            bold={true}
                            name={f.file.name}
                            size={f.file.size}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box>{fileStatus(f)}</Box>
                  </Box>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
