import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'

import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import { components, useServices } from 'cng-web-lib'
import SIUploadDetailsComponent from 'src/views/shippinginstruction/components/SIUploadDetailsComponent'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls'
import DocumentApi from 'src/views/common/api/DocumentApi'

const {
  CngGridItem,

  table: { useDefaultNotification }
} = components

const FormBody = (props) => {
  const translatedTextsObject = ShippingInstructionTranslationText()

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  const { securedSendRequest } = useServices()
  const uploadedSI = props.uploadedSIDocuments

  const downloadUploadedRowClick = (id) => {
    console.log('download uploaded: ' + id)
    DocumentApi.downloadSIUploadedDoc(
      securedSendRequest,
      uploadedSI[id].id,
      uploadedSI[id].fileName,
      onDownloadError
    )
  }

  const onDownloadError = (error) => {
    console.log(error)
    showErrorNotification('Error downloading file - ' + error.message)
  }

  const downloadLogRowClick = (id) => {
    console.log('download log: ' + id)
    DocumentApi.downloadSIUploadedDocLog(
      securedSendRequest,
      uploadedSI[id].id,
      uploadedSI[id].responseFilename,
      onDownloadError
    )
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {translatedTextsObject.uploadSiInfo}
          </Box>
          <Box>
            <SIUploadDetailsComponent
              tile={props.uploadedSIDocuments}
              downloadRowClick={downloadUploadedRowClick}
              downloadLogClick={downloadLogRowClick}
              viewOnly
            />
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({})

const ViewSIUploadDocumentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewSIUploadDocumentComponent
