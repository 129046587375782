import { Box, Grid, Paper, Typography, Card } from '@material-ui/core'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import { constants, useServices } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'
import { components } from 'cng-web-lib'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import AuditTrailSchedule from './AuditTrailSchedule'
import AuditTrailContainer from './AuditTrailContainer'
import AuditTrailContact from './AuditTrailContact'
import AuditTrailShipment from './AuditTrailShipment'
import AuditTrailPayment from 'src/views/freightbooking/components/AuditTrailPayment'
import AuditTrailCargo from './AuditTrailCargo'
import AuditTrailDgCargo from './AuditTrailDgCargo'
import Utils from 'src/views/shippinginstruction/shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import AuditTrailDocument from 'src/views/freightbooking/components/AuditTrailDocument'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import CarrierApi from 'src/views/common/api/CarrierApi'
const { CodeMaintenanceType } = constants
const {
  CngDialog,
  CngGridItem,
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const FIELDS_SHIPMENT = [
  'moveType',
  'placeOfReceiptCode',
  'placeOfDeliveryCode',
  'cargoReadyDate',
  'cargoDeliveryDate',
  'originReceipt',
  'destReceipt',
  'porStatus',
  'podStatus',
  'alignCntrCargo'
]

const FIELDS_CONTACT = [
  'blApplnType',
  'surrenderBl',
  'bolNumber',
  'originalNo',
  'shipOnBoardDate',
  'containerPackage',
  'issueDate',
  'placeOfIssue',
  'carrierClauseDesc',
  'formLayout',
  'freightCharge',
  'depot',
  'cntrPkg',
  'shpName',
  'shpAddress',
  'conName',
  'conAddress',
  'usccNo',
  'dstNotifyName',
  'dstNotifyAddress',
  'bkgRep',
  'agtName',
  'agtAddress',
  'partnerEmails',
  'notifyPartnerEmails'
]

const FIELDS_SCHEDULE = [
  'vesselName',
  'voyageNo',
  'polCode',
  'podCode',
  'polDesc',
  'podDesc',
  'preCarriageVesName',
  'preCarriageVoyNo'
]

const FIELDS_PAYMENT = ['chrgType', 'frtTerm', 'payer', 'pmtLoc']

const FIELDS_REEFER = ['nonActiveReefer', 'equipmentTemp', 'tempUom', 'equipmentAirFlow', 'equipmentComments']

function ViewAuditDetailDialog({
  isDialogOpen,
  closeDialog,
  auditCompare,
  currentSI,
  currentSIStatus,
  ports,
  newDate,
  prevDate
}) {
  const tranObj = FreightBookingTranslationText()
  const tranObjCommon = CalistaUiComponentTranslationText()
  const { fetchRecords } = useServices()
  const [codeMasterList, setCodeMasterList] = useState([])
  const [docTypes, setDocTypes] = useState([])
  const [packageUom, setPackageUom] = useState([])
  const [containerTypes, setContainerTypes] = useState([])
  const [carriers, setCarriers] = useState([])

  const matchBookStatus = {
    field: 'id',
    displayField: 'name',
    items: Utils.SIStatus
  }

  const si = auditCompare || {}

  const dgCargos = si.dangerousGoods || []
  const cargos = si.cargos || []

  useEffect(() => {
    CodeMasterApi.fetchMultiCodeMaster(
      fetchRecords,
      [
        CalistaConstant.CAL_DG_IMO,
        CalistaConstant.CAL_DG_TEMP_UOM,
        CalistaConstant.CAL_DG_MARINE,
        CalistaConstant.CAL_DG_PSN_INH_HZD_ZN,
        CalistaConstant.CAL_DG_AGGRE,
        CalistaConstant.CAL_DG_MPA_CLASS,
        CalistaConstant.CAL_DG_PKG_TYPE,
        CalistaConstant.CAL_CONT_STATUS,
        CalistaConstant.CAL_BK_MOVE_TYPE,
        CalistaConstant.CAL_BL_TYPE,
        CalistaConstant.CAL_CONT_CAR_STS,
        CalistaConstant.CAL_FORM_TYPE,
        CalistaConstant.CAL_CONT_STOWAGE,
        CalistaConstant.CAL_CONT_HANDLING,
        CalistaConstant.CAL_CARGO_LENGTH
      ],
      onLoadCodeMasterListSuccess
    )

    SharedServiceApi.fetchContainerType(
      fetchRecords,
      false,
      onContainerTypeLoadSuccess
    )

    CarrierApi.fetchCarrier(fetchRecords, onLoadCarrierSuccess)

    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.DOCUMENT,
      [],
      false,
      onLoadDocTypesSuccess
    )

    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.PACKAGE_UOM,
      [],
      false,
      onLoadPackageUomSuccess
    )
  }, [])

  function onLoadPackageUomSuccess(data) {
    setPackageUom(data)
  }
  function onLoadCodeMasterListSuccess(data) {
    setCodeMasterList(data)
  }

  function onLoadDocTypesSuccess(data) {
    setDocTypes(data)
  }

  function onContainerTypeLoadSuccess(data) {
    setContainerTypes(data)
  }
  function onLoadCarrierSuccess(data) {
    setCarriers(data)
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box m={2}>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid
                  container
                  xs={6}
                  sm={6}
                  direction='row'
                  justify='flex-start'
                  spacing={2}
                >
                  <CngGridItem>
                    <Typography variant='h5' className='font-bolder'>
                      {tranObjCommon.prevValues}
                    </Typography>
                  </CngGridItem>
                  <CngGridItem>
                    <Typography className='greyLabel2 font-bolder'>
                      {prevDate}
                    </Typography>
                  </CngGridItem>
                </Grid>

                <Grid
                  container
                  xs={6}
                  sm={6}
                  className='paddingLeft16'
                  direction='row'
                  justify='flex-start'
                  spacing={2}
                >
                  <CngGridItem>
                    <Typography variant='h5' className='font-bolder'>
                      {tranObjCommon.newValues}
                    </Typography>
                  </CngGridItem>
                  <CngGridItem>
                    <Box className='greyLabel2 font-bolder'>{newDate}</Box>
                  </CngGridItem>
                </Grid>
              </Grid>
            </Box>
            {CommonUtils.hasFieldsChanged(si, ['siStatus']) && (
              <Box>
                <AuditTrailDualColumn
                  object={si}
                  field={'siStatus'}
                  match={matchBookStatus}
                  label={tranObj.status}
                />
              </Box>
            )}
            {CommonUtils.hasFieldsChanged(si, ['shipmentType']) && (
              <Box m={2}>
                <Typography variant='h5' className='font-bolder'>
                  {tranObj.bookingType}
                </Typography>
                <AuditTrailDualColumn
                  codeType={CalistaConstant.CAL_CONT_STATUS}
                  codeMasterList={codeMasterList}
                  object={si}
                  field={'shipmentType'}
                  label={tranObj.shipmentType}
                />
              </Box>
            )}
            {(CommonUtils.hasFieldsChanged(si, ['bookingRefNo']) ||
              CommonUtils.hasFieldsChanged(si, ['carRemarks'])) && (
              <Card>
                <Typography
                  variant='h5'
                  className='font-bolder paddingTop16 paddingLeft16'
                >
                  {tranObj.carrierComments}
                </Typography>
                <AuditTrailDualColumn
                  object={si}
                  field={'bookingRefNo'}
                  label={tranObj.bookingRefNo}
                />
                <AuditTrailDualColumn
                  object={si}
                  field={'carRemarks'}
                  label={tranObj.carrierComments}
                />
              </Card>
            )}
            {CommonUtils.hasFieldsChanged(si, ['shipperRemarks']) && (
              <Card>
                <Typography
                  variant='h5'
                  className='font-bolder paddingTop16 paddingLeft16'
                >
                  {tranObj.shipperComments}
                </Typography>
                <AuditTrailDualColumn
                  object={si}
                  field={'shipperRemarks'}
                  label={tranObj.shipperComments}
                />
              </Card>
            )}
            {CommonUtils.hasFieldsChanged(si, FIELDS_SCHEDULE) && (
              <Box mt={3}>
                <AuditTrailSchedule
                  si={si}
                  currentSI={currentSI}
                  ports={ports}
                  carriers={carriers}
                />
              </Box>
            )}

            {CommonUtils.hasFieldsChanged(si, FIELDS_CONTACT) && (
              <Box mt={3}>
                <AuditTrailContact si={si} codeMasterList={codeMasterList} />
              </Box>
            )}

            {CommonUtils.hasFieldsChanged(si, FIELDS_SHIPMENT) && (
              <Box mt={3}>
                <AuditTrailShipment
                  booking={si}
                  ports={ports}
                  codeMasterList={codeMasterList}
                />
              </Box>
            )}
            
            {
            (CommonUtils.arrayHasChanges(si.shippingInfoContBoxDTOs, ['shippingInfoId', 'reefer'])
            ||
            (si != undefined && si.shippingInfoContBoxDTOs != undefined && CommonUtils.reeferArrayHasChanges(si.shippingInfoContBoxDTOs, FIELDS_REEFER))
            ) && (
              <Box mt={3}>
                <AuditTrailContainer
                  containers={si.shippingInfoContBoxDTOs}
                  codeMasterList={codeMasterList}
                  containerTypes={containerTypes}
                />
              </Box>
            )}
            {CommonUtils.arrayHasChanges(si.shippingInfoCargoDTOs, ['']) && (
              <Box mt={3}>
                <AuditTrailCargo
                  cargos={si.shippingInfoCargoDTOs}
                  codeMasterList={codeMasterList}
                />
              </Box>
            )}
            {CommonUtils.arrayHasChanges(si.shippingInfoDGCargoDTOs, [
              'bookingId',
              'version',
              'updatedDate'
            ]) && (
              <Box mt={3}>
                <AuditTrailDgCargo
                  cargos={si.shippingInfoDGCargoDTOs}
                  codeMasterList={codeMasterList}
                  packageUom={packageUom}
                />
              </Box>
            )}
            {CommonUtils.arrayHasChanges(si.shippingInfoDocumentDTOs, [
              'bookingId'
            ]) && (
              <Box mt={3}>
                <AuditTrailDocument
                  documents={si.shippingInfoDocumentDTOs}
                  docTypes={docTypes}
                />
              </Box>
            )}

            {CommonUtils.hasFieldsChanged(si, FIELDS_PAYMENT) && (
              <Box mt={3}>
                <AuditTrailPayment payment={si} />
              </Box>
            )}

            <Box display='flex' flexDirection='row-reverse' mt={2}>
              <Box>
                <CngPrimaryButton onClick={closeDialog}>OK</CngPrimaryButton>
              </Box>
              <Box flexGrow={1}></Box>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          <Typography variant='h5' className='font-bolder'>
            {currentSIStatus ? Utils.getSIStatusById(currentSIStatus).name : ''}
          </Typography>
          <CloseDialogIconButton onClick={closeDialog} />
        </>
      }
      open={isDialogOpen}
      fullWidth
      maxWidth='md'
    />
  )
}

export default ViewAuditDetailDialog
