import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef } from 'react'

import Box from '@material-ui/core/Box'
import DiscardButton from 'src/components/button/DiscardButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ReeferSettingsContext from './ReeferSettingsContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import { Grid } from '@material-ui/core'
import check from 'check-types'
import moment from 'moment'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ResetButton from 'src/components/button/ResetButton'
import CancelButton from 'src/components/button/CancelButton'
import { components } from 'cng-web-lib'

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  }
} = components

function EditReeferSettingsForm(props) {
  const { setFieldValue } = useFormikContext()
  const dialogContext = useContext(ReeferSettingsContext)

  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    currentContact
  } = dialogContext

  function onSubmit(data) {
    console.log('submit ')
    console.log(data)
    console.log(currentContact)

    closeDialog()
  }

  // const validationSchema = useAddCompanyContactValidationSchema()

  return (
    <Formik
      validateOnChange={false}
      //  validationSchema={validationSchema}
      initialValues={{
        ...initialValues
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentContact={currentContact}
        />
      </Form>
    </Formik>
  )
}

function FormBody({ closeDialog, isSubmitting, currentContact }) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const freightBookingTranslationObject = FreightBookingTranslationText()

  const { setFieldValue } = useFormikContext()
  const isEdit = useRef(false)
  const resetForm = () => {
    console.log('reset')
    console.log(DEFAULT_INITIAL_VALUES)

    const dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      //  console.log("key: " + key + " val: " + val)
      setFieldValue(key, val)
    })
  }

  useEffect(() => {
    console.log('in dialog now')
    console.log(currentContact)
    if (currentContact != null) {
      populateDGCargoFields()
    }
  }, [currentContact])

  function populateDGCargoFields() {
    const dataArr = Object.entries(currentContact)
    dataArr.forEach(([key, val]) => {
      // console.log("key: " + key + " val: " + val)
      setFieldValue(key, val)
    })

    isEdit.current = true
  }

  const { errors } = useFormikContext()
  return (
    <Box m={1.5}>
      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <ResetButton onClick={resetForm} disabled={isSubmitting} />
        </Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box pr={2}>
            <CancelButton onClick={closeDialog} disabled={isSubmitting} />
          </Box>
          <Box>
            <SubmitButton isSubmitting={isSubmitting}>Save</SubmitButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default EditReeferSettingsForm
