import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import pathMap from 'src/paths/PathMap_VesselSchedule'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

export default function Documents() {
  //const translatedTextsObject = SearchScheduleTranslationText()
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()

  const handleClick = (event) => {
    // window.print()
    // setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box >
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={'moreMenuBtn'}
      >
        <DescriptionOutlinedIcon />
      </IconButton>
    </Box>
  )
}
