import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  InputAdornment,
  IconButton,
  Button
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import CountrySelectAutoComplete from 'src/views/common/ui/CountrySelectAutoComplete'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import CalistaUiComponentTranslationText from '../../common/CalistaUiComponentTranslationText'
import CompanyPreferenceApiUrls from 'src/apiUrls/CompanyPreferenceApiUrls'
import { components, useServices } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import CommonUtils from 'src/views/common/utils/Utils'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import * as CalistaConstant from 'src/constants/CalistaConstant'

import ListCompanyContactDialog from 'src/views/company-preference/addressbook/components/addressbook/ListCompanyContactDialog'
import ListPartyTemplateDialog from 'src/views/company-preference/addressbook/components/partytemplate/ListPartyTemplateDialog'
import AddContactSIDialog from 'src/views/company-preference/addressbook/components/addressbook/AddContactSIDialog'
import Utils from 'src/views/common/utils/Utils'

const {
  table: { useDefaultNotification },
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
      CngLookupAutocompleteField,
      CngCodeMasterAutocompleteField,
      CngRadioGroupField,
      CngDateTimeField,
      CngChipInputField,
      CngCheckboxField
    }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  //reference

  //from booking
  representativeName: '',
  representativeEmail: '',
  representativeContactNo: '',

  shipperRefNo: '',
  forwarderRefNo: '',
  nvoccRefNo: '',

  //from si
  billOfLadingAppType: '',
  surrenderBl: false,
  billOfLadingNo: '',
  noOfOriginals: '3', //RadioGroup will not work if it's an integer
  commonRef: '',

  //additional bl details
  shippedOnBoardDate: '',
  containerPackage: '',
  shipperRemarks: '',
  issueDate: '',
  placeOfIssue: '',
  carrierRemarks: '',
  carrierClauseCode: '',
  carrierClauseDesc: '',
  formLayout: '',
  freightCharge: '',
  depot: '',

  //parties
  shipperName: '',
  shipperAddress: '',
  consigneeName: '',
  consigneeAddress: '',
  usccNumber: '',
  destNotifyName: '',
  destNotifyAddress: '',
  partnerEmails: [],
  notifyPartnerEmails: false,
  notifyEventMilestonesEmail: false,
  agentName: '',
  agentAddress: '',
  bookingRepresentativeDetail: '',
  skipManagerApproval: false
})

const FormBody = (props) => {
  //const { isCarrierAmend, isShipperAmend } = props
  const frbTranslatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  const [partyMaxLength, setPartyMaxLength] = useState(46)

  const [contactDetailsExpanded, setContactDetailsExpanded] = useState(false)
  const [blDetailsExpanded, setBlDetailsExpanded] = useState(false)
  const [partyDetailsExpanded, setPartyDetailsExpanded] = useState(false)
  const [contactKey, setContactKey] = useState(uuid())

  const [addressBookDialogOpen, setAddressBookDialogOpen] = useState(false)
  const [partyTemplateDialogOpen, setPartyTemplateDialogOpen] = useState(false)
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false)
  const [contactType, setContactType] = useState('')
  const [refreshContactList, setRefreshContactList] = useState(false)

  const SG_DATE_TIME_FORMAT = CommonUtils.UI_FORMAT_DATE_TIME
  const [representativeNameField, , { setValue: setRepresentativeNameField }] =
    useField('representativeName')
  const [, ,] = useField('representativeEmail')
  const [, ,] = useField('representativeContactNo')
  const [placeOfIssueField, ,] = useField('placeOfIssue')
  const [carrierPartyIdField, ,] = useField('carrierPartyId')
  const [shippedOnBoardDateField, , { setValue: setShippedOnBoardDateField }] = useField('shippedOnBoardDate')
  const [issueDateField, , { setValue: setIssueDateField }] = useField('issueDate')

  const [, , { setValue: setCarrierClauseDescField }] =
    useField('carrierClauseDesc')
  const [noOfOriginalsField, , { setValue: setNoOfOriginalsField }] =
    useField('noOfOriginals')
  //const [, ,] = useField('preAssBkRefNo')
  const [, ,] = useField('contractPartyName')
  const [, ,] = useField('mainNotifyParty')
  const [shipperNameField, , { setValue: setShipperNameField }] =
    useField('shipperName')
  const [, ,] = useField('consigneeName')
  const [, ,] = useField('forwarderName')
  const [preShipContact, , { setValue: setPreShipContactField }] =
    useField('preShipContact')
  const [surrenderBlField, ,] = useField('surrenderBl')
  const [notifyPartnerEmailsField, ,] = useField('notifyPartnerEmails')
  const { setFieldValue } = useFormikContext()
  const { fetchRecords } = useServices()

  const handleContactDetailsExpand = () => {
    setContactDetailsExpanded(!contactDetailsExpanded)
  }

  const handleBlDetailsExpand = () => {
    setBlDetailsExpanded(!blDetailsExpanded)
  }

  const handlePartyDetailsExpand = () => {
    setPartyDetailsExpanded(!partyDetailsExpanded)
  }

  const getAddressbook = (contactType) => {
    setAddressBookDialogOpen(true)
    setContactType(contactType)
  }

  const doRefresh = () => {
    setRefreshContactList(!refreshContactList)
  }

  const addNewContact = (contactType) => {
    console.log('addNewContact', contactType)
    setAddContactDialogOpen(true)
    setContactType(contactType)
  }

  const handlePartyTemplateDialog = () => {
    setPartyTemplateDialogOpen(true)
  }

  function closeDialog() {
    setAddressBookDialogOpen(false)
    setPartyTemplateDialogOpen(false)
  }

  function closeAddContactDialog() {
    setAddContactDialogOpen(false)
  }

  function handleSelectContact(data) {
    console.log('handleSelectContact', data)
    if (data.contactType == 'SHIPPER') {
      setFieldValue('shipperName', data.shpName)
      setFieldValue('shipperAddress', data.shpAddress)
      showSuccessNotification('Shipper name and address populated')
    }
    if (data.contactType == 'CONSIGNEE') {
      setFieldValue('consigneeName', data.conName)
      setFieldValue('consigneeAddress', data.conAddress)
      showSuccessNotification('Consignee name and address populated')
    }
    if (data.contactType == 'NOTIFY PARTY') {
      setFieldValue('destNotifyName', data.dstNotifyName)
      setFieldValue('destNotifyAddress', data.dstNotifyAddress)
      showSuccessNotification('Notify Party name and address populated')
    }
    if (data.contactType == 'REPRESENTATIVE') {
      setFieldValue('bookingRepresentativeDetail', data.repName)
      showSuccessNotification('Representative name populated')
    }
    if (data.contactType == 'AGENT') {
      setFieldValue('agentName', data.agentName)
      if (data.addressStatus == 'Active') {
        setFieldValue('agentAddress', data.agentAddress)
        showSuccessNotification(
          'Active Agent selected - Name and Address populated.'
        )
      } else {
        setFieldValue('agentAddress', '')
        showSuccessNotification('Inactive Agent selected - Name populated.')
      }
    }
    closeDialog()
  }

  function handleSelectTemplate(data) {
    console.log('handleSelectTemplate', data)
    setFieldValue(
      'shipperName',
      data.selectedShipperName != null ? data.selectedShipperName : ''
    )
    setFieldValue(
      'shipperAddress',
      data.selectedShipperAddress != null ? data.selectedShipperAddress : ''
    )

    setFieldValue(
      'consigneeName',
      data.selectedConsigneeName != null ? data.selectedConsigneeName : ''
    )
    setFieldValue(
      'consigneeAddress',
      data.selectedConsigneeAddress != null ? data.selectedConsigneeAddress : ''
    )

    setFieldValue(
      'destNotifyName',
      data.selectedNotifyPartyName != null ? data.selectedNotifyPartyName : ''
    )
    setFieldValue(
      'destNotifyAddress',
      data.selectedNotifyPartyAddress != null
        ? data.selectedNotifyPartyAddress
        : ''
    )

    showSuccessNotification('Party Template details populated.')

    closeDialog()
  }

  const handleChange = (event) => {
    console.log(event.target.name)
    console.log(event.target.checked)
    setFieldValue(event.target.name, event.target.checked)
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const [state, setState] = useState({
    surrenderBl: false,
    notifyPartnerEmails: false
  })

  useEffect(() => {
    // Load carrier SI preferences if exists

    if (!CommonUtils.isEmpty(carrierPartyIdField.value)) {
      //always get carrier preference as need for button layout in step 2
      fetchRecords.execute(
        props.isCarrier
          ? CompanyPreferenceApiUrls.SI_PREF_GET
          : CompanyPreferenceApiUrls.SI_PREF_GET_BY_PARTY, //
        { customData: { partyId: carrierPartyIdField.value } },
        (data) => {
          console.log(
            'SI setup preference data retreived: ',
            JSON.stringify(data)
          )
          if (data.content[0]) {
            if (data.content[0].portnetSgShpOnboardDate) {
              // if true, get shpOnboard date from port net and populate
            }
            if (
              data.content[0].placeOfIssue &&
              data.content[0].placeOfIssue.length > 0
            ) {
              if (
                props.isCarrier &&
                placeOfIssueField.value === null //only auto populate the value when it is empty
              ) {
                setFieldValue('placeOfIssue', data.content[0].placeOfIssue)
                setContactKey(uuid())
                console.log(
                  'Populated place of issue from Carrier pref: ' +
                  data.content[0].placeOfIssue
                )
              }
            }
            setFieldValue(
              'skipManagerApproval',
              data.content[0].skipManagerApproval
            )

            console.log(
              'skip manager approval ' + data.content[0].skipManagerApproval
            )
          }
        },
        (error) => {
          console.log(error)
          showErrorNotification(error.message)
        }
      )
    }

    setContactKey(uuid())
    console.log(surrenderBlField.value)
    let newState = {
      surrenderBl: surrenderBlField.value,
      notifyPartnerEmails: notifyPartnerEmailsField.value
    }
    setState(newState)
    console.log(noOfOriginalsField)
    //need to convert no. of origin from integer to string
    setNoOfOriginalsField(noOfOriginalsField.value.toString())
  }, [props.isEdit])

  useEffect(() => {
    //clear the text field
    setFieldValue('shipperName', initialValues.shipperName)
    setFieldValue('consigneeName', initialValues.consigneeName)
    setFieldValue('destNotifyName', initialValues.destNotifyName)
    if (props.isPilPartyNewSi !== undefined) {
      if (props.isPilPartyNewSi) setPartyMaxLength(46)
      else setPartyMaxLength(41)
    }
  }, [props.isPilPartyNewSi])

  const onCarrierClauseChange = (e) => {
    console.log('onCarrierClauseChange', e)
    setCarrierClauseDescField(
      e === 'SWB'
        ? siTranslatedTextsObject.seawayBillClause
        : siTranslatedTextsObject.bombayClause
    )
  }

  const handleChangeSobDate = (date) => {
    setShippedOnBoardDateField(Utils.formatString(date, Utils.UI_FORMAT_DATE_TIME))
    setIssueDateField(Utils.formatString(date, Utils.UI_FORMAT_DATE_TIME))
  }

  return (
    <Card>
      {/* <Grid container>
                    <AccordionHeaderComponent title={frbTranslatedTextsObject.contactDetails} hideMandatory />
                </Grid> */}

      <Accordion expanded={true}>
        <AccordionSummary
          aria-controls='bookingComponentContent'
          id='bookingComponentHeader'
        >
          <Grid container>
            <CngGridItem xs={12} sm={12}>
              <AccordionHeaderComponent
                title={siTranslatedTextsObject.contactDetails}
                hideMandatory
              />
              <Box fontSize={10} paddingBottom={2}></Box>
            </CngGridItem>

            <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='representativeName'
                  inputProps={{ maxLength: 100 }}
                  label={siTranslatedTextsObject.representativeName}
                  disabled
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='representativeEmail'
                  inputProps={{ maxLength: 100 }}
                  label={siTranslatedTextsObject.representativeEmail}
                  disabled
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  inputProps={{ maxLength: 100 }}
                  name='representativeContactNo'
                  label={siTranslatedTextsObject.representativeContactNumber}
                  disabled
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>

      <Card className='inner-card-group'>
        <Accordion
          expanded={contactDetailsExpanded}
          style={{ backgroundColor: '#5E81F405' }}
        >
          <AccordionSummary
            aria-controls='contactDetailsComponentContent'
            id='contactDetailsComponentHeader'
          >
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <Typography className='page-content-groupLabel'>
                  {siTranslatedTextsObject.references}
                </Typography>
                <Typography
                  variant='caption'
                  className='page-content-groupLabel'
                >
                  {uiTranslatedTextsObject.mandatoryFields}
                </Typography>
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngCodeMasterAutocompleteField
                  name='billOfLadingAppType'
                  label={siTranslatedTextsObject.blApplicationType}
                  codeType={CalistaConstant.CAL_BL_TYPE}
                  key={contactKey}
                  disabled={props.isCarrier}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='billOfLadingNo'
                  label={siTranslatedTextsObject.blNo}
                  disabled={!props.isCarrier}
                  inputProps={{ maxLength: 20 }}
                  isRequired={props.isCarrier}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={8} sm={8}>
                <FormControlLabel
                  control={
                    <CngCheckboxField
                      name='surrenderBl'
                      onChange={handleChange}
                      checked={state.surrenderBl}
                      disabled={props.isCarrier}
                    />
                  }
                  label={siTranslatedTextsObject.surrenderBl}
                />
              </CngGridItem>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={12}>
                <Grid container xs={12} sm={12} spacing={2}>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngRadioGroupField
                      name='noOfOriginals'
                      label={siTranslatedTextsObject.noOfOriginals}
                      options={[
                        { label: '1', value: '1' },
                        { label: '2', value: '2' },
                        { label: '3', value: '3' }
                      ]}
                      direction='row'
                      disabled={props.isCarrier}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      inputProps={{ maxLength: 35 }}
                      name='commonRef'
                      label={siTranslatedTextsObject.purchaseOrderNumber}
                      disabled
                    />
                  </CngGridItem>

                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      name='shipperRefNo'
                      inputProps={{ maxLength: 35 }}
                      label={siTranslatedTextsObject.shipperRefNumber}
                      disabled
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      name='forwarderRefNo'
                      inputProps={{ maxLength: 35 }}
                      label={siTranslatedTextsObject.forwarderRefNumber}
                      disabled
                    />
                  </CngGridItem>

                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      name='nvoccRefNo'
                      inputProps={{ maxLength: 30 }}
                      label={siTranslatedTextsObject.nvoccRefNumber}
                      disabled
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <AccordionFooterControlComponent
          handleAccordionExpand={handleContactDetailsExpand}
          expanded={contactDetailsExpanded}
          footerText={siTranslatedTextsObject.additionalRefDetails}
        />
      </Card>

      <br></br>

      <Card className='inner-card-group'>
        <Accordion
          expanded={blDetailsExpanded}
          style={{ backgroundColor: '#5E81F405' }}
        >
          <AccordionSummary
            aria-controls='contactDetailsComponentContent'
            id='contactDetailsComponentHeader'
          >
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <Typography className='page-content-groupLabel'>
                  {siTranslatedTextsObject.blDetails}
                </Typography>
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  name='shippedOnBoardDate'
                  label={siTranslatedTextsObject.shippedOnBoardDateTime}
                  format={SG_DATE_TIME_FORMAT}
                  disabled={!props.isCarrier}
                  isRequired={props.isCarrier}
                  onChange={handleChangeSobDate}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  multiline
                  rows={4}
                  name='containerPackage'
                  label={siTranslatedTextsObject.noOfContainersPackagesInWords}
                  disabled
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  multiline
                  rows={4}
                  name='shipperRemarks'
                  inputProps={{ maxLength: 1024 }}
                  label={siTranslatedTextsObject.shipperRemarks}
                  disabled={props.isCarrier}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  name='issueDate'
                  label={siTranslatedTextsObject.issueDateTime}
                  format={SG_DATE_TIME_FORMAT}
                  disabled={!props.isCarrier}
                  isRequired={props.isCarrier}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CountrySelectAutoComplete
                  name='placeOfIssue'
                  label={siTranslatedTextsObject.issuePlace}
                  key={contactKey}
                  disabled={!props.isCarrier}
                  required={props.isCarrier}
                />
              </CngGridItem>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={12}>
                <Grid container xs={12} sm={12} spacing={2}>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      multiline
                      rows={4}
                      name='carrierRemarks'
                      label={siTranslatedTextsObject.carrierRemarks}
                      inputProps={{ maxLength: 1024 }}
                      disabled={!props.isCarrier}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngCodeMasterAutocompleteField
                      name='carrierClauseCode'
                      label={siTranslatedTextsObject.carrierClause}
                      codeType='CAL_CARRIER_CLAUSE'
                      key={contactKey}
                      onChange={onCarrierClauseChange}
                      disabled={!props.isCarrier}
                    />
                  </CngGridItem>

                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      multiline
                      rows={4}
                      name='carrierClauseDesc'
                      label={siTranslatedTextsObject.carrierClauseDesc}
                      disabled
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngCodeMasterAutocompleteField
                      name='formLayout'
                      label={siTranslatedTextsObject.documentLayout}
                      key={contactKey}
                      codeType='CAL_FORM_TYPE'
                      disabled={props.isCarrier}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      multiline
                      rows={4}
                      name='freightCharge'
                      label={siTranslatedTextsObject.freightCharge}
                      inputProps={{ maxLength: 400 }}
                      disabled={!props.isCarrier}
                    />
                  </CngGridItem>

                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      multiline
                      rows={4}
                      name='depot'
                      label={siTranslatedTextsObject.depot}
                      inputProps={{ maxLength: 30 }}
                      disabled={!props.isCarrier}
                    />
                  </CngGridItem>
                </Grid>
                {/* </Card> */}
              </CngGridItem>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <AccordionFooterControlComponent
          handleAccordionExpand={handleBlDetailsExpand}
          expanded={blDetailsExpanded}
          footerText={siTranslatedTextsObject.additionalBLDetails}
        />
      </Card>

      <br></br>
      {/* for party section */}

      <Card className='inner-card-group'>
        <Accordion
          expanded={partyDetailsExpanded}
          style={{ backgroundColor: '#5E81F405' }}
        >
          <AccordionSummary
            aria-controls='contactDetailsComponentContent'
            id='contactDetailsComponentHeader'
          >
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <Typography className='page-content-groupLabel'>
                  {siTranslatedTextsObject.parties}
                </Typography>
                <Typography
                  variant='caption'
                  className='page-content-groupLabel'
                >
                  {uiTranslatedTextsObject.mandatoryFields}
                </Typography>
              </CngGridItem>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <Box p={2}>
                {!props.isCarrier && (
                  <Button
                    startIcon={<BorderColorIcon />}
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      handlePartyTemplateDialog()
                    }}
                    classes={{ root: 'ng-button-filled-secondary' }}
                  >
                    {siTranslatedTextsObject.partyTemplateButton}
                  </Button>
                )}
              </Box>

              <Grid container xs={12} sm={12} spacing={2}>
                <CngGridItem xs={4} sm={4}></CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name='shipperName'
                    label={siTranslatedTextsObject.shipperName}
                    inputProps={{ maxLength: partyMaxLength }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {!props.isCarrier && (
                            <IconButton
                              onClick={(e) => getAddressbook('SHIPPER')}
                            >
                              {' '}
                              <LibraryBooksIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      )
                    }}
                    disabled={props.isCarrier}
                    isRequired
                  />
                </CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name='shipperAddress'
                    InputProps={{ maxLength: 512 }}
                    label={siTranslatedTextsObject.shipperAddress}
                    multiline
                    rows={4}
                    disabled={props.isCarrier}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}></CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name='consigneeName'
                    label={siTranslatedTextsObject.consigneeName}
                    inputProps={{ maxLength: partyMaxLength }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {!props.isCarrier && (
                            <IconButton
                              onClick={(e) => getAddressbook('CONSIGNEE')}
                            >
                              {' '}
                              <LibraryBooksIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      )
                    }}
                    disabled={props.isCarrier}
                    isRequired
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name='consigneeAddress'
                    InputProps={{ maxLength: 512 }}
                    label={siTranslatedTextsObject.consigneeAddress}
                    multiline
                    rows={4}
                    disabled={props.isCarrier}
                  />
                </CngGridItem>

                <CngGridItem xs={8} sm={8}></CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    inputProps={{ maxLength: 20 }}
                    name='usccNumber'
                    label={siTranslatedTextsObject.usccNo}
                    helperText={siTranslatedTextsObject.usccNoNote}
                    disabled={props.isCarrier}
                  />
                </CngGridItem>
                <CngGridItem xs={4} sm={4}></CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name='destNotifyName'
                    label={siTranslatedTextsObject.notifyPartyName}
                    inputProps={{ maxLength: partyMaxLength }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {!props.isCarrier && (
                            <IconButton
                              onClick={(e) => getAddressbook('NOTIFY PARTY')}
                            >
                              {' '}
                              <LibraryBooksIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      )
                    }}
                    disabled={props.isCarrier}
                    isRequired
                  />
                </CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name='destNotifyAddress'
                    InputProps={{ maxLength: 512 }}
                    label={siTranslatedTextsObject.notifyPartyAddress}
                    multiline
                    rows={4}
                    disabled={props.isCarrier}
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='agentName'
                  label={siTranslatedTextsObject.agentName}
                  disabled={!props.isCarrier}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {props.isCarrier && (
                          <IconButton onClick={(e) => getAddressbook('AGENT')}>
                            {' '}
                            <LibraryBooksIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='agentAddress'
                  inputProps={{ maxLength: 512 }}
                  multiline
                  rows={4}
                  label={siTranslatedTextsObject.agentAddress}
                  disabled={!props.isCarrier}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                {!props.isCarrier && (
                  <CngTextField
                    type='text'
                    name='bookingRepresentativeDetail'
                    label={siTranslatedTextsObject.bookingRepresentativeDetails}
                    InputProps={{
                      maxLength: 30,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={(e) => getAddressbook('REPRESENTATIVE')}
                          >
                            {' '}
                            <LibraryBooksIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              </CngGridItem>

              <CngGridItem xs={12} sm={12}>
                <Card className='inner-card-group'>
                  <Grid container xs={12} sm={12} spacing={2}>
                    <CngGridItem xs={4} sm={4}>
                      <Typography className='page-content-groupLabel'>
                        {siTranslatedTextsObject.partnerEmail}
                      </Typography>
                    </CngGridItem>
                    <CngGridItem xs={8} sm={8}>
                      <CngChipInputField
                        name='partnerEmails'
                        label={siTranslatedTextsObject.emailToNotify}
                        allowDuplicates={false}
                        disabled={props.isCarrier}
                        blurBehavior='ignore'
                      />
                      <Typography
                        style={{ color: '#808080' }}
                        variant='caption'
                        align='left'
                      >
                        {frbTranslatedTextsObject.partnerEmailCaption}
                      </Typography>
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4} />
                    <CngGridItem xs={8} sm={8}>
                      <FormControlLabel
                        control={
                          <CngCheckboxField
                            disabled={props.isCarrier}
                            name='notifyPartnerEmails'
                            checked={state.notifyPartnerEmails}
                            onChange={handleChange}
                          />
                        }
                        label={siTranslatedTextsObject.notifyBookingStatus}
                      />

                      <FormControlLabel
                        control={
                          <CngCheckboxField
                            disabled={props.isCarrier}
                            name='notifyEventMilestonesEmail'
                            checked={state.notifyEventMilestonesEmail}
                            onChange={handleChange}
                          />
                        }
                         label={siTranslatedTextsObject.notifyEventMilestones}
                      />
                    </CngGridItem>

                  </Grid>
                </Card>
              </CngGridItem>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <AccordionFooterControlComponent
          handleAccordionExpand={handlePartyDetailsExpand}
          expanded={partyDetailsExpanded}
          footerText={siTranslatedTextsObject.additionalPartyDetails}
        />
      </Card>

      <ListPartyTemplateDialog
        styles={{ width: '80%', height: '90%' }}
        isDialogOpen={partyTemplateDialogOpen}
        closeDialog={closeDialog}
        handleDialogSubmit={handleSelectTemplate}
        showNotification={props.showNotification}
      />

      <ListCompanyContactDialog
        isDialogOpen={addressBookDialogOpen}
        closeDialog={closeDialog}
        contactType={contactType}
        handleDialogSubmit={handleSelectContact}
        handleAddContact={addNewContact}
        showNotification={props.showNotification}
        refresh={refreshContactList}
      />

      <AddContactSIDialog
        isDialogOpen={addContactDialogOpen}
        closeDialog={closeAddContactDialog}
        contactType={contactType}
        showNotification={props.showNotification}
        doRefresh={doRefresh}
      />
    </Card>
  )
}

const SIContactDetailsComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIContactDetailsComponent
