import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  CardContent,
  Button,
  Typography
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { components } from 'cng-web-lib'
import GeneralButtonTabComponent from 'src/views/common/ui/GeneralButtonTabComponent'
import ShowCountComponent from './ShowCountComponent'

const {
  form: {
    field: { CngTextField, CngSelectField }
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

const FormBody = (props) => {
  const [noDrafts, setNoDrafts] = useState(false)
  let tabInfo = [
    {
      tabText: props.firstTabLabel,
      tabCount: props.firstTabCount,
      tabOnClick: props.firstTabOnClick,
      tabClicked: props.firstTabClicked
    },
    {
      tabText: props.secondTabLabel,
      tabCount: props.secondTabCount,
      tabOnClick: props.secondTabOnClick,
      tabClicked: !props.firstTabClicked
    }
  ]

  const preCheckSortByChange = (e) => {
    console.log('handleSortByChange')
    //alert(e.target.value)
    if (e.target.value != null && e.target.value != 0) {
      props.handleSortByChange(e)
    }
  }

  useEffect(() => {
    if (props.draftsCount > 0) {
      setNoDrafts(false)
    } else {
      setNoDrafts(true)
    }
  }, [props.draftsCount])

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <CngGridItem item xs={9} sm={9}>
            <Grid container spacing={1}>
              {tabInfo.map((elem, i) => {
                return (
                  <CngGridItem item xs={4} sm={4}>
                    <GeneralButtonTabComponent.FormBody
                      tabInfo={elem}
                      clickAction={elem.tabOnClick}
                      disable={noDrafts}
                    />
                  </CngGridItem>
                )
              })}
            </Grid>
          </CngGridItem>
          <CngGridItem item xs={3} sm={3}>
            <CngSelectField
              name='sortCode'
              label='Sort By'
              items={props.sortByList}
              onClick={preCheckSortByChange}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const CreateNewSITabButtonAndSortComponent = Object.freeze({
  FormBody: FormBody
})

export default CreateNewSITabButtonAndSortComponent
