import {
  Card,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  Button
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { components, constants } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import ContainerTypeAutocompleteField from 'src/components/autocomplete/ContainerTypeAutocompleteField'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ReeferDetailsDialog from './ReeferDetailsDialog.js'

const {
  form: {
    field: {
      CngPackageUomAutocompleteField,
      CngSwitchField,
      CngTextField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

const {
  component: {
    textField: {
      customType: { PLAIN_NUMBER }
    }
  }
} = constants

const SIContainerDetailsComponent = (props) => {
  const [isReeferDetailsDialogOpen, setReeferDetailsDialogOpen] = useState(false)
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const [currentContainerIdx, setCurrentContainerIdx] = useState()
  const [generalContTypeList, setGeneralContTypeList] = useState()
  const [reeferContTypeList, setReeferContTypeList] = useState()
  const [tankContTypeList, setTankContTypeList] = useState()

  const GENERAL_TYPE = 'G'
  const REEFER_TYPE = 'R'
  const TANK_TYPE = 'T'

  function addReeferDetails(index) {
    setReeferDetailsDialogOpen(true)
    setCurrentContainerIdx(index)
  }

  const closeDialog = () => {
    setReeferDetailsDialogOpen(false)
  }

  //to highlight container item grid when reefer mandatory details are not entered and submit button is clicked
  useEffect(() => {
    if (props.reeferError != undefined) {
      let element = document.querySelector(`div[id^="containerGrid${props.reeferError}"]`);
      if (element != undefined) {
        element.style.outline = "2px solid #FF808B";
      }
    }
    else {
      let elements = document.querySelectorAll(`div[id^="containerGrid"]`);
      if (elements != undefined) {
        elements.forEach(element => {
          element.style.outline = "none";
        });
      }
    }
  }, [props.reeferError])

  useEffect(() => {
    if (props.contTypeList != null) {
      const generalList = [], reeferList = [], tankList = []

      //From props container type list, extract the 3 types of containers and put them in individual lists
      props.contTypeList.map(x => {
        if (x.includes(GENERAL_TYPE)) { //General Purpose Containers
          generalList.push(x)
          setGeneralContTypeList(generalList)
        }
        else if (x.includes(REEFER_TYPE)) { //Reefer Containers
          reeferList.push(x)
          setReeferContTypeList(reeferList)
        }
        else if (x.includes(TANK_TYPE)) { //Tank Containers
          tankList.push(x)
          setTankContTypeList(tankList)
        }
      })
    }
  }, [props.contTypeList])

  const container = (i, containerTypeList) => {
    return (
      <CngGridItem xs={4} sm={4}>
        <ContainerTypeAutocompleteField
          name={`shippingInfoContainerBoxes[${i}].containerIsoSizeType`}
          label={siTranslatedTextsObject.containerSizeTypeHeight}
          contTypeList={containerTypeList}
          onChange={(val, evt) =>
            props.handleDropdownChange(
              val,
              evt,
              i,
              'containerIsoSizeType'
            )
          }
          key={props.contTypeKeyRef}
          isRequired
        />
      </CngGridItem>
    )
  }

  return props.list.map((x, i) => (
    <Grid container xs={12} sm={12} key={x.id} spacing={2}>

      <CngGridItem xs={12} sm={12}>
        <Card id={`containerGrid${i}`}>
          <Accordion expanded={true}>
            <AccordionSummary
              aria-controls='contactDetailsComponentContent'
              id='contactDetailsComponentHeader'
            >
              <Grid container spacing={2} justify='flex-start'>
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name={`shippingInfoContainerBoxes[${i}].containerNo`}
                    label={siTranslatedTextsObject.containerNo}
                    value={x.containerNo}
                    inputProps={{ maxLength: 15 }}
                    onChange={(e) => props.handleInputChange(e, i)}
                    isRequired
                  />
                </CngGridItem>
                {
                  //if User is Carrier and Container iso type is General Purpose, 
                  //only display other types of General Purpose containers from this booking (if any)
                  props.isCarrier && x.containerIsoSizeType && x.containerIsoSizeType.includes(GENERAL_TYPE) && container(i, generalContTypeList)}
                {
                  //if User is Carrier and Container iso type is Reefer, 
                  //only display other types of Reefer containers from this booking (if any)
                  props.isCarrier && x.containerIsoSizeType && x.containerIsoSizeType.includes(REEFER_TYPE) && container(i, reeferContTypeList)
                }
                {
                  //if User is Carrier and Container iso type is Tank, 
                  //only display other types of Tank containers from this booking (if any)
                  props.isCarrier && x.containerIsoSizeType && x.containerIsoSizeType.includes(TANK_TYPE) && container(i, tankContTypeList)
                }
                {
                  //if User is Shipper, then load all Container iso types (from this booking or all for DirectSi)
                  !props.isCarrier && container(i, props.contTypeList)
                }
                {/* <CngGridItem xs={4} sm={4}>
                  <CngCodeMasterAutocompleteField
                    name={`shippingInfoContainerBoxes[${i}].containerStatus`}
                    label={siTranslatedTextsObject.status + ' *'}
                    codeType='CAL_CONT_STATUS'
                    onChange={(val, evt) =>
                      props.handleDropdownChange(
                        val,
                        evt,
                        i,
                        'containerStatus',
                        null,
                        true
                      )
                    }
                    key={props.contStatusKeyRef}
                    disabled
                    // disabled={!props.isDirectSI}
                  />
                </CngGridItem> */}
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='text'
                    name={`shippingInfoContainerBoxes[${i}].sealNo`}
                    label={siTranslatedTextsObject.sealNo}
                    value={x.sealNo}
                    inputProps={{ maxLength: 15 }}
                    onChange={(e) => props.handleInputChange(e, i)}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <CngPackageUomAutocompleteField
                    name={`shippingInfoContainerBoxes[${i}].packageType`}
                    label={siTranslatedTextsObject.packageType}
                    onChange={(val, evt) =>
                      props.handleDropdownChange(val, evt, i, 'packageType')
                    }
                    key={props.cargoPackageKeyRef}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='number'
                    name={`shippingInfoContainerBoxes[${i}].grossWeight`}
                    label={siTranslatedTextsObject.containerGrossWeight}
                    value={x.grossWeight}
                    onChange={(e) => props.handleInputChange(e, i)}
                    customType={PLAIN_NUMBER}
                    inputProps={{ maxLength: 11 }}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='number'
                    name={`shippingInfoContainerBoxes[${i}].totalVgmWeight`}
                    label={siTranslatedTextsObject.totalVgmWeight}
                    value={x.totalVgmWeight}
                    onChange={(e) => props.handleInputChange(e, i)}
                    customType={PLAIN_NUMBER}
                    inputProps={{ maxLength: 11 }}
                  />
                </CngGridItem>
                <CngGridItem
                  xs={4}
                  sm={4}
                  style={{ display: props.isDirectSI ? 'inline' : 'none' }}
                >
                  <CngCodeMasterAutocompleteField
                    name={`shippingInfoContainerBoxes[${i}].specialHandling`}
                    label={siTranslatedTextsObject.specialHandling}
                    codeType='CAL_CONT_HANDLING'
                    onChange={(val, evt) =>
                      props.handleDropdownChange(val, evt, i, 'specialHandling')
                    }
                    key={props.contSpecialHandlingKeyRef}
                  />
                </CngGridItem>
                <CngGridItem
                  xs={4}
                  sm={4}
                  style={{ display: props.isDirectSI ? 'inline' : 'none' }}
                >
                  <CngCodeMasterAutocompleteField
                    name={`shippingInfoContainerBoxes[${i}].stowage`}
                    label={siTranslatedTextsObject.stowage}
                    codeType='CAL_CONT_STOWAGE'
                    onChange={(val, evt) =>
                      props.handleDropdownChange(val, evt, i, 'stowage')
                    }
                    key={props.contStowageKeyRef}
                  />
                </CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <Grid container xs={12} sm={12} key={x.id} spacing={2}>
                    <CngGridItem xs={6} sm={6}>
                      <CngSwitchField
                        name='shipperOwned'
                        label={siTranslatedTextsObject.shipperOwned}
                        checked={x.shipperOwned}
                        onChange={(e) => props.handleSwitchChange(e, i)}
                      />
                    </CngGridItem>
                    {x.reeferAvailable ? (
                      <CngGridItem xs={6} sm={6}>
                        <CngSwitchField
                          name='nonActiveReefer'
                          label={siTranslatedTextsObject.nonActiveReefer}
                          checked={x.nonActiveReefer}
                          onChange={(e) => props.handleSwitchChange(e, i)}
                          disabled={props.isCarrier}
                        />
                      </CngGridItem>) : null}
                  </Grid>
                </CngGridItem>
                {/*Number of Packages*/}
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='number'
                    name={`shippingInfoContainerBoxes[${i}].pkgCnt`}
                    label={siTranslatedTextsObject.packageTypeNumber}
                    value={x.pkgCnt}
                    // onChange={(e) => props.handleInputChange(e, i)}
                    customType={PLAIN_NUMBER}
                    inputProps={{ maxLength: 8 }}
                  />
                </CngGridItem>
                {/*Measurement (CBM)*/}
                <CngGridItem xs={4} sm={4}>
                  <CngTextField
                    type='number'
                    name={`shippingInfoContainerBoxes[${i}].volume`}
                    label={siTranslatedTextsObject.volume}
                    value={x.volume}
                    onChange={(e) => props.handleInputChange(e, i)}
                    customType={PLAIN_NUMBER}
                    inputProps={{ maxLength: 10 }}
                  />
                </CngGridItem>

                {x.reeferAvailable && (
                  !props.isCarrier ?
                    <CngGridItem xs={4} sm={4}>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => { addReeferDetails(i) }}
                        classes={{ root: 'ng-button-filled-secondary' }}
                        disabled={x.nonActiveReefer}
                      >
                        {siTranslatedTextsObject.addReeferDetails}
                      </Button>
                    </CngGridItem>
                    :
                    <CngGridItem xs={4} sm={4}>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => { addReeferDetails(i) }}
                        classes={{ root: 'ng-button-filled-secondary' }}
                        disabled={x.nonActiveReefer}
                      >
                        View Reefer Details
                      </Button>
                    </CngGridItem>
                )}
                <ReeferDetailsDialog
                  isDialogOpen={isReeferDetailsDialogOpen}
                  closeDialog={closeDialog}
                  currentContainerIdx={currentContainerIdx}
                  siContainerBoxList={props.list}
                  setSiContainerBoxList={props.setSiContainerBoxList}
                  showNotification={props.showNotification}
                  showLocalCategory={props.showLocalCategory}
                  isCarrier={props.isCarrier}
                />
                {!props.isCarrier && (
                  <CngGridItem xs={12} sm={12}>
                    <IconButton aria-label='copy'>
                      <FileCopyIcon onClick={props.copyRowClick(i)} />
                    </IconButton>
                    {props.list.length > 0 && (
                      <IconButton aria-label='remove'>
                        <DeleteIcon onClick={props.removeRowClick(i)} />
                      </IconButton>
                    )}
                  </CngGridItem>
                )}
              </Grid>
            </AccordionSummary>
          </Accordion>
        </Card>
      </CngGridItem>
    </Grid>
  ))
}

export default SIContainerDetailsComponent
