import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'

import Paper from '@material-ui/core/Paper'
import ProxyPrintContext from './ProxyPrintContext.js'
import ProxyPrintForm from './ProxyPrintForm'
import { components } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'

const { CngDialog, CngDialogTitle } = components

function ProxyPrintDialog({
  isDialogOpen,
  closeDialog,
  blForPrint,
  remainingPrint,
  setRemainingPrint,
  isPil
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = ShippingInstructionTranslationText()

  return (
    <ProxyPrintContext.Provider
      value={{
        closeDialog,
        form: {
          isSubmitting,
          setSubmitting
        },
        blForPrint,
        remainingPrint,
        setRemainingPrint,
        isPil
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <ProxyPrintForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.printBl}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </ProxyPrintContext.Provider>
  )
}

export default ProxyPrintDialog
