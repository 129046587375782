import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Alert from '@material-ui/lab/Alert'
import { components, useServices } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import { FileText } from 'react-feather'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import DiscardButton from 'src/components/button/DiscardButton.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import SIApi from 'src/views/shippinginstruction/shared/api'
import SICargoUploadForm from './SICargoUploadForm'
import SICargoUploadContext from './SICargoUploadContext'

const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    }
  },
  CngDialog,
  CngGridItem,
  dropzone: { CngFileUpload },
  table: { useDefaultNotification },
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function SICargoUploadDialog({
  isDialogOpen,
  closeDialog,
  title,
  showNotification,
  shippingInfo,
  setShippingInfo,
  setPageLoading,
  tempUploadCargoPendingFile,
  setTempUploadCargoPendingFile,
  closeAndUpdateSI,
  refresh,
  setShouldRender
}) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  const { createRecord, fetchRecords } = useServices()
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const showErrorMessages = (errorMessages) => {
    showErrorNotification(errorMessages[0])
  }

  const moreActions = [
    {
      action: 'remove',
      name: 'Delete',
      icon: ['fal', 'trash']
    }
  ]

  const [loading, setLoading] = useState(false)
  const [allSuccess, setAllSuccess] = useState(false)
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false)
  const [overwriteDialogOpen, setOverwriteDialogOpen] = useState(false)
  // const [temp, setTempUploadCargoPendingFile] = useState([])

  const [pendingFiles, setPendingFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  // const [pendingFilesField, , { setValue: setPendingFilesField }] =
  //   useField('pendingFiles')

  // console.log('pendingFilesField', pendingFilesField)
  // const [uploadedFilesField, , { setValue: setUploadedFilesField }] =
  //   useField('uploadFiles')

  //console.log('pendingFilesField', pendingFilesField)
  console.log('uploadedFiles', uploadedFiles)
  console.log('temp', tempUploadCargoPendingFile)
  // if(temp && temp.length >0){
  //   setPendingFiles(temp)
  // }
  const [isSubmitting, setIsSubmitting] = useState(false)

  function confirmDiscardDialog() {
    closeDialog(false)
    if (!allSuccess) {
      console.log('confirmDiscardDialog')
      setDiscardDialogOpen(true)
    } else {
      closeAndResetDialog()
    }
  }

  function closeAndResetDialog() {
    closeDialog(false)
    setDiscardDialogOpen(false)
    setUploadedFiles([])
    setPendingFiles([])
    setTempUploadCargoPendingFile([])
    setAllSuccess(false)
  }
  function confirmOverwriteDialog() {
    console.log('confirmOverwriteDialog')
    setOverwriteDialogOpen(true)
    closeDialog(false)
  }

  function fetchSIAndRefresh() {
    setShouldRender(false)
    const siRequest = {
      shippingInfoId: shippingInfo.shippingInfoId,
      freightBookingId: shippingInfo.freightBookingId
    }
    SIApi.fetchSIDetailsById(fetchRecords, siRequest, (data) => {
      showSuccessNotification('Updating SI...')

      setShippingInfo(SIUtils.expandBookingToShippingInfo(data))
      console.log(
        'fetch si and refresh',
        SIUtils.expandBookingToShippingInfo(data)
      )
      setAllSuccess(true)
      refresh()
      //setLoading(false)
    })
  }

  function saveFiles() {
    setOverwriteDialogOpen(false)
    closeDialog(true)
    if (pendingFiles.length < 1) {
      showErrorNotification('Nothing to upload')
    } else {
      console.log('upload SI Cargo')
      console.log('pendingFiles', pendingFiles)

      setPendingFiles([])
      var formData = new FormData()
      if (shippingInfo.shippingInfoId == undefined) {
        formData.append('shippingInfoId', 0)
      } else {
        formData.append('shippingInfoId', shippingInfo.shippingInfoId)
      }
      // console.log(pendingFilesField)

      pendingFiles.map((file) => {
        // pendingFilesField.value.map((file) => {
        var fileBlob = file.file
        console.log(fileBlob)
        formData.append('fileContents', fileBlob) // name of the file upload box
      })
      console.log('formData', formData)
      console.log('shippingInfoId', shippingInfo.shippingInfoId)

      let newlyUploadedFiles = []
      newlyUploadedFiles.push(pendingFiles[0])
      setUploadedFiles(newlyUploadedFiles)
      setPendingFiles([])
      setLoading(true)

      function onSuccess(response) {
        //setUploadedFiles([])

        // closeDialog(false)
        setDiscardDialogOpen(false)
        setLoading(false)
        console.log(response)
        setPageLoading(false)
        if (response.status === 'Success') {
          showSuccessNotification('Upload Cargo - Success')
          fetchSIAndRefresh()
          let newUploaded = [...pendingFiles]
          console.log('file temp', newUploaded)
          newUploaded[0].status = 'Completed'

          setUploadedFiles(newUploaded)
        } else {
          showErrorNotification(response.errorMessages)
          let newUploaded = [...pendingFiles]
          console.log('file, uploadedFiles', uploadedFiles)
          console.log('file, pendingFiles', pendingFiles)

          newUploaded[0].status = 'Error'
          newUploaded[0].statusMessages = response.errorMessages
          // let netUploadedFiles = []
          // netUploadedFiles.push(temp)
          setUploadedFiles(newUploaded)
        }

        //  closeAndUpdateSI()
      }

      function onError(error) {
        console.log(error)
        showErrorNotification(error.message)
        setLoading(false)
        setPageLoading(false)
      }

      setPageLoading(true)
      createRecord.execute(
        ShippingInstructionApiUrls.UPLOAD_SI_UPLOAD_CARGO,
        formData,
        onSuccess,
        onError
      )
    }
  }

  const closeUploadDialog = () => {
    setAllSuccess(false)
    closeDialog(false)
    setUploadedFiles([])
    setPendingFiles([])
    setTempUploadCargoPendingFile([])
  }

  return (
    <SICargoUploadContext.Provider
      value={{
        isDialogOpen,
        closeDialog,
        title,
        showNotification,
        shippingInfo,
        setShippingInfo,
        setPageLoading,
        refresh
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper className='accordian-header'>
            <SICargoUploadForm
              confirmOverwriteDialog={confirmOverwriteDialog}
              confirmDiscardDialog={confirmDiscardDialog}
              closeAndResetDialog={closeAndResetDialog}
              saveFiles={saveFiles}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              pendingFiles={pendingFiles}
              setPendingFiles={setPendingFiles}
              loading={loading}
              isSubmitting={isSubmitting}
              allSuccess={allSuccess}
              tempUploadCargoPendingFile={tempUploadCargoPendingFile}
            />
          </Paper>
        }
        dialogTitle={
          <>
            <Typography variant='h5' className='font-bolder'>
              {title}
            </Typography>
            <CloseDialogIconButton onClick={closeUploadDialog} />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
      <ConfirmDialog
        isConfirmDialogOpen={discardDialogOpen}
        closeDialog={() => {
          setDiscardDialogOpen(false)
          closeDialog(true)
          setTempUploadCargoPendingFile(pendingFiles)
        }}
        confirmDialog={closeAndResetDialog}
        content={siTranslatedTextsObject.dialogDiscardCargoUploadContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={siTranslatedTextsObject.dialogDiscardCargoUploadTitle}
      />

      <ConfirmDialog
        isConfirmDialogOpen={overwriteDialogOpen}
        closeDialog={() => {
          setOverwriteDialogOpen(false)
          closeDialog(true)
        }}
        confirmDialog={saveFiles}
        content={siTranslatedTextsObject.dialogOverwriteCargoContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={siTranslatedTextsObject.dialogOverwriteCargoTitle}
      />
    </SICargoUploadContext.Provider>
  )
}
export default SICargoUploadDialog

function SICargoUploadDetails({ uploadedFiles, loading }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    console.log('handle close')
    setAnchorEl(null)
  }

  function fileStatus(f) {
    console.log('fileStatus', f)
    if (f.status === 'Completed') {
      return (
        <Box mt={2} mr={8} className='rs-green'>
          <b>Success</b>
        </Box>
      )
    } else if (f.status === 'Error') {
      return (
        <Box mt={2} mr={8} style={{ color: 'red' }}>
          <b>File Error</b>
          <IconButton
            edge='end'
            className={`row-more-actions`}
            onClick={handleClick}
            style={{ display: f.statusMessages.length > 0 ? 'inline' : 'none' }}
          >
            <InfoOutlinedIcon />
          </IconButton>
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 'auto',
                width: 'auto'
              }
            }}
          >
            <MenuItem disabled={true}>
              <Box ml={1}>
                <Typography variant='inherit' style={{ fontSize: 'small' }}>
                  {f.statusMessages}
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      )
    } else {
      return (
        <Box mt={2} mr={8} style={{ color: '#8181a5' }}>
          <div style={{ display: loading ? 'none' : 'inline' }}>
            <b>In Progress...</b>
          </div>
          <div style={{ display: loading ? 'inline' : 'none' }}>
            <CircularProgress />
          </div>
        </Box>
      )
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table id='table'>
        <TableBody>
          {uploadedFiles.map((f, i) => {
            return (
              <TableRow key={`row-${i}`}>
                <TableCell>
                  <Box display='flex'>
                    <Box flexGrow={1}>
                      <Box display='flex'>
                        <Box>
                          <Avatar style={{ backgroundColor: '#f5f6fa' }}>
                            <FileText size='25px' />
                          </Avatar>
                        </Box>
                        <Box ml={2}>
                          <DocumentInfoDecoratorComponent
                            bold={true}
                            name={f.file.name}
                            size={f.file.size}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box>{fileStatus(f)}</Box>
                  </Box>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
