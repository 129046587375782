import { Card, Grid, Typography, IconButton } from '@material-ui/core'

import Documents from './Documents'
import React from 'react'
import Utils from 'src/views/shippinginstruction/shared/Utils'
import ManageScheduleTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import { components, useServices } from 'cng-web-lib'
import Api from 'src/views/shippinginstruction/shared/api'

import ArchiveIcon from './ArchiveIcon'
const { CngGridItem } = components

const HeaderBarTop = (props) => {
  const translatedTextsObject = ManageScheduleTranslationText()
  const { shippingInfo } = props
  const shippingInfoStatus = shippingInfo.siStatus || ''
  let bgColor =
    shippingInfoStatus !== ''
      ? Utils.getSIStatusById(shippingInfoStatus).color
      : ''
  let status =
    shippingInfoStatus !== ''
      ? Utils.getSIStatusById(shippingInfoStatus).name.toUpperCase()
      : ''
  const { securedSendRequest } = useServices()

  const generatePreviewDoc = () => {
    Api.previewSI(securedSendRequest, shippingInfo)
  }

  return (
    <Card className={'titleBox'} style={{ backgroundColor: bgColor }}>
      <Grid container xs={12} alignItems='center'>
        <CngGridItem xs={9}>
          <Typography className={'boldLabel'} style={{ fontSize: 16 }}>
            {status + ' '}
            {(props.isCarrier
              ? shippingInfo.carrierClosed
              : shippingInfo.closed) && <ArchiveIcon size={'lg'} />}
          </Typography>
        </CngGridItem>

        <Grid container xs={3} alignItems='center'>
          <Grid sm={9}>
            <Typography className={'greyLabel alignRight'}>
              {translatedTextsObject.previewBLDocument}
            </Typography>
          </Grid>
          <Grid sm={3} container justify='flex-end'>
            <IconButton onClick={generatePreviewDoc}>
              <Documents />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default HeaderBarTop
