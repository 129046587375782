import { Box, Card, Grid, Typography } from '@material-ui/core'

import DirectSI from './DirectSI'
import Hazardous from 'src/components/button/Hazardous.js'
import React from 'react'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const SIDetailBottom = (props) => {
  const { dockey, hazardous, updatedDate, directSi } = props
  const translatedTextsObjects = ShippingInstructionTranslationText()

  return (
    <footer className={`cng-summary-card--footer-box`}>
      <Grid container xs={12} style={{ height: '100%' }}>
        <Grid item xs={12} sm={7}>
          <div className={`cng-summary-card--footer-box--left`}>
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
              ></img>
            </span>

            <span>
              <label data-label-suffix='' className={`form-label-colon`}>
                {translatedTextsObjects.bookingId}
              </label>
              <label>{dockey}</label>
            </span>

            <span>{hazardous && <Hazardous skipText />}</span>

            <span>{directSi && <DirectSI skipText />}</span>
          </div>
        </Grid>

        <Grid item xs={12} sm={5}>
          <div className={`cng-summary-card--footer-box--right`}>
            <div className={`footer-box--right-col-1 text-right-align`}>
              <Typography className={'frb-card-label-large-grey'}>
                {translatedTextsObjects.lastModifiedOn + '  '}
                <b>
                  {Utils.formatDate(
                    updatedDate,
                    Utils.FORMAT_DATE_TIME,
                    Utils.DASH
                  )}
                </b>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </footer>
  )
}

export default SIDetailBottom
