import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import CarrierViewField from 'src/components/field/CarrierViewField'
import CarrierViewWithVesselField from 'src/components/field/CarrierViewWithVesselField'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const { scheduleData, showDetails, auditSI } = props
  const translatedTextsObject = FreightBookingTranslationText()
  const siTranslationTextObject = ShippingInstructionTranslationText()
  const [, , { setValue: setCarrierPartyIdField }] = useField('carrierPartyId')
  const [, , { setValue: setPolCodeField }] = useField('polCode')
  const [, , { setValue: setPodCodeField }] = useField('podCode')
  const [, ,] = useField('polCountryCode')
  const [, ,] = useField('podCountryCode')
  const polKeyRef = useRef(uuid())
  const podKeyRef = useRef(uuid())
  const carrierPartyIdKeyRef = useRef(uuid())

  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    // alert(JSON.stringify(scheduleData.carrierPartyId))
    setCarrierPartyIdField(scheduleData.carrierPartyId)
    // setCarrierPartyIdField('SINOTRANS')
    setPolCodeField(scheduleData.polCode)
    setPodCodeField(scheduleData.podCode)

    console.log(scheduleData.polEta)

    polKeyRef.current = uuid()
    podKeyRef.current = uuid()
    carrierPartyIdKeyRef.current = uuid()

    setShouldRender(true)
  }, [])

  if (!shouldRender) {
    return null
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {/* <div> {translatedTextsObject.schedule}</div> */}
            <Grid container xs={12} sm={12} spacing={0}>
              <Grid xs={6} sm={6} container justify='flex-start'>
                {translatedTextsObject.schedule}
              </Grid>
            </Grid>
          </Box>
          <Box>
            <ComponentWrapper cardStyle={{ margin: 20 }}>
              <Grid container xs={12} sm={12} spacing={0}>
                <CngGridItem xs={12} sm={3}>
                  <Box
                    className='view-card-content'
                    style={Utils.auditHighLight(auditSI, ['carrierPartyId'])}
                  >
                    <Box className='view-card-content-label'>
                      {translatedTextsObject.carrierNvoccBookingAgent}
                    </Box>
                    <Box
                      className='view-card-content-value'
                      style={{
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-line'
                      }}
                    >
                      <CarrierViewWithVesselField
                        carrierPartyId={scheduleData.carrierPartyId}
                        vesselName={scheduleData.vesselName}
                        voyageNo={scheduleData.voyageNo}
                        service={scheduleData.service}
                      />
                    </Box>
                  </Box>
                </CngGridItem>
                <CngGridItem xs={12} sm={9}>
                  <Grid container style={{ paddingRight: 20 }}>
                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, ['polCode'])}
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.portOfLoading}
                        </Box>
                        <Box
                          className='view-card-content-value'
                          style={{ paddingRight: 0 }}
                        >
                          {/* using polCodeField.value will cause data render issue due to initial field value is empty */}
                          {/* <PortViewField portCode={polCodeField.value} /> */}
                          <PortViewField portCode={scheduleData.polCode} />
                        </Box>
                      </Box>
                    </CngGridItem>
                    <CngGridItem xs sm>
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                      >
                        {/* <Box className="view-card-content">
                                                    <Box className="view-card-content-value" style={{ paddingLeft: 30, paddingRight: 0 }}> 
                                                <ArrowForwardIcon />
                                                </Box>
                                                </Box> */}
                      </Grid>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, ['podCode'])}
                      >
                        <Box
                          className='view-card-content-label'
                          style={{ paddingLeft: 0 }}
                        >
                          {translatedTextsObject.portOfDischarge}
                        </Box>
                        <Box
                          className='view-card-content-value'
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          {/* using podCodeField.value will cause data render issue due to initial field value is empty */}
                          {/* <PortViewField portCode={podCodeField.value} /> */}
                          <PortViewField portCode={scheduleData.podCode} />
                        </Box>
                      </Box>
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
                <CngGridItem xs={12} sm={4}></CngGridItem>

                <CngGridItem xs={12} sm={8}>
                  <Grid container>
                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, ['polEta'])}
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.polPlannedEta}
                        </Box>
                        <Box className='view-card-content-value'>
                          {Utils.formatDate(
                            scheduleData.polEta,
                            Utils.UI_FORMAT_DATE_TIME,
                            Utils.DASH
                          )}
                        </Box>
                      </Box>
                    </CngGridItem>
                    <CngGridItem xs sm>
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                      >
                        {/* <Box className="view-card-content">
                                                    <Box className="view-card-content-value"> 
                                                <ArrowForwardIcon />
                                                </Box>
                                                </Box> */}
                      </Grid>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, ['podEta'])}
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.podPlannedEta}
                        </Box>
                        <Box className='view-card-content-value'>
                          {Utils.formatDate(
                            scheduleData.podEta,
                            Utils.UI_FORMAT_DATE_TIME,
                            Utils.DASH
                          )}
                        </Box>
                      </Box>
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
              </Grid>
            </ComponentWrapper>
            {showDetails && (
              <div>
                <Grid container xs={12} sm={12} spacing={0}>
                  <Grid container xs sm></Grid>
                  <Grid container xs={12} sm={8}>
                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, ['polEtd'])}
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.polPlannedEtd}
                        </Box>
                        <Box className='view-card-content-value'>
                          {Utils.formatDate(
                            scheduleData.polEtd,
                            Utils.UI_FORMAT_DATE_TIME,
                            Utils.DASH
                          )}
                        </Box>
                      </Box>
                    </CngGridItem>
                    <CngGridItem xs sm>
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                      >
                        {/* <Box className="view-card-content">
                                                    <Box className="view-card-content-value"> 
                                                <ArrowForwardIcon />
                                                 </Box>
                                                </Box> */}
                      </Grid>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, ['podEtd'])}
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.podPlannedEtd}
                        </Box>
                        <Box className='view-card-content-value'>
                          {Utils.formatDate(
                            scheduleData.podEtd,
                            Utils.UI_FORMAT_DATE_TIME,
                            Utils.DASH
                          )}
                        </Box>
                      </Box>
                    </CngGridItem>
                  </Grid>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={siTranslationTextObject.polDesc}
                      value={scheduleData.polDesc}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['polDesc']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={siTranslationTextObject.podDesc}
                      value={scheduleData.podDesc}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['podDesc']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={2} sm={2}>
                    <LabelValueVertical
                      label={siTranslationTextObject.preCarriageVesselName}
                      value={scheduleData.preCarriageVesselName}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, [
                          'preCarriageVesName'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={2} sm={2}>
                    <LabelValueVertical
                      label={siTranslationTextObject.preCarriageVoyageNo}
                      value={scheduleData.preCarriageVoyageNo}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['preCarriageVoyNo']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.internationalVoyageNumber}
                      value={scheduleData.intVoyageNo}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['intVoyageNo']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={2} sm={2}>
                    <LabelValueVertical
                      label={translatedTextsObject.vesselName}
                      value={scheduleData.vesselName}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['vesselName']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={2} sm={2}>
                    <LabelValueVertical
                      label={translatedTextsObject.voyageNumber}
                      value={scheduleData.voyageNo}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['voyageNo']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                </Grid>
              </div>
            )}
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({})

const ViewScheduleComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewScheduleComponent
