import {
  Box,
  Card,
  Grid,
  IconButton,
  Button,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import ViewWeekOutlinedIcon from '@material-ui/icons/ViewWeekOutlined'
import FitnessCenterSharpIcon from '@material-ui/icons/FitnessCenterSharp'
import ArchiveIcon from '@material-ui/icons/Archive'
import CropSquareIcon from '@material-ui/icons/CropSquare'
import Utils from 'src/views/common/utils/Utils'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const SIParticularsControlTotalComponent = (props) => {
  const [contNo, setContNo] = useState(0)
  const [packageNo, setPackageNo] = useState(0)
  const [shipmentWeight, setShipmentWeight] = useState(0)
  const [vgmWeight, setVgmWeight] = useState(0)
  const [shipmentVolume, setshipmentVolume] = useState(0)

  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  useEffect(() => {
    let vgmWeightSum = 0
    let packageSum = 0
    let shipmentWeightSum = 0
    let shipmentVolumeSum = 0

    props.containerList.map((elem, i) => {
      if ('totalVgmWeight' in elem && elem.totalVgmWeight != null) {
        vgmWeightSum += Utils.parseNumber(elem.totalVgmWeight)
      }
      return null
    })

    props.cargoList.forEach((elem, i) => {
      if (props.isDGCargo) {
        if (elem.dgDetails && elem.dgDetails.length > 0) {
          elem.dgDetails.forEach((details) => {
            packageSum +=
              details.quantity === null ? 0 : parseInt(details.quantity)

            shipmentWeightSum += Utils.parseNumber(details.grossWeight)

            shipmentVolumeSum += Utils.parseNumber(details.grossVolume)
          })
        }
      } else if (
        'quantity' in elem &&
        elem.quantity != '' &&
        elem.quantity != null
      ) {
        //normal cargo
        packageSum += parseInt(elem.quantity)
        shipmentWeightSum += Utils.parseNumber(elem.grossWeight)
        shipmentVolumeSum += Utils.parseNumber(elem.grossVolume)
      }

      return null
    })

    setVgmWeight(vgmWeightSum)
    setPackageNo(packageSum)
    setShipmentWeight(shipmentWeightSum)
    setshipmentVolume(shipmentVolumeSum)
    setContNo(props.containerList.length)
  }, [props.cargoList, props.containerList])

  return (
    <Card>
      <Box>
        <Grid container className='accordian-header' alignItems='center'>
          <Box p={2} fontSize='large' fontWeight='bolder'>
            {siTranslatedTextsObject.controlTotal}
          </Box>
          <Typography
            style={{ color: '#a2a2a2' }}
            variant='caption'
            align='left'
          >
            {siTranslatedTextsObject.controlTotalDesc}
          </Typography>
        </Grid>
        <Box p={2} style={{ backgroundColor: '#FBFBFB' }}>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            justify='center'
            alignItems='center'
          >
            <CngGridItem xs={3} sm={3}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {siTranslatedTextsObject.noOfContainers}
                  </Typography>
                  <IconButton
                    style={{
                      color: '#8e98b0',
                      backgroundColor: '#dbdbe2',
                      width: '30px',
                      height: '25px',
                      borderRadius: '20%'
                    }}
                    disabled={true}
                  >
                    <ViewWeekOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {contNo}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>

            <CngGridItem xs={3} sm={3}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {siTranslatedTextsObject.quantity}
                  </Typography>
                  <IconButton
                    style={{
                      color: '#8e98b0',
                      backgroundColor: '#dbdbe2',
                      width: '30px',
                      height: '25px',
                      borderRadius: '20%'
                    }}
                    disabled={true}
                  >
                    <ArchiveIcon />
                  </IconButton>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {packageNo}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>

            <CngGridItem xs={3} sm={3}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {siTranslatedTextsObject.shipmentWeightNoMetric}
                  </Typography>
                  <Grid>
                    <Button
                      style={{
                        color: '#8e98b0',
                        backgroundColor: 'white',
                        maxWidth: '30px',
                        maxHeight: '25px',
                        minWidth: '30px',
                        minHeight: '25px',
                        marginRight: '5px'
                      }}
                      disabled={true}
                    >
                      KG
                    </Button>
                    <IconButton
                      style={{
                        color: '#8e98b0',
                        backgroundColor: '#dbdbe2',
                        width: '30px',
                        height: '25px',
                        borderRadius: '20%'
                      }}
                      disabled={true}
                    >
                      <FitnessCenterSharpIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {shipmentWeight}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>

            <CngGridItem xs={3} sm={3}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {siTranslatedTextsObject.vgmWeightNoMetric}
                  </Typography>
                  <Grid>
                    <Button
                      style={{
                        color: '#8e98b0',
                        backgroundColor: 'white',
                        maxWidth: '30px',
                        maxHeight: '25px',
                        minWidth: '30px',
                        minHeight: '25px',
                        marginRight: '5px'
                      }}
                      disabled={true}
                    >
                      KG
                    </Button>
                    <IconButton
                      style={{
                        color: '#8e98b0',
                        backgroundColor: '#dbdbe2',
                        width: '30px',
                        height: '25px',
                        borderRadius: '20%'
                      }}
                      disabled={true}
                    >
                      <FitnessCenterSharpIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {vgmWeight}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            justify='center'
            alignItems='center'
          >
            <CngGridItem xs={12} sm={12}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {siTranslatedTextsObject.shipmentVolume}
                  </Typography>
                  <Grid>
                    <Button
                      style={{
                        color: '#8e98b0',
                        backgroundColor: 'white',
                        maxWidth: '30px',
                        maxHeight: '25px',
                        minWidth: '30px',
                        minHeight: '25px',
                        marginRight: '5px'
                      }}
                      disabled={true}
                    >
                      CBM
                    </Button>
                    <IconButton
                      style={{
                        color: '#8e98b0',
                        backgroundColor: '#dbdbe2',
                        width: '30px',
                        height: '25px',
                        borderRadius: '20%'
                      }}
                      disabled={true}
                    >
                      <CropSquareIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {shipmentVolume}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>
          </Grid>
        </Box>
      </Box>
    </Card>
  )
}

export default SIParticularsControlTotalComponent
