import { Box } from '@material-ui/core'
import React from 'react'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CommonUtils from 'src/views/common/utils/Utils'

const ReviewSISuccessMessageComponent = (props) => {
  const { title, content, date } = props
  return (
    <Box>
      <Box display='flex' flexDirection='row'>
        {title}
        <Box pl={1.5}>
          <CheckCircleOutlineOutlinedIcon className='light-green' />
        </Box>
      </Box>
      <Box className='info-label' pt={2} mb={-2}>
        {content + ' '}
        {CommonUtils.formatDate(
          date,
          CommonUtils.FORMAT_LONG_DATE_TIME,
          CommonUtils.DASH
        )}
      </Box>
    </Box>
  )
}

export default ReviewSISuccessMessageComponent
