import { Avatar, Box, Card, Chip, Grid, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import CircularProgress from '@material-ui/core/CircularProgress'
import FilterListNoResult from './FilterListNoResult'
import GeneralResetFilterComponent from 'src/views/common/ui/GeneralResetFilterComponent'
import SIDetailCarrierComponent from './SIDetailCarrierComponent'
import SIDetailComponent from './SIDetailComponent'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
  const observer = useRef()
  //const loading = props.loading
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = ShippingInstructionTranslationText()
  const [displayedSI, setDisplayedSI] = useState([])

  const progressiveApiUrl = props.helpdesk
    ? ShippingInstructionApiUrls.HELPDESK_SHIPPING_LIST
    : ShippingInstructionApiUrls.SHIPPING_LIST
  const loadAmount = 10
  const { fetchRecords } = useServices()
  const { fetchPageableRecords } = useServices()

  const [pageNumber, setPageNumber] = useState(0)

  let searchCriteria = props.searchCriteria

  useEffect(() => {
    console.log('page number changes ' + pageNumber)
    console.log(searchCriteria)

    if (pageNumber > 0) {
      setLoading(true)

      ProgressiveLoadingBooking2()
    }
  }, [pageNumber])

  function ProgressiveLoadingBooking2() {
    console.log('ProgressiveLoadingBooking2')
    fetchPageableRecords.execute(
      progressiveApiUrl,
      {
        filters: [],
        sorts: [],
        page: pageNumber,
        pageSize: loadAmount,
        customData: searchCriteria
      },
      (data) => {
        console.log(data.content)

        if (data.content.length < loadAmount) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        let temp3 = [...displayedSI]
        console.log(temp3)
        let temp4 = temp3.concat(data.content)
        console.log(temp4)

        setDisplayedSI(temp4)
        setLoading(false)
      },
      (error) => {
        props.setPageError(true)
      }
    )
  }

  useEffect(() => {
    setLoading(false)
    let temp = [...props.siList]
    if (temp.length < loadAmount) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    console.log(hasMore)
    setPageNumber(0)
    setDisplayedSI(temp)
    console.log(displayedSI)
  }, [props.siList])

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          //   setPageNumber(prevPageNumber => prevPageNumber + 1)
          // expandList()
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          console.log(hasMore)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  function showNoResultPage() {
    return (
      props.siList.length == 0 &&
      props.shownCount == 0 &&
      !loading &&
      !props.formLoading
    )
  }

  return (
    <>
      <Box
        style={{
          display: showNoResultPage() ? 'inline' : 'none'
        }}
      >
        <FilterListNoResult
          resetFilterAction={props.handleClearAllButtonClick}
        />
      </Box>

      <Box
        style={{
          display: showNoResultPage() ? 'none' : 'inline'
        }}
      >
        {displayedSI.map((m, idx) => (
          <div key={idx} ref={lastBookElementRef}>
            {props.isCarrier ? (
              <SIDetailCarrierComponent.FormBody si={m} {...props} />
            ) : (
              <SIDetailComponent.FormBody si={m} {...props} />
            )}
          </div>
        ))}

        <Box mt={5} style={{ display: loading ? 'none' : 'inline' }}>
          <GeneralResetFilterComponent
            leftCount={props.leftCount}
            resetDesc={translatedTextsObject.resetFilterDesc}
            handleResetAction={props.handleClearAllButtonClick}
          />
        </Box>
      </Box>

      <div style={{ display: loading ? 'inline' : 'none' }}>
        <Grid container justify='center'>
          <CngGridItem>
            <CircularProgress />
          </CngGridItem>
        </Grid>
      </div>
    </>
  )
}

const SIListComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIListComponent
