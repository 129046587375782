import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Box,
  Chip,
  Link
} from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components, useServices } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import { faBiohazard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import Api from 'src/views/freightbooking/shared/api'
import ApiParser from 'src/views/freightbooking/shared/apiParser'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ShipmentSearchFormProperties from '../createsi/ShipmentSearchFormProperties'
import CommonUtils from 'src/views/common/utils/Utils'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import DirectSI from './DirectSI'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import Utils from 'src/views/freightbooking/shared/Utils'
const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngSelectField,
      CngSwitchField,
      CngCodeMasterAutocompleteField,
      CngTextField
    }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
  shipmentType: CalistaConstant.FULL_CONTAINER_LOAD,
  hazardous: false,
  bookingRefNo: '',
  loadExistingBooking: false
  // forwardHubScheme: ""
})

const FormBody = (props) => {
  const { errors, setErrors } = useFormikContext()
  const { isCarrier } = props
  const history = useHistory()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const [bookingTypeExpanded, setBookingTypeExpanded] = useState(false)
  const [shipmentTypeField, , { setValue: setShipmentTypeField }] =
    useField('shipmentType')
  const [carrierRefNoField, , { setValue: setCarrierRefNoField }] =
    useField('bookingRefNo')

  const [
    loadExistingBookingField,
    ,
    { setValue: setLoadExistingBookingField }
  ] = useField('loadExistingBooking')
  const [shipmentKey, setShipmentKey] = useState()

  const handleBookingExpand = () => {
    setBookingTypeExpanded(!bookingTypeExpanded)
  }
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  // Populating booking if exists
  const { fetchRecords } = useServices()
  const [carrierRefList, setCarrierRefList] = useState([])
  const [bookingList, setBookingList] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadAmount, setloadAmount] = useState(100)
  const [loadExistingBooking, setLoadExistingBooking] = useState(false)
  const isMySI = SIUtils.Menus.mySI === props.prevMenu

  let searchCriteria = {
    dateRange: 'all',
    statusCodes: [5102],
    showArchived: true,
    filterText: '',
    partyIds: [],
    sortCode: 1,
    baseFilterDTO: { size: loadAmount }
  }

  function fetchPartyIdFromAPI() {
    Api.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
  }
  function populatePartyId(dat) {
    let FromApi = ApiParser.parsePartyId(dat)
    if (FromApi[0] !== undefined) {
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.bookingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForBookingList(dat.content)
      console.log(partyIdsForSearch)
      getBookingListForDirectSISearch()
    } else {
      setLoading(false)
    }
  }
  function getPartyIdForBookingList(carrierList) {
    console.log('getPartyIdForBookingList')
    let partyIdss = []
    console.log('loading party id from api')

    carrierList.map((v) => {
      console.log(v.partyId)
      partyIdss.push(v.partyId)
    })

    searchCriteria.partyIds = partyIdss
    return partyIdss
  }
  function getBookingListForDirectSISearch() {
    Api.fetchBookingList(
      fetchRecords,
      searchCriteria,
      populateBookingList,
      onError
    )
  }
  function populateBookingList(data) {
    if (data.totalPages > 1) {
      //in case there are more than 100 bookings
      let temp = data.totalPages * data.size
      setloadAmount(parseInt(temp))
      // getBookingList()
    }
    setBookingList(data.content)

    let tempList = []
    data.content.map((elem) => {
      if (elem.carRefNo != null) {
        tempList.push(elem.carRefNo)
      } else {
        tempList.push(null)
      }
    })
    console.log(tempList)
    setCarrierRefList(tempList)
    setLoading(false)
  }
  function onError(error) {
    console.log('connection-request error', error.message)
    showErrorNotification(error.message)
    setLoading(false)
  }

  function onClickCaptionText(evt) {
    // alert('onClickCaptionText' + carrierRefNoField.value)

    history.push({
      pathname: pathMap.CREATE_SI,
      state: { carRefNo: carrierRefNoField.value, fromDirectSIPage: true }
    })
    // setLoading(true)
    // let index = carrierRefList.indexOf(carrierRefNoField.value)
    // const bookingRequest = {
    //   bookingId: bookingList[index].bookingId
    //     ? bookingList[index].bookingId
    //     : '',
    //   dockey: bookingList[index].dockey ? bookingList[index].dockey : ''
    // }
    // Api.fetchBookingDetailsById(
    //   fetchRecords,
    //   bookingRequest,
    //   props.populateBookingFromDirect
    // )
    // setLoading(false)
  }

  // useEffect(() => {
  //   console.log('???')
  //   setLoadExistingBooking(carrierRefList.includes(carrierRefNoField.value))
  //   // setLoadExistingBookingField(
  //   //   carrierRefList.includes(carrierRefNoField.value)
  //   // )
  // })

  // useEffect(() => {
  //   if(loadExistingBooking){
  //    console.log(errors)
  //    setErrors({email:"Is already taken"})

  //   }

  // }, [loadExistingBooking])

  useEffect(() => {
    console.log('carrierRefList')
    setShipmentKey(uuid())
    if (
      props.isDirectSI &&
      // carrierRefList.length === 0 &&
      !props.isCarrier &&
      !isMySI
    ) {
      setLoading(true)
      fetchPartyIdFromAPI()
    } else {
      setLoading(false)
    }
  }, [props.isEdit, props.isDirectSI])

  const conductSearch = (e) => {
    console.log('conductSearch', carrierRefList)
    console.log(e.target.value)
    console.log(carrierRefList.includes(e.target.value))
    setLoadExistingBookingField(carrierRefList.includes(e.target.value))
  }

  return (
    <Card>
      <Accordion expanded={bookingTypeExpanded}>
        <AccordionSummary
          aria-controls='bookingComponentContent'
          id='bookingComponentHeader'
        >
          <Grid container>
            <CngGridItem xs={6} sm={6}>
              <AccordionHeaderComponent
                title={siTranslatedTextsObject.bookingType}
              />
            </CngGridItem>
            <CngGridItem xs={6} sm={6}>
              <Grid container xs={12} sm={12} spacing={2} justify='flex-end'>
                <CngGridItem xs={12} sm={12}>
                  <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                  >
                    <Box>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                      ></img>
                    </Box>

                    <Box className={'boldLabel'}>
                      &nbsp;{siTranslatedTextsObject.bookingId + ' '}
                      {!CommonUtils.isEmpty(
                        props.bookingTypeData.freightBookingId
                      )
                        ? props.bookingTypeData.freightBookingId
                        : 'N/A'}
                    </Box>
                    <Box
                      px={1}
                      style={{ display: props.isDirectSI ? 'flex' : 'none' }}
                    >
                      <span>
                        {/* <Chip
                          icon={<FileCopyOutlinedIcon />}
                          label={siTranslatedTextsObject.directSI}
                          size='small'
                        /> */}
                        <DirectSI />
                      </span>
                    </Box>
                  </Grid>
                </CngGridItem>
              </Grid>
            </CngGridItem>
            <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
              <CngGridItem xs={4} sm={4}>
                <CngCodeMasterAutocompleteField
                  name='shipmentType'
                  label={siTranslatedTextsObject.shipmentType}
                  codeType='CAL_CONT_STATUS'
                  key={shipmentKey}
                  disabled
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='bookingRefNo'
                  inputProps={{ maxLength: 35 }}
                  label={
                    siTranslatedTextsObject.freightBookingReferenceNumber
                  }
                  disabled={!props.isDirectSI || isCarrier || isMySI}
                  onChange={(e) => conductSearch(e)}
                  isRequired
                />
                <span>
                  {/* <Link
                    style={{
                      display:
                        loadExistingBookingField.value && props.isDirectSI
                          ? 'inline'
                          : 'none',
                      fontSize: 11,
                      color: '#FF0000'
                    }}
                    component='button'
                    variant='caption'
                    underline='none'
                    onClick={onClickCaptionText}
                  >
                    {siTranslatedTextsObject.bookingExist}
                  </Link> */}
                  {loadExistingBookingField.value && props.isDirectSI && (
                    <Link
                      style={{
                        // display:
                        //   loadExistingBookingField.value && props.isDirectSI
                        //     ? 'inline'
                        //     : 'none',
                        fontSize: 11
                      }}
                      component='button'
                      variant='caption'
                      underline='always'
                      onClick={onClickCaptionText}
                    >
                      {siTranslatedTextsObject.populateForm1 +
                        carrierRefNoField.value +
                        siTranslatedTextsObject.populateForm2}
                    </Link>
                  )}
                </span>
              </CngGridItem>
              <CngGridItem xs={2} sm={2}>
                <FontAwesomeIcon icon={faBiohazard} color='blue' />
                &nbsp;{siTranslatedTextsObject.hazardous}
              </CngGridItem>
              <CngGridItem xs={2} sm={2}>
                <CngSwitchField
                  name='hazardous'
                  onChange={props.onDGchange}
                  disabled={!props.isDirectSI || isCarrier || isMySI}
                  // label={translatedTextsObject.hazardous + ' *'}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          {/* forwardHub not ready yet */}
          <Grid container xs={12} sm={12} spacing={2}>
            <CngGridItem xs={4} sm={4}>
              <CngTextField
                disabled
                type='text'
                name='forwardHubScheme'
                label={siTranslatedTextsObject.outboundRefNo}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <CngSelectField
                disabled
                name='forwardHubScheme'
                label={siTranslatedTextsObject.forwardHubScheme}
              />
            </CngGridItem>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleBookingExpand}
        expanded={bookingTypeExpanded}
        footerText={siTranslatedTextsObject.additionalBookingTypeDetails}
      />
    </Card>
  )
}

const SIBookingTypeComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIBookingTypeComponent
