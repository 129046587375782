import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'

import React, { useState } from 'react'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'

const AuditTrailSchedule = (props) => {
  const { si, codeMasterList } = props
  const tranObj = FreightBookingTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)
  const siTranObj = ShippingInstructionTranslationText()
  const handleScheduleExpand = () => {
    setScheduleExpanded(!scheduleExpanded)
  }

  return (
    <Card>
      <Accordion expanded={scheduleExpanded}>
        <AccordionSummary
          aria-controls='contactComponentContent'
          id='contactComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent title={tranObj.contact} hideMandatory />
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container xs={12} sm={12}>
            <AuditTrailDualColumn
              object={si}
              field={'blApplnType'}
              codeType={CalistaConstant.CAL_BL_TYPE}
              codeMasterList={codeMasterList}
              label={siTranObj.blApplicationType}
            />
            <AuditTrailDualColumn
              object={si}
              field={'surrenderBl'}
              label={siTranObj.surrenderBl}
            />
            <AuditTrailDualColumn
              object={si}
              field={'bolNumber'}
              label={siTranObj.blNo}
            />

            <AuditTrailDualColumn
              object={si}
              field={'originalNo'}
              label={siTranObj.noOfOriginals}
            />
            {/* <AuditTrailDualColumn
              object={si}
              field={'orderNo'}
              label={siTranObj.purchaseOrderNumber}
            /> */}
            <AuditTrailDualColumn
              object={si}
              field={'shipOnBoardDate'}
              timestamp
              label={siTranObj.shippedOnBoardDateTime}
            />
            <AuditTrailDualColumn
              object={si}
              field={'cntrPkg'}
              label={siTranObj.noOfContainersPackagesInWords}
            />
            <AuditTrailDualColumn
              object={si}
              field={'issueDate'}
              timestamp
              label={siTranObj.issueDateTime}
            />

            <AuditTrailDualColumn
              object={si}
              field={'placeOfIssue'}
              label={siTranObj.issuePlace}
            />
            <AuditTrailDualColumn
              object={si}
              field={'carrierClauseDesc'}
              label={siTranObj.carrierClauseDesc}
            />

            <AuditTrailDualColumn
              object={si}
              field={'formLayoutName'}
              codeType={CalistaConstant.CAL_FORM_TYPE}
              codeMasterList={codeMasterList}
              label={siTranObj.documentLayout}
            />

            <AuditTrailDualColumn
              object={si}
              field={'freightCharge'}
              label={siTranObj.freightCharge}
            />

            <AuditTrailDualColumn
              object={si}
              field={'depot'}
              label={siTranObj.depot}
            />

            <AuditTrailDualColumn
              object={si}
              field={'shpName'}
              label={siTranObj.shipperName}
            />

            <AuditTrailDualColumn
              object={si}
              field={'shpAddress'}
              label={siTranObj.shipperAddress}
            />
            <AuditTrailDualColumn
              object={si}
              field={'conName'}
              label={siTranObj.consigneeName}
            />

            <AuditTrailDualColumn
              object={si}
              field={'conAddress'}
              label={siTranObj.consigneeAddress}
            />

            <AuditTrailDualColumn
              object={si}
              field={'usccNo'}
              label={siTranObj.usccNo}
            />

            <AuditTrailDualColumn
              object={si}
              field={'dstNotifyName'}
              label={siTranObj.notifyPartyName}
            />

            <AuditTrailDualColumn
              object={si}
              field={'dstNotifyAddress'}
              label={siTranObj.notifyPartyAddress}
            />

            <AuditTrailDualColumn
              object={si}
              field={'agtName'}
              label={siTranObj.agentName}
            />

            <AuditTrailDualColumn
              object={si}
              field={'agtAddress'}
              label={siTranObj.agentAddress}
            />

            <AuditTrailDualColumn
              object={si}
              field={'bkgRep'}
              label={siTranObj.bookingRepresentativeDetails}
            />

            <AuditTrailDualColumn
              object={si}
              field={'partnerEmails'}
              array
              label={siTranObj.partnerEmail}
            />

            <AuditTrailDualColumn
              object={si}
              field={'notifyPartnerEmails'}
              label={siTranObj.partnerEmail}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleScheduleExpand}
        expanded={scheduleExpanded}
        footerText={tranObj.showContactDetails}
        footerTextExpanded={tranObj.hideContactDetails}
        noOptionalFields
      />
    </Card>
  )
}

export default AuditTrailSchedule
