import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { components, constants, useServices } from 'cng-web-lib'

import * as CalistaConstant from 'src/constants/CalistaConstant'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls'
import { v4 as uuid } from 'uuid'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import Paper from '@material-ui/core/Paper'
import DocumentDetailsActionMenu from 'src/views/freightbooking/components/DocumentDetailsActionMenu'
import Utils from 'src/views/common/utils/Utils'
import DocumentApi from 'src/views/common/api/DocumentApi'
const { CngGridItem, button: { CngSecondaryButton }, table: { useDefaultNotification } } = components

const { CodeMaintenanceType,
  filter: {
    EQUAL
  } } = constants

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    }
  }
} = components

const FormBody = (props) => {
  const { auditSI, siStatus, billOfLadingNo, shippingInfoId } = props
  const translatedTextsObject = FreightBookingTranslationText()
  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)
  const [documentList, setDocumentList] = useState([])
  const auditDocuments =
    auditSI && auditSI.shippingInfoDocumentDTOs
      ? auditSI.shippingInfoDocumentDTOs
      : {}
  console.log('auditDocuments', auditDocuments)
  const { securedSendRequest, fetchRecords, createRecord } = useServices()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const [enableBtn, setEnableBtn] = useState(false)
  const [checkedState, setCheckedState] = useState(new Array(props.document.length).fill(false));
  const [selectedDocs, setSelectedDocs] = useState([])
  const [isSiApproved, setIsSiApproved] = useState(false)

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(props.showNotification)

  useEffect(() => {
    setShouldRender(true)
    setDocumentList(props.document)
    fetchUserRoleFromAPI()
  }, [])

  if (!shouldRender) {
    return null
  }

  function fetchUserRoleFromAPI() {
    fetchRecords.execute(
      `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/by-login/get`,
      undefined,
      (data) => {
        console.log('fetchUserRoleFromAPI data: ', data)
        if (data.length > 0) {
          let partyId = data[0].partyId
          fetchPartyId(partyId)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function fetchPartyId(partyId) {
    console.log('fetchPartyId:' + partyId)
    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/child/get`,
      {
        customData: partyId,
        filters: [
          {
            field: 'id',
            operator: EQUAL,
            value: partyId
          }
        ]
      },
      (data) => {
        console.log('fetchPartyId data: ', data)
        if (data.content.length > 0) {
          let partyGroup = []
          data.content[0].partyGroupDTOS.forEach((group) => {
            partyGroup.push(group.userGroupCode)
          })
          console.log('fetchUserGroups: ' + partyGroup)

          if (partyGroup.includes("EDOC")) {
            checkSiApprovedAndBlNumExists()
          }
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function checkSiApprovedAndBlNumExists() {
    if (((siStatus.toString()) === CalistaConstant.SI_STATUS_SI_APPROVED) && (billOfLadingNo != null)) {
      console.log('SI is approved and BL number exists.')
      setIsSiApproved(true)
    }
  }

  const downloadRowClick = (id) => () => {
    console.log('download uploaded: ' + id)

    DocumentApi.downloadDocument(
      securedSendRequest,
      documentList[id],
      props.module
    )
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, i) =>
      i === position ? !item : item
    )

    setCheckedState(updatedCheckedState)
    console.log('Items selected are: ' + updatedCheckedState)

    const numSelectedDocs = updatedCheckedState.reduce(
      (sum, currentState) => {
        if (currentState === true) {
          return (sum + 1)
        }
        return sum
      },
      0
    )

    let temp = []
    for (let index = 0; index < updatedCheckedState.length; index++) {
      if (updatedCheckedState[index] === true) {
        temp.push(documentList[index])
      } else if (updatedCheckedState[index] === false) {
        temp.splice(index, 1)
      }
      setSelectedDocs(temp)
    }

    if (numSelectedDocs == 0) {
      setEnableBtn(false)
    } else {
      setEnableBtn(true)
    }
  }

  function sendToEssdocs() {
    props.setLoading(true)
    console.log('The list of documents selected are: ' + selectedDocs)

    let formData = new FormData()
    formData.append("shippingInfoId", shippingInfoId)

    selectedDocs.map((file, i) => {
      formData.append("id", file.docId)
    })
    console.log('the form data is ', ...formData)

    function onSuccess(response) {
      props.setLoading(false)
      showSuccessNotification('Documents have been pushed successfully.')
      if (response != null) {
        console.log('The response is: ', response)
        response.map((elem, id) => {

          for (let index = 0; index < documentList.length; index++) {
            if (documentList[index].docId == elem.documentId) {

              const list = [...documentList]
              list[index].docPushCount = elem.docPushCount
              list[index].docPushDate = elem.docPushDate
              setDocumentList(list)
              console.log('The updated document list is ', documentList)
            }
          }
        })
      }
    }

    function onError(error) {
      props.setLoading(false)
      showErrorNotification(error.message)
      console.log('Error in pushing documents: ' + error)
    }

    createRecord.execute(
      ShippingInstructionApiUrls.PUSH_ESSDOCS,
      formData,
      onSuccess,
      onError
    )
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {translatedTextsObject.document}
          </Box>
          <Box>
            <TableContainer component={Paper}>
              <Table id='table'>
                <TableBody>
                  {documentList.map((elem, i) => {
                    let highlight = {}
                    if (Object.entries(auditDocuments).length > 0) {
                      highlight = Utils.auditHighLight(
                        auditDocuments,
                        ['docType', 'description'],
                        elem.shippingInfoDocId,
                        'document'
                      )
                      console.log('auditDocuments highlight', highlight)
                    }
                    return (
                      <TableRow key={`row-${i}`} style={highlight.document}>
                        {isSiApproved && (
                          <TableCell>
                            <Checkbox
                              checked={checkedState[i]}
                              onChange={() => handleOnChange(i)}>
                            </Checkbox>
                          </TableCell>
                        )}
                        <TableCell
                          className={`file-format`}
                          style={{ verticalAlign: 'top' }}
                        >
                          {' '}
                          <Box className='view-card-content'>
                            <Box className='view-card-content-value'>
                              <FileCopyIcon />
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <DocumentInfoDecoratorComponent
                            name={
                              elem.filename == null
                                ? elem.file.name
                                : elem.filename
                            }
                            size={
                              elem.filesize == null
                                ? elem.file.size
                                : elem.filesize
                            }
                          />{' '}
                        </TableCell>

                        <TableCell style={{ verticalAlign: 'top' }}>
                          <Box
                            className='view-card-content'
                            style={highlight.docType}
                          >
                            <Box className='view-card-content-label'>
                              {translatedTextsObject.fileType}
                            </Box>
                            <Box className='view-card-content-value'>
                              <CodeMasterMiscViewField
                                codeType={CodeMaintenanceType.DOCUMENT}
                                code={elem.docType}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            className='view-card-content'
                            style={highlight.description}
                          >
                            <Box className='view-card-content-label'>
                              {translatedTextsObject.fileDesc}
                            </Box>

                            <Box
                              className='view-card-content-value'
                              style={{ wordBreak: 'break-word' }}
                            >
                              {elem.description}
                              {/* <LabelValueVertical label={translatedTextsObject.fileDesc}
                              value={elem.description} /> */}
                            </Box>
                          </Box>
                        </TableCell>
                        {isSiApproved && (
                          <TableCell>
                            <Box
                              className='view-card-content'
                              style={highlight.description}
                            >
                              <Box className='view-card-content-label'>
                                {siTranslatedTextsObject.docPushCount}
                              </Box>

                              <Box
                                className='view-card-content-value'
                                style={{ wordBreak: 'break-word' }}
                              >
                                {elem.docPushCount || "-"}
                              </Box>
                            </Box>
                          </TableCell>
                        )}
                        {isSiApproved && (
                          <TableCell>
                            <Box
                              className='view-card-content'
                              style={highlight.description}
                            >
                              <Box className='view-card-content-label'>
                                {siTranslatedTextsObject.docPushDate}
                              </Box>

                              <Box
                                className='view-card-content-value'
                                style={{ wordBreak: 'break-word' }}
                              >
                                {moment(elem.docPushDate).isValid() ? moment(elem.docPushDate).format('DD-MMM-YYYY HH:mm:ss') : "-"}
                              </Box>
                            </Box>
                          </TableCell>
                        )}
                        {props.allowDownload && (
                          <TableCell>
                            {/* download action */}
                            <DocumentDetailsActionMenu
                              id={i}
                              downloadRowClick={downloadRowClick(i)}
                              hideDownloadLog
                              hideEdit
                              hideDelete
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
                {isSiApproved && (
                  <TableRow>
                    <TableCell>
                      <CngSecondaryButton
                        disabled={!enableBtn}
                        onClick={() => sendToEssdocs()}>
                        {siTranslatedTextsObject.pushToEssdocs}
                      </CngSecondaryButton>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>

            <Grid container xs={12} sm={12}>
              <CngGridItem xs={6} sm={6}></CngGridItem>
            </Grid>
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({})

const ViewSIDocumentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewSIDocumentComponent
