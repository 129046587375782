import { Box, Card, Grid, Chip } from '@material-ui/core'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import React, { useState, useEffect } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import DGCargoDetailsTable from 'src/views/freightbooking/components/DGCargoDetailsTable'
import ViewDGCargoDetailsDialog from 'src/views/freightbooking/components/ViewDGCargoDetailsDialog'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import Utils from 'src/views/common/utils/Utils'

const { CngGridItem } = components

const { CodeMaintenanceType } = constants

const FormBody = (props) => {
  const { fetchRecords } = useServices()
  const dgCargoList = props.cargo
  const { showDetails, auditSI } = props
  console.log(dgCargoList)
  console.log(showDetails)
  const [isDGDetailsDialogOpen, setDGDetailsDialogOpen] = useState(false)
  const [currentDGDetails, setCurrentDGDetails] = useState()
  const [currentDGCargoIdx, setCurrentDGCargoIdx] = useState()
  const [currentDGDetailsId, setCurrentDGDetailsId] = useState()
  const [currentAudit, setCurrentAudit] = useState()

  const [uomItems, setUomItems] = useState([])
  const [imoItems, setImoItems] = useState([])
  const [packageUomItems, setPackageUomItems] = useState([])

  const translatedTextsObject = FreightBookingTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const handleDGDeailsViewClick = (dgCargoIdx, cargoDetailsIdx) => () => {
    console.log('handleDGDeailsViewClick')
    console.log(dgCargoList)
    console.log('dgCargoIdx', dgCargoIdx)
    console.log('cargoDetailsIdx', cargoDetailsIdx)

    console.log(dgCargoList[dgCargoIdx].dgDetails[cargoDetailsIdx])
    setDGDetailsDialogOpen(true)
    setCurrentDGDetails(dgCargoList[dgCargoIdx].dgDetails[cargoDetailsIdx])
    setCurrentDGCargoIdx(dgCargoIdx)
    setCurrentDGDetailsId(cargoDetailsIdx)

    setCurrentAudit(
      getDGDetailsAudit(
        dgCargoList[dgCargoIdx].dgCargoId,
        dgCargoList[dgCargoIdx].dgDetails[cargoDetailsIdx].dgCargoDetailsId
      )
    )
  }

  function getDGDetailsAudit(cargoId, cargoDetailsId) {
    console.log('dgCargo id', cargoId)
    console.log('dgcargoDetailsIdx', cargoDetailsId)

    if (Utils.isEmptyObject(highlight)) {
      return []
    } else {
      let highlightDetails = getHighlight(cargoId, 'dgDetails')
      console.log('dgCargo idhighlightDetails', highlightDetails)
      if (highlightDetails && !Utils.isEmptyObject(highlightDetails)) {
        console.log('id is ' + cargoDetailsId)
        let auditAddUpdated = highlightDetails.added
          ? highlightDetails.added
          : [].concat(highlightDetails.updated)
        console.log('audit id is, ', auditAddUpdated)
        if (!Utils.isEmptyObject(auditAddUpdated)) {
          let dgDetailsAudit = auditAddUpdated.filter(
            (c) => c.id === cargoDetailsId
          )

          console.log('dgcargo highlight id is, ', dgDetailsAudit)
          return dgDetailsAudit
        }
      }

      return []
    }
  }

  function closeDialog() {
    setDGDetailsDialogOpen(false)
  }

  const initialHighLight = [
    {
      id: 0,
      cargo: {},
      cargoDesc: {},
      containerNo: {},
      grossVolume: {},
      grossWeight: {},
      height: {},
      hsCode: {},
      length: {},
      marksNNo: {},
      netWeight: {},
      packageType: {},
      quantity: {},
      uom: {},
      width: {}
    }
  ]

  const [highlight, setHighlight] = useState(initialHighLight)

  useEffect(() => {
    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'CAL_DG_IMO',
      false,
      onLoadIMOCodeSuccess
    )

    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'CAL_DG_TEMP_UOM',
      false,
      onLoadDGTempUOMCodeSuccess
    )

    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.PACKAGE_UOM,
      [],
      false,
      onLoadPackageUomSuccess
    )
  }, [props])

  useEffect(() => {
    console.log('auditHighLightDGCargo')
    if (auditSI && auditSI.shippingInfoDGCargoDTOs) {
      let hl = SIUtils.auditHighLightDGCargo(
        auditSI.shippingInfoDGCargoDTOs,
        dgCargoList
      )
      setHighlight(hl)
      console.log('dgcargoCompareshl', hl)
    }
  }, [auditSI])

  function getHighlight(id, field) {
    console.log('hl dg getHighlight', field)
    let matched = highlight.find((hl) => hl?.id == id)
    console.log('hl dg matched2', matched)

    return Utils.isNotEmpty(matched) ? matched[field] : {}
  }

  // useFetchCodeMaster(props.codeType, false, onLoadCodeMasterSuccess)

  function onLoadPackageUomSuccess(data) {
    console.log('onLoadPackageUomSuccess')
    console.log(data)

    if (data.length > 0) {
      setPackageUomItems(data)
    }
  }

  function onLoadIMOCodeSuccess(data) {
    console.log('onLoadCodeMasterSuccessxdd')
    console.log(data)

    if (data.length == 0) {
      console.log('null')
    } else {
      setImoItems(data)
    }
  }

  function onLoadDGTempUOMCodeSuccess(data) {
    console.log('onLoadCodeMasterSuccess')
    console.log(data)
    let optionList = []

    if (data.length == 0) {
      console.log('null')
    } else {
      console.log(optionList)
      setUomItems(data)

      console.log(uomItems)
    }
  }

  const ViewDgCargoDetailComponent = () => {
    return dgCargoList.map((m, idx) => (
      <Grid
        xs={12}
        sm={12}
        key={idx}
        spacing={2}
        className='view-card view-card-content'
      >
        <Card>
          <Box>
            <Grid container className='accordian-header'>
              <CngGridItem xs={6} sm={6}>
                <Box p={1} fontSize='large' fontWeight='bolder'>
                  {translatedTextsObject.dgCargo + ' ' + (idx + 1)}
                </Box>
              </CngGridItem>

              {/* {showDetails && (
                <CngGridItem xs={6} sm={6} className={'alignRight'}>
                  <Box p={1} fontSize='small'>
                    {
                      <React.Fragment>
                        {' '}
                        {translatedTextsObject.cargoId}:&nbsp;
                        <b>{m.dgCargoId == null ? 'N/A' : m.dgCargoId}</b>
                      </React.Fragment>
                    }
                  </Box>
                </CngGridItem>
              )} */}
            </Grid>
            {!showDetails && (
              <div>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  className='view-card-item-content'
                  style={
                    Utils.isEmptyObject(highlight)
                      ? {}
                      : getHighlight(m.dgCargoId, 'cargo')
                  }
                >
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    style={{
                      background: '#F4BE5E0F 0% 0% no-repeat padding-box'
                    }}
                  >
                    <LabelValueVertical
                      label={translatedTextsObject.cargoDesc}
                      value={m.description}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
          {showDetails && (
            <div>
              <Grid
                container
                xs={12}
                sm={12}
                className='view-card-item-content'
                style={
                  Utils.isEmptyObject(highlight) || highlight === undefined
                    ? {}
                    : getHighlight(m.dgCargoId, 'cargo')
                }
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  style={
                    Utils.isEmptyObject(highlight)
                      ? {}
                      : getHighlight(m.dgCargoId, 'description')
                  }
                >
                  <LabelValueVertical
                    label={translatedTextsObject.cargoDesc}
                    value={m.description}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  style={{ paddingRight: 20, marginTop: 16 }}
                >
                  <ComponentWrapper>
                    <Grid container xs={12} sm={12}>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        style={
                          Utils.isEmptyObject(highlight)
                            ? {}
                            : getHighlight(m.dgCargoId, 'hsCode')
                        }
                      >
                        <LabelValueVertical
                          label={translatedTextsObject.hsCode}
                          value={m.hsCode}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        alignContent='center'
                        style={
                          Utils.isEmptyObject(highlight)
                            ? {}
                            : getHighlight(m.dgCargoId, 'packageType')
                        }
                      >
                        <Grid item xs={12} sm={12}>
                          <Box className='view-card-content'>
                            <Grid container justify='space-between'>
                              <Grid item xs={12} sm={12}>
                                <Box className='view-card-content-label'>
                                  {translatedTextsObject.packageType}
                                </Box>
                              </Grid>
                              <Box className='view-card-content-value'>
                                <CodeMasterMiscViewField
                                  codeType={CodeMaintenanceType.PACKAGE_UOM}
                                  code={m.packageType}
                                />
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={4}
                        alignContent='flex-end'
                        style={
                          Utils.isEmptyObject(highlight)
                            ? {}
                            : getHighlight(m.dgCargoId, 'quantity')
                        }
                      >
                        <LabelValueVertical
                          label={siTranslatedTextsObject.totalQuantity}
                          value={m.quantity}
                        />
                      </Grid>
                    </Grid>
                  </ComponentWrapper>

                  <Grid container xs={12} sm={12} style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={12}>
                      <ComponentWrapper>
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          style={
                            Utils.isEmptyObject(highlight)
                              ? {}
                              : getHighlight(m.dgCargoId, 'containerNo')
                          }
                        >
                          <Grid item xs={12} sm={12} alignContent='center'>
                            <Box className='view-card-content'>
                              <Grid container justify='space-between'>
                                <Grid item xs={12} sm={12}>
                                  <Box className='view-card-content-label'>
                                    {
                                      siTranslatedTextsObject.relatedContainerNos
                                    }
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Box className='view-card-content-value'>
                                    <Grid container xs={12} sm={12} spacing={1}>
                                      {m.containerNo &&
                                        m.containerNo.map((contNo) => {
                                          return (
                                            <Grid item>
                                              <Chip
                                                size='small'
                                                label={contNo}
                                              ></Chip>
                                            </Grid>
                                          )
                                        })}
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ComponentWrapper>
                    </Grid>
                  </Grid>

                  <Grid container xs={12} sm={12} style={{ marginTop: 10 }}>
                    <Grid item xs={6} sm={6} style={{ paddingRight: 10 }}>
                      <ComponentWrapper
                        rightConner={
                          <Chip
                            label={siTranslatedTextsObject.cbm}
                            size='small'
                            className='white-chip'
                          />
                        }
                      >
                        <div
                          style={
                            Utils.isEmptyObject(highlight)
                              ? {}
                              : getHighlight(m.dgCargoId, 'totalGrossVolume')
                          }
                        >
                          <LabelValueVertical
                            label={siTranslatedTextsObject.totalGrossVolume}
                            value={m.totalGrossVolume}
                          />
                        </div>

                        <div
                          style={
                            Utils.isEmptyObject(highlight)
                              ? {}
                              : getHighlight(m.dgCargoId, 'totalNetVolume')
                          }
                        >
                          <LabelValueVertical
                            label={siTranslatedTextsObject.totalNetVolume}
                            value={m.totalNetVolume}
                          />
                        </div>
                      </ComponentWrapper>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <ComponentWrapper
                        rightConner={
                          <Chip
                            label={siTranslatedTextsObject.kg}
                            size='small'
                            className='white-chip'
                          />
                        }
                      >
                        <div
                          style={
                            Utils.isEmptyObject(highlight)
                              ? {}
                              : getHighlight(m.dgCargoId, 'totalGrossWeight')
                          }
                        >
                          <LabelValueVertical
                            label={siTranslatedTextsObject.totalGrossWeight}
                            value={m.totalGrossWeight}
                          />
                        </div>

                        <div
                          style={
                            Utils.isEmptyObject(highlight)
                              ? {}
                              : getHighlight(m.dgCargoId, 'totalNetWeight')
                          }
                        >
                          <LabelValueVertical
                            label={siTranslatedTextsObject.totalNetWeight}
                            value={m.totalNetWeight}
                          />
                        </div>
                      </ComponentWrapper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {console.log('id', m.dgCargoId)}
              {console.log(
                'id details',
                Utils.isEmptyObject(highlight)
                  ? []
                  : getHighlight(m.dgCargoId, 'dgDetails')
              )}
              <DGCargoDetailsTable
                cargoIdx={idx}
                detailsList={m.dgDetails}
                handleRowClick={handleDGDeailsViewClick}
                imoItems={imoItems}
                audit={
                  Utils.isEmptyObject(highlight)
                    ? []
                    : getHighlight(m.dgCargoId, 'dgDetails')
                }
              />
            </div>
          )}
        </Card>
      </Grid>
    ))
  }

  return (
    <Card>
      <Box className='view-card-header'>
        <div> {translatedTextsObject.dgCargo}</div>
      </Box>
      <ViewDgCargoDetailComponent {...props} />

      <ViewDGCargoDetailsDialog
        isDialogOpen={isDGDetailsDialogOpen}
        closeDialog={closeDialog}
        currentDGDetails={currentDGDetails}
        currentDGCargoIdx={currentDGCargoIdx}
        currentDGDetailsId={currentDGDetailsId}
        uomItems={uomItems}
        packageUomItems={packageUomItems}
        currentAudit={currentAudit}
      />
    </Card>
  )
}

const ViewSIDgCargoComponent = Object.freeze({
  FormBody: FormBody
  // initialValues : initialValues
})

export default ViewSIDgCargoComponent
