import { Box, Card, Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import CreateSIBookingListDetailComponent from './CreateSIBookingListDetailComponent'
import CircularProgress from '@material-ui/core/CircularProgress'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import ResetFilterComponent from 'src/views/freightbooking/components/ResetFilterComponent'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
  const observer = useRef()
  //const loading = props.loading
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [originalBooking, setOriginalBooking] = useState([])
  const [displayedBooking, setDisplayedBooking] = useState([])
  const loadAmount = 10
  const { fetchRecords } = useServices()
  const { fetchPageableRecords } = useServices()
  const [pageNumber, setPageNumber] = useState(0)

  let searchCriteria = props.searchCriteria

  useEffect(() => {
    console.log('page number changes ' + pageNumber)

    if (pageNumber > 0) {
      setLoading(true)
      ProgressiveLoadingBooking2()
    }
  }, [pageNumber])

  function ProgressiveLoadingBooking2() {
    console.log('ProgressiveLoadingBooking2')
    fetchPageableRecords.execute(
      FreightBookingApiUrls.BOOKING_LIST_ENHANCEMENT,
      {
        filters: [],
        sorts: [],
        page: pageNumber,
        pageSize: 10,
        customData: searchCriteria
      },
      (data) => {
        console.log('booking is back')
        console.log(data.content)

        if (data.content.length < loadAmount) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }

        let temp3 = [...displayedBooking]
        console.log(temp3)
        let temp4 = temp3.concat(data.content)
        console.log(temp4)

        setDisplayedBooking(temp4)
        setLoading(false)
      },
      (error) => {
        props.setPageError(true)
      }
    )
  }

  useEffect(() => {
    setLoading(false)
    let temp = [...props.bookingList]
    if (temp.length < loadAmount) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    console.log(hasMore)

    setDisplayedBooking(temp)
    console.log(displayedBooking)
  }, [props.bookingList])

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          //   setPageNumber(prevPageNumber => prevPageNumber + 1)
          // expandList()
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          console.log(hasMore)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  return (
    <Card>
      {displayedBooking.map((m, idx) => (
        <div key={idx} ref={lastBookElementRef}>
          <CreateSIBookingListDetailComponent.FormBody booking={m} {...props} />
        </div>
      ))}

      <div style={{ display: loading ? 'inline' : 'none' }}>
        <Grid container justify='center'>
          <CngGridItem>
            <CircularProgress />
          </CngGridItem>
        </Grid>
      </div>

      <Box mt={5}>
        <ResetFilterComponent
          leftCount={props.leftCount}
          handleClearAllButtonClick={props.handleClearAllButtonClick}
        />
      </Box>
    </Card>
  )
}

const CreateSIBookingListComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default CreateSIBookingListComponent
