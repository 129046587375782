import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Button,
  Box,
  Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText.js'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import SIDocumentDetails from './SIDocumentDetails'
import DocumentDetails from './DocumentDetails'
import SIDocumentDetailsComponents from '../components/SIDocumentDetailsComponents'
import DocumentDetailsComponents from '../components/DocumentDetailsComponents'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import DocumentApi from 'src/views/common/api/DocumentApi'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import { v4 as uuid } from 'uuid'
const { CngGridItem, CngFilesDropzone } = components

const {
  CodeMaintenanceType
} = constants

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  }
} = components

const FormBody = (props) => {
  const [
    pendingShippingInfoDocField,
    ,
    { setValue: setPendingShippingInfoDocField }
  ] = useField('pendingShippingInfoDocuments')
  const [uploadedDocsField, , { setValue: setUploadedDocsField }] = useField(
    'shippingInfoDocuments'
  )
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const translatedTextsObject = FreightBookingTranslationText()
  const [pendingDocs, setPendingDocs] = useState([]) //for the file list below drop zone
  const [uploadedDocs, setUploadedDocs] = useState([]) //for the file list above the drop zone
  const [fieldDict, setFieldDict] = useState([])
  const {
    fetchRecords,
    securedSendRequest
  } = useServices()
  const [documentExpanded, setDocumentExpanded] = useState(false)
  const { errors, touched } = useFormikContext()
  const docTypeKeyRef = useRef(uuid())
  const handleShipmentExpand = () => {
    setDocumentExpanded(!documentExpanded)
  }
  const [containersField, containersMeta, { setValue: setContainersField }] =
    useField('bookingContainers')

  const handleUploadedInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...uploadedDocs]
    // const list = [...containersField]
    list[index][name.split('.')[1]] = value

    console.log(list)
    setUploadedDocs(list)
    setUploadedDocsField(list)
  }

  const handleInputChange = (e, index) => {
    // console.log('handleInputChange : ' + JSON.stringify(e) )
    const { name, value } = e.target
    const list = [...pendingDocs]
    // const list = [...containersField]
    list[index][name.split('.')[1]] = value

    console.log(list)
    setPendingDocs(list)
    setPendingShippingInfoDocField(list)
  }

  const handleUploadedDropdownChange = (val, evt, index, name) => {
    if (evt) {
      const { value } = evt
      const list = [...uploadedDocs]
      // // const list = [...containersField]
      // alert('list[index][name] : ' + index + "." + name)
      list[index][name] = value
      // console.log('handleInputChange : ' + JSON.stringify(list) )
      // alert('containerList : ' + JSON.stringify(list))

      setUploadedDocs(list)
      setUploadedDocsField(list)
    }
  }

  const handleDropdownChange = (val, evt, index, name) => {
    if (evt) {
      const { value } = evt
      const list = [...pendingDocs]
      // // const list = [...containersField]
      // alert('list[index][name] : ' + index + "." + name)
      list[index][name] = value
      // console.log('handleInputChange : ' + JSON.stringify(list) )
      // alert('containerList : ' + JSON.stringify(list))

      setPendingDocs(list)
      setPendingShippingInfoDocField(list)
    }
  }

  useEffect(() => {
    console.log(props.isEdit)
    if (props.isEdit) {
      console.log(pendingShippingInfoDocField)
      setPendingDocs(pendingShippingInfoDocField.value)

      console.log(uploadedDocsField)

      let uploadedDocList = uploadedDocsField.value
      if (uploadedDocList != null) {
        let uploadedDocListWithEdiable = uploadedDocList.map((v) => ({
          ...v,
          editable: false
        }))

        setUploadedDocs(uploadedDocListWithEdiable)
      }
    }
  }, [props.isEdit, props.isDirectSI, containersField.value])
  // }, [props.isEdit])

  const removeRowClick = (id) => (e) => {
    console.log('remove: ' + id)
    pendingDocs.splice(id, 1)
    const newFiles = [].concat([], pendingDocs)
    setPendingDocs(newFiles)
    setPendingShippingInfoDocField(newFiles)

    docTypeKeyRef.current = uuid()
    //e.stopPropagation()
  }

  const removeUploadedRowClick = (id) => {
    console.log('remove uploaded: ' + id)
    uploadedDocs.splice(id, 1)
    const newFiles = [].concat([], uploadedDocs)
    setUploadedDocs(newFiles)
    setUploadedDocsField(newFiles)

    docTypeKeyRef.current = uuid()
  }

  const downloadUploadedRowClick = (id) => {
    console.log('download uploaded: ' + id)
    console.log(uploadedDocs[id])
    DocumentApi.downloadDocument(
      securedSendRequest,
      uploadedDocs[id],
      CalistaConstant.MODULE_SI
    )

    // downloadFile4(uploadedDocs[id])
  }

  function bin2String(array) {
    var result = ''
    for (var i = 0; i < array.length; i++) {
      result += String.fromCharCode(parseInt(array[i], 2))
    }
    return result
  }

  function downloadFile4(doc) {
    let request = { id: doc.docId }

    securedSendRequest.execute(
      'POST',
      FreightBookingApiUrls.DOCUMENT_DOWNLOAD,
      { id: doc.docId },
      (data) => {
        console.log('success')
        console.log(data)

        var blob = data.data

        //console.log(blob)

        var link = document.createElement('a')

        link.href = window.URL.createObjectURL(blob)
        link.download = doc.filename

        document.body.appendChild(link)

        console.log(link)
        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      },
      (error) => {
        console.log(error)
      },
      onComplete,

      { responseType: 'blob' }
    )
  }

  function onComplete() {
    console.log('complete')
  }

  const editRowClick = (id) => {
    console.log('edit row: ')
    console.log(id)

    const newFiles = [].concat([], uploadedDocs)
    newFiles[id].editable = true
    setUploadedDocs(newFiles)
    setUploadedDocsField(newFiles)
  }

  function onDropAccepted(newFiles) {
    return new Promise((resolve) => {
      const jsonFile = newFiles[0]
      const fileReader = new FileReader()
      // fileReader.onload = (event) =>
      //     parseJsonFile(event, resolve, jsonFile.name)
      fileReader.readAsText(jsonFile)
    })
  }

  const getFieldError = (itemIndex, fieldPath) => {
    //fieldPath : containers.quantity
    if (errors) {
      let temp = fieldPath.split('.')
      let pathArr = [temp[0].split('[')[0], temp[1]]
      if (
        errors[pathArr[0]] &&
        errors[pathArr[0]][itemIndex] &&
        errors[pathArr[0]][itemIndex][pathArr[1]]
      ) {
        if (touched) {
          if (
            touched[pathArr[0]] &&
            touched[pathArr[0]][itemIndex] &&
            touched[pathArr[0]][itemIndex][pathArr[1]]
          ) {
            // return true
            return errors[pathArr[0]][itemIndex][pathArr[1]]
          } else {
            return null
          }
        }
      }
    }
    return null
  }

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.DOCUMENT,
      [
        {
          field: 'docType',
          operator: 'EQUAL',
          value: 'CLOGS_FRB'
        }
      ],
      false,
      onLoadDocTypeSuccess
    )
  }, [])

  function onLoadDocTypeSuccess(data) {
    console.log('onLoadDocTypeSuccess')
    let docTypeOptionDict = {}
    console.log(data)
    if (data.length == 0) {
      console.log('null')
    } else {
      data.map((singleDocTypeOption) => {
        var detailedOption = {
          value: singleDocTypeOption.code,
          label: singleDocTypeOption.descriptionEn
        }
        docTypeOptionDict[singleDocTypeOption.code] =
          singleDocTypeOption.descriptionEn
      })
    }

    setFieldDict(docTypeOptionDict)
  }

  return (
    <Card>
      <Accordion expanded={documentExpanded}>
        <AccordionSummary
          aria-controls='shipmentComponentContent'
          id='shipmentComponentHeader'
        >
          <Grid container>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={12}>
                <AccordionHeaderComponent
                  title={siTranslatedTextsObject.document}
                  hideMandatory
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={12} sm={12} spacing={2}>
            <CngGridItem xs={12} sm={12}>
              <SIDocumentDetailsComponents
                tile={uploadedDocs}
                removeRowClick={removeUploadedRowClick}
                handleInputChange={handleUploadedInputChange}
                handleDropdownChange={handleUploadedDropdownChange}
                editRowClick={editRowClick}
                downloadRowClick={downloadUploadedRowClick}
                getFieldError={getFieldError}
                docTypeKeyRef={docTypeKeyRef.current}
                fieldDict={fieldDict}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={12}>
              <DropzoneAreaBase
                acceptedFiles={[
                  'image/jpg',
                  'image/jpeg',
                  'image/png',
                  'application/pdf',
                  'application/msword',
                  'application/vnd.ms-excel',
                  'application/vnd.ms-powerpoint',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ]}
                maxFileSize={10485760}
                onAdd={(newFileObjs) => {
                  console.log(newFileObjs)
                  console.log(pendingDocs)

                  newFileObjs = newFileObjs.map((item) => ({
                    ...item,
                    docType: '',
                    description: ''
                  }))

                  console.log(pendingShippingInfoDocField)
                  if (pendingDocs == null || pendingDocs.length <= 0) {
                    setPendingDocs(newFileObjs)
                    setPendingShippingInfoDocField(newFileObjs)
                  } else {
                    console.log(pendingDocs.concat(newFileObjs))
                    setPendingDocs(pendingDocs.concat(newFileObjs))
                    // setBookingDocField([files, ...newFileObjs])
                  }
                  // setFiles([].concat(files, newFileObjs));

                  //var newFileFields = [].concat(bookingDocField, newFileObjs)
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={12}>
              <Typography
                style={{ color: '#808080' }}
                variant='body2'
                align='left'
              >
                <label>{translatedTextsObject.documentInstruction}</label>
              </Typography>
            </CngGridItem>
            <CngGridItem xs={12} sm={12}>
              <SIDocumentDetails
                tile={pendingDocs}
                removeRowClick={removeRowClick}
                handleInputChange={handleInputChange}
                handleDropdownChange={handleDropdownChange}
                getFieldError={getFieldError}
                docTypeKeyRef={docTypeKeyRef.current}
              />
            </CngGridItem>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <AccordionFooterControlComponent
        handleAccordionExpand={handleShipmentExpand}
        expanded={documentExpanded}
        footerText={siTranslatedTextsObject.attachDocument}
      />
    </Card>
  )
}

const initialValues = {
  bookingDocs: {
    description: '',
    docType: '',
    ownerType: 'FRB'
  }
}

const DocumentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default DocumentComponent
