import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Utils from 'src/views/shippinginstruction/shared/Utils'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import pathMap2 from 'src/paths/PathMap_Compliance'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import ActionMenuSIList from './ActionMenuSIList'
import ActionMenuSIDetail from 'src/views/shippinginstruction/components/ActionMenuSIDetail'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'
import * as calistaConstant from 'src/constants/CalistaConstant'
import CommonUtils from 'src/views/common/utils/Utils'
const {
  button: { CngPrimaryButton, CngSecondaryButton },
  table: { useDefaultNotification }
} = components

function SICarrierActionMenu(props) {
  const history = useHistory()

  const { si, page, refreshAction, role } = props
  const SIStatus = si.siStatus || ''
  console.log('si statusxx' + SIStatus)
  console.log('rolex' + role)
  // let actions =
  //   SIStatus != '' ? Utils.getSIStatusById(SIStatus).carrierActions : []
  // let actionsH =
  //   SIStatus != '' ? Utils.getSIStatusById(SIStatus).carrierActionsH : []

  let actions = getActions()

  console.log('actionsx', actions)
  const translatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { createRecord } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const [confirmAction, setConfirmAction] = useState(Utils.Action.delete) //need to initiable a value

  const { showNotification } = props
  //console.log(showNotification)
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function getActions() {
    let currentActions = []

    if (SIStatus != '') {
      if (page === Utils.Page.SIDetail) {
        if (
          SIStatus.toString() === calistaConstant.SI_STATUS_PENDING_SI_APPROVED
        ) {
          console.log('pending si approval details')
          currentActions = getActionBasedOnRole()
          console.log('currentActions', currentActions)
        } else {
          currentActions = Utils.getSIStatusById(SIStatus).carrierActionsH
        }
      } else {
        if (
          SIStatus.toString() === calistaConstant.SI_STATUS_PENDING_SI_APPROVED
        ) {
          console.log('pending si approval2')
          currentActions = getActionBasedOnRole()
        } else {
          currentActions = Utils.getSIStatusById(SIStatus).carrierActions
        }
      }
    }

    return currentActions
  }

  function getActionBasedOnRole() {
    let currentActions = []
    if (page === Utils.Page.SIList) {
      switch (role) {
        case Utils.carrierRole.CLERK:
          currentActions = Utils.getSIStatusById(SIStatus).carrierActions
          break
        case Utils.carrierRole.MANAGER:
          currentActions = Utils.getSIStatusById(SIStatus).carrierManagerActions
          break
        case Utils.carrierRole.BOTH:
          currentActions = Utils.getSIStatusById(
            SIStatus
          ).carrierManagerActions.concat(
            Utils.getSIStatusById(SIStatus).carrierActions
          )
          break
      }
    } else {
      switch (role) {
        case Utils.carrierRole.CLERK:
          currentActions = Utils.getSIStatusById(SIStatus).carrierActionsH
          console.log('role clerk>')
          break
        case Utils.carrierRole.MANAGER:
          currentActions =
            Utils.getSIStatusById(SIStatus).carrierManagerActionsH
          break
        case Utils.carrierRole.BOTH:
          currentActions = Utils.getSIStatusById(
            SIStatus
          ).carrierManagerActionsH.concat(
            Utils.getSIStatusById(SIStatus).carrierActionsH
          )
          break
      }
    }

    return currentActions
  }

  const gotoManageSI = () => {
    history.push({
      pathname: pathMap.MANAGE_SI
    })
  }

  const gotoCreateNewSI = () => {
    history.push({
      pathname: pathMap.CREATE_SI
    })
  }

  //retract si
  const retractSI = () => {
    console.log('retractSI')

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.retractSuccessMsg} `)
        redirectToSIList()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CARRIER_RETRACT_SI,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const SIConfirmMessage = [
    {
      action: Utils.Action.delete,
      title: siTranslatedTextsObject.confirmDeleteSITitle,
      content: siTranslatedTextsObject.confirmDeleteSIContent,
      okMsg: siTranslatedTextsObject.confirmDeleteSIOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    },
    {
      action: Utils.Action.cancel,
      title: siTranslatedTextsObject.confirmCancelSITitle,
      content: siTranslatedTextsObject.confirmCancelSIContent,
      okMsg: siTranslatedTextsObject.confirmCancelSIOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    },
    {
      action: Utils.Action.archive,
      title: siTranslatedTextsObject.confirmArchiveSITitle,
      content: siTranslatedTextsObject.confirmArchiveSIContent,
      okMsg: siTranslatedTextsObject.confirmArchiveSIOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    }
  ]

  function getSIDialogMessageByAction(action) {
    return SIConfirmMessage.find((b) => b.action == action)
  }

  const issueBL = () => {
    console.log('issueBL')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.issueSuccessMsg} `)
        redirectToSIList()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CARRIER_ISSUE_BL,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const revokeBL = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.revokeSuccessMsg} `)
        redirectToSIList()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CARRIER_REVOKE_BL,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const revokeIssuedBL = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.revokeSuccessMsg} `)
        redirectToSIList()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CARRIER_REVOKE_ISSUED_BL,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  //cancel si
  const cancelSI = () => {
    console.log('new cancel')
    setConfirmDialogOpen(true)
    setConfirmAction(Utils.Action.cancel)
  }

  const confirmDialog = () => {
    console.log('confirmAction--> ' + confirmAction)
    setConfirmDialogOpen(false) //close the dialog first
    switch (confirmAction) {
      case Utils.Action.cancel:
        proceedCancelSI()
        break

      default:
        break
    }
  }

  function redirectToSIList() {
    if (page === Utils.Page.SIDetail) {
      gotoManageSI()
    } else {
      //if action is done from menu list, then just refresh the list
      refreshAction()
    }
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  }

  const proceedCancelSI = () => {
    console.log('cancel')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.cancelSuccessMsg} `)
        redirectToSIList()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CANCEL_SI,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const manageSI = (prevPage) => (event) => {
    history.push({
      pathname: pathMap.MANAGE_SI_MANAGE_SI,
      state: {
        shippingInfo: props.si,
        prevPage: prevPage,
        action: Utils.Action.edit
      }
    })
  }

  const createPermit = () => {
    history.push({
      pathname: pathMap2.CREATE_NEW_PERMIT,
      state: {
        shippingInfo: props.si,
        action: Utils.Action.createPermit
      }
    })
  }

  const updateSI = (prevPage) => (event) => {
    //for draft si, direct to create new si > update draft si
    console.log(si)
    history.push({
      pathname: pathMap.CREATE_SI_UPDATE_SI_DRAFT,
      state: {
        shippingInfo: props.si,
        prevPage: prevPage,
        action: Utils.Action.edit
      }
    })
  }

  const approveSI = () => {
    console.log('approveSI')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.approveSuccessMsg} `)
        redirectToSIList()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CARRIER_APPROVE_SI,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const rejectSI = () => {
    console.log('approveSI')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${siTranslatedTextsObject.rejectedSuccessMsg} `
        )
        redirectToSIList()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CARRIER_REJECT_SI,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const eventHandlers = {
    gotoManageSI,
    manageSI,
    cancelSI,
    issueBL,
    revokeBL,
    revokeIssuedBL,
    approveSI,
    rejectSI,
    updateSI,
    gotoCreateNewSI,
    retractSI,
    createPermit
  }

  return (
    <div>
      {page === Utils.Page.SIDetail && (
        <ActionMenuSIDetail
          actions={actions}
          si={si}
          eventHandlers={eventHandlers}
          isCarrier
        />
      )}
      {page === Utils.Page.SIList && (
        <ActionMenuSIList
          actions={actions}
          si={si}
          page={page}
          eventHandlers={eventHandlers}
          isCarrier
        />
      )}

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={confirmDialog}
        content={getSIDialogMessageByAction(confirmAction).content}
        okMsg={getSIDialogMessageByAction(confirmAction).okMsg}
        cancelMsg={getSIDialogMessageByAction(confirmAction).cancelMsg}
        title={getSIDialogMessageByAction(confirmAction).title}
      />
    </div>
  )
}

export default SICarrierActionMenu
