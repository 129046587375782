import React from 'react'
import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive } from '@fortawesome/pro-light-svg-icons'

export default function ArchiveIcon(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()

  const { size } = props

  return (
    <Tooltip title={translatedTextsObject.archived}>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        style={{
          backgroundColor: 'whitesmoke',
          width: size === 'lg' ? 35 : 15,
          height: size === 'lg' ? 35 : 15,
          fontSize: size === 'lg' ? 24 : 12
        }}
      >
        <FontAwesomeIcon icon={faArchive} color='grey' size='sm' />
      </IconButton>
    </Tooltip>
  )
}
