import { Box, Chip, Grid, Typography } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'
import ManageScheduleTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import React from 'react'
import StatusCheckboxGroup from 'src/views/shippinginstruction/components/StatusCheckboxGroup'
import { components } from 'cng-web-lib'
import { faUser } from '@fortawesome/pro-light-svg-icons'

const {
  form: {
    field: { CngSwitchField }
  }
} = components

const SIStatusFilterComponent = (props) => {
  const translatedTextsObject = ManageScheduleTranslationText()
  console.log('SIStatusFilterComponent', props.status)

  return (
    <section className={'cng-filter-pane--section-container'}>
      <header>
        <Box className={`cng-filter-pane--section-header-label-box`}>
          <Typography class={'cng-filter-pane--section-header-label'}>
            {translatedTextsObject.status}
          </Typography>
        </Box>

        <Box className={`cng-filter-pane--section-header-cta-box`}>
          {props.isCarrierManager && (
            <HtmlTooltip title={'My tasks'}>
              <Chip
                avatar={
                  <FontAwesomeIcon
                    icon={faUser}
                    className={`cng-filter-pane--header-chip--icon`}
                  />
                }
                label={
                  <CngSwitchField
                    name='myTask'
                    onChange={props.onMyTaskChange}
                    className={`cng-filter-pane--header-chip--cta`}
                  />
                }
                classes={{
                  label: 'cng-filter-pane--header-chip--label'
                }}
                className={`cng-filter-pane--header-chip`}
                variant='outlined'
              />
            </HtmlTooltip>
          )}
        </Box>
      </header>

      <article>
        <Box className={'cng-filter-pane--controls-wrapper'}>
          <StatusCheckboxGroup
            checkBoxList={props.status}
            onChange={props.onStatusCheckBoxChange}
            filterName={''}
          />
        </Box>
      </article>
    </section>
  )
}

export default SIStatusFilterComponent
