import React, { useContext, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import ReeferSettingsContext from './ReeferSettingsContext.js'
import { Grid, InputAdornment } from '@material-ui/core'
import ResetButton from 'src/components/button/ResetButton'
import { components, constants } from 'cng-web-lib'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import ReeferValidationSchema from 'src/views/shippinginstruction/shared/validation/ReeferValidationSchema'
import { v4 as uuid } from 'uuid'

const {
  form: { CngViewForm },
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  button: { CngPrimaryButton },
  CngGridItem
} = components

const {
  component: {
    textField: {
      customType: { PLAIN_NUMBER }
    }
  },
  CodeMaintenanceType
} = constants

function ReeferDetailsForm(props) {
  const dialogContext = useContext(ReeferSettingsContext)

  const {
    closeDialog,
    form: { isSubmitting },
    currentContainerIdx,
    siContainerBoxList,
    setSiContainerBoxList,
    showLocalCategory,
    isCarrier
  } = dialogContext

  function onSubmit(data) {
    console.log('submit:', data)

    const newList = [...siContainerBoxList]

    if (currentContainerIdx != null) {
      ///add new reefer details in the selected reefer/tank container
      let siContainerItem = siContainerBoxList[currentContainerIdx]
      siContainerItem.equipmentTemp = data.equipmentTemp
      siContainerItem.tempUom = data.tempUom
      siContainerItem.equipmentAirFlow = data.equipmentAirFlow
      siContainerItem.equipmentComments = data.equipmentComments
    }

    setSiContainerBoxList(newList)
    closeDialog()
  }

  const validationSchema = ReeferValidationSchema()
  const currentReeferDetails = siContainerBoxList[currentContainerIdx]
  return (


    <CngViewForm
      formikProps={{
        initialValues: { ...initialValues },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          currentContainerIdx={currentContainerIdx}
          isSubmitting={isSubmitting}
          currentReeferDetails={currentReeferDetails}
          showLocalCategory={showLocalCategory}
          isCarrier={isCarrier}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  currentReeferDetails,
  isCarrier
}) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { setFieldValue, submitForm } = useFormikContext()
  const [key, setKey] = useState()

  const resetForm = () => {
    const dataArr = Object.entries(RESET_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
    })
    setKey(uuid())
  }

  function limitDecimalPlaces(event, maxDecimalPlaces) {
    let cVal = event.target.value;
    let dec = cVal.indexOf('.');
    //no decimal places
    if (maxDecimalPlaces == 0) {
      if (event.which == 46) {
        //not allowing decimal places
        event.preventDefault();
      }
    } else {
      //allowing only one time
      if (event.which == 46 && dec != -1) {
        event.preventDefault(); // prevent if there is already a dot
      }
    }

    //prevent exceeding number of decimal places      
    if (dec != -1) {
      //reading the decimal places  count
      let decPlaces = cVal.substring(dec + 1, cVal.length).length;
      //compare it with props value
      if (decPlaces == maxDecimalPlaces) {
        event.preventDefault();
      }
    }
  }

  useEffect(() => {
    const dataArr = Object.entries(currentReeferDetails)
    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
      if (key === "tempUom") {
        //it seems when amending a container from non-reefer to reefer, the temperature UOM value is not defaulted to CEL. 
        //Hence explicitly setting it here
        if (val == null || val == undefined) {
          setFieldValue("tempUom", "CEL")
        }
        else setFieldValue("tempUom", currentReeferDetails.tempUom)
      }
    })
    setKey(uuid())
  }, [currentReeferDetails])

  return (
    <Box m={1.5}>
      <Grid container spacing={2} justify='flex-start'>
        <CngGridItem xs={4} sm={4}>
          <CngTextField
            type='number'
            name='equipmentTemp'
            label={siTranslatedTextsObject.equipmentTemp}
            isRequired
            disabled={isCarrier}
            inputProps={{ maxLength: 6 }}
            onKeyPress={(event) => {
              limitDecimalPlaces(event, 1)
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </CngGridItem>
        <CngGridItem xs={4} sm={4}>
          <CngCodeMasterAutocompleteField
            name='tempUom'
            label={siTranslatedTextsObject.tempUom}
            codeType='CAL_REEFER_TEMP_UOM'
            isRequired
            disabled={isCarrier}
            key={key}
          />
        </CngGridItem>
        <CngGridItem xs={4} sm={4}>
          <CngTextField
            type='number'
            name='equipmentAirFlow'
            label={siTranslatedTextsObject.equipmentAirFlow}
            disabled={isCarrier}
            customType={PLAIN_NUMBER}
            InputProps={{
              maxLength: 20,
              endAdornment: <InputAdornment position='end'>
                <div className='cfc-chip'>{siTranslatedTextsObject.cbm}</div>
              </InputAdornment>
            }}
            onKeyPress={(event) => {
              limitDecimalPlaces(event, 2)
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </CngGridItem>
      </Grid>

      <Grid container spacing={2} justify='flex-start'>
        <CngGridItem xs={12} sm={12}>
          <CngTextField
            type='text'
            name='equipmentComments'
            label={siTranslatedTextsObject.equipmentComments}
            disabled={isCarrier}
            multiline
            rows={4}
            inputProps={{ maxLength: 512 }}
          />
        </CngGridItem>

      </Grid>
      {!isCarrier ? (
        <Box display='flex' mt={2}>
          <Grid xs={6} sm={6} justify='flex-start'>
            <ResetButton onClick={resetForm} disabled={isSubmitting} />
          </Grid>

          <Grid container xs={6} sm={6} justify='flex-end'>
            <Box pr={2}>
              <CancelButton onClick={closeDialog} disabled={isSubmitting} />
            </Box>
            <Box>
              <CngPrimaryButton
                type='button'
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {uiTranslatedTextsObject.apply}
              </CngPrimaryButton>
            </Box>
          </Grid>
        </Box>
      )
        :
        (
          <Box display='flex' flexDirection={'row-reverse'} mt={2}>
            <Box mr={1}>
              <CngPrimaryButton onClick={closeDialog}>
                {uiTranslatedTextsObject.close}
              </CngPrimaryButton>
            </Box>
          </Box>
        )
      }
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  equipmentTemp: undefined,
  tempUom: CalistaConstant.REEFER_TEMP_CEL,
  equipmentAirFlow: undefined,
  equipmentComments: undefined
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

const RESET_INITIAL_VALUES = Object.freeze({
  equipmentTemp: '',
  tempUom: CalistaConstant.REEFER_TEMP_CEL,
  equipmentAirFlow: '',
  equipmentComments: ''
})

export default ReeferDetailsForm
