import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'

import React, { useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import AuditTrailDualColumnPort from 'src/components/auditTrail/AuditTrailDualColumnPort'
import AuditTrailDualColumnCarrier from 'src/components/auditTrail/AuditTrailDualColumnCarrier'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'

const AuditTrailSchedule = (props) => {
  const { si, ports, currentSI, carriers } = props
  const tranObj = FreightBookingTranslationText()
  const siTranObj = ShippingInstructionTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)

  const curCarrierPartyId = currentSI.carrierPartyId

  const handleScheduleExpand = () => {
    setScheduleExpanded(!scheduleExpanded)
  }

  return (
    <Card>
      <Accordion expanded={scheduleExpanded}>
        <AccordionSummary
          aria-controls='scheduleComponentContent'
          id='scheduleComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent title={tranObj.schedule} hideMandatory />
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container xs={12} sm={12}>
            <AuditTrailDualColumnCarrier
              object={si}
              curCarrierPartyId={curCarrierPartyId}
              fields={['carrierPartyId', 'vesselName']}
              carriers={carriers}
              label={tranObj.carrier}
            />

            {/* <AuditTrailDualColumn
              object={si}
              field={'vesselName'}
              label={tranObj.vesselName}
            /> */}
            <AuditTrailDualColumn
              object={si}
              field={'voyageNo'}
              label={tranObj.voyageNumber}
            />

            <AuditTrailDualColumnPort
              object={si}
              field={'polCode'}
              ports={ports}
              label={tranObj.portOfLoading}
            />
            <AuditTrailDualColumnPort
              object={si}
              field={'podCode'}
              ports={ports}
              label={tranObj.portOfDischarge}
            />

            <AuditTrailDualColumn
              object={si}
              field={'polDesc'}
              label={tranObj.loadTerminal}
            />
            <AuditTrailDualColumn
              object={si}
              field={'podDesc'}
              label={tranObj.dischargeTerminal}
            />
            <AuditTrailDualColumn
              object={si}
              field={'preCarriageVesName'}
              label={siTranObj.preCarriageVesselName}
            />
            <AuditTrailDualColumn
              object={si}
              field={'preCarriageVoyNo'}
              label={siTranObj.preCarriageVoyageNo}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleScheduleExpand}
        expanded={scheduleExpanded}
        footerText={tranObj.showScheduleDetails}
        footerTextExpanded={tranObj.hideScheduleDetails}
        noOptionalFields
      />
    </Card>
  )
}

export default AuditTrailSchedule
