import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Box,
  Typography
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  chrgType: '',
  payer: '',
  freightTerm: '',
  paymentLocation: ''
})

const FormBody = (props) => {
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const [paymentExpanded, setPaymentExpanded] = useState(false)


  const [paymentLocField, ,] = useField('paymentLocation')
  const handlePaymentExpand = () => {
    setPaymentExpanded(!paymentExpanded)
  }

  useEffect(() => {
    setPaymentExpanded(props.forceExpand)
  }, [props.isEdit])

  return (
    <Card>
      <Accordion expanded={paymentExpanded}>
        <AccordionSummary
          aria-controls='paymentComponentContent'
          id='paymentComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={siTranslatedTextsObject.paymentInstruction}
            />
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container xs={12} sm={12} spacing={2} justify='flex-end'>
            <CngGridItem xs={4} sm={4}>
              <CngCodeMasterAutocompleteField
                name='chrgType'
                label={siTranslatedTextsObject.chargeType}
                codeType='CAL_CHG_TYPE'
                key={props.paymentKeyRef}
                disabled={props.isCarrier}
                isRequired
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngCodeMasterAutocompleteField
                name='payer'
                label={siTranslatedTextsObject.payer}
                codeType='CAL_CHG_PAYER'
                key={props.paymentKeyRef}
                disabled={props.isCarrier}
                isRequired
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngCodeMasterAutocompleteField
                name='freightTerm'
                label={siTranslatedTextsObject.freightTerm}
                codeType='CAL_CHG_FT'
                key={props.paymentKeyRef}
                disabled={props.isCarrier}
                isRequired
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={12}>
              <CngTextField
                type='text'
                name='paymentLocation'
                label={siTranslatedTextsObject.pmtLoc}
                multiline
                rows={2}
                inputProps={{ maxLength: 30 }}
                disabled={props.isCarrier}
              />
            </CngGridItem>
            <Box mr={2}>
              <Typography
                style={{ color: '#808080' }}
                variant='caption'
                align='right'
                color='initial'
              >
                {paymentLocField.value == null
                  ? '0/30'
                  : paymentLocField.value.length + '/30'}
              </Typography>
            </Box>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handlePaymentExpand}
        expanded={paymentExpanded}
        footerText={siTranslatedTextsObject.addPaymentInstruction}
      />
    </Card>
  )
}

const SIPaymentInstructionComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIPaymentInstructionComponent
