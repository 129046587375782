import {
  Box,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'

import AvatarInitial from 'src/views/freightbooking/components/AvatarInitial'
import LabelValueVerticalSimple from 'src/views/freightbooking/components/LabelValueVerticalSimple'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import PortDetailComponent from 'src/views/freightbooking/components/PortDetailComponent'
import React from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import SIDetailBottom from './SIDetailBottom'
import SIDetailTop from './SIDetailTop'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const InfoIconButton = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(IconButton)

const FormBody = (props) => {
  const translatedTextsObject = ShippingInstructionTranslationText()
  const frbTranslatedTextsObject = FreightBookingTranslationText()
  const { si } = props
  const history = useHistory()
  console.log(si)

  let shipperShortName = si.shipperPartyName
  let showTooltip = false
  if (si.shipperPartyName && si.shipperPartyName.length > 5) {
    shipperShortName = si.shipperPartyName.substring(0, 5).trim() + '...'
    showTooltip = true
  }

  let multipleCntr = false
  let contIsoType = ''
  if (si.containerISOCounts != null && si.containerISOCounts.length > 0){
    contIsoType =
      si.containerISOCounts[0].count +
      ' x ' +
      si.containerISOCounts[0].contIsoType
    if (si.containerISOCounts.length > 1) {
      contIsoType = 'Multiple CNTS'
      multipleCntr = true
    }
  }

  const gotoReviewSI = () => {
    console.log('click')
    console.log(si)

    history.push({
      pathname: pathMap.MANAGE_SI_VIEW_SI,
      state: {
        shippingInfoId: si.shippingInfoId,
        freightBookingId: si.freightBookingId,
        siStatus: si.siStatus,
        role: props.role,
        isCarrier: true
      }
    })
  }

  return (
    <Card className={`summary-list-item--card`}>
      <SIDetailTop si={si} {...props} />
      <CardActionArea
        onClick={gotoReviewSI}
        className={`cng-summary-card--body-box`}
      >
        <Grid container xs={12} className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={12} sm={1}>
            <AvatarInitial
              withTooltip={showTooltip}
              longName={si.shipperPartyName}
              shortName={shipperShortName}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={5} style={{ paddingLeft: 20 }}>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.vesselName}
                value={si.vesselName}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.voyageNumber}
                value={si.voyageNo}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

            <Box pt={0.5}>
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='center'
              >
                <CngGridItem sm={4}>
                  <LabelValueVerticalSimple
                    label={frbTranslatedTextsObject.containersType}
                    value={contIsoType}
                    defaultValue='N/A'
                    labelStyle={'frb-card-label'}
                    valueStyle={'frb-card-content'}
                  />
                </CngGridItem>

                {multipleCntr && (
                  <HtmlTooltip
                    title={
                      <Box>
                        CONTAINER DETAILS
                        <br />
                        {si.containerISOCounts != null &&
                          si.containerISOCounts.map((m, idx) => (
                            <Typography>
                              {m.count} x {m.contIsoType}
                            </Typography>
                          ))}
                      </Box>
                    }
                  >
                    <InfoIconButton>
                      <InfoOutlinedIcon fontSize='small' />
                    </InfoIconButton>
                  </HtmlTooltip>
                )}
              </Grid>
            </Box>

            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.shipperRefNo}
                value={si.shipperRefNo}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>
          </CngGridItem>

          <CngGridItem xs={12} sm={6}>
            <Grid container>
              <CngGridItem xs={12} sm={12}>
                <Grid container>
                  <CngGridItem xs={12} sm={6}>
                    <PortDetailComponent
                      title={translatedTextsObject.placeOfReceipt}
                      portCode={si.placeOfReceiptCode}
                      portName={si.placeOfReceiptCodeDesc}
                      date={si.placeOfReceiptEtd}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <PortDetailComponent
                      title={translatedTextsObject.placeOfDelivery}
                      portCode={si.placeOfDeliveryCode}
                      portName={si.placeOfDeliveryCodeDesc}
                      date={si.placeOfDeliveryEta}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
              <CngGridItem xs={12} sm={12}>
                <Grid container>
                  {/* <CngGridItem xs={12} sm={6}>
                    <LabelValueVerticalSimple
                      label={translatedTextsObject.bookingDateAndTime}
                      // value={si.bookingSubmittedDate}
                      value={Utils.formatDate(
                        si.bookingSubmittedDate,
                        Utils.FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      defaultValue='-'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    />
                  </CngGridItem> */}

                  <CngGridItem xs={12} sm={6}>
                    <LabelValueVerticalSimple
                      label={translatedTextsObject.blrefNo}
                      value={si.billOfLadingNo}
                      defaultValue='-'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <LabelValueVerticalSimple
                      label={translatedTextsObject.blApplicationType}
                      value={si.billOfLadingAppTypeDesc}
                      defaultValue='-'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </CardActionArea>
      <SIDetailBottom
        dockey={si.freightBookingId}
        updatedDate={si.updatedDate}
        hazardous={si.hazardous}
        createdDate={si.createdDate}
        directSi={si.directSi}
      />
    </Card>
  )
}

const SIDetailCarrierComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIDetailCarrierComponent
