import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import { v4 as uuid } from 'uuid'
import CommonUtils from 'src/views/common/utils/Utils'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import Utils from 'src/views/shippinginstruction/shared/Utils'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDateField, CngDateTimeField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  carrierPartyId: '',

  polEta: null,
  podEta: null,
  polEtd: null,
  podEtd: null,
  portOfLoadingTerminal: '',
  portOfDischargeTerminal: '',
  intVoyageNo: '',
  //could be changed due to direct si
  vesselName: '',
  voyageNo: '',
  polCode: '',
  podCode: '',

  //from si
  preCarriageVesselName: '',
  preCarriageVoyageNo: '',
  polDesc: '',
  podDesc: ''
})

const FormBody = (props) => {
  const { countryCodeByPortCodeRef } = props

  //const shipmentComponent = ShipmentComponent()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)
  // const countryCodeByPortCodeRef = useRef([])
  const [, ,] = useField('voyageId')
  const [carrierPartyIdField, ,] = useField('carrierPartyId')
  const [carrierNameField, , { setValue: setCarrierPartyNameField }] =
    useField('carrierName')
  const [, , { setValue: setIsPilParty }] = useField('isPilParty')
  const [polCodeField, ,] = useField('polCode')
  const [podCodeField, ,] = useField('podCode')
  const [polEtaField, ,] = useField('polEta')
  const [podEtaField, ,] = useField('podEta')
  const [polEtdField, ,] = useField('polEtd')
  const [podEtdField, ,] = useField('podEtd')
  const [, ,] = useField('vesselName')
  const [, ,] = useField('intVoyageNo')
  const [, ,] = useField('voyageNo')
  const [, ,] = useField('portOfLoadingTerminal')
  const [, ,] = useField('portOfDischargeTerminal')
  const [carrierKey, setCarrierKey] = useState()
  const SG_DATE_TIME_FORMAT = CommonUtils.UI_FORMAT_DATE_TIME
  const nonDirectSiDraft = SIUtils.isNotDirectSiDraft(
    props.prevMenu,
    props.isDirectSI
  )
  const nonDirectSiCarrier = SIUtils.isNotDirectSiCarrier(
    props.isCarrier,
    props.isDirectSI
  )
  console.log('directSiDraft', nonDirectSiDraft)
  console.log('isDirectSiCarrier', nonDirectSiCarrier)
  // countryCodeByPortCodeRef.current = useFetchCountryCodes()

  const handleScheduleExpand = () => {
    setScheduleExpanded(!scheduleExpanded)
  }

  useEffect(() => {
    setScheduleExpanded(props.forceExpand)
    setIsPilParty(Utils.isThisPartyPIL(carrierNameField.value))
  }, [props.isEdit])

  const onChangePolAutoComplete = (value, option) => {
    if (value != null) {
      props.setPlaceOfReceiptCodeField(value)
      props.setPlaceOfReceiptField(option.label)
      props.placeOfReceiptKeyRef.current = uuid()
    }
    setDGLocalCategoryFromPort(value)
  }
  const onChangePodAutoComplete = (value, option) => {
    if (value != null) {
      props.setPlaceOfDeliveryCodeField(value)
      props.setPlaceOfDeliveryField(option.label)
      props.placeOfDeliveryKeyRef.current = uuid()
    }
    setDGLocalCategoryFromPort(value)
  }

  const setDGLocalCategoryFromPort = (value) => {
    props.setShowLocalCategory(value === 'SGSIN')
  }

  const setPartyName = (value, option) => {
    if (value != null) {
      setCarrierPartyNameField(option.label)
      setIsPilParty(Utils.isThisPartyPIL(option.label))
      props.setIsPilPartyNewSi(Utils.isThisPartyPIL(option.label))
    }
  }

  return (
    <Card>
      <Accordion expanded={scheduleExpanded}>
        <AccordionSummary
          aria-controls='scheduleComponentContent'
          id='scheduleComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={siTranslatedTextsObject.schedule}
            />
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <CarrierAutocompleteField
                  name='carrierPartyId'
                  key={props.polKeyRef}
                  label={
                    siTranslatedTextsObject.carrierNvoccBookingAgent
                  }
                  disabled={nonDirectSiDraft}
                  onChange={setPartyName}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <PortSelectAutoComplete
                  name='polCode'
                  label={siTranslatedTextsObject.portOfLoading}
                  keyRef={props.polKeyRef}
                  value={polCodeField.value}
                  onChangeAutoComplete={onChangePolAutoComplete}
                  //not disable when it is draft si or carrier  --> disable when it is neither not draft si nor non carrier
                  required
                  disabled={nonDirectSiDraft && nonDirectSiCarrier}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <PortSelectAutoComplete
                  name='podCode'
                  label={siTranslatedTextsObject.portOfDischarge}
                  keyRef={props.podKeyRef}
                  value={podCodeField.value}
                  onChangeAutoComplete={onChangePodAutoComplete}
                  disabled={nonDirectSiDraft && nonDirectSiCarrier}
                  required
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={siTranslatedTextsObject.polPlannedEta}
                  name='polEta'
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(moment().format(SG_DATE_TIME_FORMAT)).isAfter(
                      moment(date)
                    )
                  }}
                  disabled
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={siTranslatedTextsObject.podPlannedEta}
                  name='podEta'
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(polEtdField.value).isAfter(moment(date))
                  }}
                  disabled
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={siTranslatedTextsObject.polPlannedEtd}
                  name='polEtd'
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(polEtaField.value).isAfter(moment(date))
                  }}
                  disabled
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateField
                  label={siTranslatedTextsObject.podPlannedEtd}
                  name='podEtd'
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(podEtaField.value).isAfter(moment(date))
                  }}
                  disabled
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  inputProps={{ maxLength: 255 }}
                  label={siTranslatedTextsObject.polDesc}
                  name='polDesc'
                  disabled={props.isCarrier}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  inputProps={{ maxLength: 255 }}
                  label={siTranslatedTextsObject.podDesc}
                  name='podDesc'
                  disabled={props.isCarrier}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='preCarriageVesselName'
                  label={siTranslatedTextsObject.preCarriageVesselName}
                  inputProps={{ maxLength: 35 }}
                  disabled={props.isCarrier}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='preCarriageVoyageNo'
                  label={siTranslatedTextsObject.preCarriageVoyageNo}
                  inputProps={{ maxLength: 17 }}
                  disabled={props.isCarrier}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='intVoyageNo'
                  label={siTranslatedTextsObject.internationalVoyageNumber}
                  inputProps={{ maxLength: 20 }}
                  disabled
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='vesselName'
                  label={siTranslatedTextsObject.vesselName}
                  inputProps={{ maxLength: 35 }}
                  disabled={nonDirectSiDraft && nonDirectSiCarrier}
                  isRequired
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='voyageNo'
                  label={siTranslatedTextsObject.voyageNumber}
                  inputProps={{ maxLength: 17 }}
                  disabled={nonDirectSiDraft && nonDirectSiCarrier}
                  isRequired
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleScheduleExpand}
        expanded={scheduleExpanded}
        footerText={siTranslatedTextsObject.additionalScheduleDetails}
      />
    </Card>
  )
}

const SIScheduleComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIScheduleComponent
