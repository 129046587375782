import React, { useState, useMemo } from 'react'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import ReeferSettingsContext from './ReeferSettingsContext.js'
import ReeferDetailsForm from './ReeferDetailsForm.js'
import { components } from 'cng-web-lib'


const { CngDialog, CngDialogTitle } = components

function ReeferDetailsDialog({
  isDialogOpen,
  closeDialog,
  currentContainerIdx,
  siContainerBoxList,
  setSiContainerBoxList,
  showNotification,
  showLocalCategory,
  isCarrier
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  const value = useMemo(() => {
    return {
      closeDialog,
      showNotification,
      form: {
        isSubmitting,
        setSubmitting
      },
      currentContainerIdx,
      siContainerBoxList,
      setSiContainerBoxList,
      showLocalCategory,
      isCarrier
    }
  }, [closeDialog, showNotification, isSubmitting, currentContainerIdx, siContainerBoxList, setSiContainerBoxList, showLocalCategory, isCarrier])


  return (
    <ReeferSettingsContext.Provider
      value={value}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <ReeferDetailsForm
            />
          </Paper>
        }
        dialogTitle={
          <>
            {
              (siContainerBoxList != null
                && siContainerBoxList[currentContainerIdx] != null
                && siContainerBoxList[currentContainerIdx].containerIsoSizeType != null
              ) ? (
                <b>{'Reefer Details for Container ' + siContainerBoxList[currentContainerIdx].containerIsoSizeType}</b>
              )
                :
                (
                  <b>{'Reefer Details for Container'}</b>
                )
            }
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </ReeferSettingsContext.Provider>
  )
}

export default ReeferDetailsDialog
