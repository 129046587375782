import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Box,
  Card,
  Grid
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FooterAddComponent from '../../common/ui/FooterAddComponent'
import { v4 as uuid } from 'uuid'
import SIContainerDetailsComponent from './SIContainerDetailsComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ReeferSettingsDialog from './ReeferSettingsDialog'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import { useFieldArray, useFormContext } from 'react-hook-form'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

const { CodeMaintenanceType } = constants

const initialValues = {
  // maxContainerNo: 1,
  // contTypeList: [],
  shippingInfoContainerBoxes: [
    {
      containerId: '',
      containerNo: '',
      sealNo: '',
      packageType: '',
      pkgCnt: '',
      volume: '',
      containerIsoSizeType: '',
      grossWeight: '',
      measurement: '',
      cargoNetWeight: '',
      hazardous: false,
      shipperOwned: false,
      specialHandling: '',
      stowage: '',
      reeferAvailable: false,
      nonActiveReefer: false
    }
  ]
}

const FormBody = (props) => {
  const { showNotification } = props
  const translationTextObject = FreightBookingTranslationText()
  const { errors, touched, setFieldValue } = useFormikContext()
  const { trigger } = useFormContext()
  const contSpecialHandlingKeyRef = useRef(uuid())
  const contStowageKeyRef = useRef(uuid())
  const [currentContainer, setCurrentContainer] = useState()
  const [isReeferSettingsDialogOpen, setReeferSettingsDialogOpen] = useState(false)
  const [contIdx, setContIdx] = useState();
  const [reeferOrTankContType, setReeferOrTankContType] = useState(false)
  const { fetchRecords } = useServices()
  const [, , { setValue: setContainerPackageField }] =
    useField('containerPackage')
  const [maxContainerNoField, , { setValue: setMaxContainerNoField }] =
    useField('maxContainerNo')
  const [contIsoTypeListField, , { setValue: setContIsoTypeListField }] =
    useField('contIsoTypeList')
  const maxContainerDirectSI = 50

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  const getFieldError = (contIndex, cargoIndex, fieldPath, isCont) => {
    //dont think this is required
    return null
  }

  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const contTypeKeyRef = useRef(uuid())
  const contStatusKeyRef = useRef(uuid())

  const containerItem = {
    containerId: '', //for react key reference, actual field is "contId"
    id: uuid(), //as the key of each component
    containerQty: '',
    containerStatus: CalistaConstant.FULL_CONTAINER_LOAD,
    containerIsoSizeType: '',
    shipperOwned: false,
    packageType: '',
    pkgCnt: '',
    volume: '',
    containerNo: '',
    sealNo: '',
    noOfPackage: '',
    grossWeight: '',
    measurement: '',
    cargoNetWeight: '',
    specialHandling: '',
    stowage: '',
    reeferId: '',
    nonActiveReefer: false,
    reeferAvailable: false,
    equipmentTemp: '',
    tempUom: CalistaConstant.REEFER_TEMP_CEL,
    equipmentAirFlow: '',
    equipmentComments: ''
  }

  // useState containerList for handle containerList dynamically
  const [siContainerBoxList, setSiContainerBoxList] = useState([])
  const [contTypeList, setContTypeList] = useState([])
  const [maxContainerNo, setMaxContainerNo] = useState(1)
  //useField containers for handle formik valiation and submission
  const [containersField, ,] = useField('bookingContainers')

  const { fields: siContainersField } = useFieldArray({
    name: 'shippingInfoContainerBoxes'
  })
  // const [siContainersField, , { setValue: setSiContainersField }] = useField(
  //   'shippingInfoContainerBoxes'
  // )
  const [shouldRender, setShouldRender] = useState(false)
  const draftContId = useRef(-1)

  // handle input change
  const handleInputChange = (e, index) => {
    //console.log('handleInputChange : ' + JSON.stringify(e) )
    const { name, value } = e.target

    const list = [...siContainerBoxList]
    let field = name.split('.')[1]
    console.log('handleInputChange field', field)
    list[index][field] = value

    setSiContainerBoxList(list)
    //setSiContainersField(list)
    setFieldValue('shippingInfoContainerBoxes', list)
    //trigger(`shippingInfoContainerBoxes[${index}].${field}`)
  }

  const handleSwitchChange = (e, index) => {
    const { name } = e.target

    const list = [...siContainerBoxList]
    console.log('handleSwitchChange', name, list[index][name], list)
    if (name === 'nonActiveReefer') {
      list[index].nonActiveReefer = e.target.checked
    } else {
      list[index][name] = e.target.checked
    }
    console.log('handleSwitchChange', name, list[index][name], list)

    setSiContainerBoxList(list)
    //setSiContainersField(list)
    setFieldValue('shippingInfoContainerBoxes', list)
  }

  const handleDropdownChange = (val, evt, index, name) => {
    const list = [...siContainerBoxList]
    list[index][name] = val

    setSiContainerBoxList(list)
    // setSiContainersField(list)
    setFieldValue('shippingInfoContainerBoxes', list)

    if (name === 'containerIsoSizeType') {
      setContainerPackageField(generateContainerTypeInWordsNew(list))
      setReeferOrTankContType(true)
      setContIdx(index)
    }

    setSiContainerBoxList(list)
    setFieldValue('shippingInfoContainerBoxes', list)
  }

  function onContainerTypeLoadSuccess(data) {
    if (data != null) {
      const list = [...siContainerBoxList]
      let containerItem = list[contIdx]
      if (containerItem != null) {
        let matched = false;

        data.forEach(container => {
          if (container?.isoCode === containerItem.containerIsoSizeType &&
            (container.contTypeDesc.toLowerCase() === 'reefer' || container.contTypeDesc.toLowerCase() === 'tank')) {
            matched = true;
          }
        })

        if (matched) {
          list[contIdx]['reeferAvailable'] = true
          setSiContainerBoxList(list)
        }
        else {
          list[contIdx]['reeferAvailable'] = false
          setSiContainerBoxList(list)
        }
      }
    }
    setReeferOrTankContType(false);
  }

  useEffect(() => {
    if (reeferOrTankContType) {
      SharedServiceApi.fetchContainerType(
        fetchRecords,
        false,
        onContainerTypeLoadSuccess
      )
    }
  }, [reeferOrTankContType])

  // handle click event of the Remove button
  const handleRemoveContainer = (index) => (e) => {
    const list = [...siContainerBoxList]

    list.splice(index, 1)
    contTypeKeyRef.current = uuid()
    contStatusKeyRef.current = uuid()

    if (props.isDirectSI) {
      contSpecialHandlingKeyRef.current = uuid()
      contStowageKeyRef.current = uuid()
    }

    setSiContainerBoxList(list)
    // setSiContainersField(list)
    setFieldValue('shippingInfoContainerBoxes', list)
    setContainerPackageField(generateContainerTypeInWordsNew(list))

    //remove all the corresponding entries in cargoList
    // alert("cargolist:" + JSON.stringify(cargoList))
  }

  // handle click event of the Add button
  const handleAddContainer = () => {
    if (siContainerBoxList.length < maxContainerNo) {
      const addContainer = { ...containerItem }
      draftContId.current = draftContId.current - 1
      setSiContainerBoxList([...siContainerBoxList, addContainer])

      // setSiContainersField([...siContainerBoxList, addContainer])
      setFieldValue('shippingInfoContainerBoxes', [
        ...siContainerBoxList,
        addContainer
      ])
    } else {
      showErrorNotification(
        props.isDirectSI
          ? `${siTranslatedTextsObject.cannotAddContainerDirectSIMsg +
          maxContainerDirectSI
          }`
          : `${siTranslatedTextsObject.cannotAddContainerMsg}`
      )
    }
  }

  //handle click event of the Copy buttion
  const handleCopyContainer = (index) => (e) => {
    if (siContainerBoxList.length < maxContainerNo) {
      const list = [...siContainerBoxList]
      const copyContainer = list.slice(index, index + 1)[0]
      //alert('index : ' + index + ", " + JSON.stringify(copyContainer))

      const copyContainerItem = { ...copyContainer }
      copyContainerItem.containerId = draftContId.current
      copyContainerItem.id = uuid()
      copyContainerItem.contBoxId = null //cont id is unique for every cargo,cannot be copied from an existing one
      copyContainerItem.seqNo = null //seqNo cannot be copied from other containers
      draftContId.current = draftContId.current - 1

      setSiContainerBoxList([...siContainerBoxList, copyContainerItem])
      // alert('container List,' + JSON.stringify(containerList))
      // setSiContainersField([...siContainerBoxList, copyContainerItem])
      setFieldValue('shippingInfoContainerBoxes', [
        ...siContainerBoxList,
        copyContainerItem
      ])
      setContainerPackageField(
        generateContainerTypeInWordsNew([
          ...siContainerBoxList,
          copyContainerItem
        ])
      )
    } else {
      showErrorNotification(
        props.isDirectSI
          ? `${siTranslatedTextsObject.cannotAddContainerDirectSIMsg +
          maxContainerDirectSI
          }`
          : `${siTranslatedTextsObject.cannotAddContainerMsg}`
      )
    }
  }

  useEffect(() => {
    /**
     * there are 3 scenarios.
     * 1. isEdit is false, then this is a new si
     *    1.1. directSI is false --> populate si list from booking
     *    1.2  directSI is true --> add one general container
     * 2. isEdit is true, then this is a created si
     *    no matter this is direct si or not, --> popullate si list from si field
     */
    const cont = { ...containerItem }
    cont.containerId = 'cont' + draftContId.current
    cont.containerQty = 1
    cont.containerIsoSizeType = ''
    cont.containerStatus = CalistaConstant.FULL_CONTAINER_LOAD
    draftContId.current = draftContId.current - 1

    if (props.isEdit) {
      console.log('siContainersField', siContainersField)
      if (siContainersField == null) {
        setSiContainerBoxList([cont])
        //  setFieldValue('shippingInfoContainerBoxes', [cont])
      } else {
        setSiContainerBoxList(siContainersField)
        setFieldValue('shippingInfoContainerBoxes', siContainersField)
      }

      if (props.isDirectSI) {
        setMaxContainerNo(maxContainerDirectSI)
        setMaxContainerNoField(maxContainerDirectSI)
        if (props.isCarrier) {
          if (siContainersField != null) {
            let temp_contType = []
            siContainersField.map((contElem) => {
              temp_contType.push(
                contElem.containerIsoSizeType ? contElem.containerIsoSizeType : null
              )
            })
            setContTypeList(temp_contType)
          }
        }
      } else {
        setContTypeList(contIsoTypeListField.value)
        setMaxContainerNo(maxContainerNoField.value)
      }
    } else {
      let newContArray = []
      let temp_contType = []

      if (props.isDirectSI) {
        setSiContainerBoxList([cont])
        // setSiContainersField([cont])
        setFieldValue('shippingInfoContainerBoxes', [cont])
        setMaxContainerNo(maxContainerDirectSI)
        setMaxContainerNoField(maxContainerDirectSI)
        setContainerPackageField(generateContainerTypeInWordsNew([cont]))
      } else {
        console.log('containersField new', containersField)
        if (containersField.value == null) {
          //in case there is no container saved in the bookings
          setSiContainerBoxList([cont])
          // setSiContainersField([cont])
          setFieldValue('shippingInfoContainerBoxes', [cont])
        } else {
          containersField.value.map((contElem) => {
            newContArray.push({
              ...contElem,
              id: uuid(),
              containerQty: 1,
              containerNo: '',
              containerIsoSizeType: contElem.containerIsoType
            })
            temp_contType.push(
              contElem.containerIsoType ? contElem.containerIsoType : null
            )
            for (let i = 1; i < contElem['containerQty']; i++) {
              let container_id = 'cont' + draftContId.current
              newContArray.push({
                ...contElem,
                id: uuid(),
                containerQty: 1,
                contId: null,
                containerId: container_id,
                seqNo: null,
                containerNo: '',
                containerIsoSizeType: contElem.containerIsoType
              })
              draftContId.current = draftContId.current - 1
            }
            return null
          })
          console.log('newContArray', newContArray)
          setContTypeList(temp_contType)
          setFieldValue('contIsoTypeList', temp_contType)
          setSiContainerBoxList(newContArray)
          // setSiContainersField(newContArray)
          setFieldValue('shippingInfoContainerBoxes', newContArray)
          setMaxContainerNo(newContArray.length)
          setMaxContainerNoField(newContArray.length)
          setContainerPackageField(
            generateContainerTypeInWordsNew(newContArray)
          )
        }
      }
    }
  }, [
    props.isEdit,
    containersField.value,
    props.isDirectSI,
    props.reloadSIData
  ])

  function generateContainerTypeInWords(newContArray) {
    console.log('generateContainerTypeInWordsNew', newContArray)

    let arr = newContArray
    let std = SIUtils.containerType
    console.log(arr)
    console.log(std)

    let objList = []
    std.map((elem) => {
      let countx = arr.filter(
        (x) => x.containerIsoSizeType === elem.isoCode
      ).length
      let obj = { code: elem.containerCode, count: countx }
      objList.push(obj)
      return null
    })
    console.log(objList)

    let result = ''
    objList.map((elem) => {
      if (elem.count > 0) result += elem.count + 'X' + elem.code + ', '
      return null
    })
    if (result.length > 0) {
      result = result.slice(0, -2)
      result += ' CONTAINER(S) ONLY '
    }
    console.log(result)
    return result
  }

  function generateContainerTypeInWordsNew(newContArray) {
    const FEET_20_WORD = ' TWENTY FOOTER '

    const FEET_40_WORD = ' FORTY FOOTER '

    const FEET_45_WORD = ' FORTY-FIVE FOOTER '

    let result = ''
    let foot20 = 0
    let foot40 = 0
    let foot45 = 0
    let arr = newContArray
    arr.map((cb) => {
      if (cb.containerIsoSizeType.startsWith('2')) {
        foot20++
      }
      if (cb.containerIsoSizeType.startsWith('4')) {
        foot40++
      }
      if (cb.containerIsoSizeType.startsWith('L')) {
        foot45++
      }
    })
    if (foot20 > 0)
      result = SIUtils.generateContainerWords(result, foot20, FEET_20_WORD)
    if (foot40 > 0)
      result = SIUtils.generateContainerWords(result, foot40, FEET_40_WORD)
    if (foot45 > 0)
      result = SIUtils.generateContainerWords(result, foot45, FEET_45_WORD)

    result += ' ONLY'
    return result
  }

  const editReeferSettings = (idx) => {
    setCurrentContainer(idx)
    setReeferSettingsDialogOpen(true)
  }

  function closeReeferSettingsDialog() {
    setReeferSettingsDialogOpen(false)
  }

  return (
    <Box>
      <Card>
        <Box px={2} my={1.5}>
          <Grid container justify='flex-end'>
            <AccordionHeaderComponent
              title={siTranslatedTextsObject.containerDetails}
            />
          </Grid>

          {/* {'container box list: ' + JSON.stringify(siContainerBoxList)} */}
          <SIContainerDetailsComponent
            isCarrier={props.isCarrier}
            isDirectSI={props.isDirectSI}
            list={siContainerBoxList}
            setSiContainerBoxList={setSiContainerBoxList}
            contTypeList={contTypeList}
            removeRowClick={handleRemoveContainer}
            copyRowClick={handleCopyContainer}
            getFieldError={getFieldError}
            handleDropdownChange={handleDropdownChange}
            handleSwitchChange={handleSwitchChange}
            handleInputChange={handleInputChange}
            editReeferSettings={editReeferSettings}
            contTypeKeyRef={contTypeKeyRef.current}
            contStatusKeyRef={contStatusKeyRef.current}
            contSpecialHandlingKeyRef={contSpecialHandlingKeyRef.current}
            contStowageKeyRef={contStowageKeyRef.current}
            reeferError={props.reeferError}
          />
        </Box>

        {!props.isCarrier && (
          <FooterAddComponent
            handleAddChild={handleAddContainer}
            footerText={siTranslatedTextsObject.addAContainer}
          />
        )}
      </Card>

      <ReeferSettingsDialog
        isDialogOpen={isReeferSettingsDialogOpen}
        closeDialog={closeReeferSettingsDialog}
        showNotification={showNotification}
        currentContainer={currentContainer}
      />
    </Box>
  )
}

const SIContainerComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIContainerComponent
