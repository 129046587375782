import { Grid } from '@material-ui/core'

import ExportMenuComponent from 'src/views/helpdesk/components/ExportMenuComponent'
import React from 'react'
import ShowCountComponent from './ShowCountComponent'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSelectField }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  return (
    <Grid container spacing={1}>
      <CngGridItem item xs={12} sm={12}>
        <div className={`cng-summary--header`}>
          <div className={`cng-summary--header-col auto`}>
            {/* Placeholder container for tabs */}
          </div>
          <div className={`cng-summary--header-col w-190px`}>
            <CngSelectField
              name='sortCode'
              label='Sort By'
              items={props.sortByList}
              onClick={props.handleSortByChange}
              inlineLabel
            />
          </div>
          {props.exportData && (
            <div className={`cng-summary--header-col cta-46`}>
              <ExportMenuComponent
                showNotification={props.showNotification}
                columns={props.columns}
                fileName={props.fileName}
                apiurl={props.apiurl}
                searchCriteria={props.searchCriteria}
              />
            </div>
          )}
        </div>
      </CngGridItem>
      <CngGridItem item xs={12} sm={12}>
        <ShowCountComponent
          shownCount={props.listCount}
          totalCount={props.shownCount}
        />
      </CngGridItem>
    </Grid>
  )
}

const ButtonTabComponent = Object.freeze({
  FormBody: FormBody
})

export default ButtonTabComponent
