import { Chip, Grid, Typography, Tooltip } from '@material-ui/core'

import BookingActionMenu from 'src/views/freightbooking/components/BookingActionMenu'
import React from 'react'
import Utils from 'src/views/freightbooking/shared/Utils'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import LabelValueVerticalSimple from 'src/views/freightbooking/components/LabelValueVerticalSimple'
import ArchiveIcon from './ArchiveIcon'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'

const CreateSIDetailTop = (props) => {
  const { booking } = props
  const { bookStatus, bookStatusDesc, carRefNo, shpRefNo } = booking
  const translatedTextsObjects = ShippingInstructionTranslationText()
  const frbTranslatedTextsObject = FreightBookingTranslationText()

  let temp = Utils.getBookingStatusById(bookStatus).color

  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container xs={12} style={{ height: '100%' }}>
        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--left`}>
            <Chip
              label={
                <Typography className={'boldLabel'} style={{ fontSize: 16 }}>
                  {frbTranslatedTextsObject.booking +
                    ' ' +
                    bookStatusDesc.toUpperCase()}
                  {booking.closed && <ArchiveIcon />}
                </Typography>
              }
              style={{ backgroundColor: temp }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1`}>
              <LabelValueVerticalSimple
                label={translatedTextsObjects.bookingRefNo + ':'}
                value={carRefNo}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content-bold'}
              />
            </div>
            <div className={`cng-summary-card--more-actions-wrapper`}>
              {!booking.closed && (
                <BookingActionMenu
                  booking={booking}
                  {...props}
                  isCreateSI
                  page={Utils.Page.BookingList}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default CreateSIDetailTop
