import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Box,
  Chip
} from '@material-ui/core'

import React, { useState } from 'react'
import { components } from 'cng-web-lib'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import Utils from 'src/views/common/utils/Utils'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import AuditTrailDualColumnContainerType from 'src/components/auditTrail/AuditTrailDualColumnContainerType'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'

const {
  CngGridItem,
} = components

const CHANGE = '_changes_'
const ADDED = 'added'
const REMOVED = 'removed'
const REEFER = 'reefer'

const FIELDS_CONTAINER = ['contNo', 'contRefNo', 'containerCode', 'hazardous', 'isoSizeType', 'masterProduct', 'netWeight',
  'pkgCnt', 'pkgType', 'pkgTypeDesc', 'productCode', 'sealNo', 'shpOwned', 'specialHandling', 'stowage', 'tareWeight',
  'vgmAcqTime', 'vgmRefNo', 'vgmSignPerson', 'vgmWeight', 'vgmWeightMethod', 'volume', 'weight']

const FIELDS_REEFER = ['nonActiveReefer', 'equipmentTemp', 'tempUom', 'equipmentAirFlow', 'equipmentComments']

const AuditTrailContainer = (props) => {
  const { containers, codeMasterList, containerTypes } = props
  const tranObj = FreightBookingTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)
  const siTranObj = ShippingInstructionTranslationText()

  console.log('AuditTrailContainer', containers)

  const handleScheduleExpand = () => {
    setScheduleExpanded(!scheduleExpanded)
  }


  const addContainer = containers && containers.added ? containers.added.map(a => a) : []
  const removeContainer = containers && containers.removed ? containers.removed.map(r => r) : []
  const updateContainer = containers && containers.updated ? containers.updated.map(u => u) : []

  const allContainer = [...addContainer, ...removeContainer, ...updateContainer].sort((c1, c2) => c1.id - c2.id)
  let containerStatus = ''

  const ContainerFields = ({ container, index, ...props }) => {
    console.log('AuditTrailContainer container', container)

    const {
      added,
      removed,
      updated,
    } = props

    let className = 'white-chip'
    let hadChangeContNo = false
    if (added) {
      containerStatus = tranObj.added
      className = 'success-chip'
    }
    if (removed) {
      containerStatus = tranObj.deleted
      className = 'danger-chip'
    }
    if (updated) {
      containerStatus = tranObj.updated
      className = 'primary-chip'

      const valueContNo = Utils.getAuditTrailDiffs(container, ['contNo'])
      const valueIsoType = Utils.getAuditTrailDiffs(container, ['isoSizeType'])
      const valuePkgTypeDesc = Utils.getAuditTrailDiffs(container, ['pkgTypeDesc'])
      const valueSealNo = Utils.getAuditTrailDiffs(container, ['sealNo'])
      const valueTareWeight = Utils.getAuditTrailDiffs(container, ['tareWeight'])
      const valueVgmWeight = Utils.getAuditTrailDiffs(container, ['vgmWeight'])
      const valueSpecialHandling = Utils.getAuditTrailDiffs(container, ['specialHandling'])
      const valueStowage = Utils.getAuditTrailDiffs(container, ['stowage'])
      const valueShpOwned = Utils.getAuditTrailDiffs(container, ['shpOwned'])
      const valuePkgCnt = Utils.getAuditTrailDiffs(container, ['pkgCnt'])
      const valueVolume = Utils.getAuditTrailDiffs(container, ['volume'])
      if (Utils.noChanges(valueContNo) && Utils.noChanges(valueIsoType) && Utils.noChanges(valuePkgTypeDesc)
        && Utils.noChanges(valueSealNo) && Utils.noChanges(valueTareWeight) && Utils.noChanges(valueVgmWeight)
        && Utils.noChanges(valueSpecialHandling) && Utils.noChanges(valueStowage) && Utils.noChanges(valueShpOwned)
        && Utils.noChanges(valuePkgCnt) && Utils.noChanges(valueVolume)) {
        containerStatus = null
      }

      if (!Utils.noChanges(valueContNo)) {
        hadChangeContNo = true
      }
    }

    container.contIsoType = container.isoSizeType
    if (container._changes_) {
      container._changes_.contIsoType = container._changes_.isoSizeType
    }
    return (
      <Box width='100%'>
        <ComponentWrapper
          header={tranObj.container + ' ' + (index + 1)}
          rightConnerHeader={
            <Box>
              {hadChangeContNo && (
                <Chip label={tranObj.renamed.toUpperCase()} size='small' className='info-chip' style={{ marginRight: 5 }} />
              )}
              {containerStatus && (<Chip label={containerStatus.toUpperCase()} size='small' className={className} style={{ marginRight: 5 }} />)}
            </Box>
          }
          cardStyle={{ marginBottom: 20 }}
          backgroundStyle='inner-card-background'
          headerStyle='inner-card-background inner-card-group boldLabel'
        >
          <AuditTrailDualColumn
            {...props}
            object={container}
            field={'contNo'}
            label={siTranObj.containerNo}
          />
          <AuditTrailDualColumnContainerType
            container={container}
            containerTypes={containerTypes}
            {...props}
          />
          <AuditTrailDualColumn
            {...props}
            object={container}
            field={'pkgTypeDesc'}
            label={siTranObj.packageType}
          />

          <AuditTrailDualColumn
            {...props}
            object={container}
            field={'sealNo'}
            label={siTranObj.sealNo}
          />

          <AuditTrailDualColumn
            {...props}
            object={container}
            field={'tareWeight'}
            label={siTranObj.containerGrossWeight}
          />

          <AuditTrailDualColumn
            {...props}
            object={container}
            field={'vgmWeight'}
            label={siTranObj.totalVgmWeight}
          />

          <AuditTrailDualColumn
            {...props}
            object={container}
            codeType={CalistaConstant.CAL_CONT_HANDLING}
            codeMasterList={codeMasterList}
            field={'specialHandling'}
            label={siTranObj.specialHandling}
          />

          <AuditTrailDualColumn
            {...props}
            object={container}
            codeType={CalistaConstant.CAL_CONT_STOWAGE}
            codeMasterList={codeMasterList}
            field={'stowage'}
            label={siTranObj.stowage}
          />

          {/* <AuditTrailDualColumn
            {...props}
            object={container}
            field={'noOfPackage'}
            label={siTranObj.noOfPackages}
          /> */}

          <AuditTrailDualColumn
            {...props}
            object={container}
            field={'shpOwned'}
            label={tranObj.shipperOwned}
          />
        </ComponentWrapper>
      </Box>
    )
  }
  return (
    <Card>
      <Accordion expanded={scheduleExpanded}>
        <AccordionSummary
          aria-controls='containerComponentContent'
          id='containerComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={tranObj.containerDetails}
              hideMandatory
            />
          </Grid>
        </AccordionSummary>

        <Box mx={2}>
          <Grid container xs={12} sm={12} spacing={0}>
            <CngGridItem xs={6} sm={6}>
              <Box>
                <Box className="view-card-content-label">
                  <Grid container justify="space-between">
                    <Grid item>
                      {tranObj.oldContainer}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CngGridItem>
            <CngGridItem xs={6} sm={6}>
              <Box>
                <Box className="view-card-content-label">
                  <Grid container justify="space-between">
                    <Grid item>
                      {tranObj.newContainer}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CngGridItem>
          </Grid>
        </Box>

        <AccordionDetails>
          <Grid container>
            {
              allContainer.map((c, i) => {
                let containerField;
                let reeferField;

                //Check for Container Details and populate containerField var if there are changes
                if (c.added) {
                  containerField = <ContainerFields added container={c} index={i} />
                } else if (c.removed) {
                  containerField = <ContainerFields removed container={c} index={i} />
                } else {
                  //Flow will come to this if only container has updates. 
                  //However it also comes in when other container items have changes but current item has no changes
                  //Hence adding this container check to ensure it displays changes if container item fields have changes
                  if (Utils.hasFieldsChanged(c, FIELDS_CONTAINER)) {
                    containerField = <ContainerFields updated container={c} index={i} />
                  }
                }

                //Check for Reefer Details and populate reeferField var if there are changes
                if (c[ADDED] != undefined && c[ADDED][REEFER] &&
                  c[ADDED][REEFER].added && c[ADDED][REEFER].added[0]) {
                  reeferField = <ReeferFields added contNo={i} reefer={c[ADDED][REEFER].added[0]} />
                }

                else if (c[REMOVED] != undefined && c[REMOVED][REEFER] &&
                  c[REMOVED][REEFER].removed && c[REMOVED][REEFER].removed[0]) {
                  reeferField = <ReeferFields removed contNo={i} reefer={c[REMOVED][REEFER].removed[0]} />
                }

                else if (c[CHANGE] != undefined && c[CHANGE][REEFER] &&
                  c[CHANGE][REEFER].added && c[CHANGE][REEFER].added[0]) {
                  reeferField = <ReeferFields added contNo={i} reefer={c[CHANGE][REEFER].added[0]} />
                }

                else if (c[CHANGE] != undefined && c[CHANGE][REEFER] &&
                  c[CHANGE][REEFER].removed && c[CHANGE][REEFER].removed[0]) {
                  reeferField = <ReeferFields removed contNo={i} reefer={c[CHANGE][REEFER].removed[0]} />
                }

                else if (
                  c[CHANGE] != undefined && c[CHANGE][REEFER] &&
                  c[CHANGE][REEFER].updated && c[CHANGE][REEFER].updated[0] &&
                  Utils.reeferObjectHasChanges(c[CHANGE][REEFER], FIELDS_REEFER)) {
                  reeferField = <ReeferFields updated contNo={i} reefer={c[CHANGE][REEFER].updated[0]} />
                }

                return (
                  <Grid container xs={12} sm={12}>
                    <Grid container xs={12} sm={12}>
                      {containerField}
                    </Grid>
                    <Grid container xs={12} sm={12}>
                      {reeferField}
                    </Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleScheduleExpand}
        expanded={scheduleExpanded}
        footerText={tranObj.showContainerDetails}
        footerTextExpanded={tranObj.hideContainerDetails}
        noOptionalFields
      />
    </Card>
  )
}

const ReeferFields = ({ reefer, ...props }) => {
  const tranObj = FreightBookingTranslationText()

  const {
    added,
    removed,
    updated,
    contNo
  } = props

  let reeferStatus = '';
  let className = 'white-chip'

  if (added) {
    reeferStatus = tranObj.added;
    className = 'success-chip'
  }
  if (removed) {
    reeferStatus = tranObj.deleted
    className = 'danger-chip'
  }
  if (updated) {
    reeferStatus = tranObj.updated
    className = 'primary-chip'
  }

  return (
    <Box width="100%">
      <ComponentWrapper
        rightConnerHeader={
          <Box>
            {reeferStatus && (<Chip label={reeferStatus.toUpperCase()} size='small' className={className} style={{ marginRight: 5 }} />)}
          </Box>
        }
        cardStyle={{ marginBottom: 20 }} backgroundStyle="inner-card-background"
        headerStyle="inner-card-background inner-card-group boldLabel">

        <AccordionSummary>
          <Grid container>
            <AccordionHeaderComponent title={tranObj.reeferDetails + " " + (contNo + 1)} hideMandatory />
          </Grid>
        </AccordionSummary>

        <AuditTrailDualColumn object={reefer} field={'nonActiveReefer'} label={tranObj.nonActiveReefer} />
        <AuditTrailDualColumn object={reefer} field={'equipmentTemp'} label={tranObj.equipmentTemp} />
        <AuditTrailDualColumn object={reefer} field={'tempUom'} label={tranObj.tempUom} />
        <AuditTrailDualColumn object={reefer} field={'equipmentAirFlow'} label={tranObj.equipmentAirFlow} />
        <AuditTrailDualColumn object={reefer} field={'equipmentComments'} label={tranObj.equipmentComments} />
      </ComponentWrapper>
    </Box >
  )
}

export default AuditTrailContainer
