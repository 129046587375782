import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core'
import RestoreIcon from '@material-ui/icons/Restore';
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import {
    components
} from 'cng-web-lib'

const {
    button: { CngSecondaryButton }
} = components


const FilterListNoResult = props => {
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    return (
        <div className={'vs-noresult'}>
            <img
                src={
                    process.env.PUBLIC_URL + '/static/images/shippingInstruction/NotFound_Empty.svg' 
                }
                alt='No Filter Result'
            ></img>
            <Typography variant='h3' style={{ fontWeight: 'bold', paddingTop: '10px'}}>
                {uiTranslatedTextsObject.resultNotFound}
            </Typography>

            <Typography variant='subtitle1' align="center" style={{ paddingTop: '10px', color:'#808080'}}>
            {uiTranslatedTextsObject.noFilterResultDesc_1}
            <br/>
            {uiTranslatedTextsObject.noFilterResultDesc_2}
            </Typography>

            <Box pt={2}>
                <Grid container>
                    <CngSecondaryButton onClick={props.resetFilterAction}>
                        <RestoreIcon />
                        <Box pl={1}>
                        {uiTranslatedTextsObject.resetFilter}
                        </Box>
                    </CngSecondaryButton>
                </Grid>
            </Box>

        </div>
    )
}

export default FilterListNoResult;