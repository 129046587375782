import {
  Box,
  Card,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Api from 'src/views/freightbooking/shared/api'
import { useServices } from 'cng-web-lib'
const {
  CngGridItem,
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 16,
    fontWeight: 'bolder'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
})

const FormBody = (props) => {
  const { auditList, setAuditCompare, setDialogOpen, setNewDate, setPrevDate, userCriteria } = props
  const [users, setUsers] = useState([])
  const { fetchRecords } = useServices()

  const calTranObj = CalistaUiComponentTranslationText()
  const classes = useStyles()

  useEffect(() => {
        if(userCriteria?.length>0){
            Api.fetchUsersWithCriteria(fetchRecords,userCriteria, onFetchUsersSuccess)
        }
  }, [userCriteria])

  function onFetchUsersSuccess(data) {
    setUsers(data.content)
  }

  const viewAudit = (index) => (event) => {
    console.log('fetchAuditTrailCompare start')
    let newVersion = auditList[index].frozenVersion
    let oldVersion =
      index == auditList.length - 1
        ? newVersion
        : auditList[index + 1].frozenVersion
    let newDate = Utils.formatDate(
      auditList[index].createdDate,
      Utils.FORMAT_DATE_TIME
    )
    let prevDate =
      index == auditList.length - 1 ? newDate : auditList[index + 1].createdDate
    setNewDate(newDate)
    setPrevDate(Utils.formatDate(prevDate, Utils.FORMAT_DATE_TIME))

    const versions = {
      oldVersion: oldVersion,
      newVersion: newVersion
    }
    setAuditCompare({})
    Api.fetchAuditTrailCompare(fetchRecords, versions, onAuditTrailLoadSuccess)
    setDialogOpen(true)
  }

  function onAuditTrailLoadSuccess(data) {
    setAuditCompare(data)
    console.log('Audit trail compare: ', data)
  }

  return (
    <div>
      <Grid container xs={12} sm={12}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <Box className='view-card-header'>{calTranObj.history}</Box>
            <TableContainer component={Paper} style={{ padding: 10 }}>
              <Table className={classes.table} aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{calTranObj.status}</StyledTableCell>
                    <StyledTableCell>
                      {calTranObj.account_email}
                    </StyledTableCell>
                    <StyledTableCell>{calTranObj.company}</StyledTableCell>
                    <StyledTableCell>{calTranObj.action_on}</StyledTableCell>
                    <StyledTableCell>{calTranObj.date_time}</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {auditList.map((row, i) => {
                    let user = users?.find((u) => u.id === row.userId)
                    let partyName = user && user.party ? user.party.name : ''
                    return (
                      <StyledTableRow key={i}>
                        <StyledTableCell component='th' scope='row'>
                          <b>{row.auditDescription}</b>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography>{row.userName}</Typography>
                          <Typography className={'greyLabel'}>
                            {user ? user.email : ''}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>{partyName}</StyledTableCell>
                        <StyledTableCell>
                          {row.auditModuleDisplayName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {Utils.formatDate(
                            row.createdDate,
                            Utils.FORMAT_DATE_TIME
                          )}
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          {i !== auditList.length - 1 && (
                            <CngSecondaryButton onClick={viewAudit(i)}>
                              <FontAwesomeIcon icon={faHistory} />
                            </CngSecondaryButton>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </CngGridItem>
      </Grid>
    </div>
  )
}

const initialValues = Object.freeze({})

const AuditTrailTableComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default AuditTrailTableComponent
