import { Box, Card, Grid, Chip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import Hazardous from 'src/components/button/Hazardous'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import DirectSI from './DirectSI'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const translatedTextsObject = ShippingInstructionTranslationText()
  const shipmentTypeKeyRef = useRef(uuid())
  const hazardousKeyRef = useRef(uuid())

  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    shipmentTypeKeyRef.current = uuid()
    hazardousKeyRef.current = uuid()

    console.log('shipment --> ' + props.bookingTypeData.shipmentType)
    setFieldValue('shipmentType', props.bookingTypeData.shipmentType)
    setFieldValue('hazardous', props.bookingTypeData.hazardous)

    setShouldRender(true)
  }, [])

  if (!shouldRender) {
    return null
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            <Grid container xs={12} sm={12}>
              <CngGridItem xs={6} sm={6}>
                {translatedTextsObject.bookingType}
              </CngGridItem>
              <CngGridItem xs={6} sm={6}>
                <Grid container xs={12} sm={12} justify='flex-end'>
                  {props.bookingTypeData.hazardous && (
                    <CngGridItem>
                      <Hazardous />
                    </CngGridItem>
                  )}
                  {props.bookingTypeData.directSi && (
                    <CngGridItem>
                      {/* <Chip
                        icon={<FileCopyOutlinedIcon />}
                        label={translatedTextsObject.directSI}
                        size='small'
                      /> */}
                      <DirectSI />
                    </CngGridItem>
                  )}
                </Grid>
              </CngGridItem>
            </Grid>
          </Box>

          <Box>
            <Grid container xs={12} sm={12}>
              <CngGridItem xs={6} sm={6}>
                <Box className='view-card-content'>
                  <Box className='view-card-content-label'>
                    {translatedTextsObject.shipmentType}
                  </Box>
                  <Box className='view-card-content-value'>
                    <CodeMasterViewField
                      codeType='CAL_CONT_STATUS'
                      code={props.bookingTypeData.shipmentType}
                    />
                  </Box>
                </Box>
              </CngGridItem>

              <CngGridItem xs={6} sm={6}>
                <LabelValueVertical
                  label={translatedTextsObject.referenceHeader}
                  value={props.bookingTypeData.bookingRefNo}
                />
              </CngGridItem>

              <CngGridItem xs={6} sm={6}>
                <LabelValueVertical
                  label={translatedTextsObject.fowardHubScheme}
                  value={props.bookingTypeData.fowardHubScheme}
                />
              </CngGridItem>

              <CngGridItem xs={6} sm={6}>
                <LabelValueVertical
                  label={translatedTextsObject.outboundReferenceNo}
                  value={props.bookingTypeData.forwardHubOutboundRefNo}
                />
              </CngGridItem>
            </Grid>
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({})

const ViewBookingTypeComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewBookingTypeComponent
