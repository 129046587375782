import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  Button
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import RemoveIcon from '@material-ui/icons/Clear'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'

import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import SIDocumentDetailsActionMenu from '../components/SIDocumentDetailsActionMenu'

import Paper from '@material-ui/core/Paper'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngDocumentAutocompleteField }
  }
} = components

const SIDocumentDetails = (props) => {
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { createRecord, fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()

  function Download(arrayBuffer, type) {
    var blob = new Blob([arrayBuffer], { type: type })
    console.log(blob)
    var url = URL.createObjectURL(blob)
    window.open(url)
  }

  useEffect(() => {
    console.log('file details change')

    var files = props.tile
    console.log(files)
  }, [props.tile])

  function testuploadDoc(array) {
    console.log('test upload')
    var formData = new FormData()
    formData.append('fileContents', array[0]) // name of the file upload box
    // formData.append("fileContents", array[1]); // name of the file upload box
    console.log(formData)

    createRecord.execute(
      FreightBookingApiUrls.DOCUMENT_UPLOAD,
      formData,
      onSuccess2,
      onError
    )

    function onSuccess2(response) {
      console.log('connection-request onSuccess2')
      // var headers = response.headers();
      console.log(response.length)

      // let bytes = new Uint8Array(response.length);

      // for (let i = 0; i < response.length; i++) {
      //   bytes[i] = response[i]
      // }

      var blob = array[0]
      console.log(blob)
      // let url = window.URL.createObjectURL(blob);
      // console.log(url)
      // window.open(url);
      var link = document.createElement('a')

      link.href = window.URL.createObjectURL(blob)
      link.download = 'Deployment Document of Calista Logistics (1).pdf'

      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    }
  }

  function testDownload() {
    console.log('test download')
    // var formData = new FormData();
    // formData.append("fileContent", array); // name of the file upload box
    // console.log(formData)

    createRecord.execute(
      FreightBookingApiUrls.DOCUMENT_DOWNLOAD,
      { id: 2045 },
      onSuccess,
      onError
    )
  }

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64)
    var binaryLen = binaryString.length
    var bytes = new Uint8Array(binaryLen)
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    return bytes
  }

  function onSuccess(response) {
    console.log('connection-request onSuccess')
    // var headers = response.headers();
    console.log(response.length)

    // let bytes = new Uint8Array(response.length);

    // for (let i = 0; i < response.length; i++) {
    //   bytes[i] = response[i]
    // }

    //let bytes = base64ToArrayBuffer(response)
    let bytes = new Uint8Array(response)

    var blob = new File(
      [bytes],
      'Deployment Document of Calista Logistics (1).pdf',
      { type: 'application/pdf' }
    )
    console.log(blob)
    // let url = window.URL.createObjectURL(blob);
    // console.log(url)
    // window.open(url);
    var link = document.createElement('a')

    link.href = window.URL.createObjectURL(blob)
    link.download = 'Deployment Document of Calista Logistics (1).pdf'

    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  function onError(error) {
    console.log('connection-request error', error.message)
  }

  const [anchorMoreActionEl, setAnchorMoreActionEl] = React.useState(null)
  const open = Boolean(anchorMoreActionEl)

  const handleMoreActionClick = (event) => {
    setAnchorMoreActionEl(event.currentTarget)
  }

  const handleMoreActionClose = () => {
    setAnchorMoreActionEl(null)
  }

  if (props.tile == null || props.tile.length <= 0) {
    return <br />
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id='table'>
          <TableBody>
            {props.tile.map((elem, i) => {
              return (
                <TableRow key={`row-${i}`}>
                  <TableCell style={{ width: '5%' }} className={`file-format`}>
                    <FileCopyIcon />
                  </TableCell>
                  <TableCell style={{ width: '25%' }}>
                    <DocumentInfoDecoratorComponent
                      name={elem.file.name}
                      size={elem.file.size}
                    />{' '}
                  </TableCell>

                  <TableCell style={{ width: '30%' }}>
                    <CngDocumentAutocompleteField
                      name={`pendingShippingInfoDocuments[${i}].docType`}
                      label={siTranslatedTextsObject.fileType + '*'}
                      onChange={(val, evt) =>
                        props.handleDropdownChange(val, evt, i, 'docType')
                      }
                      codeType='CLOGS_FRB'
                      error={props.getFieldError(
                        i,
                        `pendingShippingInfoDocuments[${i}].docType`
                      )}
                      helperText={props.getFieldError(
                        i,
                        `pendingShippingInfoDocuments[${i}].docType`
                      )}
                      key={props.docTypeKeyRef}
                    />

                    {/* 
                  <CngTextField
                    type='text'
                    name={`bookingDocs[${i}].fileType`}
                    label={siTranslatedTextsObject.fileType}
                    onChange={e => props.handleInputChange(e, i)}
                  /> */}
                  </TableCell>
                  <TableCell style={{ width: '30%' }}>
                    <CngTextField
                      type='text'
                      name={`pendingShippingInfoDocuments[${i}].description`}
                      label={siTranslatedTextsObject.fileDesc + '*'}
                      onChange={(e) => props.handleInputChange(e, i)}
                      inputProps={{ maxLength: 1024 }}
                      error={props.getFieldError(
                        i,
                        `pendingShippingInfoDocuments[${i}].description`
                      )}
                      helperText={props.getFieldError(
                        i,
                        `pendingShippingInfoDocuments[${i}].description`
                      )}
                    />
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    <SIDocumentDetailsActionMenu
                      id={i}
                      removeRowClick={props.removeRowClick(i)}
                      hideDownloadLog
                      hideEdit
                      hideDownload
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default SIDocumentDetails
