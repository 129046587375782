import {
    Box,
    Typography
  } from '@material-ui/core'
  
  import CheckboxGroup from './CheckboxGroup'
  import React from 'react'
  import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'

  
  const ShipperFilterComponents = (props) => {
    const translatedTextsObject = ShippingInstructionTranslationText()
  
    return (
      <section className={'cng-filter-pane--section-container'}>
        <header>
          <Box className={`cng-filter-pane--section-header-label-box`}>
            <Typography className={'cng-filter-pane--section-header-label'}>
              {translatedTextsObject.shipper}
            </Typography>
          </Box>
        </header>
        
        <article>
          <Box className={`cng-filter-pane--controls-wrapper`}>
            <CheckboxGroup
              checkBoxList={props.shippers}
              onChange={props.onShipperCheckBoxChange}
              filterName={''}
            />
          </Box>
        </article>
      </section>
    )
  }
  
  export default ShipperFilterComponents
  