import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Badge,
  Box,
  Card,
  Chip,
  Grid,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import AcUnit from '@material-ui/icons/AcUnit'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ManageScheduleTranslationText from '../ShippingInstructionTranslationText'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import Utils from 'src/views/common/utils/Utils'
import { faSnowflake } from '@fortawesome/pro-light-svg-icons'
import { hi } from 'date-fns/locale'
import roundToNearestMinutesWithOptions from 'date-fns/fp/roundToNearestMinutesWithOptions'
import { withStyles } from '@material-ui/core/styles'

const {
  form: {
    field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const { CodeMaintenanceType } = constants

const siBadgeWrapperStyle = makeStyles({
  root: {
    backgroundColor: '#ffe6ff',
    position: 'absolute',
    right: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  }
})

const FormBody = (props) => {
  const translatedTextsObjects = ManageScheduleTranslationText()
  const frbTranslatedTextsObject = FreightBookingTranslationText()
  const { fetchRecords } = useServices()
  const [containerTypes, setContainerTypes] = useState([])
  let siContainerList = props.containers
  const { auditSI } = props

  const siBadgeWrapperClasses = siBadgeWrapperStyle()

  console.log('ViewContainerDetailComponent containerList:', siContainerList)
  const getContainerTotalCount = () => {
    var totalCount = 0
    if (siContainerList != null && siContainerList.length > 0) {
      totalCount = siContainerList.length
    }

    return totalCount
  }

  function onContainerTypeLoadSuccess(data) {
    setContainerTypes(data)
    // const matched = data.find((container) => container?.isoCode === props.containerIsoType)
    // setContainerTypeMatched(matched)
  }

  useEffect(() => {
    SharedServiceApi.fetchContainerType(
      fetchRecords,
      false,
      onContainerTypeLoadSuccess
    )
  }, [props])

  const initialHighLight = [
    {
      id: 0,
      contNo: {},
      contIsoType: {},
      contHeightDesc: {},
      contTypeDesc: {},
      contSize: {},
      pkgType: {},
      pkgCnt: {},
      volume: {},
      sealNo: {},
      specialHandling: {},
      stowage: {},
      tareWeight: {},
      vgmWeight: {},
      container: {}
    }
  ]

  const [highlight, setHighlight] = useState(initialHighLight)

  useEffect(() => {
    if (auditSI && auditSI.shippingInfoContBoxDTOs) {
      let hl = SIUtils.auditHighLightContainer(
        auditSI.shippingInfoContBoxDTOs,
        siContainerList,
        containerTypes
      )
      setHighlight(hl)
      console.log('hl', hl)
    }
  }, [props, containerTypes])

  function getHighlight(id, field) {
    let matched = highlight.find((hl) => hl?.id == id)
    return Utils.isNotEmpty(matched) ? matched[field] : {}
  }

  const CustomizedBadge = withStyles(() => ({
    root: {
      height: '32px'
    },
    badge: {
      backgroundColor: 'white',
      color: '#1C1D21CC',
      borderStyle: 'solid',
      borderColor: '#EEEEEE',
      borderWidth: '1px',
      marginTop: '8px',

      width: '60px',
      height: '100%',
      padding: '0px',

      borderRadius: '16px',

      '& > label': {
        marginBottom: 0,
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingInline: '10px',

        paddingTop: '6px',
        paddingBottom: '6px',
        paddingBlock: '6px',
        fontSize: '16px',
        width: '100%',
        height: '100%',

        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center'
      },

      '&:hover': {
        backgroundColor: '#5E81F433'
      }
    }
  }))(Badge)

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {frbTranslatedTextsObject.containerDetails}
            <span className='view-card-header-info'>
              {' '}
              / {getContainerTotalCount()} {frbTranslatedTextsObject.containers}
            </span>
          </Box>

          <Box>
            {siContainerList.map((row, i) => {
              let matched = {}
              if (containerTypes != null) {
                matched = containerTypes.find(
                  (container) => container?.isoCode === row.containerIsoSizeType
                )
              }

              return (
                <ComponentWrapper
                  headerStyle='view-card-header-no-bg'
                  cardStyle={{ margin: 20 }}
                  backgroundStyle='inner-card-background'
                >
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    spacing={0}
                    alignItems='center'
                    style={{
                      height: '100%',
                      ...getHighlight(row.contBoxId, 'container')
                    }}
                  >
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      style={{
                        backgroundColor: '#5E81F405',
                        height: '100%',
                        position: 'relative'
                      }}
                    >
                      <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        style={{
                          height: '100%',
                          justifyContent: 'center'
                        }}
                      //  spacing={10}
                      >
                        <div
                          style={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '98%',
                            ...getHighlight(row.contBoxId, 'contIsoType')
                          }}
                        >
                          <Box className='view-card-content-label'>
                            {translatedTextsObjects.containerSizeTypeHeight}
                          </Box>

                          <Box className='view-card-content-value'>
                            <span style={highlight.contQty}>1</span> x{' '}
                            <span
                              className='bold-large-text'
                              style={highlight.contIsoType}
                            >
                              {matched === undefined ? '' : matched.isoCode}
                            </span>
                          </Box>

                          {/* Badge container --- Starts here */}
                          <div className={siBadgeWrapperClasses.root}>
                            {/* Badge for SOC - Shipper Owned Container */}
                            {row.shipperOwned && (
                              <CustomizedBadge
                                badgeContent={
                                  <Tooltip
                                    title={translatedTextsObjects.shipperOwned}
                                  >
                                    <label>
                                      {row.shipperOwned ? 'SOC' : null}
                                    </label>
                                  </Tooltip>
                                }
                                style={{ width: '100%' }}
                              ></CustomizedBadge>
                            )}

                            {/* Badge for Reefer */}
                            {row.reeferOwned && (
                              <CustomizedBadge
                                badgeContent={
                                  <Tooltip
                                    title={translatedTextsObjects.reeferOwned}
                                  >
                                    <label>
                                      <FontAwesomeIcon icon={faSnowflake} />
                                    </label>
                                  </Tooltip>
                                }
                                style={{ width: '100%' }}
                              ></CustomizedBadge>
                            )}
                          </div>
                          {/* Badge container --- Ends here */}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs sm></Grid>

                    <Grid item xs={8} sm={8}>
                      <Grid container xs={12} sm={12}>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'contNo')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.containerNo}
                            value={row.containerNo}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'contSize')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.size}
                            value={
                              matched === undefined ? '' : matched.contSize
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'contTypeDesc')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.type}
                            value={
                              matched === undefined ? '' : matched.contTypeDesc
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'contHeightDesc')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.heightType}
                            value={
                              matched === undefined
                                ? ''
                                : matched.contHeightDesc
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'sealNo')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.sealNo}
                            value={row.sealNo}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} alignContent='center'>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={getHighlight(row.contBoxId, 'pkgType')}
                          >
                            <Box className='view-card-content'>
                              <Grid container justify='space-between'>
                                <Grid item xs={12} sm={12}>
                                  <Box className='view-card-content-label'>
                                    {translatedTextsObjects.packageType}
                                  </Box>
                                </Grid>
                                <Box className='view-card-content-value'>
                                  <CodeMasterMiscViewField
                                    codeType={CodeMaintenanceType.PACKAGE_UOM}
                                    code={row.packageType}
                                  />
                                </Box>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'tareWeight')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.containerGrossWeight}
                            value={row.grossWeight}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'vgmWeight')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.totalVgmWeight}
                            value={row.totalVgmWeight}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'volume')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.volume}
                            value={row.volume}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={getHighlight(row.contBoxId, 'packageTypeNumber')}
                        >
                          <LabelValueVertical
                            label={translatedTextsObjects.packageTypeNumber}
                            value={row.pkgCnt}
                          />
                        </Grid>

                        {props.directSi && (
                          <Grid container>
                            <Grid item xs={12} sm={3} alignContent='center'>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={getHighlight(
                                  row.contBoxId,
                                  'specialHandling'
                                )}
                              >
                                <Box className='view-card-content'>
                                  <Grid container justify='space-between'>
                                    <Grid item xs={12} sm={12}>
                                      <Box className='view-card-content-label'>
                                        {translatedTextsObjects.specialHandling}
                                      </Box>
                                    </Grid>
                                    <Box className='view-card-content-value'>
                                      <CodeMasterViewField
                                        codeType={'CAL_CONT_HANDLING'}
                                        code={row.specialHandling}
                                      />
                                    </Box>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} alignContent='center'>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={getHighlight(row.contBoxId, 'stowage')}
                              >
                                <Box className='view-card-content'>
                                  <Grid container justify='space-between'>
                                    <Grid item xs={12} sm={12}>
                                      <Box className='view-card-content-label'>
                                        {translatedTextsObjects.stowage}
                                      </Box>
                                    </Grid>
                                    <Box className='view-card-content-value'>
                                      <CodeMasterViewField
                                        codeType={'CAL_CONT_STOWAGE'}
                                        code={row.stowage}
                                      />
                                    </Box>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {row.reeferAvailable && (
                          <>
                            {!row.nonActiveReefer ? (
                              <>
                                <Grid container xs={12} sm={12}>
                                  <Grid item
                                    xs={12}
                                    sm={3}>
                                    <LabelValueVertical
                                      label={translatedTextsObjects.nonActiveReefer}
                                      value='No'
                                    />
                                  </Grid>
                                  <Grid item
                                    xs={12}
                                    sm={3}>
                                    <LabelValueVertical
                                      label={translatedTextsObjects.equipmentTemp}
                                      value={row.equipmentTemp}
                                    />
                                  </Grid>
                                  <Grid item
                                    xs={12}
                                    sm={3}>
                                    <LabelValueVertical
                                      label={translatedTextsObjects.tempUom}
                                      value={row.tempUom}
                                    />
                                  </Grid>
                                  <Grid item
                                    xs={12}
                                    sm={3}>
                                    {row.equipmentAirFlow != null ?
                                      <LabelValueVertical label={translatedTextsObjects.equipmentAirFlow}
                                        value={` ${row.equipmentAirFlow} ${translatedTextsObjects.cbm}`} />
                                      :
                                      <LabelValueVertical label={translatedTextsObjects.equipmentAirFlow}
                                        value={row.equipmentAirFlow} />
                                    }
                                  </Grid>
                                </Grid>
                                <Grid container xs={12} sm={12}>
                                  <Grid item
                                    xs={12}
                                    sm={12}>
                                    <LabelValueVertical
                                      label={translatedTextsObjects.equipmentComments}
                                      value={row.equipmentComments}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )
                              :
                              (<Grid container xs={12} sm={12}>
                                <Grid item
                                  xs={12}
                                  sm={3}>
                                  <LabelValueVertical
                                    label={translatedTextsObjects.nonActiveReefer}
                                    value='Yes'
                                  />
                                </Grid>
                              </Grid>
                              )
                            }
                          </>)}
                      </Grid>
                    </Grid>
                  </Grid>
                </ComponentWrapper>
              )
            })}
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const ViewSIContainerComponent = Object.freeze({
  FormBody: FormBody
  // initialValues: initialValues
})

export default ViewSIContainerComponent
