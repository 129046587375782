import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import ReeferSettingsContext from './ReeferSettingsContext.js'
import EditReeferSettingsForm from './EditReeferSettingsForm.js'
import { components } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'

const { CngDialog } = components

function ReeferSettingsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentContainer
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = ShippingInstructionTranslationText()

  return (
    <ReeferSettingsContext.Provider
      value={{
        closeDialog,
        showNotification,
        currentContainer,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <EditReeferSettingsForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>
              {translatedTextsObject.container +
                (currentContainer + 1) +
                ' - ' +
                translatedTextsObject.reeferSettings}
            </b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
    </ReeferSettingsContext.Provider>
  )
}

export default ReeferSettingsDialog
