import {
    FormControlLabel,
    FormGroup,
  } from '@material-ui/core'
  import React, { useEffect, useState } from 'react'
  import { components } from 'cng-web-lib'
  
  const {
    form: {
      field: { CngTextField, CngSelectField, CngDateField, CngCheckboxField  }
    },
    CngGridItem
  } = components
  
  const { CngPackageUomAutocompleteField } = components.form.field
  
  const CheckboxGroup = (props) => {
  
    const [options, setOptions] = useState([])
  
    useEffect(() => {
      console.log('CHECKBOX CHECK', props.checkBoxList)
      setOptions(props.checkBoxList)
    })
  
    return (
      <>
        {options != null && (
          <FormGroup
            row={true}
            className={`cng-filter-criteria--chkbox-container`}
          >
            <ul className={`filter-criteria--all`}>
              {options
                .filter((option) => option.nameOth === 'ALL')
                .map((option, index) => (
                  <li key={option.carrierPartyId}>
                    <FormControlLabel
                      control={
                        <CngCheckboxField 
                          disabled={!option.eligible}
                          checked={
                            options.filter((option) => option.checked === true)
                              .length === options.length
                          }
                          indeterminate={
                            options.filter((option) => option.checked === true)
                              .length > 0 &&
                            options.filter((option) => option.checked === true)
                              .length < options.length
                          }
                          onChange={props.onChange(options.length - 1)}
                          inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                        />
                      }
                      label={`All ${props.filterName} `}
                    />
                  </li>
                ))}
            </ul>
            <ul className={`filter-criteria--others occupy-full-width`}>
              {options
                .filter((option) => option.nameOth !== 'ALL')
                .map((option, index) => (
                  <li  key={option.carrierPartyId}>
                    <FormControlLabel
                      control={
                        <CngCheckboxField 
                          value={option.partyId}
                          disabled={!option.eligible}
                          checked={option.checked}
                          onChange={props.onChange(index)}
                        />
                      }
                      label={`${option.name} (${option.bookingCount})`}
                    />
                  </li>
                ))}
            </ul>
          </FormGroup>
        )}
      </>
    )
  }
  
  export default CheckboxGroup
  