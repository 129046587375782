import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import ManageScheduleTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import PickupDetailComponent from 'src/views/freightbooking/components/PickupDetailComponent'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import CheckIcon from '@material-ui/icons/Check'

const {
  form: {
    adapter: {
      useFormAdapter: {useField, useFormikContext}
    }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const { shipmentData, showDetails, isCarrier, auditSI } = props
  const translatedTextsObjects = ManageScheduleTranslationText()

  const moveType = shipmentData.moveType
  const [, , { setValue: setPlaceOfReceiptCdeField }] =
    useField('placeOfReceiptCode')
  const [, , { setValue: setPlaceOfDeliveryCodeField }] = useField(
    'placeOfDeliveryCode'
  )

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const moveTypeKeyRef = useRef(uuid())

  const { setFieldValue } = useFormikContext()
  //const [shouldRender, setShouldRender] = useState(false)

  // useEffect(() => {
  //   setPlaceOfReceiptCdeField(shipmentData.placeOfReceiptCode)
  //   setPlaceOfDeliveryCodeField(shipmentData.placeOfDeliveryCode)

  //   placeOfReceiptKeyRef.current = uuid()
  //   placeOfDeliveryKeyRef.current = uuid()
  //   moveTypeKeyRef.current = uuid()

  //   setFieldValue('moveType', shipmentData.moveType)

  //   setShouldRender(true)
  // }, [])

  // if (!shouldRender) {
  //   return null
  // }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {translatedTextsObjects.shipment}
          </Box>
          <Box>
            <ComponentWrapper cardStyle={{ margin: 20 }}>
              <Grid container xs={12} sm={12}>
                <CngGridItem xs={3} sm={3}>
                  <Box
                    className='view-card-content'
                    style={{
                      ...Utils.auditHighLight(auditSI, ['moveType']),
                      display: 'inline-block'
                    }}
                  >
                    <Box className='view-card-content-label'>
                      {translatedTextsObjects.moveType}
                    </Box>
                    <Box className='view-card-content-value'>
                      <CodeMasterViewField
                        codeType='CAL_BK_MOVE_TYPE'
                        code={shipmentData.moveType}
                      />
                    </Box>
                  </Box>
                </CngGridItem>
                <CngGridItem xs={12} sm={9}>
                  <Grid container style={{ paddingRight: 20 }}>
                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, [
                          'placeOfReceiptCode'
                        ])}
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObjects.placeOfReceipt}
                        </Box>
                        <Box
                          className='view-card-content-value'
                          style={{ paddingRight: 0 }}
                        >
                          {/* using placeOfReceiptCodeField.value will cause data render issue due to initial field value is empty */}
                          {/* <PortViewField portCode={placeOfReceiptCodeField.value} /> */}
                          <PortViewField
                            portCode={shipmentData.placeOfReceiptCode}
                          />
                        </Box>
                      </Box>
                    </CngGridItem>

                    <CngGridItem xs sm>
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                      ></Grid>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditSI, [
                          'placeOfDeliveryCode'
                        ])}
                      >
                        <Box
                          className='view-card-content-label'
                          style={{ paddingLeft: 0 }}
                        >
                          {translatedTextsObjects.placeOfDelivery}
                        </Box>
                        <Box
                          className='view-card-content-value'
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          {/* using placeOfDeliveryCodeField.value will cause data render issue due to initial field value is empty */}
                          {/* <PortViewField portCode={placeOfDeliveryCodeField.value} /> */}
                          <PortViewField
                            portCode={shipmentData.placeOfDeliveryCode}
                          />
                        </Box>
                      </Box>
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
                <CngGridItem xs={12} sm={4}></CngGridItem>
                <Grid container xs={12} sm={8}>
                  <CngGridItem xs={12} sm={4}>
                    <Box
                      className='view-card-content'
                      style={Utils.auditHighLight(auditSI, ['cargoReadyDate'])}
                    >
                      <Box className='view-card-content-label'>
                        {translatedTextsObjects.cargoReadyDate}
                      </Box>
                      <Box className='view-card-content-value'>
                        {Utils.formatDate(
                          shipmentData.cargoReadyDate,
                          Utils.FORMAT_DATE,
                          Utils.DASH
                        )}
                      </Box>
                    </Box>
                  </CngGridItem>

                  <CngGridItem xs={2} sm={2}>
                    <Grid
                      container
                      justify='center'
                      alignItems='center'
                      style={{ height: '100%' }}
                    ></Grid>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                    <Box
                      className='view-card-content'
                      style={Utils.auditHighLight(auditSI, [
                        'cargoDeliveryDate'
                      ])}
                    >
                      <Box className='view-card-content-label'>
                        {translatedTextsObjects.cargoDeliveryDate}
                      </Box>
                      <Box className='view-card-content-value'>
                        {Utils.formatDate(
                          shipmentData.cargoDeliveryDate,
                          Utils.FORMAT_DATE,
                          Utils.DASH
                        )}
                      </Box>
                    </Box>
                  </CngGridItem>
                </Grid>
              </Grid>
            </ComponentWrapper>

            {showDetails && (
              <div>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObjects.placeOfReceiptDesc}
                      value={shipmentData.placeOfReceipt}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['originReceipt']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObjects.placeOfDeliveryDesc}
                      value={shipmentData.placeOfDelivery}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['destReceipt']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>

                  <CngGridItem item xs={4} sm={4}>
                    {/* <Box className='view-card-content-label'>
                      {translatedTextsObjects.termPor}
                    </Box>
                    <Box className='view-card-content-value'>
                      <CodeMasterViewField
                        codeType='CAL_CONT_CAR_STS'
                        code={shipmentData.porStatus}
                      />
                    </Box> */}
                    <Box
                      className='view-card-content'
                      style={{
                        ...Utils.auditHighLight(auditSI, ['porStatus']),
                        display: 'inline-block'
                      }}
                    >
                      <Grid container justify='space-between'>
                        <Grid item xs={12} sm={12}>
                          <Box className='view-card-content-label'>
                            {translatedTextsObjects.termPor}
                          </Box>
                        </Grid>
                        <Box className='view-card-content-value'>
                          <CodeMasterViewField
                            codeType='CAL_CONT_CAR_STS'
                            code={shipmentData.porStatus}
                          />
                        </Box>
                      </Grid>
                    </Box>
                  </CngGridItem>

                  <CngGridItem item xs={4} sm={4}>
                    <Box
                      className='view-card-content'
                      style={{
                        ...Utils.auditHighLight(auditSI, ['porStatus']),
                        display: 'inline-block'
                      }}
                    >
                      <Grid container justify='space-between'>
                        <Grid item xs={12} sm={12}>
                          <Box className='view-card-content-label'>
                            {translatedTextsObjects.termPod}
                          </Box>
                        </Grid>
                        <Box className='view-card-content-value'>
                          <CodeMasterViewField
                            codeType='CAL_CONT_CAR_STS'
                            code={shipmentData.podStatus}
                          />
                        </Box>
                      </Grid>
                    </Box>
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <Grid item xs={4} sm={4}></Grid>
                  <CngGridItem
                    xs={4}
                    sm={4}
                    style={{
                      ...Utils.auditHighLight(auditSI, ['alignCntrCargo']),
                      paddingLeft: 20
                    }}
                  >
                    {shipmentData.alignContainerWithCargo && (
                      <p>
                        <CheckIcon color='secondary' />
                        {translatedTextsObjects.alignContainerWithCargo}
                      </p>
                    )}
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObjects.placeOfReceiptETD}
                      value={Utils.formatDate(
                        shipmentData.placeOfReceiptEtd,
                        Utils.UI_FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['placeOfReceiptEtd']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObjects.placeOfDeliveryETA}
                      value={Utils.formatDate(
                        shipmentData.placeOfDeliveryEta,
                        Utils.UI_FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, [
                          'placeOfDeliveryEta'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>

                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>

                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObjects.destinationCode}
                      value={shipmentData.destCode}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['destCode']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>

                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObjects.finalDestination}
                      value={shipmentData.finalDest}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['finalDest']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={8} sm={8}>
                    <LabelValueVertical
                      label={translatedTextsObjects.shipperRemarks}
                      value={shipmentData.bookingShipperRemarks}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['shpRemarks']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={8} sm={8}>
                    <LabelValueVertical
                      label={translatedTextsObjects.carrierRemarks}
                      value={shipmentData.bookingCarrierRemarks}
                      boxStyle={{
                        ...Utils.auditHighLight(auditSI, ['carRemarks']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
              </div>
            )}
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({
  moveType: 'PTP' //myMoveType,
})

const ViewShipmentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewShipmentComponent
