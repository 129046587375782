import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngCheckboxField }
  }
} = components

const BLCheckboxGroup = (props) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(props.checkBoxList)
  })

  return (
    <FormControl component='fieldset'>
      <FormGroup row={true} className={`cng-filter-criteria--chkbox-container`}>
        <ul className={`filter-criteria--all`}>
          {options
            .filter((option) => option.statusDesc === 'ALL')
            // .slice(0)
            .map((option, index) => (
              <FormControlLabel
                key={options.length - 1}
                control={
                  <CngCheckboxField
                    disabled={!option.eligible}
                    checked={
                      options.filter((opt) => opt.checked === true).length ===
                      options.length
                    }
                    indeterminate={
                      options.filter((opt) => opt.checked === true).length >
                        0 &&
                      options.filter((opt) => opt.checked === true).length <
                        options.length
                    }
                    onChange={props.onChange(options.length - 1)}
                    inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                  />
                }
                label={`All ${props.filterName} `}
              />
            ))}
        </ul>
        <ul className={`filter-criteria--others`}>
          {options
            .filter((option) => option.statusDesc !== 'ALL')
            .map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <CngCheckboxField
                    value={option.statusCode}
                    // name={`statusCodes[${index}]`}
                    disabled={!option.eligible}
                    checked={option.checked}
                    onChange={props.onChange(index)}
                  />
                }
                label={`${option.statusDesc}`}
              />
            ))}
        </ul>
      </FormGroup>
    </FormControl>
  )
}

export default BLCheckboxGroup
