import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import Utils from 'src/views/common/utils/Utils'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDateField, CngDateTimeField }
  },
  CngGridItem
} = components

const { CngCodeMasterAutocompleteField } = components.form.field

const FormBody = (props) => {
  const frbTranslatedTextsObject = FreightBookingTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const [shipmentTypeExpanded, setShipmentTypeExpanded] = useState(true)
  // const countryCodeByPortCodeRef = useRef([])

  const nonDirectSiDraft = SIUtils.isNotDirectSiDraft(
    props.prevMenu,
    props.isDirectSI
  )
  const nonDirectSiCarrier = SIUtils.isNotDirectSiCarrier(
    props.isCarrier,
    props.isDirectSI
  )

  const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCodeField }] =
    useField('placeOfReceiptCode')
  const [, ,] = useField('placeOfDeliveryCode')
  const [, , { setValue: setPolCountryCodeField }] = useField('polCountryCode')
  const [, , { setValue: setPodCountryCodeField }] = useField('podCountryCode')
  const [placeOfReceiptField, ,] = useField('placeOfReceipt')
  const [placeOfDeliveryField, ,] = useField('placeOfDelivery')

  const [cargoReadyDateField, , { setValue: setCargoReadyDateField }] =
    useField('cargoReadyDate')
  const [moveTypeField, ,] = useField('moveType')
  const [showEmptyPickup, , { setValue: setShowEmptyPickupField }] =
    useField('showEmptyPickup')
  const [showShipFrom, , { setValue: setShowShipFromField }] =
    useField('showShipFrom')
  const [showShipTo, , { setValue: setShowShipToField }] =
    useField('showShipTo')
  const [cargoDeliveryDateField, , { setValue: setCargoDeliveryDateField }] =
    useField('cargoDeliveryDate')
  const [placeOfReceiptEtdField, , { setValue: setPlaceOfReceiptEtdField }] =
    useField('placeOfReceiptEtd')
  const [, , { setValue: setPlaceOfDeliveryEtaField }] =
    useField('placeOfDeliveryEta')
  //const [isEmptyPickupCompNameFilled, setIsEmptyPickupCompNameFilled] = useState(false)
  const [moveTypeKey, setMoveTypeKey] = useState()
  const [
    placeOfReceiptRequiredField,
    ,
    { setValue: setPlaceOfReceiptRequiredField }
  ] = useField('placeOfReceiptRequired')
  const [
    placeOfDeliveryRequiredField,
    ,
    { setValue: setPlaceOfDeliveryRequiredField }
  ] = useField('placeOfDeliveryRequired')

  const [alignContainerWithCargoField, ,] = useField('alignContainerWithCargo')
  useEffect(() => {
    if (moveTypeField != null) {
      setMandatoryFieldBaseOnMoveType(moveTypeField.value)
    }
    console.log(cargoReadyDateField.value)
    setMoveTypeKey(uuid())

    let newState = {
      alignContainerWithCargo: alignContainerWithCargoField.value
    }
    setState(newState)
  }, [props.isEdit, props.placeOfReceiptKeyRef])

  function setMandatoryFieldBaseOnMoveType(moveType) {
    setPlaceOfReceiptRequiredField(
      moveType === CalistaConstant.MOVE_TYPE_DOOR_TO_PORT ||
        moveType === CalistaConstant.MOVE_TYPE_DOOR_TO_DOOR
    )

    setPlaceOfDeliveryRequiredField(
      moveType === CalistaConstant.MOVE_TYPE_PORT_TO_DOOR ||
        moveType === CalistaConstant.MOVE_TYPE_DOOR_TO_DOOR
    )
  }

  const handleShipmentExpand = () => {
    setShipmentTypeExpanded(!shipmentTypeExpanded)
  }

  const onChangePlaceOfReceiptAutoComplete = (value, option) => {
    if (value != null) {
      // setPolCountryCodeField(countryCodeByPortCodeRef.current[value])
      props.setPlaceOfReceiptField(option.label)
    }
  }
  const onChangeplaceOfDeliveryAutoComplete = (value, option) => {
    if (value != null) {
      //  setPodCountryCodeField(countryCodeByPortCodeRef.current[value])
      props.setPlaceOfDeliveryField(option.label)
    }
  }

  function onMoveTypeChange(e) {
    setMandatoryFieldBaseOnMoveType(e)
  }

  const { setFieldValue } = useFormikContext()
  const handleChange = (event) => {
    console.log(event.target.name)
    console.log(event.target.checked)
    setFieldValue(event.target.name, event.target.checked)
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const [state, setState] = useState({
    alignContainerWithCargo: false
  })

  return (
    <Card>
      <Accordion expanded={shipmentTypeExpanded}>
        <AccordionSummary
          aria-controls='shipmentComponentContent'
          id='shipmentComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={frbTranslatedTextsObject.shipment}
            />
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <CngCodeMasterAutocompleteField
                  name='moveType'
                  label={siTranslatedTextsObject.moveType}
                  codeType='CAL_BK_MOVE_TYPE'
                  onChange={onMoveTypeChange}
                  key={moveTypeKey}
                  disabled={nonDirectSiDraft}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <PortSelectAutoComplete
                  name='placeOfReceiptCode'
                  label={
                    siTranslatedTextsObject.placeOfReceipt
                  }
                  keyRef={props.placeOfReceiptKeyRef}
                  value={props.placeOfReceiptCodeField.value}
                  onChangeAutoComplete={onChangePlaceOfReceiptAutoComplete}
                  disabled={nonDirectSiDraft}
                  required={placeOfReceiptRequiredField.value}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <PortSelectAutoComplete
                  name='placeOfDeliveryCode'
                  label={
                    siTranslatedTextsObject.placeOfDelivery
                  }
                  keyRef={props.placeOfDeliveryKeyRef}
                  value={props.placeOfDeliveryCodeField.value}
                  onChangeAutoComplete={onChangeplaceOfDeliveryAutoComplete}
                  disabled={nonDirectSiDraft}
                  required={placeOfDeliveryRequiredField.value}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateField
                  label={siTranslatedTextsObject.cargoReadyDate}
                  name='cargoReadyDate'
                  format={Utils.UI_FORMAT_DATE}
                  disabled={nonDirectSiDraft && nonDirectSiCarrier}
                  // shouldDisableDate={(date) => {
                  //   return moment(
                  //     moment().format(Utils.UI_FORMAT_DATE)
                  //   ).isAfter(moment(date))
                  // }}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateField
                  label={siTranslatedTextsObject.cargoDeliveryDate}
                  name='cargoDeliveryDate'
                  format={Utils.UI_FORMAT_DATE}
                  disabled={nonDirectSiDraft && nonDirectSiCarrier}
                  shouldDisableDate={(date) => {
                    return moment(
                      moment(cargoReadyDateField.value).format(
                        Utils.UI_FORMAT_DATE
                      )
                    ).isAfter(moment(date))
                  }}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}></CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='placeOfReceipt'
                  inputProps={{ maxLength: 36 }}
                  label={siTranslatedTextsObject.placeOfReceiptDesc}
                  disabled={props.isCarrier}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='placeOfDelivery'
                  inputProps={{ maxLength: 36 }}
                  label={siTranslatedTextsObject.placeOfDeliveryDesc}
                  disabled={props.isCarrier}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngCodeMasterAutocompleteField
                  name='porStatus'
                  label={siTranslatedTextsObject.term}
                  codeType={CalistaConstant.CAL_CONT_CAR_STS}
                  key={moveTypeKey}
                  disabled={props.isCarrier}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngCodeMasterAutocompleteField
                  name='podStatus'
                  label={siTranslatedTextsObject.term}
                  codeType={CalistaConstant.CAL_CONT_CAR_STS}
                  key={moveTypeKey}
                  disabled={props.isCarrier}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>

              <CngGridItem xs={8} sm={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='alignContainerWithCargo'
                      onChange={handleChange}
                      checked={state.alignContainerWithCargo}
                      disabled={props.isCarrier}
                    />
                  }
                  label={siTranslatedTextsObject.alignContainerWithCargo}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={siTranslatedTextsObject.placeOfReceiptETD}
                  name='placeOfReceiptEtd'
                  disabled
                  format={Utils.UI_FORMAT_DATE_TIME}
                  shouldDisableDate={(date) => {
                    return moment(
                      moment().format(Utils.UI_FORMAT_DATE)
                    ).isAfter(moment(date))
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={siTranslatedTextsObject.placeOfDeliveryETA}
                  name='placeOfDeliveryEta'
                  format={Utils.UI_FORMAT_DATE_TIME}
                  disabled
                  shouldDisableDate={(date) => {
                    return moment(
                      moment(placeOfReceiptEtdField.value).format(
                        Utils.UI_FORMAT_DATE
                      )
                    ).isAfter(moment(date))
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='destCode'
                  inputProps={{ maxLength: 10 }}
                  label={siTranslatedTextsObject.destinationCode}
                  disabled
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='finalDest'
                  inputProps={{ maxLength: 100 }}
                  label={siTranslatedTextsObject.finalDestination}
                  disabled
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={8} sm={8}>
                <CngTextField
                  type='text'
                  name='bookingCarrierRemarks'
                  label={siTranslatedTextsObject.carrierRemarks}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 1024 }}
                  disabled
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleShipmentExpand}
        expanded={shipmentTypeExpanded}
        footerText={siTranslatedTextsObject.additionalShipmentDetails}
      />
    </Card>
  )
}

const initialValues = {
  placeOfReceiptEtd: null,
  placeOfDeliveryEta: null,
  destCode: '',
  finalDest: '',
  bookingCarrierRemarks: '',

  //could be change due to direct si
  moveType: CalistaConstant.MOVE_TYPE_PORT_TO_PORT,
  placeOfReceiptCode: '',
  placeOfDeliveryCode: '',
  cargoReadyDate: null,
  cargoDeliveryDate: null,

  //from si
  porStatus: '',
  podStatus: '',
  alignContainerWithCargo: false,
  placeOfReceipt: '',
  placeOfDelivery: ''
}

const SIShipmentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIShipmentComponent
