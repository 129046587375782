import React from 'react'
import { components } from 'cng-web-lib'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function SurrenderBLButton(buttonProps) {
  const isPrimary = buttonProps.isPrimary
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <div>
      {isPrimary ? (
        <CngPrimaryButton {...buttonProps}>
          <EventAvailableIcon />
          {translatedTextsObject.surrenderBl}
        </CngPrimaryButton>
      ) : (
        <CngSecondaryButton {...buttonProps}>
          <EventAvailableIcon />
          {translatedTextsObject.surrenderBl}
        </CngSecondaryButton>
      )}
    </div>
  )
}

export default SurrenderBLButton
