import { Box, Card, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import ViewDGCargoDetailsDialog from 'src/views/freightbooking/components/ViewDGCargoDetailsDialog'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import FooterAddComponent from '../../common/ui/FooterAddComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import DGCargoDetailsDialog from 'src/views/freightbooking/components/DGCargoDetailsDialog'
import DGCargoDetailsTable from 'src/views/freightbooking/components/DGCargoDetailsTable'
import Utils from 'src/views/common/utils/Utils'
import SIParticularsControlTotalComponent from './SIParticularsControlTotalComponent'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import { useFieldArray } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngSelectField }
  },
  CngGridItem
} = components

const {
  component: {
    textField: {
      customType: { PLAIN_NUMBER }
    }
  }
} = constants

const { CngPackageUomAutocompleteField } = components.form.field

const { CodeMaintenanceType } = constants

const FormBody = (props) => {
  /*
        seqNo
    */
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const [currentDGCargoIdx, setCurrentDGCargoIdx] = useState()
  const [, ,] = useField('packageType')
  const { errors, touched } = useFormikContext()
  const [dgCargoList, setDGCargoList] = useState([])
  const [bookingDGCargoField, ,] = useField('bookingDGCargoes')
  const [previousContainerLength, setPreviousContainerLength] = useState()

  const setSIDGCargoField = (dgCargo) => {
    setFieldValue('shippingInfoDGCargoes', dgCargo)
    setRefreshDGCargo(refreshDGCargo + 1)
  }
  const { fields: siDGCargoField } = useFieldArray({
    name: 'shippingInfoDGCargoes'
  })
  const { fetchRecords } = useServices()
  const { setFieldValue } = useFormikContext()
  // const { watch } = useFormContext()
  // const test = watch('shippingInfoDGCargoes')
  // console.log('watch', test)
  const draftCargoId = useRef(-1)
  const translatedTextsObject = FreightBookingTranslationText()
  const [currentDGDetailsId, setCurrentDGDetailsId] = useState()
  const [isDGDetailsDialogOpen, setDGDetailsDialogOpen] = useState(false)
  const [isViewDGDetailsDialogOpen, setViewDGDetailsDialogOpen] =
    useState(false)
  const [currentDGDetails, setCurrentDGDetails] = useState()
  const DG_DETAILS_MAX_COUNT = 9
  // const [siContainersField, containersMeta, { setValue: setContainersField }] =
  //   useField('shippingInfoContainerBoxes')
  const { fields: siContainersField } = useFieldArray({
    name: 'shippingInfoContainerBoxes'
  })
  const [siContainerList, setSiContainerList] = useState([])

  const [containerNoItems, setContainerNoItems] = useState([])
  const [containerNoList, setContainerNoList] = useState([])
  const [uomItems, setUomItems] = useState([])
  const [packageUomItems, setPackageUomItems] = useState([])
  const [refreshDGCargo, setRefreshDGCargo] = useState(0)
  const cargoItem = {
    cargoTempId: -1, //for react key reference, actual field is "dgCargoId"
    id: uuid(),
    dgCargoId: null,
    description: '',
    hsCode: '',
    quantity: '',
    packageType: '',
    totalGrossVolume: '',
    totalGrossWeight: '',
    totalNetVolume: '',
    totalNetWeight: '',
    containerNo: []
  }

  const removeRowClick = (id) => (e) => {
    siDGCargoField.splice(id, 1)
    const newlist = [].concat([], siDGCargoField)
    setDGCargoList(newlist)
    setFieldValue('shippingInfoDGCargoes', newlist)
    e.stopPropagation()
  }

  const removeDGDetailsRow = (cargoIdx, detailsIdx) => (e) => {
    var newDGCargoList = [...dgCargoList]
    var newDGCargo = dgCargoList[cargoIdx]

    var newDGCargoDetails = newDGCargo.dgDetails
    newDGCargoDetails.splice(detailsIdx, 1)
    newDGCargo.dgDetails = newDGCargoDetails
    newDGCargoList[cargoIdx] = newDGCargo

    console.log(newDGCargoList)

    // setDGCargoList(newDGCargoList)

    let dgCargoListWithTotalValue =
      SIUtils.calculateTotalWeightVolume(newDGCargoList)
    setSIDGCargoField(dgCargoListWithTotalValue)
    setFieldValue('shippingInfoDGCargoes', dgCargoListWithTotalValue)

    e.stopPropagation()
  }

  const copyDGDetailsRow = (cargoIdx, detailsIdx) => (e) => {
    if (dgCargoDetailReachMax(cargoIdx)) {
      alert('you cannot add dg cargo details any more')
    } else {
      var newDGCargoList = [...dgCargoList]
      var newDGCargo = dgCargoList[cargoIdx]

      var newDGCargoDetails = newDGCargo.dgDetails

      var copyDGCargoDetails = newDGCargoDetails.slice(
        detailsIdx,
        detailsIdx + 1
      )

      console.log(copyDGCargoDetails)
      //if a dg cargo details exist in db is copied, its unique dg cargo details id will be copied also, which is wrong
      //hence need to reset it to the copied dg cargo
      const copy = copyDGCargoDetails.map((object) => ({
        ...object,
        dgCargoDetailsId: null
      }))

      newDGCargoDetails = newDGCargoDetails.concat(copy)
      console.log(newDGCargoDetails)
      newDGCargo.dgDetails = newDGCargoDetails
      newDGCargoList[cargoIdx] = newDGCargo
      console.log(newDGCargoList)

      let dgCargoListWithTotalValue =
        SIUtils.calculateTotalWeightVolume(newDGCargoList)

      setDGCargoList(dgCargoListWithTotalValue)
      setFieldValue('shippingInfoDGCargoes', dgCargoListWithTotalValue)
    }

    e.stopPropagation()
  }

  const handleDGDeailsRowClick = (dgCargoIdx, cargoDetailsIdx) => () => {
    console.log('handleCellClick')
    console.log(siDGCargoField)
    console.log(dgCargoIdx)
    console.log(cargoDetailsIdx)
    if (props.isCarrier) {
      setViewDGDetailsDialogOpen(true)
      setCurrentDGDetails(dgCargoList[dgCargoIdx].dgDetails[cargoDetailsIdx])
    } else {
      setDGDetailsDialogOpen(true)
    }

    setCurrentDGCargoIdx(dgCargoIdx)
    setCurrentDGDetailsId(cargoDetailsIdx)
  }

  const copyRowClick = (index) => () => {
    const list = [...siDGCargoField]

    const copyCargo = list.slice(index, index + 1)[0]
    //need to copy dg cargo without reference
    console.log('copyCargo', copyCargo)
    const copyCargoItem = {
      cargoId: draftCargoId.current,
      id: uuid(),
      containerNo: copyCargo.containerNo,
      description: copyCargo.description,
      quantity: copyCargo.quantity,
      hsCode: copyCargo.hsCode,
      packageType: copyCargo.packageType,
      totalGrossVolume: copyCargo.totalGrossVolume,
      totalGrossWeight: copyCargo.totalGrossWeight,
      totalNetVolume: copyCargo.totalNetVolume,
      totalNetWeight: copyCargo.totalNetWeight
    }
    if (copyCargo.dgDetails != null && copyCargo.dgDetails.length > 0) {
      var copyCargoDetails = copyCargo.dgDetails
      // console.log(copyCargoDetails)
      const copy = copyCargoDetails.map((object) => ({
        ...object,
        dgCargoDetailsId: null
      }))
      // console.log(copy)
      copyCargoItem.dgDetails = copy
    }

    // copyCargoItem.cargoId = draftCargoId.current
    draftCargoId.current = draftCargoId.current - 1

    setDGCargoList([...siDGCargoField, copyCargoItem])

    // setSIDGCargoField([...dgCargoList, copyCargoItem])
    setFieldValue('shippingInfoDGCargoes', [...siDGCargoField, copyCargoItem])
  }

  const handleAddCargo = () => {
    console.log('handleAddCargo')
    setDGCargoList([...dgCargoList, cargoItem])
    // setSIDGCargoField([...dgCargoList, cargoItem])
    setFieldValue('shippingInfoDGCargoes', [...dgCargoList, cargoItem])
  }

  function dgCargoDetailReachMax(cargoIdx) {
    var dgDetailsSize =
      dgCargoList[cargoIdx].dgDetails == null
        ? 0
        : dgCargoList[cargoIdx].dgDetails.length
    console.log('dgDetailsSize' + dgDetailsSize)
    return dgDetailsSize >= DG_DETAILS_MAX_COUNT
  }

  const handleAddDGCargoDetails = (idx) => () => {
    console.log('handleAddDGCargoDetails ' + idx)

    //check the dg details of this cargo

    if (dgCargoDetailReachMax(idx)) {
      alert('you cannot add dg cargo details any more')
    } else {
      //open a popup window for dg details
      setCurrentDGCargoIdx(idx)
      setCurrentDGDetailsId(null)
      setDGDetailsDialogOpen(true)
    }
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...siDGCargoField]

    let field = name.split('.')[1]
    let newValue = parseValueWhenInputChange(field, value)
    list[index][field] = newValue

    console.log('handleInputChangevalue', newValue)
    setDGCargoList(list)
    //setSIDGCargoField(list)
    console.log('handleInputChangedgcargo', list)
    //setFieldValue('shippingInfoDGCargoes', list)
    if (field === 'containerNo') {
      setFieldValue(`shippingInfoDGCargoes[${index}].${field}`, newValue)
    } else {
      setFieldValue(`shippingInfoDGCargoes`, list)
    }
  }

  function parseValueWhenInputChange(field, value) {
    if (field === 'containerNo') {
      let newValue = []
      if (value.length > 0) {
        console.log(containerNoList)
        console.log(value)
        newValue = value.filter(function (elem) {
          return containerNoList.includes(elem)
        })
        console.log(newValue)
      }
      return newValue
    } else {
      return value
    }
  }

  const handleDropdownChange = (val, index, name) => {
    const list = [...dgCargoList]

    list[index][name] = val

    //  setDGCargoList(list)
  }

  function closeDialog() {
    //before close the dialog, re-calculate the total weight/volume
    // calculateTotalWeightVolume()
    props.isCarrier
      ? setViewDGDetailsDialogOpen(false)
      : setDGDetailsDialogOpen(false)
  }

  function calculateTotalWeightVolume2(input) {
    console.log('calculateTotalWeightVolume', input)
    console.log(siDGCargoField)
    console.log('calculateTotalWeightVolume dgCargoList', siDGCargoField)
    console.log(siDGCargoField)
    const list = [...input]

    if (list != null) {
      list.forEach((dgcargo, id) => {
        let newTotalGrossWeight = 0
        let newTotalNetWeight = 0
        let newTotalGrossVolume = 0
        let newTotalNetVolume = 0
        let newQuantity = 0
        console.log('calculateTotalWeightVolume dgcargo', dgcargo)
        if (dgcargo != null && dgcargo.dgDetails != null) {
          dgcargo.dgDetails.forEach((dgdetails) => {
            console.log('calculateTotalWeightVolume dgdetails', dgdetails)
            newTotalNetWeight += parseNumber(dgdetails.netWeight)
            newTotalGrossVolume += parseNumber(dgdetails.grossVolume)
            newTotalGrossWeight += parseNumber(dgdetails.grossWeight)
            newTotalNetVolume += parseNumber(dgdetails.netVolume)
            newQuantity += parseNumber(dgdetails.quantity)
          })
        }
        console.log(
          'calculateTotalWeightVolume newTotalNetWeight',
          newTotalNetWeight
        )
        dgcargo.totalNetWeight = newTotalNetWeight
        dgcargo.totalGrossVolume = newTotalGrossVolume
        dgcargo.totalNetVolume = newTotalNetVolume
        dgcargo.totalGrossWeight = newTotalGrossWeight
        dgcargo.quantity = newQuantity
      })
    }

    console.log(
      'calculateTotalWeightVolume list',
      JSON.parse(JSON.stringify(list))
    )

    // setDGCargoList(list)
    //setFieldValue('shippingInfoDGCargoes', list)
    return list
  }

  function parseNumber(number) {
    return Utils.isEmpty(number) ? 0 : Number(number)
  }

  useEffect(() => {
    // if (dgCargoList != null) {
    //   calculateTotalWeightVolume()
    let newList = [...siDGCargoField]
    if (refreshDGCargo > 0) {
      let dgCargoListWithTotalValue =
        SIUtils.calculateTotalWeightVolume(newList)

      setFieldValue('shippingInfoDGCargoes', dgCargoListWithTotalValue)
      setDGCargoList(dgCargoListWithTotalValue)
    }
    // }
  }, [refreshDGCargo])

  useEffect(() => {
    console.log('dg cargo component props.isEdit > ' + props.isEdit)
    console.log('dg cargo component props.isDirectSI > ' + props.isDirectSI)

    setFieldValue('shippingInfoCargoes', [])

    /**
     * there are 3 scenarios.
     * 1. isEdit is false, then this is a new si
     *    1.1. directSI is false --> populate si list from booking
     *    1.2  directSI is true --> add one general cargo
     * 2. isEdit is true, then this is a created si
     *    no matter this is direct si or not, --> popullate si list from si field
     */

    const dgCargo = { ...cargoItem }
    dgCargo.cargoId = draftCargoId.current
    draftCargoId.current = draftCargoId.current - 1

    if (props.isEdit) {
      // setSiCargoList([...siCargoField.value])
      if (siDGCargoField.length === 0) {
        //when user switch from dg cargo, the cargo field could be empty
        createNewDGCargoList(dgCargo)
      } else {
        let cargoListWithExpanded = siDGCargoField.map((v) => ({
          ...v,
          containerNo: v.containerNo === null ? [] : v.containerNo //in case si without container
          //cargoBoxExpanded: false
        }))
        setDGCargoList(cargoListWithExpanded)
        setFieldValue('shippingInfoDGCargoes', cargoListWithExpanded)
      }
    } else {
      if (props.isDirectSI) {
        let cargoListWithExpanded = [cargoItem]

        setDGCargoList(cargoListWithExpanded)
        console.log(siDGCargoField)
        // setSIDGCargoField(cargoListWithExpanded)
        setFieldValue('shippingInfoDGCargoes', cargoListWithExpanded)
      } else {
        if (bookingDGCargoField.value != null) {
          let temp = bookingDGCargoField.value.map((v) => ({
            ...v,
            description: v.cargoDesc,
            containerNo: [],
            dgCargoId: null,
            dgDetails: v.dgDetails.map((detail) => ({
              ...detail,
              dgCargoDetailsId: null
            })),
            totalGrossWeight: v.grossWeight,
            totalGrossVolume: v.grossVolume,
            totalNetVolume: v.netVolume,
            totalNetWeight: v.netWeight
          }))

          console.log(temp)
          setDGCargoList(temp)
          // setSIDGCargoField(temp)
          setFieldValue('shippingInfoDGCargoes', temp)
        }
      }
    }

    //if user is carrier, need to pre-load uomitems and package uom item as the dg cargo section will become read-only
    if (props.isCarrier) {
      CodeMasterApi.fetchCodeMaster(
        fetchRecords,
        'CAL_DG_TEMP_UOM',
        false,
        onLoadDGTempUOMCodeSuccess
      )

      CodeMaintainanceApi.fetchCodeMaintainance(
        fetchRecords,
        CodeMaintenanceType.PACKAGE_UOM,
        [],
        false,
        onLoadPackageUomSuccess
      )
    }

    // if (props.isEdit) {
    //   console.log(bookingDGCargoField)

    //   // if cargoList is populated from review booking then set back to ContainerList to display
    //   // data population done in NewBookingFormProperties
    //   if (bookingDGCargoField.value == null) {
    //     //when user switch from dg cargo, the cargo field could be empty
    //     createNewDGCargoList(dgCargo)
    //   } else {
    //     let cargoListWithExpanded = bookingDGCargoField.value

    //     setDGCargoList(cargoListWithExpanded)
    //   }
    // } else {
    //   // for create new booking
    //   createNewDGCargoList(dgCargo)
    // }
  }, [props.isEdit, props.isDirectSI, bookingDGCargoField.value])

  function onLoadPackageUomSuccess(data) {
    console.log('onLoadPackageUomSuccess')
    console.log(data)

    if (data.length > 0) {
      setPackageUomItems(data)
    }
  }

  function onLoadDGTempUOMCodeSuccess(data) {
    console.log('onLoadCodeMasterSuccess')
    console.log(data)
    let optionList = []

    if (data.length > 0) {
      console.log(optionList)
      setUomItems(data)

      console.log(uomItems)
    }
  }

  const createNewDGCargoList = (dgCargo) => {
    setDGCargoList([dgCargo])
    // setSIDGCargoField([dgCargo])
    setFieldValue('shippingInfoDGCargoes', [dgCargo])
  }

  useEffect(() => {
    if (props.recalculate > 0) {
      let dgCargoListWithTotalValue =
        SIUtils.calculateTotalWeightVolume(siDGCargoField)
      setSIDGCargoField(dgCargoListWithTotalValue)
      setFieldValue('shippingInfoDGCargoes', dgCargoListWithTotalValue)
    }
  }, [props.recalculate])

  useEffect(() => {
    console.log('siContainersField', siContainersField)
    setSiContainerList(siContainersField)
    //set container no list for cargo to link
    let containerList = []
    let containerItems = []
    siContainersField.map((elem) => {
      let temp
      if (!Utils.isEmpty(elem.containerNo)) {
        temp = { text: elem.containerNo, value: elem.containerNo }
        containerList.push(elem.containerNo)
        return containerItems.push(temp)
      } else {
        return null
      }
    })
    console.log(containerItems)
    console.log(containerList)
    setContainerNoItems(containerItems)
    setContainerNoList(containerList)

    //check if cargo container no contains any deleted container no and remove them
    if (previousContainerLength > containerList.length) {
      const list = [...siDGCargoField]
      list.forEach((cargo) => {
        let containerNos = cargo.containerNo
        if (containerNos != null) {
          containerNos.forEach((containerNo, i) => {
            if (!containerList.includes(containerNo)) {
              containerNos.splice(i, 1)
            }
          })
        }
      })
    }
    setPreviousContainerLength(containerList.length)
  }, [siContainersField])

  const getFooterText = () => {
    return (
      <>
        {translatedTextsObject.addDGDetails}{' '}
        <b style={{ color: '#FF808B' }}>*</b>
      </>
    )
  }
  return (
    <Card>
      <Box px={2} my={1.5}>
        <Grid container>
          <AccordionHeaderComponent title={translatedTextsObject.dgCargo} />
        </Grid>

        {dgCargoList.map((m, idx) => {
          return (
            <Grid container xs={12} sm={12} spacing={2} key={m.id}>
              <CngGridItem xs={12} sm={12}>
                <Card>
                  <Box>
                    <Grid container className='accordian-header'>
                      <CngGridItem xs={6} sm={6}>
                        <Box p={1} fontSize='large' fontWeight='bolder'>
                          {translatedTextsObject.cargo + ' ' + (idx + 1)}
                        </Box>
                      </CngGridItem>
                      {!props.isCarrier && (
                        <Grid container xs={6} sm={6} justify='flex-end'>
                          {/* <Box> */}
                          <IconButton aria-label='copy'>
                            <FileCopyIcon onClick={copyRowClick(idx)} />
                          </IconButton>
                          <IconButton
                            aria-label='remove'
                            onClick={removeRowClick(idx)}
                            style={{
                              display:
                                dgCargoList.length > 1 ? 'inline' : 'none'
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {/* </Box> */}

                          {/* <Divider orientation='vertical' flexItem />
                        <Box p={2} fontSize='small' fontWeight='bold'>
                          {translatedTextsObject.cargoId +
                            ': ' +
                            (m.cargoId < 0 ? 'N/A' : m.dgCargoId)}
                        </Box> */}
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  <Card className='inner-card-group'>
                    <Grid container xs={12} sm={12} spacing={2}>
                      <CngGridItem xs={12} sm={12}>
                        <CngTextField
                          type='text'
                          name={`shippingInfoDGCargoes[${idx}].description`}
                          label={siTranslatedTextsObject.cargoDesc + '*'}
                          value={m.description}
                          multiline
                          rows={4}
                          inputProps={{ maxLength: 512 }}
                          onChange={(e) => handleInputChange(e, idx)}
                          // error={getFieldError(
                          //   idx,
                          //   'shippingInfoDGCargoes.description'
                          // )}
                          // helperText={getFieldError(
                          //   idx,
                          //   'shippingInfoDGCargoes.description'
                          // )}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={12}>
                        <CngSelectField
                          name={`shippingInfoDGCargoes[${idx}].containerNo`}
                          label={siTranslatedTextsObject.relatedContainerNos}
                          multiple
                          items={containerNoItems}
                          onChange={(e) => handleInputChange(e, idx)}
                        />
                      </CngGridItem>

                      <CngGridItem xs={4} sm={4}>
                        <CngTextField
                          type='number'
                          name={`shippingInfoDGCargoes[${idx}].quantity`}
                          label={siTranslatedTextsObject.quantity}
                          value={m.quantity}
                          inputProps={{ maxLength: 5 }}
                          onChange={(e) => handleInputChange(e, idx)}
                          disabled
                          customType={PLAIN_NUMBER}
                          // error={getFieldError(
                          //   idx,
                          //   `shippingInfoDGCargoes.quantity`
                          // )}
                          // helperText={getFieldError(
                          //   idx,
                          //   `shippingInfoDGCargoes.quantity`
                          // )}
                        />
                      </CngGridItem>

                      <CngGridItem xs={4} sm={4}>
                        <CngTextField
                          name={`shippingInfoDGCargoes[${idx}].hsCode`}
                          label={siTranslatedTextsObject.hsCode}
                          type='text'
                          value={m.hsCode}
                          inputProps={{ maxLength: 16 }}
                          onChange={(e) => handleInputChange(e, idx)}
                          disabled={props.isCarrier}
                          // error={getFieldError(
                          //   idx,
                          //   'shippingInfoDGCargoes.hsCode'
                          // )}
                          // helperText={getFieldError(
                          //   idx,
                          //   'shippingInfoDGCargoes.hsCode'
                          // )}
                        />
                      </CngGridItem>
                      <CngGridItem xs={4} sm={4}>
                        <CngPackageUomAutocompleteField
                          name={`shippingInfoDGCargoes[${idx}].packageType`}
                          label={siTranslatedTextsObject.packageType}
                          onChange={(val) =>
                            handleDropdownChange(val, idx, 'packageType')
                          }
                          disabled={props.isCarrier}
                        />
                      </CngGridItem>

                      <CngGridItem xs={2} sm={2}></CngGridItem>
                      <CngGridItem xs={10} sm={10}>
                        <Card className='inner-card-group'>
                          <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={2} sm={2}>
                              <Typography className='page-content-groupLabel text-upper'>
                                {siTranslatedTextsObject.gross}
                              </Typography>
                            </CngGridItem>
                            <CngGridItem xs={5} sm={5}>
                              <CngTextField
                                type='number'
                                name={`shippingInfoDGCargoes[${idx}].totalGrossVolume`}
                                label={siTranslatedTextsObject.grossVolume}
                                value={m.totalGrossVolume}
                                disabled
                                customType={PLAIN_NUMBER}
                                // error={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.grossVolume`
                                // )}
                                // helperText={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.grossVolume`
                                // )}
                                onChange={(e) => handleInputChange(e, idx)}
                              />
                            </CngGridItem>
                            <CngGridItem xs={5} sm={5}>
                              <CngTextField
                                type='number'
                                name={`shippingInfoDGCargoes[${idx}].totalGrossWeight`}
                                label={siTranslatedTextsObject.grossWeight}
                                value={m.totalGrossWeight}
                                disabled
                                customType={PLAIN_NUMBER}
                                // error={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.grossWeight`
                                // )}
                                // helperText={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.grossWeight`
                                // )}
                                onChange={(e) => handleInputChange(e, idx)}
                              />
                            </CngGridItem>
                          </Grid>
                        </Card>
                      </CngGridItem>

                      <CngGridItem xs={2} sm={2}></CngGridItem>
                      <CngGridItem xs={10} sm={10}>
                        <Card className='inner-card-group'>
                          <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={2} sm={2}>
                              <Typography className='page-content-groupLabel text-upper'>
                                {siTranslatedTextsObject.net}
                              </Typography>
                            </CngGridItem>
                            <CngGridItem xs={5} sm={5}>
                              <CngTextField
                                type='number'
                                name={`shippingInfoDGCargoes[${idx}].totalNetVolume`}
                                label={siTranslatedTextsObject.netVolume}
                                value={m.totalNetVolume}
                                disabled
                                customType={PLAIN_NUMBER}
                                // error={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.netVolume`
                                // )}
                                // helperText={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.netVolume`
                                // )}
                                onChange={(e) => handleInputChange(e, idx)}
                              />
                            </CngGridItem>
                            <CngGridItem xs={5} sm={5}>
                              <CngTextField
                                type='number'
                                name={`shippingInfoDGCargoes[${idx}].totalNetWeight`}
                                label={siTranslatedTextsObject.netWeight}
                                value={m.totalNetWeight}
                                disabled
                                customType={PLAIN_NUMBER}
                                // error={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.netWeight`
                                // )}
                                // helperText={props.getFieldError(
                                //   idx,
                                //   `shippingInfoCargoes.netWeight`
                                // )}
                                onChange={(e) => handleInputChange(e, idx)}
                              />
                            </CngGridItem>
                          </Grid>
                        </Card>
                      </CngGridItem>
                    </Grid>
                  </Card>

                  <DGCargoDetailsTable
                    cargoIdx={idx}
                    detailsList={m.dgDetails}
                    removeRowClick={removeDGDetailsRow}
                    copyRowClick={copyDGDetailsRow}
                    handleRowClick={handleDGDeailsRowClick}
                    editMode={!props.isCarrier}
                  />

                  {!props.isCarrier && (
                    <FooterAddComponent
                      handleAddChild={handleAddDGCargoDetails(idx)}
                      footerText={getFooterText()}
                    />
                  )}
                </Card>
              </CngGridItem>
            </Grid>
          )
        })}
      </Box>

      {!props.isCarrier && (
        <FooterAddComponent
          handleAddChild={handleAddCargo}
          footerText={translatedTextsObject.addCargo}
        />
      )}

      <DGCargoDetailsDialog
        isDialogOpen={isDGDetailsDialogOpen}
        closeDialog={closeDialog}
        currentDGCargoIdx={currentDGCargoIdx}
        dgCargoField={dgCargoList}
        setDGCargoField={setSIDGCargoField}
        showNotification={props.showNotification}
        currentDGDetailsId={currentDGDetailsId}
        showLocalCategory={props.showLocalCategory}
      />

      <ViewDGCargoDetailsDialog
        isDialogOpen={isViewDGDetailsDialogOpen}
        closeDialog={closeDialog}
        currentDGDetails={currentDGDetails}
        currentDGCargoIdx={currentDGCargoIdx}
        currentDGDetailsId={currentDGDetailsId}
        uomItems={uomItems}
        packageUomItems={packageUomItems}
      />

      <Box pt={5}>
        <SIParticularsControlTotalComponent
          cargoList={dgCargoList}
          containerList={siContainerList}
          isDGCargo
        />
      </Box>
    </Card>
  )
}

const initialValues = {
  shippingInfoDGCargoes: [
    {
      dgCargoId: '',
      description: '',
      hsCode: '',
      packageType: '',
      hsCode: '',
      quantity: '',
      packageType: '',
      totalGrossVolume: '',
      totalGrossWeight: '',
      totalNetVolume: '',
      totalNetWeight: '',
      dgDetails: []
    }
  ]
}

const SIDGCargoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIDGCargoComponent
