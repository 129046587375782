import Namespace from 'src/constants/locale/Namespace'
import { Yup } from 'cng-web-lib'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    closeShippingInfoUpdate: Yup.number().min(24, 'Min value 24 hours.').max(4320, 'Max value 4320 hours.'),
  })
}

export default makeValidationSchema
