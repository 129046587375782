import { Card, CardContent, Grid } from '@material-ui/core'

import BLApplicationFilterComponent from 'src/views/shippinginstruction/components/BLApplicationFilterComponent'
import CarrierFilterComponents from 'src/views/freightbooking/components/CarrierFilterComponent'
import DateRangeFilterComponent from 'src/views/freightbooking/components/DateRangeFilterComponent'
import FilterFooterComponent from 'src/views/freightbooking/components/FilterFooterComponent'
import FilterHeaderComponent from 'src/views/freightbooking/components/FilterHeaderComponent'
import ShipperFilterComponents from './ShipperFilterComponent'
import ForwardHubFilterComponents from './ForwardHubFilterComponents'
import React from 'react'
import SIStatusFilterComponent from './SIStatusFilterComponent'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSwitchField }
  },
  CngGridItem
} = components

function SIFilterPanelComponent(props) {
  return (
    <Grid md={12}>
      <Card>
        <CardContent className={`cng-filter-pane--wrapper`}>
          <FilterHeaderComponent
            handleClearAllButtonClick={props.handleClearAllButtonClick}
          />

          <DateRangeFilterComponent
            dateRange={props.dateRange}
            dateRangeChange={props.dateRangeChange}
          />

          {props.showStatus && (
            <SIStatusFilterComponent
              directOnly={props.directOnly}
              directOnlyCount={2}
              status={props.status}
              onStatusCheckBoxChange={props.onStatusCheckBoxChange}
              isCarrierManager={props.isCarrierManager}
              onMyTaskChange={props.onMyTaskChange}
            />
          )}

          {/* draft is for My shipping Instuction filter and draft tab filter */}
          {props.draft && (
            <BLApplicationFilterComponent
              bls={props.bls}
              onBLCheckBoxChange={props.onBLCheckBoxChange}
              directOnly={props.directOnly}
              directOnlyCount={2}
            />
          )}

          {!props.isCarrier && (
            <CarrierFilterComponents
              carriers={props.carriers}
              onCarrierCheckBoxChange={props.onCarrierCheckBoxChange}
            />
          )}

          {props.isCarrier && (
            <ShipperFilterComponents
              shippers={props.shippers}
              onShipperCheckBoxChange={props.onShipperCheckBoxChange}
            />
          )}

          {props.draft && (
            <FilterFooterComponent
              name='showDirectSI'
              label='Show Direct SI only'
              onToggleSwitchChange={props.showDirectSIChange}
            />
            // <CngSwitchField
            //   name='showDirectSI'
            //   label='Show Direct SI only'
            //   onChange={props.showDirectSIChange}
            // />
          )}

          {props.draft && (
            <FilterFooterComponent
              name='showArchived'
              label='Show Archived'
              onToggleSwitchChange={props.showArchivedChange}
            />
            // <CngSwitchField
            //   name='showArchived'
            //   label='Show Archived'
            //   onChange={props.showArchivedChange}
            // />
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SIFilterPanelComponent
