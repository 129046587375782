import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import NoteAltIcon from '@material-ui/icons/NoteOutlined'
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined'
import ViewSiIcon from '@material-ui/icons/VisibilityOutlined'
import CreateSiIcon from '@material-ui/icons/OpenInNew'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'
import BookingActionMenuItem from 'src/views/freightbooking/components/BookingActionMenuItem'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import PrintIcon from '@material-ui/icons/Print'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CachedIcon from '@material-ui/icons/Cached'
import { faRetweetAlt } from '@fortawesome/pro-light-svg-icons'
import { faArchive } from '@fortawesome/pro-solid-svg-icons'
const copyIcon = <FileCopyIcon fontSize='small' />
const cancelIcon = <CancelIcon fontSize='small' />
const deleteIcon = <DeleteIcon fontSize='small' />
const amendIcon = <EditIcon fontSize='small' />
const createPermitIcon = <NoteAltIcon fontSize='small' />
const archiveIcon = <FontAwesomeIcon icon={faArchive} fontSize='small' />
const approveIcon = <CheckCircleOutlineIcon fontSize='small' />
const rejectIcon = <FontAwesomeIcon icon={faRetweetAlt} fontSize='small' />
const trackContainersIcon = <FontAwesomeIcon icon={faRoute} fontSize='small' />
const printIcon = <PrintIcon fontSize='small' />
const surrenderBLIcon = <EventAvailableIcon fontSize='small' />

const ActionMenuSIList = (props) => {
  const { actions, eventHandlers, si, page, isCarrier } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const translationObject = CalistaUiComponentTranslationText()
  const siTranslationObject = ShippingInstructionTranslationText()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const printBLinList = () => {
    setAnchorEl(null)
    eventHandlers.printBL()
  }

  return (
    <Box>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={'moreMenuBtn'}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      {actions.length > 0 && (
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {actions.map((action, idx) => {
            switch (action) {
              case SIUtils.Action.printbl:
                return (
                  <BookingActionMenuItem
                    icon={printIcon}
                    action={printBLinList}
                    label={siTranslationObject.printBl}
                  />
                )

              case SIUtils.Action.approve:
                return (
                  <BookingActionMenuItem
                    icon={approveIcon}
                    action={eventHandlers.approveSI}
                    label={siTranslationObject.approveSI}
                  />
                )

              case SIUtils.Action.reject:
                return (
                  <BookingActionMenuItem
                    icon={rejectIcon}
                    action={eventHandlers.rejectSI}
                    label={siTranslationObject.rejectSI}
                  />
                )

              case SIUtils.Action.surrenderbl:
                return (
                  <BookingActionMenuItem
                    icon={surrenderBLIcon}
                    action={eventHandlers.surrenderBL}
                    label={siTranslationObject.surrenderBl}
                  />
                )

              case SIUtils.Action.copy:
                return (
                  <BookingActionMenuItem
                    icon={copyIcon}
                    label={translationObject.copy}
                    action={eventHandlers.copySI}
                  />
                )
              case SIUtils.Action.cancel:
                return (
                  <BookingActionMenuItem
                    icon={cancelIcon}
                    action={eventHandlers.cancelSI}
                    label={translationObject.cancel}
                  />
                )
              case SIUtils.Action.delete:
                return (
                  <BookingActionMenuItem
                    icon={deleteIcon}
                    action={eventHandlers.deleteSI}
                    label={translationObject.deleteText}
                  />
                )

              case SIUtils.Action.edit:
                return (
                  <BookingActionMenuItem
                    icon={amendIcon}
                    action={eventHandlers.editSIg(page)}
                    label={translationObject.edit}
                  />
                )
              case SIUtils.Action.archive:
                return (isCarrier ? !si.carrierClosed : !si.closed) ? (
                  <BookingActionMenuItem
                    icon={archiveIcon}
                    action={eventHandlers.archiveSI}
                    label={translationObject.archive}
                  />
                ) : (
                  <Box pr={2}></Box>
                )

              case SIUtils.Action.createPermit:
                return (
                  SIUtils.ableToCreatePermit(si) && (
                    <BookingActionMenuItem
                      icon={createPermitIcon}
                      action={eventHandlers.createPermit(page)}
                      label={translationObject.createPermit}
                    />
                  )
                )

              case SIUtils.Action.manage:
                return (
                  <BookingActionMenuItem
                    icon={amendIcon}
                    action={eventHandlers.manageSI(page)}
                    label={translationObject.manage}
                  />
                )

              case SIUtils.Action.update:
                return (
                  <BookingActionMenuItem
                    icon={amendIcon}
                    action={eventHandlers.updateSI(page)}
                    label={translationObject.update}
                  />
                )

              case SIUtils.Action.issue:
                return (
                  <BookingActionMenuItem
                    icon={approveIcon}
                    action={eventHandlers.issueBL}
                    label={siTranslationObject.issueBL}
                  />
                )

              case SIUtils.Action.revoke:
                return (
                  <BookingActionMenuItem
                    icon={rejectIcon}
                    action={eventHandlers.revokeBL}
                    label={siTranslationObject.revokeBL}
                  />
                )

              case SIUtils.Action.revokeIssuedBL:
                return (
                  <BookingActionMenuItem
                    icon={rejectIcon}
                    action={eventHandlers.revokeIssuedBL}
                    label={siTranslationObject.revokeIssuedBL}
                  />
                )

              case SIUtils.Action.retract:
                return (
                  <BookingActionMenuItem
                    icon={rejectIcon}
                    action={eventHandlers.retractSI}
                    label={siTranslationObject.retractSI}
                  />
                )

              default:
                return <div></div>
            }
          })}
        </Menu>
      )}
    </Box>
  )
}

export default ActionMenuSIList
