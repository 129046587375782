import { Box, Card, Grid, Chip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import ManageScheduleTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import CheckIcon from '@material-ui/icons/Check'
import CountryIconViewField from 'src/components/field/CountryIconViewField'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngCheckboxField, CngChipInputField }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const { contactData, auditSI } = props
  const translatedTextsObjects = ManageScheduleTranslationText()

  const [, , { setValue: setPlaceOfReceiptCdeField }] =
    useField('placeOfReceiptCode')
  const [, , { setValue: setPlaceOfDeliveryCodeField }] = useField(
    'placeOfDeliveryCode'
  )

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const moveTypeKeyRef = useRef(uuid())

  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    // setMoveTypeField(contactData.moveType)
    setPlaceOfReceiptCdeField(contactData.placeOfReceiptCode)
    setPlaceOfDeliveryCodeField(contactData.placeOfDeliveryCode)

    placeOfReceiptKeyRef.current = uuid()
    placeOfDeliveryKeyRef.current = uuid()
    moveTypeKeyRef.current = uuid()

    setFieldValue('moveType', contactData.moveType)

    setShouldRender(true)
  }, [])

  if (!shouldRender) {
    return null
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {translatedTextsObjects.contactDetails}
          </Box>
          <Box>
            <Grid container xs={12} sm={12} spacing={0}>
              <CngGridItem xs={4} sm={4}>
                <LabelValueVertical
                  label={translatedTextsObjects.representativeName}
                  value={contactData.representativeName}
                  boxStyle={{
                    ...Utils.auditHighLight(auditSI, ['repName']),
                    display: 'inline-block'
                  }}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <LabelValueVertical
                  label={translatedTextsObjects.representativeEmail}
                  value={contactData.representativeEmail}
                  boxStyle={{
                    ...Utils.auditHighLight(auditSI, ['repEmail']),
                    display: 'inline-block'
                  }}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <LabelValueVertical
                  label={translatedTextsObjects.representativeContactNumber}
                  value={contactData.representativeContactNo}
                  boxStyle={{
                    ...Utils.auditHighLight(auditSI, ['repContactNo']),
                    display: 'inline-block'
                  }}
                />
              </CngGridItem>
            </Grid>

            <ComponentWrapper
              cardStyle={{ margin: 20 }}
              backgroundStyle='inner-card-background'
            >
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm>
                  <Box
                    className={'view-card-header-no-bg'}
                    style={{ color: '#8e98b0' }}
                  >
                    <div> {translatedTextsObjects.references}</div>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Box
                    className='view-card-content'
                    style={{
                      ...Utils.auditHighLight(auditSI, ['blApplnType']),
                      display: 'inline-block'
                    }}
                  >
                    <Grid container justify='space-between'>
                      <Grid item xs={12} sm={12}>
                        <Box className='view-card-content-label'>
                          {translatedTextsObjects.blApplicationType}
                        </Box>
                      </Grid>
                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_BL_TYPE'
                          code={contactData.billOfLadingAppType}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.blNo}
                    value={contactData.billOfLadingNo}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['billOfLadingNo']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs={4} sm={4}></Grid>
                <CngGridItem
                  xs={4}
                  sm={4}
                  style={{
                    ...Utils.auditHighLight(auditSI, ['surrenderBl']),
                    paddingLeft: 20
                  }}
                >
                  {contactData.surrenderBl && (
                    <p>
                      <CheckIcon color='secondary' />
                      {translatedTextsObjects.surrenderBl}
                    </p>
                  )}
                </CngGridItem>
              </Grid>

              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>
                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.noOfOriginals}
                    value={contactData.noOfOriginals}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['originalNo']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.purchaseOrderNumber}
                    value={contactData.commonRef}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['commonRef']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>
                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.shipperRefNumber}
                    value={contactData.shipperRefNo}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.forwarderRefNumber}
                    value={contactData.forwarderRefNo}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>
                <Grid item xs={8} sm={8}>
                  <LabelValueVertical
                    label={translatedTextsObjects.nvoccRefNumber}
                    value={contactData.nvoccRefNumber}
                  />
                </Grid>
              </Grid>
            </ComponentWrapper>

            <ComponentWrapper
              cardStyle={{ margin: 20 }}
              backgroundStyle='inner-card-background'
            >
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm>
                  <Box
                    className={'view-card-header-no-bg'}
                    style={{ color: '#8e98b0' }}
                  >
                    <div> {translatedTextsObjects.additionalBLDetails}</div>
                  </Box>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.shippedOnBoardDateTime}
                    value={Utils.formatDate(
                      contactData.shippedOnBoardDate,
                      Utils.UI_FORMAT_DATE_TIME,
                      Utils.DASH
                    )}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['shipOnBoardDate']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.noOfContainersPackagesInWords}
                    value={contactData.containerPackage}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['cntrPkg']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.shipperRemarks}
                    value={contactData.shipperRemarks}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['shipperRemarks']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.issueDateTime}
                    value={Utils.formatDate(
                      contactData.issueDate,
                      Utils.UI_FORMAT_DATE_TIME,
                      Utils.DASH
                    )}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['issueDate']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <Box
                    className='view-card-content'
                    style={Utils.auditHighLight(auditSI, ['placeOfIssue'])}
                  >
                    <Box className='view-card-content-label'>
                      {translatedTextsObjects.issuePlace}
                    </Box>
                    <Box
                      className='view-card-content-value'
                      style={{ paddingRight: 0 }}
                    >
                      <CountryIconViewField
                        countryCode={contactData.placeOfIssue}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.carrierRemarks}
                    value={contactData.carrierRemarks}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['carrierRemarks']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <Box
                    className='view-card-content'
                    style={{
                      ...Utils.auditHighLight(auditSI, ['carrierClauseCode']),
                      display: 'inline-block'
                    }}
                  >
                    <Box className='view-card-content-label'>
                      {translatedTextsObjects.carrierClause}
                    </Box>
                    <Box className='view-card-content-value'>
                      <CodeMasterViewField
                        codeType='CAL_CARRIER_CLAUSE'
                        code={contactData.carrierClauseCode}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.carrierClauseDesc}
                    value={contactData.carrierClauseDesc}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['carrierClauseDesc']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>

                <Grid item xs={4} sm={4}>
                  <Box
                    className='view-card-content'
                    style={{
                      ...Utils.auditHighLight(auditSI, ['formLayoutName']),
                      display: 'inline-block'
                    }}
                  >
                    <Grid container justify='space-between'>
                      <Grid item xs={12} sm={12}>
                        <Box className='view-card-content-label'>
                          {translatedTextsObjects.documentLayout}
                        </Box>
                      </Grid>
                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_FORM_TYPE'
                          code={contactData.formLayout}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.freightCharge}
                    value={contactData.freightCharge}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['freightCharge']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.depot}
                    value={contactData.depot}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['depot']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>
            </ComponentWrapper>

            <ComponentWrapper
              cardStyle={{ margin: 20 }}
              backgroundStyle='inner-card-background'
            >
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm>
                  <Box
                    className={'view-card-header-no-bg'}
                    style={{ color: '#8e98b0' }}
                  >
                    <div> {translatedTextsObjects.parties}</div>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.shipperName}
                    value={contactData.shipperName}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['shpName']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.shipperAddress}
                    value={contactData.shipperAddress}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['shpAddress']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs={4} sm={4}></Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.consigneeName}
                    value={contactData.consigneeName}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['conName']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.consigneeAddress}
                    value={contactData.consigneeAddress}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['conAddress']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <CngGridItem xs sm></CngGridItem>
                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.usccNo}
                    value={contactData.usccNumber}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['usccNo']),
                      display: 'inline-block'
                    }}
                  />
                </CngGridItem>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>
                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.notifyPartyName}
                    value={contactData.destNotifyName}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['dstNotifyName']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.notifyPartyAddress}
                    value={contactData.destNotifyAddress}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['dstNotifyAddress']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs={4} sm={4}></Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.agentName}
                    value={contactData.agentName}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['agtName']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.agentAddress}
                    value={contactData.agentAddress}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['agtAddress']),
                      display: 'inline-block'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs={4} sm={4}></Grid>
                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObjects.bookingRepresentativeDetails}
                    value={contactData.bookingRepresentativeDetail}
                    boxStyle={{
                      ...Utils.auditHighLight(auditSI, ['bkgRep']),
                      display: 'inline-block'
                    }}
                  />
                </CngGridItem>
              </Grid>
            </ComponentWrapper>

            <ComponentWrapper
              cardStyle={{ margin: 20 }}
              backgroundStyle='inner-card-background'
            >
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs={4} sm={4}>
                  <Box
                    className={'view-card-header-no-bg'}
                    style={{ color: '#8e98b0' }}
                  >
                    <div> {translatedTextsObjects.partnerEmail}</div>
                  </Box>
                </Grid>

                <CngGridItem
                  item
                  xs={8}
                  sm={8}
                  style={{
                    ...Utils.auditHighLight(auditSI, ['partnerEmails']),
                    paddingLeft: 20
                  }}
                >
                  <Box className='view-card-content'>
                    <Box className='view-card-content-label'>
                      {translatedTextsObjects.emailToNotify}
                    </Box>
                    <Box
                      className='view-card-content-value'
                      style={{ paddingRight: 0 }}
                    >
                      <CngChipInputField
                        disabled
                        value={contactData.partnerEmails}
                      />
                    </Box>
                  </Box>
                </CngGridItem>
              </Grid>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid item xs sm></Grid>

                <CngGridItem
                  xs={8}
                  sm={8}
                  style={{ paddingLeft: 20 }}
                  style={{
                    ...Utils.auditHighLight(auditSI, ['notifyPartnerEmails']),
                    paddingLeft: 20
                  }}
                >
                  <CngCheckboxField
                    name='notifyPartnerEmails'
                    checked={
                      JSON.stringify(contactData.notifyPartnerEmails) == 'true'
                        ? true
                        : false
                    }
                    label={translatedTextsObjects.notifyBookingStatus}
                    disabled={true}
                  />
                  <CngCheckboxField
                    name='notifyEventMilestonesEmail'
                    checked={
                       JSON.stringify(contactData.notifyEventMilestonesEmail) == 'true'
                         ? true
                         : false
                    }
                    label={translatedTextsObjects.notifyEventMilestones}
                    disabled={true}
                  />
                </CngGridItem>
              </Grid>
            </ComponentWrapper>
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({
  moveType: 'PTP' //myMoveType,
})

const ViewContactComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewContactComponent
