import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@material-ui/core'

import BLCheckboxGroup from 'src/views/shippinginstruction/components/BLCheckboxGroup'
import CheckboxGroup from 'src/views/freightbooking/components/CheckboxGroup'
import ManageScheduleTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import React from 'react'

const BLApplicationFilterComponent = (props) => {
  const translatedTextsObject = ManageScheduleTranslationText()

  return (
    <section className={'cng-filter-pane--section-container'}>
      <header>
        <Box className={`cng-filter-pane--section-header-label-box`}>
          <Typography class={'cng-filter-pane--section-header-label'}>
            {translatedTextsObject.blApplicationType}
          </Typography>
        </Box>
      </header>

      <article>
        <Box className={`cng-filter-pane--controls-wrapper`}>
          <BLCheckboxGroup
            checkBoxList={props.bls}
            onChange={props.onBLCheckBoxChange}
            filterName={''}
          />
        </Box>
      </article>
    </section>
  )
}

export default BLApplicationFilterComponent
