import { Box, Button, Grid } from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import * as calistaConstant from 'src/constants/CalistaConstant'
function EditSIButtonComponent(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()

  const status = props.siStatus || 0
  console.log('props.prevMenu:' + props.prevMenu)
  const SaveDraftButton = () => {
    if (
      status.toString() === calistaConstant.SI_STATUS_SI_CONFIRMED &&
      props.prevMenu === SIUtils.Menus.manageSI
    ) {
      return <Box pr={2}></Box>
    } else {
      let buttonText = ''
      switch (status.toString()) {
        case '0':
          buttonText = translatedTextsObject.saveDraft
          break
        case calistaConstant.SI_STATUS_PENDING_SUBMISSION:
          buttonText = translatedTextsObject.saveChangesToDrafts
          break
        default:
          buttonText = translatedTextsObject.saveChanges
          break
      }

      return (
        <Box pr={2}>
          <Button
            variant='contained'
            className='button-blue originalText'
            onClick={() => {
              props.onSaveDraft()
            }}
          >
            {buttonText}
          </Button>
        </Box>
      )
    }
  }
  return (
    <Box pl={5} pr={5} pt={4} pb={4}>
      <Grid container xs={12} sm={12}>
        <Grid container xs={6} sm={6} justify='flex-start'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              props.onDiscard()
            }}
            classes={{ root: 'ng-button-filled-secondary' }}
          >
            {translatedTextsObject.discard}
          </Button>
        </Grid>
        <Grid container xs={6} sm={6} justify='flex-end'>
          <SaveDraftButton />
          {/* <Box pr={2}>
            {!Utils.isEmpty(props.siStatus) &&
              props.siStatus.toString() !==
                calistaConstant.SI_STATUS_SI_CONFIRMED && (
                <Button
                  variant='contained'
                  className='button-blue originalText'
                  onClick={() => {
                    props.onSaveDraft()
                  }}
                >
                  {Utils.isEmpty(props.siStatus)
                    ? translatedTextsObject.saveDraft
                    : props.siStatus.toString() ===
                      calistaConstant.SI_STATUS_PENDING_SUBMISSION
                    ? translatedTextsObject.saveChangesToDrafts
                    : translatedTextsObject.saveChanges}
                </Button>
              )}
          </Box> */}
          <Box>
            <Button
              variant='contained'
              className='button-light-green originalText'
              onClick={() => {
                props.onConfirmReview()
              }}
            >
              {translatedTextsObject.confirmReview}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditSIButtonComponent
