import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Paper
} from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import SIDocumentDetailsActionMenu from '../components/SIDocumentDetailsActionMenu'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDocumentAutocompleteField }
  },
  CngGridItem
} = components

const SIUploadDetailsComponents = (props) => {
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { createRecord, fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()
  console.log('props.tile', props.tile)
  useEffect(() => {
    console.log('file details change 2')

    var files = props.tile
    console.log(files)
  }, [props.tile])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    console.log('handle close')
    setAnchorEl(null)
  }

  console.log('UploadedSI', props.tile)

  function isSuccess(elem) {
    return elem.uploadSuccess || elem.status === 'Success'
  }

  if (props.tile == null || props.tile.length <= 0) {
    return <br />
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id='table'>
          <TableBody>
            {props.tile.map((elem, i) => {
              return (
                <TableRow key={`row-${i}`}>
                  <TableCell style={{ width: '5%' }}>
                    {' '}
                    <FileCopyIcon />
                  </TableCell>
                  <TableCell style={{ width: '70%' }}>
                    <DocumentInfoDecoratorComponent
                      name={elem.fileName}
                      size={elem.fileSize}
                      displayDate={elem.createdDate}
                    />
                  </TableCell>
                  {!props.viewOnly && (
                    <TableCell style={{ width: '15%' }}>
                      <Box
                        style={{
                          display: isSuccess(elem) ? 'inline' : 'none'
                        }}
                      >
                        <CngGridItem>
                          <Typography
                            style={{ color: '#B8F057' }}
                            variant='caption'
                            align='left'
                          >
                            {siTranslatedTextsObject.success}
                          </Typography>
                        </CngGridItem>
                      </Box>
                      <Box
                        style={{
                          display: isSuccess(elem) ? 'none' : 'inline'
                        }}
                      >
                        <CngGridItem>
                          <Typography
                            style={{ color: '#FB1805' }}
                            variant='caption'
                            align='left'
                          >
                            {siTranslatedTextsObject.fileError}
                          </Typography>
                          <Menu
                            id='long-menu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: 'auto',
                                width: 'auto'
                              }
                            }}
                          >
                            <MenuItem disabled={true}>
                              <Box ml={1}>
                                <Typography
                                  variant='inherit'
                                  style={{ fontSize: 'small' }}
                                >
                                  {elem.errorMsg}
                                </Typography>
                              </Box>
                            </MenuItem>
                          </Menu>
                        </CngGridItem>
                      </Box>
                    </TableCell>
                  )}
                  <TableCell style={{ width: '10%' }}>
                    <Box>
                      <SIDocumentDetailsActionMenu
                        id={i}
                        removeRowClick={props.removeRowClick}
                        downloadRowClick={props.downloadRowClick}
                        downloadLogClick={props.downloadLogClick}
                        hideEdit
                        hideDownload={!isSuccess(elem)}
                        hideDownloadLog={isSuccess(elem)}
                        hideDelete={props.viewOnly}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default SIUploadDetailsComponents
