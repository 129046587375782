import React, { useEffect, useContext, useState } from 'react'
import CancelButton from 'src/components/button/CancelButton.js'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import ProxyPrintContext from './ProxyPrintContext.js'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import { Grid, Card, Box, Button, Typography } from '@material-ui/core'
import useDGCargoDetailsMakeValidationSchema from 'src/views/freightbooking/newbooking/useDGCargoDetailsMakeValidationSchema'
import { components, useServices, SendRequest } from 'cng-web-lib'
import Utils from 'src/views/common/utils/Utils'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import SubmitButton from 'src/components/button/SubmitButton.js'
import { v4 as uuid } from 'uuid'
import SIApi from 'src/views/shippinginstruction/shared/api'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngSelectField, CngRadioGroupField }
  },
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function ProxyPrintForm({ showNotification }) {
  const dialogContext = useContext(ProxyPrintContext)

  const {
    closeDialog,
    form: { isSubmitting, setSubmitting },
    blForPrint,
    remainingPrint,
    setRemainingPrint,
    isPil
  } = dialogContext

  function onSubmit(data) {
    console.log('submit ')
    console.log(data)
  }

  const validationSchema = useDGCargoDetailsMakeValidationSchema()

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          blForPrint={blForPrint}
          remainingPrint={remainingPrint}
          setRemainingPrint={setRemainingPrint}
          isPil={isPil}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  setSubmitting,
  blForPrint,
  remainingPrint,
  setRemainingPrint,
  isPil
}) {
  const translatedTextsObject = ShippingInstructionTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const { setFieldValue } = useFormikContext()
  const [printerNameField, , { setValue: setPrinterNameField }] =
    useField('printerName')
  const [printOptionField, ,] = useField('printerOption')
  const { CngGridItem } = components
  const { fetchRecords } = useServices()
  const [message, setMessage] = useState()
  const [printerOptions, setPrinterOptions] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [defaultPrinter, setDefaultPrinter] = useState()
  const [printerKey, setPrinterKey] = useState(uuid())
  const [verifyString, setVerifyString] = useState()

  const send = new SendRequest()

  const genericPrintOption = [
    {
      label: translatedTextsObject.printOptionAll,
      value: 'all'
    },
    {
      label: translatedTextsObject.printOptionCurrent,
      value: 'current'
    }
  ]

  const pilPrintOptoin = [
    {
      label: translatedTextsObject.printOptionAll,
      value: 'all'
    }
  ]

  useEffect(() => {
    //start to call the proxy
    console.log('get version')
    setIsLoading(true)

    setMessage(translatedTextsObject.proxyPrintLoadingMessage)

    //get session
    const jsessionid = document.cookie
    console.log('id', document)

    send.execute(
      'GET',
      ShippingInstructionApiUrls.PROXY_PRINT_VERSION,
      {},
      (data) => {
        console.log('success')
        console.log(data)
        validationVersionNumber(data)
      },
      (error) => {
        console.log(error)
      },
      onComplete,

      {}
    )

    function onComplete() {
      console.log('complete')
    }
  }, [blForPrint])

  function populateBookingAndSIData(data) {
    console.log('si details', data)
    //setRemainingPrint(data.noOfOriginals - data.noOfOriginalsPrinted)
  }

  function validationVersionNumber(data) {
    let appVersion = data.data.appVersion
    console.log('appVersion: ' + appVersion)

    function onLoadCodeMasterSuccess(codeMasterData) {
      console.log('codeMasterData', codeMasterData)
      if (codeMasterData.length > 0) {
        let validVersion = codeMasterData[0].descriptionEn
        //tried to put verify string in a different config in amdin config, but caused some error during fetchRecord.execute. Hence will put the value in pcp version different description
        let verifyStringFromCodeMaster = codeMasterData[0].descriptionEs + codeMasterData[0].descriptionFr +  codeMasterData[0].descriptionHi
        console.log("verifyStringFromCodeMaster", verifyStringFromCodeMaster)
        setVerifyString(verifyStringFromCodeMaster)
        if (validVersion.includes(appVersion)) {
          //proceed to load printer
          queryPrinter()
        } else {
          setMessage(translatedTextsObject.proxyPrintDownLoadMessage)
        }
      }
    }
    //get validat app version from code master
    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'PCP_VERSION',
      false,
      onLoadCodeMasterSuccess
    )
  }

  function queryPrinter() {
    console.log('queryPrinter')

    send.execute(
      'POST',
      ShippingInstructionApiUrls.PROXY_PRINT_QUERY_PRINTER,
      'refresh=' +
        refresh.toString() +
        '&showUnsupported=false&pCustom=multipleimageprinting',
      (data) => {
        console.log('success')
        console.log(data)
        if (data.data.length === 0) {
          //warning of no printer

          setMessage(translatedTextsObject.proxyPrintNoPrinterMessage)
        } else {
          let pritnerList = []
          data.data.forEach((printer) => {
            console.log(printer)
            let printerInfo = {
              text: printer.printerName,
              value: printer.printerName
            }
            pritnerList.push(printerInfo)
          })
          console.log('the defaultvalue i want ' + pritnerList[0].text)

          setPrinterNameField(pritnerList[0].text)
          setIsLoading(false)
          setPrinterOptions(pritnerList)
          setPrinterKey(uuid())
        }
      },
      (error) => {
        console.log(error)
      },
      onQueryPrinterComplete,

      {}
    )

    function onQueryPrinterComplete() {
      console.log('complete')
      setSubmitting(false)
    }
  }

  function onComplete() {
    console.log('complete')
    setSubmitting(false)
  }

  function refreshPrinters() {
    setRefresh(true)
    setIsLoading(true)
    setMessage(translatedTextsObject.proxyPrintLoadingMessage)
    setSubmitting(true)
    queryPrinter()
  }

  function getFileName() {
    console.log('getFileName', blForPrint)
    let fileName = [
      blForPrint.shippingInfoId,
      blForPrint.billOfLadingAppType,
      //print original no
      printOptionField.value,
      blForPrint.freightBookingId,
      remainingPrint
    ] //doc_id + "," + bl_type + "," + printOption + "," + print_original_no + "," + booking_id;
    return fileName.toString()
  }

  function print() {
    setSubmitting(true)
    setIsLoading(true)
    setMessage(translatedTextsObject.proxyPrintJobSendingMessage)

    //first get the token based on environment (as sso will make the token store difference)
    //then proceed to print

    getTokenAndProceedToPrint()
  }

  function getTokenAndProceedToPrint() {
    function onLoadTokenSuccess(codeMasterData) {
      if (codeMasterData.length > 0) {
        let tokenKey = codeMasterData[0].descriptionEn

        let token = JSON.parse(localStorage.getItem(tokenKey))
        console.log('token', token)
       
        if (Utils.isEmpty(token.access_token)) {
          proceedToPrint(token) //without sso
        } else {
          proceedToPrint(token.access_token) //with sso
        }
      }
    }

    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'PCP_TOKEN',
      false,
      onLoadTokenSuccess
    )
  }

  function proceedToPrint(token) {
    let pFileName = getFileName()
    let pURL = ShippingInstructionApiUrls.PRINT_BL
    let printerName = printerNameField.value
    console.log("verifyString", verifyString)
    send.execute(
      'POST',
      ShippingInstructionApiUrls.PROXY_PRINT_SEND_PRINT,
      'pSessionKey=Authorization' +
        '&' +
        'pSessionValue=Bearer ' +
        token +
        '&' +
        'printerName=' +
        printerName +
        '&' +
        'pFilename=' +
        pFileName +
        '&' +
        'pURL=' +
        pURL +
        '&' +
        'pCopiesToPrint=1' +
        '&' +
        'pCustomHeader=Authorization:Bearer ' +
        token +
        '&' +
        'pCustom=multipleimageprinting' +
        "&" +
        'pVerifyString='+
        verifyString,
      (data) => {
        console.log('success', data)
        console.log(data)
        if (Utils.isNotEmpty(data.data.jobError)) {
          setMessage(data.data.jobError)
        } else {
          setMessage(translatedTextsObject.proxyPrintJobSentMessage)
          //only decresae the remaining amount when there is no job error
          if (printOptionField.value === 'all') {
            setRemainingPrint(0)
          } else {
            let newRemainingPrint = remainingPrint - 1
            setRemainingPrint(newRemainingPrint)
          }
        }
      },
      (error) => {
        console.log(error)
        setMessage('Request failed')
      },
      onComplete,

      {}
    )
  }

  return (
    <Box m={1.5}>
      <Box pt={1}>
        <Grid container xs={12} sm={12}>
          <CngGridItem xs={12} sm={12}>
            <Card>
              <Box className='view-card-header'>
                <img
                  alt=''
                  src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                ></img>
                &nbsp;{translatedTextsObject.proxyPrint}
              </Box>
              <Box>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={12} sm={12}>
                    <LabelValueVertical
                      label={translatedTextsObject.availablePrintRemaining}
                      value={remainingPrint}
                    />
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    sm={12}
                    style={{ display: isLoading ? 'inline' : 'none' }}
                  >
                    <Box m={1.5}>
                      <Grid container spacing={5}>
                        <CngGridItem xs={12} sm={12}>
                          <Typography> {message}</Typography>
                        </CngGridItem>
                      </Grid>
                    </Box>
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    sm={12}
                    style={{ display: !isLoading ? 'inline' : 'none' }}
                  >
                    <Box m={1.5}>
                      <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={12}>
                          <CngSelectField
                            name='printerName'
                            label={translatedTextsObject.printerName}
                            defaultValue={defaultPrinter}
                            items={printerOptions}
                            key={printerKey}
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={12}>
                          <Box className='view-card-header'>
                            {translatedTextsObject.printOption}
                          </Box>
                          <CngRadioGroupField
                            name='printerOption'
                            options={
                              isPil ? pilPrintOptoin : genericPrintOption
                            }
                            direction='row'
                          />
                        </CngGridItem>
                      </Grid>
                    </Box>
                  </CngGridItem>
                </Grid>
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={12}>
            <Box m={1.5}>
              <Grid container xs={12} sm={12}>
                <Grid container xs={6} sm={6} justify='flex-start'>
                  <CngSecondaryButton
                    disabled={isSubmitting || isLoading}
                    onClick={() => {
                      refreshPrinters()
                    }}
                  >
                    {translatedTextsObject.refreshPrinters}
                  </CngSecondaryButton>
                </Grid>
                <Grid container xs={6} sm={6} justify='flex-end'>
                  <Box pr={2}>
                    <CancelButton
                      onClick={closeDialog}
                      disabled={isSubmitting}
                    />
                  </Box>
                  <Box>
                    <CngPrimaryButton
                      onClick={() => {
                        print()
                      }}
                      disabled={isLoading}
                    >
                      {uiTranslatedTextsObject.print}
                    </CngPrimaryButton>
                    {/* <SubmitButton isSubmitting={isSubmitting}>
                      {uiTranslatedTextsObject.print}
                    </SubmitButton> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CngGridItem>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  printerOption: 'all'
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default ProxyPrintForm
