import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Checkbox,
  Card,
  Grid,
  CardContent
} from '@material-ui/core'
import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

const FormBody = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const [bookingTypeExpanded, setBookingTypeExpanded] = useState(false)

  const handleBookingExpand = () => {
    setBookingTypeExpanded(!bookingTypeExpanded)
  }

  const placeHolder = translatedTextsObject.freightBookingRefNo

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <CngGridItem item xs={6} sm={6}>
            <CngTextField
              type='text'
              name='carRefNo'
              label={placeHolder}
              onKeyUp={e => { if (e.keyCode == 13) { props.handelFreeTextSearch() } }}
              onChange={(e) => props.handleInputChange(e)}
            />
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <CngCodeMasterAutocompleteField
              name='shipmentType'
              label={translatedTextsObject.shipmentType}
              codeType='CAL_CONT_STATUS'
              onChange={(e) => props.handleShipmentTypeChange(e)}
            />
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <Grid
              conotainer
              spacing={0}
              direction='row'
              alignItems='center'
              justify='center'
            >
              <CngGridItem style={{ paddingLeft: 30, paddingTop: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='hazardous'
                      checked={props.hazardous}
                      onChange={(e) => props.handleHazardousChange(e)}
                    />
                  }
                  label={translatedTextsObject.hazardous}
                />
              </CngGridItem>
            </Grid>
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <CngPrimaryButton
              name='search'
              startIcon={<SearchIcon />}
              className={'search-button'}
              onClick={props.handelFreeTextSearch}
            >
              Search
            </CngPrimaryButton>
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const TopBarSearchComponent = Object.freeze({
  FormBody: FormBody
})

export default TopBarSearchComponent
