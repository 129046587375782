import { Box, Grid, Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import AddLocationOutlinedIcon from '@material-ui/icons/AddLocationOutlined'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'
import SIpathMap from 'src/paths/PathMap_ShippingInstruction'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const SIBookingListNoResult = (props) => {
  const { createSI } = props
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

  const history = useHistory()

  const goToCreateBooking = () => {
    history.push({
      pathname: pathMap.CREATE_NEW_BOOKING
    })
  }

  const goToDirectSI = () => {
    history.push({
      pathname: SIpathMap.CREATE_SI_NEW_SI,
      state: { directSI: true }
    })
  }

  return (
    <div className={'vs-noresult'}>
      <img
        src={
          process.env.PUBLIC_URL +
          '/static/images/shippingInstruction/CreateNewSI_Empty.svg'
        }
        alt='No Result'
      ></img>
      <Typography
        variant='h3'
        style={{ fontWeight: 'bold', paddingTop: '10px' }}
      >
        {siTranslatedTextsObject.noItemHere}
      </Typography>
      {createSI ? (
        <Typography
          variant='subtitle1'
          style={{ paddingTop: '10px', color: '#808080' }}
        >
          {siTranslatedTextsObject.noItemDescShipper}
        </Typography>
      ) : (
        <Typography
          variant='subtitle1'
          style={{ paddingTop: '10px', color: '#808080' }}
        >
          not set yet {/* {siTranslatedTextsObject.noItemDescCarrier} */}
        </Typography>
      )}
      {createSI ? (
        <Box pt={2}>
          <Grid container>
            <CngPrimaryButton onClick={goToDirectSI}>
              <AddLocationOutlinedIcon />
              <Box pl={1}>{siTranslatedTextsObject.createDirectSI}</Box>
            </CngPrimaryButton>
            <Box pl={2} pr={2} display='flex' alignItems='center'>
              {uiTranslatedTextsObject.or}
            </Box>
            <CngSecondaryButton onClick={goToCreateBooking}>
              <AddCircleOutlineOutlinedIcon />
              <Box pl={1}>{siTranslatedTextsObject.createBooking}</Box>
            </CngSecondaryButton>
          </Grid>
        </Box>
      ) : (
        <Box pt={2}>
          <Grid container>
            <CngSecondaryButton onClick={props.refreshAction}>
              <RefreshIcon />
              <Box pl={1}>
                not set yet {/* {siTranslatedTextsObject.refresh} */}
              </Box>
            </CngSecondaryButton>
          </Grid>
        </Box>
      )}
    </div>
  )
}

export default SIBookingListNoResult
