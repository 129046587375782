import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Typography
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import RemoveIcon from '@material-ui/icons/Clear'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'

import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import SIDocumentDetailsActionMenu from '../components/SIDocumentDetailsActionMenu'

import Paper from '@material-ui/core/Paper'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngDocumentAutocompleteField }
  },
  CngGridItem
} = components

const SIDocumentDetailsComponents = (props) => {
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { createRecord, fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()

  useEffect(() => {
    console.log('file details change 2')

    var files = props.tile
    console.log(files)
  }, [props.tile])

  const [anchorMoreActionEl, setAnchorMoreActionEl] = React.useState(null)
  const open = Boolean(anchorMoreActionEl)

  const handleMoreActionClick = (event) => {
    console.log(event)
    setAnchorMoreActionEl(event.currentTarget)
  }

  const handleMoreActionClose = () => {
    setAnchorMoreActionEl(null)
  }

  if (props.tile == null || props.tile.length <= 0) {
    return <br />
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id='table'>
          <TableBody>
            {props.tile.map((elem, i) => {
              return (
                <TableRow key={`row-${i}`}>
                  <TableCell>
                    {' '}
                    <FileCopyIcon />
                  </TableCell>
                  <TableCell>
                    <DocumentInfoDecoratorComponent
                      name={elem.filename}
                      size={elem.filesize}
                    />{' '}
                  </TableCell>

                  <TableCell>
                    <CngDocumentAutocompleteField
                      name={`shippingInfoDocuments[${i}].docType`}
                      label={siTranslatedTextsObject.fileType}
                      onChange={(val, evt) =>
                        props.handleDropdownChange(val, evt, i, 'docType')
                      }
                      codeType='CLOGS_FRB'
                      disabled={!elem.editable}
                      error={props.getFieldError(
                        i,
                        'shippingInfoDocuments.docType'
                      )}
                      helperText={props.getFieldError(
                        i,
                        'shippingInfoDocuments.docType'
                      )}
                      key={props.docTypeKeyRef}
                      isRequired
                    />
                  </TableCell>
                  <TableCell>
                    <CngTextField
                      type='text'
                      name={`shippingInfoDocuments[${i}].description`}
                      label={siTranslatedTextsObject.fileDesc}
                      onChange={(e) => props.handleInputChange(e, i)}
                      inputProps={{ maxLength: 1024 }}
                      disabled={!elem.editable}
                      rows={4}
                      error={props.getFieldError(
                        i,
                        'shippingInfoDocuments.description'
                      )}
                      helperText={props.getFieldError(
                        i,
                        'shippingInfoDocuments.description'
                      )}
                      isRequired
                    />
                  </TableCell>
                  <TableCell>
                    <SIDocumentDetailsActionMenu
                      id={i}
                      removeRowClick={props.removeRowClick}
                      editRowClick={props.editRowClick}
                      downloadRowClick={props.downloadRowClick}
                      hideDownloadLog
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default SIDocumentDetailsComponents
