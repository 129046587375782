import {

  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import RemoveIcon from '@material-ui/icons/Clear'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'

import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import DocumentDetailsActionMenu from 'src/views/freightbooking/components/DocumentDetailsActionMenu'


import Paper from '@material-ui/core/Paper';



const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngDocumentAutocompleteField

    },
  },



} = components




const DocumentDetailsComponents = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const { createRecord, fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()
  console.log(props.tile)
  useEffect(() => {
    console.log("file details change 2")

    var files = props.tile
    console.log(files)

    // const fileContent = []

    // if (files != null && files.length > 0) {

    //   files.map((file, i) => {
    //     var fileBlob = file.file
    //     console.log(fileBlob)
    //     fileContent.push(fileBlob)

    //   })

    //   console.log(fileContent)
    // }
  }, [props.tile])




  const [anchorMoreActionEl, setAnchorMoreActionEl] = React.useState(null)
  const open = Boolean(anchorMoreActionEl)

  const handleMoreActionClick = (event) => {
    console.log(event)
    setAnchorMoreActionEl(event.currentTarget)
  }

  const handleMoreActionClose = () => {
    setAnchorMoreActionEl(null)
  }


  if (props.tile == null || props.tile.length <= 0) {
    return (<br />)
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id="table">
          <TableBody>
            {props.tile.map((elem, i) => {
              return (<TableRow key={`row-${i}`}>

                <TableCell > <FileCopyIcon /></TableCell>
                <TableCell>

                  <DocumentInfoDecoratorComponent name={elem.filename} size={elem.filesize} /> </TableCell>


                <TableCell>
                  <CngDocumentAutocompleteField
                    name={`bookingDocuments[${i}].docType`}
                    label={translatedTextsObject.fileType + "*"}
                    onChange={(val, evt) => props.handleDropdownChange(val, evt, i, 'docType')}
                    codeType='CLOGS_FRB'
                    disabled={!elem.editable}
                    error={props.getFieldError(i, 'bookingDocuments.docType')}
                    helperText={props.getFieldError(i, 'bookingDocuments.docType')}
                    key={props.docTypeKeyRef}
                  />
                </TableCell>
                <TableCell>

                  <CngTextField
                    type='text'
                    name={`bookingDocuments[${i}].description`}
                    label={translatedTextsObject.fileDesc + "*"}
                    onChange={e => props.handleInputChange(e, i)}
                    disabled={!elem.editable}
                    error={props.getFieldError(i, 'bookingDocuments.description')}
                    helperText={props.getFieldError(i, 'bookingDocuments.description')}
                  />
                </TableCell>
                <TableCell>
                  <DocumentDetailsActionMenu id={i}
                    removeRowClick={props.removeRowClick}
                    editRowClick={props.editRowClick}
                    downloadRowClick={props.downloadRowClick}
                    hideDownloadLog
                  /></TableCell>
                {/* <TableCell>
                  {i}
                  <IconButton
                    edge='end'
                    className={`row-more-actions`}
                    onClick={handleMoreActionClick}
                  >
                    <MoreIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorMoreActionEl}
                    keepMounted
                    open={open}
                    onClose={handleMoreActionClose}
                    PaperProps={{
                      style: {
                        maxHeight: 'auto',
                        width: 'auto'
                      }
                    }}
                  >
                    <MenuItem key={'r'} onClick={props.removeRowClick(i)}>
                      Delete
                    </MenuItem>
                    <MenuItem key={'r'} onClick={props.editRowClick(elem)}>
                      Edit?
                    </MenuItem>
                    <MenuItem key={'r'} onClick={props.downloadRowClick(i)}>
                      Download
                    </MenuItem>

                  </Menu>


                </TableCell> */}


              </TableRow>)
            })
            }
          </TableBody >
        </Table>
      </TableContainer>

    )
  }

}

export default DocumentDetailsComponents
